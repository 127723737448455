import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NewsHeader from "../components/news/NewsHeader";
import Footer from "../components/Footer";
import NewsSectionNav from "../components/news/NewsSectionNav";
import { useNavigate, useParams } from "react-router-dom";
import { blogs } from "../utils/blog_data";

const PressBlog = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [blog, setBlog] = useState({});

  useEffect(() => {
    blogs.forEach((blog) => {
      if (blog.param === params.title) {
        setBlog(blog);
      }
    });

    if (blogs.map((blog) => blog.param).includes(params.title) === false) {
      navigate("/notfound");
    }
  }, [blogs, params.title, navigate]);

  return (
    <div>
      <Helmet>
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta charset="utf-8" />
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Immunocore Holdings plc - Recent News"
          href="https://www.immunocore.com/investors/news/press-releases/rss"
        />

        <title>{blog.title}</title>

        <link
          rel="canonical"
          href="https://www.immunocore.com/investors/news/press-releases/detail/94/immunocore-announces-randomization-of-first-patient-in-the-global-registrational-phase-3-clinical-trial-testing-brenetafusp-for-the-treatment-of-first-line-advanced-or-metastatic-cutaneous-melanoma"
        />
        <meta
          property="og:url"
          content="https://www.immunocore.com/investors/news/press-releases/detail/94/immunocore-announces-randomization-of-first-patient-in-the-global-registrational-phase-3-clinical-trial-testing-brenetafusp-for-the-treatment-of-first-line-advanced-or-metastatic-cutaneous-melanoma"
        />

        <meta property="og:site_name" content="Immunocore Holdings plc" />
        <meta property="og:title" content={blog.title} />
        <meta property="og:type" content="website" />

        <meta
          property="og:image"
          content="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2281/20579/social_image_resized.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <meta property="og:description" content={blog.title} />
        <meta name="description" content={blog.title} />

        <meta class="elastic" name="published_time" content="2024-06-18" />
        <meta class="elastic" name="published_day" content="5292.46" />

        <meta class="elastic" name="el_description" content={blog.title} />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <link rel="preconnect" href="https://d1io3yog0oux5.cloudfront.net" />
        <link rel="preconnect" href="https://qmod.quotemedia.com" />

        <link
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/css/ir.stockpr.css"
          rel="stylesheet"
          type="text/css"
          media="screen"
        />
        <link
          rel="stylesheet"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/css/style.css"
        />

        <link
          rel="shortcut icon"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon.ico"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="manifest"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/manifest.json"
        />
        <link
          rel="mask-icon"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/safari-pinned-tab.svg"
        />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.datatables.net/1.13.7/css/jquery.dataTables.min.css"
        />
      </Helmet>

      <div class="general-wrapper">
        <NewsHeader />
        <header
          class="page-banner"
          style={{
            backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2370/21091/image_resized.jpg)`,
            backgroundPosition: "50% 50%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundColor: "#333",
          }}
        >
          <div class="container">
            <div class="row">
              <div class="col-lg-10">
                <p class="heading--h1">Press Releases</p>
              </div>
            </div>
          </div>
        </header>
        <NewsSectionNav />
        <div
          dangerouslySetInnerHTML={{ __html: blog.html }}
          class="container"
        />
        <Footer />
      </div>
    </div>
  );
};

export default PressBlog;
