export const jobs = [
  {
    job: "Principal Auditor, Enterprise Quality Auditing",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Clinical Research Associate",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Fixed Term Contract",
  },
  {
    job: "Quality Control Analyst",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Staff Augmentation",
  },
  {
    job: "Regulatory Affairs Specialist",
    location: "India",
    timeType: "Full time",
    jobType: "Intern Temporary",
  },
  {
    job: "Manufacturing Engineer",
    location: "Ireland",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Pharmacovigilance Officer",
    location: "United States of America",
    timeType: "Remote",
    jobType: "Fixed Term Contract",
  },
  {
    job: "HR Business Partner",
    location: "Canada",
    timeType: "Full time",
    jobType: "Staff Augmentation",
  },
  {
    job: "Data Scientist",
    location: "India",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Sales Representative",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Intern Temporary",
  },
  {
    job: "Supply Chain Coordinator",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "IT Support Specialist",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Fixed Term Contract",
  },
  {
    job: "Marketing Manager",
    location: "India",
    timeType: "Full time",
    jobType: "Staff Augmentation",
  },
  {
    job: "Clinical Data Manager",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Quality Assurance Inspector",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Intern Temporary",
  },
  {
    job: "Regulatory Compliance Officer",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Product Development Scientist",
    location: "India",
    timeType: "Full time",
    jobType: "Fixed Term Contract",
  },
  {
    job: "Project Manager",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Staff Augmentation",
  },
  {
    job: "Financial Analyst",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Production Supervisor",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Intern Temporary",
  },
  {
    job: "Research Scientist",
    location: "India",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Training Coordinator",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Fixed Term Contract",
  },
  {
    job: "Medical Writer",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Staff Augmentation",
  },
  {
    job: "Laboratory Technician",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Customer Service Representative",
    location: "India",
    timeType: "Full time",
    jobType: "Intern Temporary",
  },
  {
    job: "Business Analyst",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Validation Engineer",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Fixed Term Contract",
  },
  {
    job: "Clinical Trials Assistant",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Staff Augmentation",
  },
  {
    job: "Digital Marketing Specialist",
    location: "India",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Pharmacist",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Intern Temporary",
  },
  {
    job: "Manufacturing Technician",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Product Manager",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Fixed Term Contract",
  },
  {
    job: "Statistician",
    location: "India",
    timeType: "Full time",
    jobType: "Staff Augmentation",
  },
  {
    job: "Regulatory Affairs Manager",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Quality Control Technician",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Intern Temporary",
  },
  {
    job: "Operations Manager",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Clinical Research Coordinator",
    location: "India",
    timeType: "Full time",
    jobType: "Fixed Term Contract",
  },
  {
    job: "Laboratory Manager",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Staff Augmentation",
  },
  {
    job: "Sales Analyst",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Supply Chain Analyst",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Intern Temporary",
  },
  {
    job: "Human Resources Specialist",
    location: "India",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Medical Affairs Manager",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Fixed Term Contract",
  },
  {
    job: "Biostatistician",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Staff Augmentation",
  },
  {
    job: "Marketing Coordinator",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Pharmacovigilance Specialist",
    location: "India",
    timeType: "Full time",
    jobType: "Intern Temporary",
  },
  {
    job: "Clinical Project Manager",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "QA Specialist",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Fixed Term Contract",
  },
  {
    job: "Product Quality Manager",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Staff Augmentation",
  },
  {
    job: "Training Specialist",
    location: "India",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Research Assistant",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Intern Temporary",
  },
  {
    job: "Operations Analyst",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "IT Systems Analyst",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Fixed Term Contract",
  },
  {
    job: "Regulatory Affairs Coordinator",
    location: "India",
    timeType: "Full time",
    jobType: "Staff Augmentation",
  },
  {
    job: "Project Coordinator",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Data Entry Specialist",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Intern Temporary",
  },
  {
    job: "Supply Chain Planner",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Clinical Study Manager",
    location: "India",
    timeType: "Full time",
    jobType: "Fixed Term Contract",
  },
  {
    job: "Manufacturing Operations Manager",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Staff Augmentation",
  },
  {
    job: "Financial Controller",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Production Planner",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Intern Temporary",
  },
  {
    job: "Research Scientist II",
    location: "India",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Validation Specialist",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Fixed Term Contract",
  },
  {
    job: "Medical Science Liaison",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Staff Augmentation",
  },
  {
    job: "Quality Control Manager",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "HR Generalist",
    location: "India",
    timeType: "Full time",
    jobType: "Intern Temporary",
  },
  {
    job: "Clinical Operations Manager",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Sales Operations Analyst",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Fixed Term Contract",
  },
  {
    job: "Regulatory Document Specialist",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Staff Augmentation",
  },
  {
    job: "Clinical Trials Manager",
    location: "India",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Product Development Manager",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Intern Temporary",
  },
  {
    job: "Quality Assurance Analyst",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Project Manager II",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Fixed Term Contract",
  },
  {
    job: "Clinical Data Specialist",
    location: "India",
    timeType: "Full time",
    jobType: "Staff Augmentation",
  },
  {
    job: "Supply Chain Coordinator II",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "IT Project Manager",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Intern Temporary",
  },
  {
    job: "Laboratory Technician II",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Quality Assurance Inspector II",
    location: "India",
    timeType: "Full time",
    jobType: "Fixed Term Contract",
  },
  {
    job: "Operations Coordinator",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Staff Augmentation",
  },
  {
    job: "Clinical Research Nurse",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Regulatory Affairs Associate",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Intern Temporary",
  },
  {
    job: "Marketing Assistant",
    location: "India",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Clinical Research Scientist",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Fixed Term Contract",
  },
  {
    job: "Quality Control Associate",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Staff Augmentation",
  },
  {
    job: "Financial Planning Analyst",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Research Technician",
    location: "India",
    timeType: "Full time",
    jobType: "Intern Temporary",
  },
  {
    job: "Project Engineer",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Training Manager",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Fixed Term Contract",
  },
  {
    job: "Clinical Quality Manager",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Staff Augmentation",
  },
  {
    job: "Product Marketing Manager",
    location: "India",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Clinical Study Coordinator",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Intern Temporary",
  },
  {
    job: "Regulatory Affairs Director",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Quality Assurance Manager",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Fixed Term Contract",
  },
  {
    job: "Clinical Data Analyst",
    location: "India",
    timeType: "Full time",
    jobType: "Staff Augmentation",
  },
  {
    job: "Laboratory Director",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Supply Chain Director",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Intern Temporary",
  },
  {
    job: "Project Director",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Clinical Research Director",
    location: "India",
    timeType: "Full time",
    jobType: "Fixed Term Contract",
  },
  {
    job: "Marketing Director",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Staff Augmentation",
  },
  {
    job: "Regulatory Compliance Manager",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Clinical Trials Director",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Intern Temporary",
  },
  {
    job: "Quality Control Director",
    location: "India",
    timeType: "Full time",
    jobType: "Regular",
  },
  {
    job: "Training Director",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Fixed Term Contract",
  },
  {
    job: "Product Development Director",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Staff Augmentation",
  },
  {
    job: "Operations Director",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "Research Director",
    location: "India",
    timeType: "Full time",
    jobType: "Intern Temporary",
  },
  {
    job: "Supply Chain Manager",
    location: "Ireland",
    timeType: "Remote",
    jobType: "Full time, Regular",
  },
  {
    job: "IT Director",
    location: "United States of America",
    timeType: "Full time",
    jobType: "Fixed Term Contract",
  },
  {
    job: "Clinical Quality Director",
    location: "Canada",
    timeType: "Remote",
    jobType: "Full time, Staff Augmentation",
  },
  {
    job: "Financial Director",
    location: "India",
    timeType: "Full time",
    jobType: "Regular",
  },
];
