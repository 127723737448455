import React, { useState } from "react";

const EmailMain = ({ notify, successNotify }) => {
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactType: "",
  });

  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!formState.firstName || !formState.lastName || !formState.email) {
      notify("Please fill all the fields");
      return;
    } else {
      fetch("https://formspree.io/f/mrbzbnab", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          purpose: "Company Press Releases Via Email",
          name: `${formState.firstName} ${formState.lastName}`,
          email: formState.email,
          contactType: formState.contactType,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            setFormState({
              ...formState,
              firstName: "",
              lastName: "",
              email: "",
              contactType: "",
            });
            successNotify("Message sent!!!");
          }
        })
        .catch(() => notify("Failed to send message."));
    }
  };

  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <aside id="tertiaryNav">
            <nav id="tertiary-navigation">
              <ul>
                <li class="sidebar-title">
                  <h2>News</h2>
                </li>
                <li class="">
                  <a href="/investors/news/press-releases" target="_self">
                    Press Releases
                  </a>
                </li>
                <li class="active active-landing">
                  <a href="/investors/news/email-alerts" target="_self">
                    Email Alerts
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <main id="mainContent" class="main-content has-tertiary-nav">
            <p>
              Please fill out the form below to receive company press releases
              via email when they occur.
            </p>
            <noscript>
              <div class="alert alert-warning">
                <strong>We're sorry</strong>, but this form has spam protection
                that requires JavaScript to be enabled to work correctly. If
                you'd like to contact us via this form, please enable scripts in
                your browser and reload the page.
              </div>
            </noscript>
            <div data-name="hcaptcha">
              <form class="email-alerts-form email-alerts validate-form">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first_name">First Name:</label>
                      <input
                        autocomplete="given-name"
                        type="text"
                        id="first_name"
                        name="firstName"
                        value={formState.firstName}
                        onChange={changeHandler}
                        class="required form-control"
                        minlength="2"
                        maxlength="40"
                      />
                    </div>
                    <div class="form-group">
                      <label for="last_name">Last Name:</label>
                      <input
                        autocomplete="family-name"
                        type="text"
                        name="lastName"
                        value={formState.lastName}
                        onChange={changeHandler}
                        id="last_name"
                        class="required form-control"
                        minlength="2"
                        maxlength="40"
                      />
                    </div>
                    <div class="form-group">
                      <label for="email">Email:</label>
                      <input
                        autocomplete="email"
                        type="email"
                        name="email"
                        value={formState.email}
                        onChange={changeHandler}
                        id="email"
                        maxlength="40"
                        class="required form-control"
                      />
                    </div>
                    <div class="form-group">
                      <label for="type_id">Contact Type:</label>
                      <select
                        name="contactType"
                        id="type_id"
                        class="form-control eq-select"
                        onChange={changeHandler}
                        value={formState.contactType}
                      >
                        <option value="Individual Investor">
                          Individual Investor
                        </option>
                        <option value="Analyst">Analyst</option>
                        <option value="Broker/Investment Advisor">
                          Broker/Investment Advisor
                        </option>
                        <option value="Portfolio Manager">
                          Portfolio Manager
                        </option>
                        <option value="Banker/Financing Source">
                          Banker/Financing Source
                        </option>
                        <option value="Press/Media">Press/Media</option>
                        <option value="Cousultant">Consultant</option>
                        <option value="Employee">Employee</option>
                        <option value="Customer/Client">Customer/Client</option>
                        <option value="Student">Student</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row email-alerts-notifications-row">
                  <div class="col-md-12">
                    <fieldset class="column">
                      <div class="padding">
                        <h2 class="heading--h4">Choose Notifications</h2>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <h3 class="heading--h5">Company Information</h3>
                              <div class="eq-checkbox">
                                <input
                                  checked="checked"
                                  type="checkbox"
                                  value="1"
                                  name="ir-updates"
                                  id="all-press-releases"
                                />
                                <label for="all-press-releases">
                                  All Press Releases
                                </label>
                              </div>
                              <div class="eq-checkbox">
                                <input
                                  type="checkbox"
                                  value="1"
                                  name="event_alert"
                                  id="event_alert"
                                />
                                <label for="event_alert">Events</label>
                              </div>
                              <div class="eq-checkbox">
                                <input
                                  type="checkbox"
                                  value="1"
                                  name="stock_alert_end_of_day"
                                  id="stock_alert_end_of_day"
                                />
                                <label for="stock_alert_end_of_day">
                                  End of Day Stock Quote
                                </label>
                              </div>
                              <div class="eq-checkbox">
                                <input
                                  type="checkbox"
                                  value="1"
                                  name="weekly_summary_alert"
                                  id="weekly_summary_alert"
                                />
                                <label for="weekly_summary_alert">
                                  Weekly Summary Report
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6 sec-filings-col">
                            <h3 class="heading--h5">SEC Filings</h3>
                            <fieldset>
                              <div class="eq-checkbox">
                                <input
                                  type="checkbox"
                                  value="1"
                                  name="sec_alert_all"
                                  id="ir-sec-all"
                                />
                                <label for="ir-sec-all">All SEC Filings</label>
                              </div>
                              <div class="eq-checkbox">
                                <input
                                  type="checkbox"
                                  value="1"
                                  name="sec_alert_annual_quarterly"
                                  class="ir-sec-sub"
                                  id="sec-annual-reports"
                                />
                                <label for="sec-annual-reports">
                                  Annual &#38; Quarterly Reports
                                </label>
                              </div>
                              <div class="eq-checkbox">
                                <input
                                  type="checkbox"
                                  value="1"
                                  name="sec_alert_current"
                                  class="ir-sec-sub"
                                  id="sec-current-reports"
                                />
                                <label for="sec-current-reports">
                                  Current Reports
                                </label>
                              </div>
                              <div class="eq-checkbox">
                                <input
                                  type="checkbox"
                                  value="1"
                                  name="sec_alert_insider"
                                  class="ir-sec-sub"
                                  id="sec-insider-transactions"
                                />
                                <label for="sec-insider-transactions">
                                  Insider Transactions
                                </label>
                              </div>
                              <div class="eq-checkbox">
                                <input
                                  type="checkbox"
                                  value="1"
                                  name="sec_alert_proxy"
                                  class="ir-sec-sub"
                                  id="sec-proxy-information"
                                />
                                <label for="sec-proxy-information">
                                  Proxy Information
                                </label>
                              </div>
                            </fieldset>
                            <div class="eq-checkbox">
                              <input
                                type="checkbox"
                                value="1"
                                name="sec_alert_end_of_day"
                                id="sec_alert_end_of_day"
                              />
                              <label for="sec_alert_end_of_day">
                                End of Day SEC Filings
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div id="captcha-email-alerts" class="h-captcha"></div>
                    <div class="disclaimer">
                      This site is protected by hCaptcha and its{" "}
                      <a
                        href="https://hcaptcha.com/privacy"
                        target="_blank"
                        rel="noopener"
                      >
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://hcaptcha.com/terms"
                        target="_blank"
                        rel="noopener"
                      >
                        {" "}
                        Terms of Service{" "}
                      </a>
                      apply.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="field">
                      <input
                        autocomplete="off"
                        type="hidden"
                        name="a"
                        value=""
                      />
                      <br />
                      <button onClick={submitHandler} class="btn spr_ir_submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <p>
                We respect your privacy. We do not sell, rent, or loan any
                information to any third party. Any information you give us is
                held with the utmost care and security, and will be used only in
                ways to which you have consented.
              </p>
            </div>

            <script
              type="text/plain"
              id="hcaptcha"
              data-type="application/javascript"
              data-name="hcaptcha"
              data-src="https://hcaptcha.com/1/secure-api.js?onload=hCaptchaOnLoad&amp;render=explicit&amp;host=www.immunocore.com"
              async
            ></script>
          </main>
        </div>
      </div>
    </div>
  );
};

export default EmailMain;
