export const blogs = [
  {
    param:
      "immunocore-announces-randomization-of-first-patient-in-the-global-registrational-phase-3-clinical-trial-testing-brenetafusp-for-the-treatment-of-first-line-advanced-or-metastatic-cutaneous-melanoma",
    title: `Immunocore announces randomization of first patient in the global,
      registrational Phase 3 clinical trial testing brenetafusp for the
      treatment of first-line advanced or metastatic cutaneous melanoma ::
      Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore announces randomization of first patient in the
                  global, registrational Phase 3 clinical trial testing
                  brenetafusp for the treatment of first-line advanced or
                  metastatic cutaneous melanoma
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2024-06-18T07:00:00" class="date">
                    June 18, 2024 7:00am EDT
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-06-18_Immunocore_announces_randomization_of_first_94.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore announces randomization of first patient in the global, registrational Phase 3 clinical trial testing brenetafusp for the treatment of first-line advanced or metastatic cutaneous melanoma"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <p align="center">
                  Immunocore announces randomization of first patient in the
                  global, registrational Phase 3 clinical trial testing
                  brenetafusp for the treatment of first-line advanced or
                  metastatic cutaneous melanoma
                </p>
                <p align="center">
                  <strong
                    ><em
                      >The Phase 3 PRISM-MEL-301 trial will study the efficacy
                      and safety of brenetafusp (IMC-F106C; PRAME-A02) in
                      combination with nivolumab versus nivolumab or nivolumab +
                      relatlimab in first-line advanced or metastatic cutaneous
                      melanoma</em
                    ></strong
                  >
                </p>
                <p align="center">
                  <strong
                    ><em
                      >First registrational Phase 3 trial investigating
                      PRAME-targeted therapy</em
                    ></strong
                  >
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, 18 June 2024)
                  <a
                    href="https://www.globenewswire.com/Tracker?data=6SYStD4kbYaaR0XkvB2zxGj0hR9AJK3kCpCgeeUX1iFYk2KVTP0HXA2kd9ymG4oYRwxbzfTMeBi3EMAdQ6B9VSnYL4LBoPBF2W6Q2AmbkZy8BlCEsi1rOGNjpPEwoZZWJhRSe-nqZpM6GArK06wkyPOs22D21eDwbZezvOWFwurS_ml4ZHXUqAsKrUSP4wZZ"
                    rel="nofollow"
                    target="_blank"
                    >Immunocore Holdings plc (Nasdaq: IMCR) (“Immunocore” or the
                    “Company”)</a
                  >, a commercial-stage biotechnology company pioneering and
                  delivering transformative immunomodulating medicines to
                  radically improve outcomes for patients with cancer,
                  infectious diseases and autoimmune diseases, today announces
                  randomization of the first patient in the PRISM-MEL-301 trial,
                  assessing the efficacy and safety of brenetafusp (IMC-F106C;
                  PRAME-A02), in combination with nivolumab, in first-line
                  advanced or metastatic cutaneous melanoma.
                </p>
                <p align="justify">
                  “We are very proud to have started the registrational program
                  for brenetafusp, our PRAME candidate, supported by the recent
                  promising brenetafusp monotherapy data in late-line cutaneous
                  melanoma” said
                  <strong
                    >Mohammed Dar, Senior Vice President, Clinical Development,
                    and Chief Medical Officer, Immunocore</strong
                  >. “The PRISM-MEL-301 trial – the first Phase 3 trial for any
                  PRAME-targeted therapy – will test whether combining
                  brenetafusp with nivolumab may be a more effective treatment
                  option than current standards of care for newly diagnosed
                  metastatic or advanced cutaneous melanoma patients.”
                </p>
                <p align="justify">
                  The Phase 3 trial (NCT06112314) will randomize HLA-A*02:01
                  positive patients with first-line, advanced or metastatic
                  cutaneous melanoma to brenetafusp + nivolumab versus a control
                  arm of either nivolumab or nivolumab + relatlimab, depending
                  on country. Bristol Myers Squibb will provide nivolumab.
                </p>
                <p align="justify">
                  Professor Georgina Long,
                  <strong
                    >Co-Medical Director of Melanoma Institute Australia</strong
                  >, said: “The PRISM-MEL-301 Phase 3 trial is a great example
                  of outside-the-box scientific thinking, leveraging the immune
                  system in a new way in the hope of beating cancer. My hope is
                  that we can get closer to our goal of zero deaths from
                  melanoma by conducting clinical trials with innovative drug
                  therapies such as this.”<br />
                  <br />The Company has shared the cutaneous melanoma Phase 1
                  data during an oral presentation at the 2024 American Society
                  of Oncology (ASCO) Annual Meeting on 31 May. The data showed
                  that brenetafusp was well tolerated as monotherapy and in
                  combination with anti-PD1, and demonstrated promising
                  monotherapy clinical activity, including disease control rate
                  (partial response and stable disease), progression free
                  survival, and circulating tumor DNA molecular response.
                </p>
                <p align="justify">
                  Brenetafusp is the first PRAME x CD3 ImmTAC bispecific protein
                  targeting an HLA-A*02:01 PRAME (PReferentially expressed
                  Antigen in Melanoma) antigen. The Company is continuing to
                  enroll patients into a Phase 1/2 trial in monotherapy and
                  combination arms across multiple tumor types, including three
                  expansion arms for patients with advanced ovarian, non-small
                  cell lung, and endometrial cancers.
                </p>
                <p align="justify">
                  <strong>About ImmTAC</strong><sup><strong>®</strong></sup
                  ><strong> molecules for cancer </strong>
                </p>
                <p align="justify">
                  Immunocore’s proprietary T cell receptor (TCR) technology
                  generates a novel class of bispecific biologics called ImmTAC
                  (Immune mobilizing monoclonal TCRs Against Cancer) molecules
                  that are designed to redirect the immune system to recognize
                  and kill cancerous cells. ImmTAC molecules are soluble TCRs
                  engineered to recognize intracellular cancer antigens with
                  ultra-high affinity and selectively kill these cancer cells
                  via an anti-CD3 immune-activating effector function. Based on
                  the demonstrated mechanism of T cell infiltration into human
                  tumors, the ImmTAC mechanism of action holds the potential to
                  treat hematologic and solid tumors, regardless of mutational
                  burden or immune infiltration, including immune “cold” low
                  mutation rate tumors.
                </p>
                <p align="justify">
                  <strong
                    >About PRISM-MEL-301 – Phase 3 trial with brenetafusp
                    (IMC-F106C, PRAME-A02) in 1L advanced cutaneous melanoma
                  </strong>
                </p>
                <p align="justify">
                  The Phase 3 registrational trial will randomize
                  HLA-A*02:01-positive patients with previously untreated
                  advanced melanoma to brenetafusp + nivolumab versus nivolumab
                  or nivolumab + relatlimab, depending on the country where the
                  patient is enrolled. The trial will initially randomize to
                  three arms: two brenetafusp dose regimens (40 mcg and 160 mcg)
                  and control arm and will discontinue one of the brenetafusp
                  dose regimens after an initial review of the first 60 patients
                  randomized to the two experimental arms (90 patients
                  randomized total). The primary endpoint of the trial is
                  progression free survival (PFS) by blinded independent central
                  review (BICR), with secondary endpoints of overall survival
                  (OS) and overall response rate (ORR).
                </p>
                <p align="justify">
                  <strong>About the IMC-F106C-101 Phase 1/2 trial </strong>
                </p>
                <p align="justify">
                  IMC-F106C-101 is a first-in-human, Phase 1/2 dose escalation
                  trial in patients with multiple solid tumor cancers including
                  non-small cell lung cancer (NSCLC), small-cell lung cancer
                  (SCLC), endometrial, ovarian, cutaneous melanoma, and breast
                  cancers. The Phase 1 dose escalation trial was designed to
                  determine the maximum tolerated dose (MTD), as well as to
                  evaluate the safety, preliminary anti-tumor activity and
                  pharmacokinetics of IMC-F106C (brenetafusp), a bispecific
                  protein built on Immunocore’s ImmTAC technology, and the
                  Company’s first molecule to target the PRAME antigen. The
                  Company has initiated patient enrollment into four expansion
                  arms in cutaneous melanoma, ovarian, NSCLC, and endometrial
                  carcinomas. The IMC-F106C-101 trial is adaptive and includes
                  the option for Phase 2 expansion, allowing for approximately
                  100 patients treated per tumor type in the Phase 1 and 2
                  expansion arms. Ph1 monotherapy continues in additional solid
                  tumors as well as multiple combinations with
                  standards-of-care, including checkpoint inhibitors,
                  chemotherapy, targeted therapies, and tebentafusp.
                </p>
                <p align="justify"><strong>About Cutaneous Melanoma</strong></p>
                <p align="justify">
                  Cutaneous melanoma (CM) is the most common form of melanoma.
                  It is the most aggressive skin carcinoma and is associated
                  with the vast majority of skin cancer-related mortality. The
                  majority of patients with CM are diagnosed before metastasis
                  but survival remains poor for the large proportion of patients
                  with metastatic disease. Despite recent progress in advanced
                  melanoma therapy, there is still an unmet need for new
                  therapies that improve first-line response rates and duration
                  of response as well as for patients who are refractory to
                  first-line treatments.
                </p>
                <p align="justify"><strong>About Immunocore</strong></p>
                <p align="justify">
                  Immunocore is a commercial-stage biotechnology company
                  pioneering the development of a novel class of TCR bispecific
                  immunotherapies called ImmTAX – Immune mobilizing monoclonal
                  TCRs Against X disease – designed to treat a broad range of
                  diseases, including cancer, autoimmune, and infectious
                  disease. Leveraging its proprietary, flexible, off-the-shelf
                  ImmTAX platform, Immunocore is developing a deep pipeline in
                  multiple therapeutic areas, including nine active clinical and
                  pre-clinical programs​ in oncology, infectious diseases, and
                  autoimmune diseases. The Company’s most advanced oncology TCR
                  therapeutic, KIMMTRAK has been approved for the treatment of
                  HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p align="justify">
                  <strong>Forward Looking Statements </strong>
                </p>
                <p align="justify">
                  This press release contains “forward-looking statements”
                  within the meaning of the safe harbor provisions of the
                  Private Securities Litigation Reform Act of 1995. Words such
                  as “may,” “will,” “believe,” “expect,” “plan,” “anticipate,”
                  “estimate,” and similar expressions (as well as other words or
                  expressions referencing future events or circumstances) are
                  intended to identify forward-looking statements. All
                  statements, other than statements of historical facts,
                  included in this press release are forward-looking statements.
                  These statements include, but are not limited to, statements
                  regarding the expected clinical benefits of brenetafusp and
                  the Company’s expectations regarding the design, progress,
                  randomization and scope of the Phase 3 PRISM-MEL301 trial with
                  brenetafusp plus nivolumab versus standard nivolumab in 1L
                  advanced cutaneous melanoma and the IMC-F106C-101 Phase 1/2
                  dose escalation trial with brenetafusp in patients with
                  multiple solid tumor cancers including non-small cell lung
                  cancer, small-cell lung cancer, endometrial, ovarian,
                  cutaneous melanoma, and breast cancers. Any forward-looking
                  statements are based on management’s current expectations and
                  beliefs of future events and are subject to a number of risks
                  and uncertainties that could cause actual events or results to
                  differ materially and adversely from those set forth in or
                  implied by such forward-looking statements, many of which are
                  beyond the Company’s control. These risks and uncertainties
                  include, but are not limited to, the impact of worsening
                  macroeconomic conditions on the Company’s business, financial
                  position, strategy and anticipated milestones, including
                  Immunocore’s ability to conduct ongoing and planned clinical
                  trials; Immunocore’s ability to obtain a clinical supply of
                  current or future product candidates or commercial supply of
                  KIMMTRAK or any future approved products, including as a
                  result of health epidemics or pandemics, war in Ukraine, the
                  conflict between Hamas and Israel, the broader risk of a
                  regional conflict in the Middle East, or global geopolitical
                  tension; Immunocore’s ability to obtain and maintain
                  regulatory approval of its product candidates, including
                  KIMMTRAK; Immunocore’s ability and plans in continuing to
                  establish and expand a commercial infrastructure and to
                  successfully launch, market and sell KIMMTRAK and any future
                  approved products; Immunocore’s ability to successfully expand
                  the approved indications for KIMMTRAK or obtain marketing
                  approval for KIMMTRAK in additional geographies in the future;
                  the delay of any current or planned clinical trials, whether
                  due to patient enrollment delays or otherwise; Immunocore’s
                  ability to successfully demonstrate the safety and efficacy of
                  its product candidates and gain approval of its product
                  candidates on a timely basis, if at all; competition with
                  respect to market opportunities; unexpected safety or efficacy
                  data observed during preclinical studies or clinical trials;
                  actions of regulatory agencies, which may affect the
                  initiation, timing and progress of clinical trials or future
                  regulatory approval; Immunocore’s need for and ability to
                  obtain additional funding, on favorable terms or at all,
                  including as a result of worsening macroeconomic conditions,
                  including inflation, interest rates and unfavorable general
                  market conditions, and the impacts thereon of the war in
                  Ukraine, the conflict between Hamas and Israel, and global
                  geopolitical tension; Immunocore’s ability to obtain, maintain
                  and enforce intellectual property protection for KIMMTRAK or
                  any of its product candidates it or its collaborators are
                  developing; and the success of Immunocore’s current and future
                  collaborations, partnerships or licensing arrangements. These
                  and other risks and uncertainties are described in greater
                  detail in the section titled "Risk Factors" in Immunocore’s
                  filings with the Securities and Exchange Commission, including
                  Immunocore’s most recent Annual Report on Form 10-K for the
                  year ended December 31, 2023 filed with the Securities and
                  Exchange Commission on February 28, 2024, as well as
                  discussions of potential risks, uncertainties, and other
                  important factors in the Company’s subsequent filings with the
                  Securities and Exchange Commission. All information in this
                  press release is as of the date of the release, and the
                  Company undertakes no duty to update this information, except
                  as required by law.
                </p>
                <p align="justify"><strong>Contact Information</strong></p>
                <p align="justify"><strong>Immunocore </strong></p>
                <p align="justify">
                  Sébastien Desprez, Head of Communications<br />T: +44 (0)
                  7458030732<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  ><br />Follow on Twitter: @Immunocore
                </p>
                <p align="justify"><strong>Investor Relations</strong></p>
                <p align="justify">
                  Clayton Robertson, Head of Investor Relations<br />T: +1 (215)
                  384-4781<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  >
                </p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDk2NjcwNSM0MDE4NzE3ODQjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/MTgyNzE1ZjUtYjI3YS00ZDYzLTllM2UtZWYwMjlmN2YzZWM5LTEyMTc4MTE=/tiny/Immunocore-Holdings-plc.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings plc
                <p class="spr-ir-news-article-date">Released June 18, 2024</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param:
      "immunocore-presents-kimmtrak-clinical-data-demonstrating-that-patients-with-stable-disease-and-confirmed-tumor-reduction-have-similar-clinical-outcomes-to-patients-with-partial-response",
    title: `Immunocore presents KIMMTRAK clinical data demonstrating that patients
      with stable disease and confirmed tumor reduction have similar clinical
      outcomes to patients with partial response :: Immunocore Holdings plc
      (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore presents KIMMTRAK clinical data demonstrating that
                  patients with stable disease and confirmed tumor reduction
                  have similar clinical outcomes to patients with partial
                  response
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2024-06-01T08:00:00" class="date">
                    June 01, 2024 8:00am EDT
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-06-01_Immunocore_presents_KIMMTRAK_clinical_data_93.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore presents KIMMTRAK clinical data demonstrating that patients with stable disease and confirmed tumor reduction have similar clinical outcomes to patients with partial response"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <p align="center">
                  Immunocore presents KIMMTRAK clinical data demonstrating that
                  patients with stable disease and confirmed tumor reduction
                  have similar clinical outcomes to patients with partial
                  response
                </p>
                <p align="center">
                  <strong
                    ><em
                      >Data were presented at ASCO 2024, where Immunocore also
                      presented Phase 1 data from its PRAME trial with
                      brenetafusp (IMC-F106C) in advanced cutaneous melanoma</em
                    ></strong
                  >
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, PA &amp; ROCKVILLE,
                  MD, US, June 1, 2024)
                  <a
                    href="https://www.globenewswire.com/Tracker?data=6dLIR35AKwzMxL3eOccpbpZTbyilGSQ9PBs03p-YLSQVY3xS_9GUA1NHCgjUF5Wlr8BklDg92JfFTItBfcMs9sR4j-ItDbwuu7BaRCcEEaLAzXIGvbA4PERibLXIpkT2JiA2dEPRWeRgUHmrg3hgIFxLpwo44SijuP1p8mNdFjCqhESQJejuck-NicLC7wTB"
                    rel="nofollow"
                    target="_blank"
                    >Immunocore Holdings plc (Nasdaq: IMCR) (“Immunocore” or the
                    “Company”)</a
                  >, a commercial-stage biotechnology company pioneering and
                  delivering transformative immunomodulating medicines to
                  radically improve outcomes for patients with cancer,
                  infectious diseases and autoimmune diseases, today presented
                  three posters about KIMMTRAK<sup>®</sup> (tebentafusp-tebn)
                  for the treatment of patients with unresectable or metastatic
                  uveal melanoma (mUM) at the 2024 ASCO (American Society for
                  Clinical Oncology) Annual Meeting. These data showed that
                  treatment benefit for patients with stable disease and any
                  confirmed tumor reduction was similar to patients with partial
                  response.
                </p>
                <p align="justify">
                  “In both Phase 2 and Phase 3 KIMMTRAK trials, patients with
                  stable disease and durable tumor reduction, regardless of
                  depth, had similar benefit as patients with RECIST partial
                  response,” said
                  <strong
                    >Mohammed Dar, Senior Vice President, Clinical Development,
                    and Chief Medical Officer, Immunocore</strong
                  >. “The data presented at ASCO builds upon the mounting
                  evidence confirming that disease control is the best early
                  radiographic measure of clinical benefit across our ImmTAC
                  platform.”
                </p>
                <p align="justify">
                  “KIMMTRAK is now the standard of care, in launched countries,
                  for HLA-A*02:01-positive patients with metastatic or
                  unresectable uveal melanoma,” said
                  <strong
                    >Ralph Torbay, Immunocore’s Chief Commercial Officer</strong
                  >. “Physicians can now leverage these data, presented at ASCO
                  today, to positively inform their conversations with patients
                  who have stable disease and minor reductions in tumor size.”
                </p>
                <p align="justify">
                  Of the 127 patients treated with KIMMTRAK in the Phase 2 trial
                  (IMCgp100-102), 25% (32/127) had any tumor reduction that was
                  confirmed in at least one subsequent scan, including 6 partial
                  responses (PR), an overall response rate of 5%, and 20%
                  (26/127) stable disease (SD). The clinical outcomes in the 26
                  patients with SD were similar to the 6 PR patients, including
                  durable duration of tumor reduction or response, ctDNA
                  molecular response, and overall survival. In the Phase 3 trial
                  (IMCgp100-202), KIMMTRAK-treated patients with SD who had any
                  confirmed tumor reduction had durability of tumor reduction of
                  11 months, which was the same as the durability of response
                  for patients with RECIST PR or CR.
                </p>
                <p align="justify"><strong>Full poster details:</strong></p>
                <p align="justify">
                  <em
                    >Stable disease with confirmed tumor reduction has a similar
                    clinical outcome as RECIST partial response for tebentafusp
                    in metastatic uveal melanoma</em
                  ><br />Presenting author: Alexandra Ikeguchi
                </p>
                <p align="justify">
                  <em
                    >Association between clinical and disease characteristics
                    and detectable or undetectable baseline ctDNA in patients
                    with metastatic uveal melanoma</em
                  ><br />Presenting author: Paul Nathan
                </p>
                <p align="justify">
                  <em
                    >Baseline and serial ctDNA dynamics predicts outcomes in
                    patients treated with first-line tebentafusp including those
                    who were and were not treated beyond progression</em
                  ><br />Presenting author: Ryan Sullivan
                </p>
                <p align="center">###</p>
                <p align="justify"><strong>About Immunocore</strong></p>
                <p align="justify">
                  Immunocore is a commercial-stage biotechnology company
                  pioneering the development of a novel class of TCR bispecific
                  immunotherapies called ImmTAX – Immune mobilizing monoclonal
                  TCRs Against X disease – designed to treat a broad range of
                  diseases, including cancer, autoimmune, and infectious
                  disease. Leveraging its proprietary, flexible, off-the-shelf
                  ImmTAX platform, Immunocore is developing a deep pipeline in
                  multiple therapeutic areas, including nine active clinical and
                  pre-clinical programs​ in oncology, infectious diseases, and
                  autoimmune diseases. The Company’s most advanced oncology TCR
                  therapeutic, KIMMTRAK has been approved for the treatment of
                  HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p align="justify">
                  <strong>About KIMMTRAK<sup>®</sup></strong>
                </p>
                <p align="justify">
                  KIMMTRAK is a novel bispecific protein comprised of a soluble
                  T cell receptor fused to an anti-CD3 immune-effector function.
                  KIMMTRAK specifically targets gp100, a lineage antigen
                  expressed in melanocytes and melanoma. This is the first
                  molecule developed using Immunocore’s ImmTAC technology
                  platform designed to redirect and activate T cells to
                  recognize and kill tumor cells. KIMMTRAK has been approved for
                  the treatment of HLA-A*02:01-positive adult patients with
                  unresectable or metastatic uveal melanoma in the United
                  States, European Union, Canada, Australia, and the United
                  Kingdom.
                </p>
                <p align="justify">
                  <strong>About Phase 2 IMCgp100-102 Trial</strong>
                </p>
                <p align="justify">
                  IMCgp100-102 (NCT02570308) was an open-label, multi-center,
                  single-arm trial of the safety and efficacy of tebentafusp in
                  patients with previously treated mUM. The trial included 127
                  HLA-A*02:01+ 2L+ mUM patients, treated with tebentafusp at the
                  recommended Phase 2 dose of 68mcg following intra-patient dose
                  escalation of 20 mcg (week 1) and 30 mcg (week 2). The primary
                  endpoint was objective response rate by blinded independent
                  central review, with secondary objectives being overall
                  survival (OS) and safety in 127 patients who had enrolled
                  after progressing on one or more prior therapies.
                </p>
                <p align="justify">
                  <strong>About Phase 3 IMCgp100-202 Trial</strong>
                </p>
                <p align="justify">
                  IMCgp100-202 (NCT03070392) is a randomized pivotal trial that
                  evaluated overall survival (OS) of KIMMTRAK compared to
                  investigator’s choice (either pembrolizumab, ipilimumab, or
                  dacarbazine) in HLA-A*02:01-positive adult patients with
                  previously untreated mUM. KIMMTRAK demonstrated an
                  unprecedented OS benefit with a Hazard Ratio (HR) in the
                  intent-to-treat population favoring KIMMTRAK, HR=0.51 (95% CI:
                  0.37, 0.71); p&lt; 0.0001, over investigator’s choice (82%
                  pembrolizumab; 13% ipilimumab; 6% dacarbazine).
                </p>
                <p align="justify">
                  <strong>IMPORTANT SAFETY INFORMATION</strong>
                </p>
                <p align="justify">
                  <strong
                    >Cytokine Release Syndrome (CRS), which may be serious or
                    life-threatening, occurred in patients receiving KIMMTRAK.
                    Monitor for at least 16 hours following first three
                    infusions and then as clinically indicated.</strong
                  >
                  Manifestations of CRS may include fever, hypotension, hypoxia,
                  chills, nausea, vomiting, rash, elevated transaminases,
                  fatigue, and headache. CRS occurred in 89% of patients who
                  received KIMMTRAK with 0.8% being grade 3 or 4. Ensure
                  immediate access to medications and resuscitative equipment to
                  manage CRS. Ensure patients are euvolemic prior to initiating
                  the infusions. Closely monitor patients for signs or symptoms
                  of CRS following infusions of KIMMTRAK. Monitor fluid status,
                  vital signs, and oxygenation level and provide appropriate
                  therapy. Withhold or discontinue KIMMTRAK depending on
                  persistence and severity of CRS.
                </p>
                <p align="justify"><strong>Skin Reactions</strong></p>
                <p align="justify">
                  Skin reactions, including rash, pruritus, and cutaneous edema
                  occurred in 91% of patients treated with KIMMTRAK. Monitor
                  patients for skin reactions. If skin reactions occur, treat
                  with antihistamine and topical or systemic steroids based on
                  persistence and severity of symptoms. Withhold or permanently
                  discontinue KIMMTRAK depending on the severity of skin
                  reactions.
                </p>
                <p align="justify"><strong>Elevated Liver Enzymes</strong></p>
                <p align="justify">
                  Elevations in liver enzymes occurred in 65% of patients
                  treated with KIMMTRAK. Monitor alanine aminotransferase (ALT),
                  aspartate aminotransferase (AST), and total blood bilirubin
                  prior to the start of and during treatment with KIMMTRAK.
                  Withhold KIMMTRAK according to severity.
                </p>
                <p align="justify"><strong>Embryo-Fetal Toxicity</strong></p>
                <p align="justify">
                  KIMMTRAK may cause fetal harm. Advise pregnant patients of
                  potential risk to the fetus and patients of reproductive
                  potential to use effective contraception during treatment with
                  KIMMTRAK and 1 week after the last dose.
                </p>
                <p align="justify">
                  The most common adverse reactions (≥30%) in patients who
                  received KIMMTRAK were cytokine release syndrome, rash,
                  pyrexia, pruritus, fatigue, nausea, chills, abdominal pain,
                  edema, hypotension, dry skin, headache, and vomiting. The most
                  common (≥50%) laboratory abnormalities were decreased
                  lymphocyte count, increased creatinine, increased glucose,
                  increased AST, increased ALT, decreased hemoglobin, and
                  decreased phosphate.
                </p>
                <p align="justify">
                  For more information, please see full Summary of Product
                  Characteristics (SmPC) or full U.S. Prescribing Information
                  (including BOXED WARNING for CRS).
                </p>
                <p align="justify">
                  <strong>Forward Looking Statements</strong>
                </p>
                <p align="justify">
                  This press release contains “forward-looking statements”
                  within the meaning of the safe harbor provisions of the
                  Private Securities Litigation Reform Act of 1995. Words such
                  as “may,” “will,” “believe,” “expect,” “plan,” “anticipate,”
                  “estimate,” and similar expressions (as well as other words or
                  expressions referencing future events or circumstances) are
                  intended to identify forward-looking statements. All
                  statements, other than statements of historical facts,
                  included in this press release are forward-looking statements.
                  These statements include, but are not limited to, statements
                  regarding the expected clinical benefits of KIMMTRAK,
                  including for mUM patients with stable disease and any
                  confirmed tumor reduction, brenetafusp and Immunocore’s other
                  product candidates, including RECIST response rate, tumor
                  reduction, including the durability of tumor reduction, ctDNA
                  molecular response, progression free survival and extended
                  overall survival benefit; the expectation that different
                  baseline characteristics and responses to therapy are
                  prognostic factors for benefit from treatment with KIMMTRAK;
                  the benefit of Immunocore’s clinical data for physicians
                  treating patients with mUM; the value proposition of
                  Immunocore’s products and product candidates, including
                  KIMMTRAK and brenetafusp; and future development plans of
                  Immunocore’s products and product candidates, including
                  KIMMTRAK and brenetafusp. Any forward-looking statements are
                  based on management’s current expectations and beliefs of
                  future events and are subject to a number of risks and
                  uncertainties that could cause actual events or results to
                  differ materially and adversely from those set forth in or
                  implied by such forward-looking statements, many of which are
                  beyond Immunocore’s control. These risks and uncertainties
                  include, but are not limited to, the impact of worsening
                  macroeconomic conditions on Immunocore’s business, financial
                  position, strategy and anticipated milestones, including
                  Immunocore’s ability to conduct ongoing and planned clinical
                  trials; Immunocore’s ability to obtain a clinical supply of
                  current or future product candidates or commercial supply of
                  KIMMTRAK or any future approved products, including as a
                  result of health epidemics or pandemics, war in Ukraine, the
                  conflict between Hamas and Israel, the broader risk of a
                  regional conflict in the Middle East, or global geopolitical
                  tension; Immunocore’s ability to obtain and maintain
                  regulatory approval of its product candidates, including
                  KIMMTRAK; Immunocore’s ability and plans in continuing to
                  establish and expand a commercial infrastructure and to
                  successfully launch, market and sell KIMMTRAK and any future
                  approved products; Immunocore’s ability to successfully expand
                  the approved indications for KIMMTRAK or obtain marketing
                  approval for KIMMTRAK in additional geographies in the future;
                  the delay of any current or planned clinical trials, whether
                  due to patient enrollment delays or otherwise; Immunocore’s
                  ability to successfully demonstrate the safety and efficacy of
                  its product candidates and gain approval of its product
                  candidates on a timely basis, if at all; competition with
                  respect to market opportunities; unexpected safety or efficacy
                  data observed during preclinical studies or clinical trials;
                  actions of regulatory agencies, which may affect the
                  initiation, timing and progress of clinical trials or future
                  regulatory approval; Immunocore’s need for and ability to
                  obtain additional funding, on favorable terms or at all,
                  including as a result of worsening macroeconomic conditions,
                  including inflation, interest rates and unfavorable general
                  market conditions, and the impacts thereon of the war in
                  Ukraine, the conflict between Hamas and Israel, and global
                  geopolitical tension; Immunocore’s ability to obtain, maintain
                  and enforce intellectual property protection for KIMMTRAK or
                  any of its product candidates it or its collaborators are
                  developing; and the success of Immunocore’s current and future
                  collaborations, partnerships or licensing arrangements. These
                  and other risks and uncertainties are described in greater
                  detail in the section titled "Risk Factors" in Immunocore’s
                  filings with the Securities and Exchange Commission, including
                  Immunocore’s most recent Annual Report on Form 10-K for the
                  year ended December 31, 2023 filed with the Securities and
                  Exchange Commission on February 28, 2024, as well as
                  discussions of potential risks, uncertainties, and other
                  important factors in the Company’s subsequent filings with the
                  Securities and Exchange Commission. All information in this
                  press release is as of the date of the release, and the
                  Company undertakes no duty to update this information, except
                  as required by law.
                </p>
                <p align="justify">Contact Information</p>
                <p align="justify"><strong>Immunocore </strong></p>
                <p align="justify">
                  Sébastien Desprez, Head of Communications<br />T: +44 (0)
                  7458030732<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  ><br />Follow on Twitter: @Immunocore
                </p>
                <p align="justify"><strong>Investor Relations</strong></p>
                <p align="justify">
                  Clayton Robertson, Head of Investor Relations<br />T: +1 (215)
                  384-4781<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  >
                </p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDk2Mzc4MyM0MDE4NjM1MDMjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/YTQyYTA2YTAtYzdkOC00NDk0LThhNWYtZWE5MzY3OGY0OWRkLTEyMTc4MTE=/tiny/Immunocore-Holdings-plc.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings plc
                <p class="spr-ir-news-article-date">Released June 1, 2024</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param:
      "immunocore-reports-updated-phase-1-data-of-brenetafusp-imc-f106c-an-immtac-bispecific-targeting-prame-in-immune-checkpoint-pre-treated-cutaneous-melanoma-patients-at-asco-2024",
    title: `Immunocore reports updated Phase 1 data of brenetafusp (IMC-F106C), an
      ImmTAC bispecific targeting PRAME, in immune checkpoint pre-treated
      cutaneous melanoma patients at ASCO 2024 :: Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore reports updated Phase 1 data of brenetafusp
                  (IMC-F106C), an ImmTAC bispecific targeting PRAME, in immune
                  checkpoint pre-treated cutaneous melanoma patients at ASCO
                  2024
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2024-05-31T16:05:00" class="date">
                    May 31, 2024 4:05pm EDT
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-05-31_Immunocore_reports_updated_Phase_1_data_of_92.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore reports updated Phase 1 data of brenetafusp (IMC-F106C), an ImmTAC bispecific targeting PRAME, in immune checkpoint pre-treated cutaneous melanoma patients at ASCO 2024"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <p align="center">
                  Immunocore reports updated Phase 1 data of brenetafusp
                  (IMC-F106C), an ImmTAC bispecific targeting PRAME, in immune
                  checkpoint pre-treated cutaneous melanoma patients at ASCO
                  2024
                </p>
                <p align="center">
                  <strong
                    ><em
                      >Monotherapy brenetafusp (IMC-F106C) in late-line
                      cutaneous melanoma demonstrated promising disease control
                      (partial response and stable disease), progression free
                      survival (PFS), and ctDNA molecular response
                    </em></strong
                  >
                </p>
                <p align="center">
                  <strong
                    ><em
                      >Clinical activity was enriched in PRAME positive patients
                      with 58% disease control rate and 4.2 months median PFS
                    </em></strong
                  >
                </p>
                <p align="center">
                  <strong
                    ><em
                      >Peripheral blood T cell fitness was associated with
                      increased brenetafusp clinical activity and was higher in
                      earlier lines of therapy</em
                    ></strong
                  >
                </p>
                <p align="center">
                  <strong
                    ><em
                      >Brenetafusp is well tolerated as monotherapy and in
                      combination with anti-PD1
                    </em></strong
                  >
                </p>
                <p align="center">
                  <strong
                    ><em
                      >Currently screening patients in a Phase 3 clinical trial
                      (PRISM-MEL-301) of brenetafusp with nivolumab in
                      first-line advanced cutaneous melanoma
                    </em></strong
                  >
                </p>
                <p align="center">
                  <strong
                    ><em
                      >Company to host a live webcast and conference call today
                      at 7:15 PM ET / 6:15 PM CT</em
                    ></strong
                  >
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, PA &amp; ROCKVILLE,
                  MD, US, 31 May 2024)
                  <a
                    href="https://www.globenewswire.com/Tracker?data=fgnHDoJC4jHpg4_yZ-vCWNl4MzUmcS35PVyMkpZGKMHJq_VTEH0Ebv7oyofm5dI4XvDpvC-FFa6LuRJE8ofEVF2Rl3rXWGNedwEnFW2dPfmz9WiQjUy-gVKNhzqV7zcPYQ8vIA6xBMVUobnTCoUo_LPdOSg7KiadLucbTFuoW-i4ldy0Tex-ibluFrMRnXZh"
                    rel="nofollow"
                    target="_blank"
                    >Immunocore Holdings plc (Nasdaq: IMCR) (“Immunocore” or the
                    “Company”)</a
                  >, a commercial-stage biotechnology company pioneering and
                  delivering transformative immunomodulating medicines to
                  radically improve outcomes for patients with cancer,
                  infectious diseases and autoimmune diseases, today released
                  Phase 1 data with the first off-the-shelf ImmTAC<sup>®</sup>
                  targeting PRAME, brenetafusp (IMC-F106C), in patients with
                  late-line, post-checkpoint cutaneous melanoma. Brenetafusp was
                  shown to be well tolerated, in monotherapy and in combination
                  with anti-PD1, and demonstrated durable clinical benefit.
                </p>
                <p align="justify">
                  “Brenetafusp continues to demonstrate promising monotherapy
                  clinical activity in late-line cutaneous melanoma patients who
                  were previously treated with checkpoint therapies,” said
                  <strong>Dr.</strong>
                  <strong
                    >Omid Hamid, Chief, Translational Research and
                    Immunotherapy, Co-Director, Melanoma Therapeutics at
                    Cedars-Sinai Cancer at the Angeles Clinic and Research
                    Institute</strong
                  >. “The disease control and PFS benefit for these
                  brenetafusp-treated melanoma patients compares favorably to
                  data with other immunotherapies.”
                </p>
                <p align="justify">
                  “The best measure of brenetafusp monotherapy activity is
                  disease control, which is observed in 56% of checkpoint
                  pre-treated melanoma patients,” said
                  <strong>David Berman, Head of Research and Development</strong
                  >. “We expect brenetafusp PFS to be even higher in first-line
                  based on our analysis of blood T cell fitness. These data
                  points, in conjunction with the significant molecular response
                  and the expected additive benefit of combining with an active
                  anti-PD1, provide confidence for PFS as an endpoint in our
                  ongoing Phase 3 first-line trial.”
                </p>
                <p align="justify">
                  <strong
                    >Phase 1 data in post-checkpoint cutaneous melanoma
                  </strong>
                </p>
                <p align="justify">
                  As of 18 March 2024, 47 patients have received brenetafusp
                  (IMC-F106C) monotherapy at clinically active target dose
                  levels. All monotherapy treated patients had received prior
                  immune checkpoint inhibitors (100% anti-PD1, 81% anti-CTLA4).
                  PRAME expression was high amongst evaluable patients (median H
                  score of 215). Only 11% of patients had PRAME negative tumors,
                  as measured by immunohistochemistry.
                </p>
                <p align="justify">
                  Brenetafusp was well-tolerated, with treatment-related adverse
                  events (TRAEs) that were manageable and consistent with the
                  mechanism of action. The most frequent TRAE reported was Grade
                  1 or 2 cytokine release syndrome (CRS) and rash; these events
                  occurred predominantly following the initial three doses.
                  There were no Grade 3 or higher CRS TRAEs.
                </p>
                <p align="justify">
                  Of the 47 monotherapy patients, 36 had a RECIST evaluable
                  tumor assessment. The disease control rate (DCR), consisting
                  of partial response (PR) and stable disease (SD), was 56%
                  including 4 PR (ORR 11%) and 16 SD (44%). Durable tumor
                  reduction, confirmed by at least one subsequent scan, was
                  observed in 28% of patients and is an attribute of the ImmTAC
                  platform<sup>1</sup>. Clinical benefit was enriched in the 31
                  evaluable PRAME positive patients. The DCR in this group was
                  58% and included all 10 patients (32%) with confirmed tumor
                  reduction.
                </p>
                <p align="justify">
                  Both median progression free survival (mPFS) and 6-month
                  overall survival (OS) rates were greater in PRAME positive
                  than in PRAME negative monotherapy patients: 4.2 vs 2.1 months
                  and 95% vs 40%, respectively.
                </p>
                <p align="justify">
                  42% of ctDNA-evaluable, PRAME positive monotherapy patients
                  had a molecular response (10/24) and there was a trend for
                  longer PFS and OS in molecular responders. No ctDNA-evaluable
                  PRAME negative patients had ctDNA reduction.
                </p>
                <p align="justify">
                  In addition to the monotherapy patients treated with
                  brenetafusp, there were 9 cutaneous melanoma patients who
                  received brenetafusp in combination with an anti-PD1
                  (pembrolizumab), all of whom had received prior checkpoint
                  inhibitors (100% prior anti-PD1, 89% prior CTLA4). Overall,
                  patients were more heavily pre-treated in the combination
                  cohort compared to monotherapy (median prior lines: 4 vs 2;
                  PD-1 refractory: 67% vs 30%). Brenetafusp in combination with
                  pembrolizumab was well tolerated, with TRAEs that were
                  manageable and consistent with the mechanism of action of both
                  agents. There was one dose-limiting toxicity (transaminitis)
                  reported in one patient with prior history of checkpoint
                  inhibitor induced autoimmune hepatitis.
                </p>
                <p align="justify">
                  Of the 7 patients evaluable for efficacy in combination, 4
                  achieved disease control including 1 ongoing PR (confirmed
                  after the data cut off for the presentation), and 3 of the 4
                  ctDNA evaluable patients having molecular response.
                </p>
                <p align="justify">
                  In 41 gene-expression evaluable monotherapy patients, a gene
                  signature was identified from baseline peripheral blood that
                  was a measure of systemic T cell fitness. Patients with gene
                  signature expression levels greater than or equal to the
                  median had higher clinical benefit including a median PFS of 6
                  months and DCR of 69%, compared to those with less than the
                  median gene expression levels (2 months and 42%,
                  respectively). Patients with only 1-2 prior lines of therapy
                  had higher T cell fitness gene signature, on average, than
                  those with 3 or more prior lines of therapy.
                </p>
                <p align="justify">
                  The advanced cutaneous melanoma data from the ongoing Phase
                  1/2 trial of brenetafusp will be presented today at 2:45 PM CT
                  / 3:45 PM ET, in the Melanoma/Skin Cancers oral abstract
                  session at the 2024 American Society of Oncology (ASCO) Annual
                  Meeting. The presentation will be accessible in the ‘Events
                  &amp; Presentations’ section of the Investor Relations section
                  of the Company’s website.
                </p>
                <p align="justify">
                  <strong
                    >PRISM-MEL-301 – First PRAME Phase 3 clinical trial with
                    brenetafusp in first-line advanced cutaneous
                    melanoma</strong
                  >
                </p>
                <p align="justify">
                  The Company is enrolling patients in a registrational Phase 3
                  clinical trial with brenetafusp in first-line advanced
                  cutaneous melanoma (CM) with a primary endpoint of
                  progression-free survival (PFS) (NCT06112314). The trial will
                  randomize HLA-A*02:01-positive, first-line advanced CM
                  patients to brenetafusp + nivolumab versus a control arm of
                  either nivolumab or nivolumab + relatlimab, depending on the
                  country where the patient is enrolled.
                </p>
                <p align="justify">
                  Under the terms of a clinical trial collaboration and supply
                  agreement, Immunocore will sponsor and fund this
                  registrational Phase 3 clinical trial, and Bristol Myers
                  Squibb will provide nivolumab.
                </p>
                <p align="justify"><strong>Audio Webcast</strong></p>
                <p align="justify">
                  Immunocore will host a conference call today, May 31, 2024 at
                  7:15 PM ET / 6:15 PM CT, to discuss the Phase 1 PRAME
                  expansion data and Phase 3 registrational trial in cutaneous
                  melanoma. The call will also be available via webcast by
                  visiting the Events &amp; Presentations section on
                  Immunocore’s website. A replay of this webcast will be
                  available for 30 days.
                </p>
                <p align="justify">
                  Conference Call Details:<br />U.S. (toll-free):
                  877-405-1239<br />International (toll): +1 201-389-0851
                </p>
                <p align="center">###</p>
                <p align="justify">
                  <strong>About ImmTAC<sup>®</sup> molecules for cancer</strong>
                </p>
                <p align="justify">
                  Immunocore’s proprietary T cell receptor (TCR) technology
                  generates a novel class of bispecific biologics called ImmTAC
                  (Immune mobilizing monoclonal TCRs Against Cancer) molecules
                  that are designed to redirect the immune system to recognize
                  and kill cancerous cells. ImmTAC molecules are soluble TCRs
                  engineered to recognize intracellular cancer antigens with
                  ultra-high affinity and selectively kill these cancer cells
                  via an anti-CD3 immune-activating effector function. Based on
                  the demonstrated mechanism of T cell infiltration into human
                  tumors, the ImmTAC mechanism of action holds the potential to
                  treat hematologic and solid tumors, regardless of mutational
                  burden or immune infiltration, including immune “cold” low
                  mutation rate tumors.
                </p>
                <p align="justify">
                  <strong
                    >About PRISM-MEL-301 – Phase 3 trial with brenetafusp
                    (IMC-F106C; PRAME-A02) in 1L advanced cutaneous
                    melanoma</strong
                  >
                </p>
                <p align="justify">
                  The Phase 3 registrational trial will randomize patients with
                  previously untreated, HLA-A*02:01-positive, advanced melanoma
                  to brenetafusp + nivolumab versus nivolumab or nivolumab +
                  relatlimab, depending on the country where the patient is
                  enrolled. The study will initially randomize to three arms:
                  two brenetafusp dose regimens (40 mcg and 160 mcg) and control
                  arm and will discontinue one of the brenetafusp dose regimens
                  after an initial review of the first 60 patients randomized to
                  the two experimental arms (90 patients randomized total). The
                  primary endpoint of the trial is progression free survival
                  (PFS) by blinded independent central review (BICR), with
                  secondary endpoints of overall survival (OS) and overall
                  response rate (ORR).
                </p>
                <p align="justify">
                  <strong>About the IMC-F106C-101 Phase 1/2 trial</strong>
                </p>
                <p align="justify">
                  IMC-F106C-101 is a first-in-human, Phase 1/2 dose escalation
                  trial in patients with multiple solid tumor cancers including
                  non-small cell lung cancer (NSCLC), small-cell lung cancer
                  (SCLC), endometrial, ovarian, cutaneous melanoma, and breast
                  cancers. The Phase 1 dose escalation trial was designed to
                  determine the maximum tolerated dose (MTD), as well as to
                  evaluate the safety, preliminary anti-tumor activity and
                  pharmacokinetics of IMC-F106C (brenetafusp), a bispecific
                  protein built on Immunocore’s ImmTAC technology, and the
                  Company’s first molecule to target the PRAME antigen. The
                  Company has initiated patient enrollment into four expansion
                  arms in cutaneous melanoma, ovarian, NSCLC, and endometrial
                  carcinomas. The IMC-F106C-101 trial is adaptive and includes
                  the option for Phase 2 expansion, allowing for approximately
                  100 patients treated per tumor type in the Phase 1 and 2
                  expansion arms. Ph1 monotherapy continues in additional solid
                  tumors as well as multiple combinations with
                  standards-of-care, including checkpoint inhibitors,
                  chemotherapy, targeted therapies, and tebentafusp.
                </p>
                <p align="justify"><strong>About Cutaneous Melanoma</strong></p>
                <p align="justify">
                  Cutaneous melanoma (CM) is the most common form of melanoma.
                  It is the most aggressive skin carcinoma and is associated
                  with the vast majority of skin cancer-related mortality. The
                  majority of patients with CM are diagnosed before metastasis
                  and survival remains poor for the large proportion of patients
                  with metastatic disease. Despite recent progress in advanced
                  melanoma therapy, there is still an unmet need for new
                  therapies that improve first-line response rates and duration
                  of response as well as for patients who are refractory to
                  first-line treatments.
                </p>
                <p align="justify">
                  <strong>About KIMMTRAK<sup>®</sup></strong>
                </p>
                <p align="justify">
                  KIMMTRAK is a novel bispecific protein comprised of a soluble
                  T cell receptor fused to an anti-CD3 immune-effector function.
                  KIMMTRAK specifically targets gp100, a lineage antigen
                  expressed in melanocytes and melanoma. This is the first
                  molecule developed using Immunocore’s ImmTAC technology
                  platform designed to redirect and activate T cells to
                  recognize and kill tumor cells. KIMMTRAK has been approved for
                  the treatment of HLA-A*02:01-positive adult patients with
                  unresectable or metastatic uveal melanoma in the United
                  States, European Union, Canada, Australia, and the United
                  Kingdom.
                </p>
                <p align="justify">IMPORTANT SAFETY INFORMATION</p>
                <p align="justify">
                  <strong
                    >Cytokine Release Syndrome (CRS), which may be serious or
                    life-threatening, occurred in patients receiving KIMMTRAK.
                    Monitor for at least 16 hours following first three
                    infusions and then as clinically indicated.</strong
                  >
                  Manifestations of CRS may include fever, hypotension, hypoxia,
                  chills, nausea, vomiting, rash, elevated transaminases,
                  fatigue, and headache. CRS occurred in 89% of patients who
                  received KIMMTRAK with 0.8% being grade 3 or 4. Ensure
                  immediate access to medications and resuscitative equipment to
                  manage CRS. Ensure patients are euvolemic prior to initiating
                  the infusions. Closely monitor patients for signs or symptoms
                  of CRS following infusions of KIMMTRAK. Monitor fluid status,
                  vital signs, and oxygenation level and provide appropriate
                  therapy. Withhold or discontinue KIMMTRAK depending on
                  persistence and severity of CRS.
                </p>
                <p align="justify"><strong>Skin Reactions</strong></p>
                <p align="justify">
                  Skin reactions, including rash, pruritus, and cutaneous edema
                  occurred in 91% of patients treated with KIMMTRAK. Monitor
                  patients for skin reactions. If skin reactions occur, treat
                  with antihistamine and topical or systemic steroids based on
                  persistence and severity of symptoms. Withhold or permanently
                  discontinue KIMMTRAK depending on the severity of skin
                  reactions.
                </p>
                <p align="justify"><strong>Elevated Liver Enzymes</strong></p>
                <p align="justify">
                  Elevations in liver enzymes occurred in 65% of patients
                  treated with KIMMTRAK. Monitor alanine aminotransferase (ALT),
                  aspartate aminotransferase (AST), and total blood bilirubin
                  prior to the start of and during treatment with KIMMTRAK.
                  Withhold KIMMTRAK according to severity.
                </p>
                <p align="justify"><strong>Embryo-Fetal Toxicity</strong></p>
                <p align="justify">
                  KIMMTRAK may cause fetal harm. Advise pregnant patients of
                  potential risk to the fetus and patients of reproductive
                  potential to use effective contraception during treatment with
                  KIMMTRAK and 1 week after the last dose.
                </p>
                <p align="justify">
                  The most common adverse reactions (≥30%) in patients who
                  received KIMMTRAK were cytokine release syndrome, rash,
                  pyrexia, pruritus, fatigue, nausea, chills, abdominal pain,
                  edema, hypotension, dry skin, headache, and vomiting. The most
                  common (≥50%) laboratory abnormalities were decreased
                  lymphocyte count, increased creatinine, increased glucose,
                  increased AST, increased ALT, decreased hemoglobin, and
                  decreased phosphate.
                </p>
                <p align="justify">
                  For more information, please see full Summary of Product
                  Characteristics (SmPC) or full U.S. Prescribing Information
                  (including BOXED WARNING for CRS).
                </p>
                <p align="justify"><strong>About Immunocore</strong></p>
                <p align="justify">
                  Immunocore is a commercial-stage biotechnology company
                  pioneering the development of a novel class of TCR bispecific
                  immunotherapies called ImmTAX – Immune mobilizing monoclonal
                  TCRs Against X disease – designed to treat a broad range of
                  diseases, including cancer, autoimmune, and infectious
                  disease. Leveraging its proprietary, flexible, off-the-shelf
                  ImmTAX platform, Immunocore is developing a deep pipeline in
                  multiple therapeutic areas, including nine active clinical and
                  pre-clinical programs​ in oncology, infectious diseases, and
                  autoimmune diseases. The Company’s most advanced oncology TCR
                  therapeutic, KIMMTRAK has been approved for the treatment of
                  HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p align="justify">
                  <strong>Forward Looking Statements</strong>
                </p>
                <p align="justify">
                  This press release contains “forward-looking statements”
                  within the meaning of the safe harbor provisions of the
                  Private Securities Litigation Reform Act of 1995. Words such
                  as “may,” “will,” “believe,” “expect,” “plan,” “anticipate,”
                  “estimate,” and similar expressions (as well as other words or
                  expressions referencing future events or circumstances) are
                  intended to identify forward-looking statements. All
                  statements, other than statements of historical facts,
                  included in this press release are forward-looking statements.
                  These statements include, but are not limited to, statements
                  regarding the expected clinical benefits of ImmTAC molecules,
                  including KIMMTRAK, brenetafusp, and the Immunocore’s other
                  product candidates, including tumor reduction, disease control
                  rate of partial responses and stable diseases, ctDNA molecular
                  response, progression free survival and extended overall
                  survival benefit, alone and in combination with other
                  therapies; the expected efficacy and tolerability of
                  Immunocore’s products and product candidates, including
                  brenetafusp; expectations regarding receipt of regulatory
                  approvals and completion of related procedures; the value
                  proposition of Immunocore’s products and product candidates,
                  including KIMMTRAK and brenetafusp; future development plans
                  of Immunocore’s products and product candidates, including
                  KIMMTRAK and brenetafusp; and expectations regarding the
                  design, progress, timing, enrollment, randomization, scope,
                  expansion, funding and results of Immunocore’s existing and
                  planned clinical trials, including the Phase 3 PRISM-MEL301
                  trial with brenetafusp plus nivolumab versus standard
                  nivolumab in 1L advanced cutaneous melanoma and the
                  IMC-F106C-101 Phase 1/2 dose escalation trial with brenetafusp
                  in patients with multiple solid tumor cancers including
                  non-small cell lung cancer (NSCLC), small-cell lung cancer
                  (SCLC), endometrial, ovarian, cutaneous melanoma, and breast
                  cancers, and those of the Immunocore’s collaboration partners
                  or the combined clinical trials with Immunocore’s
                  collaboration partners; statements regarding the benefits of
                  the Company’s collaboration with Bristol-Meyers Squibb; and
                  the timing and sufficiency of clinical trial outcomes to
                  support potential approval of any of the Immunocore’s product
                  candidates or those of, or combined with, its collaboration
                  partners. Any forward-looking statements are based on
                  management’s current expectations and beliefs of future events
                  and are subject to a number of risks and uncertainties that
                  could cause actual events or results to differ materially and
                  adversely from those set forth in or implied by such
                  forward-looking statements, many of which are beyond the
                  Company’s control. These risks and uncertainties include, but
                  are not limited to, the impact of worsening macroeconomic
                  conditions on the Company’s business, financial position,
                  strategy and anticipated milestones, including Immunocore’s
                  ability to conduct ongoing and planned clinical trials;
                  Immunocore’s ability to obtain a clinical supply of current or
                  future product candidates or commercial supply of KIMMTRAK or
                  any future approved products, including as a result of health
                  epidemics or pandemics, war in Ukraine, the conflict between
                  Hamas and Israel, the broader risk of a regional conflict in
                  the Middle East, or global geopolitical tension; Immunocore’s
                  ability to obtain and maintain regulatory approval of its
                  product candidates, including KIMMTRAK; Immunocore’s ability
                  and plans in continuing to establish and expand a commercial
                  infrastructure and to successfully launch, market and sell
                  KIMMTRAK and any future approved products; Immunocore’s
                  ability to successfully expand the approved indications for
                  KIMMTRAK or obtain marketing approval for KIMMTRAK in
                  additional geographies in the future; the delay of any current
                  or planned clinical trials, whether due to patient enrollment
                  delays or otherwise; Immunocore’s ability to successfully
                  demonstrate the safety and efficacy of its product candidates
                  and gain approval of its product candidates on a timely basis,
                  if at all; competition with respect to market opportunities;
                  unexpected safety or efficacy data observed during preclinical
                  studies or clinical trials; actions of regulatory agencies,
                  which may affect the initiation, timing and progress of
                  clinical trials or future regulatory approval; Immunocore’s
                  need for and ability to obtain additional funding, on
                  favorable terms or at all, including as a result of worsening
                  macroeconomic conditions, including inflation, interest rates
                  and unfavorable general market conditions, and the impacts
                  thereon of the war in Ukraine, the conflict between Hamas and
                  Israel, and global geopolitical tension; Immunocore’s ability
                  to obtain, maintain and enforce intellectual property
                  protection for KIMMTRAK or any of its product candidates it or
                  its collaborators are developing; and the success of
                  Immunocore’s current and future collaborations, partnerships
                  or licensing arrangements, including the risk that Immunocore
                  may not realize the anticipated benefits of its collaboration
                  with Bristol Myers Squibb. These and other risks and
                  uncertainties are described in greater detail in the section
                  titled "Risk Factors" in Immunocore’s filings with the
                  Securities and Exchange Commission, including Immunocore’s
                  most recent Annual Report on Form 10-K for the year ended
                  December 31, 2023 filed with the Securities and Exchange
                  Commission on February 28, 2024, as well as discussions of
                  potential risks, uncertainties, and other important factors in
                  the Company’s subsequent filings with the Securities and
                  Exchange Commission. All information in this press release is
                  as of the date of the release, and the Company undertakes no
                  duty to update this information, except as required by law.
                </p>
                <p align="justify">Contact Information</p>
                <p align="justify"><strong>Immunocore </strong></p>
                <p align="justify">
                  Sébastien Desprez, Head of Communications<br />T: +44 (0)
                  7458030732<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  ><br />Follow on Twitter: @Immunocore
                </p>
                <p align="justify"><strong>Investor Relations</strong></p>
                <p align="justify">
                  Clayton Robertson, Head of Investor Relations<br />T: +1 (215)
                  384-4781<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  >
                </p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDk2NDAzNyM0MDE4NjQxNzcjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/NThkNTI0NjQtYzJmMS00ZjdmLTk0MDgtZDA3ZmY0NjhmZjE0LTEyMTc4MTE=/tiny/Immunocore-Holdings-plc.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings plc
                <p class="spr-ir-news-article-date">Released May 31, 2024</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param:
      "immunocore-to-present-at-the-jefferies-global-healthcare-conference",
    title: `Immunocore to present at the Jefferies Global Healthcare Conference ::
      Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore to present at the Jefferies Global Healthcare
                  Conference
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2024-05-30T16:00:00" class="date">
                    May 30, 2024 4:00pm EDT
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-05-30_Immunocore_to_present_at_the_Jefferies_Global_91.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore to present at the Jefferies Global Healthcare Conference"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <p align="center">
                  Immunocore to present at the Jefferies Global Healthcare
                  Conference
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, 30 May 2024)
                  <a
                    href="https://www.globenewswire.com/Tracker?data=D_XHODIVEoxELQXAsSl94lz8xx3l5G1K1Mb3zYhjyXS4ZlXSdLC7_34p31ftw-KOm1WTgwQV8h46iIchP_k9AwV3abG-svdNzg-0B9I8PlgpA33PvQ1johNUwltCnxvzDjZmW8LFUgGis2noC338YEbWj37lc20vuuaf8qiekaLLue-d4meikSx3JcRAA102"
                    rel="nofollow"
                    target="_blank"
                    >Immunocore Holdings plc (Nasdaq: IMCR) (“Immunocore” or the
                    “Company”)</a
                  >, a commercial-stage biotechnology company pioneering and
                  delivering transformative immunomodulating medicines to
                  radically improve outcomes for patients with cancer,
                  infectious diseases and autoimmune diseases, today announced
                  that management will participate in the Jefferies Global
                  Healthcare Conference.
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    <strong>Jefferies Global Healthcare Conference</strong>
                    <ul>
                      <li style="text-align: justify">
                        Fireside Chat: Thursday, June 6, 2024, at 2:30 p.m.
                        EDT<br />
                      </li>
                    </ul>
                  </li>
                </ul>
                <p align="justify">
                  The presentation will be webcast live and can be accessed by
                  visiting ‘Events &amp; Presentations’, under ‘Events’, via the
                  ‘Investors’ section of Immunocore’s website at
                  www.immunocore.com. Following the event, a replay of the
                  presentation will be made available for a limited time.
                </p>
                <p align="justify"><strong>About Immunocore</strong></p>
                <p align="justify">
                  Immunocore is a commercial-stage biotechnology company
                  pioneering the development of a novel class of TCR bispecific
                  immunotherapies called ImmTAX – Immune mobilizing monoclonal
                  TCRs Against X disease – designed to treat a broad range of
                  diseases, including cancer, autoimmune, and infectious
                  disease. Leveraging its proprietary, flexible, off-the-shelf
                  ImmTAX platform, Immunocore is developing a deep pipeline in
                  multiple therapeutic areas, including five clinical stage
                  programs in oncology and infectious disease, advanced
                  pre-clinical programs in autoimmune disease and multiple
                  earlier pre-clinical programs. The Company’s most advanced
                  oncology TCR therapeutic, KIMMTRAK has been approved for the
                  treatment of HLA-A*02:01-positive adult patients with
                  unresectable or metastatic uveal melanoma in the United
                  States, European Union, Canada, Australia, and the United
                  Kingdom.
                </p>
                <p align="justify">
                  <strong>Forward Looking Statements</strong>
                </p>
                <p align="justify">
                  This press release contains “forward-looking statements”
                  within the meaning of the safe harbor provisions of the
                  Private Securities Litigation Reform Act of 1995. Words such
                  as “may,” “will,” “believe,” “expect,” “plan,” “anticipate,”
                  and similar expressions (as well as other words or expressions
                  referencing future events or circumstances) are intended to
                  identify forward-looking statements. All statements, other
                  than statements of historical facts, included in this press
                  release are forward-looking statements. These statements
                  include, but are not limited to, statements regarding the
                  marketing and therapeutic potential of KIMMTRAK for mUM; the
                  expected clinical benefits of KIMMTRAK and the Company’s other
                  product candidates, including extended overall survival
                  benefit; expectations regarding commercialization of KIMMTRAK
                  in the United States, Germany and France as well as in other
                  EU member states; expectations regarding receipt of regulatory
                  approvals and completion of related procedures; expectations
                  regarding the success and performance of obligations under
                  Immunocore’s collaboration agreements with third parties;
                  expectations regarding Immunocore’s cash runway; Immunocore’s
                  sales and marketing plans, including with respect to the
                  United States, Germany and France; the potential for and
                  timing of commercial availability of KIMMTRAK in additional
                  countries and the ability to reach patients in a timely
                  manner; the value proposition of Immunocore’s product
                  candidates, including KIMMTRAK in mUM and its benefit as an
                  orphan indication, including expectations regarding the
                  potential market opportunity; physician’s feedback,
                  endorsements, guidelines and interest in prescribing KIMMTRAK
                  as the standard of care for mUM; Immunocore’s efforts to
                  expand patients’ access to medicine; future development plans
                  of KIMMTRAK, including the timing or likelihood of expansion
                  into additional markets or geographies; expectations regarding
                  the design, progress, timing, scope and results of
                  Immunocore’s existing and planned clinical trials, including
                  the randomized Phase 2/3 clinical trial in previously treated
                  advanced melanoma and PRAME and MAGE-A4 clinical trials. Any
                  forward-looking statements are based on management’s current
                  expectations and beliefs of future events and are subject to a
                  number of risks and uncertainties that could cause actual
                  events or results to differ materially and adversely from
                  those set forth in or implied by such forward-looking
                  statements, many of which are beyond the Company’s control.
                  These risks and uncertainties include, but are not limited to,
                  the impact of the ongoing and evolving COVID-19 pandemic on
                  the Company’s business, financial position, strategy and
                  anticipated milestones, including Immunocore’s ability to
                  conduct ongoing and planned clinical trials; Immunocore’s
                  ability to obtain a clinical supply of current or future
                  product candidates or commercial supply of KIMMTRAK or any
                  future approved products, including as a result of the
                  COVID-19 pandemic, war in Ukraine or global geopolitical
                  tension; Immunocore’s ability to obtain and maintain
                  regulatory approval of its product candidates, including
                  KIMMTRAK; Immunocore’s ability and plans in continuing to
                  establish and expand a commercial infrastructure and to
                  successfully launch, market and sell KIMMTRAK and any future
                  approved products; Immunocore’s ability to successfully expand
                  the approved indications for KIMMTRAK or obtain marketing
                  approval for KIMMTRAK in additional geographies in the future;
                  the delay of any current or planned clinical trials, whether
                  due to the COVID-19 pandemic, patient enrollment delays or
                  otherwise; Immunocore’s ability to successfully demonstrate
                  the safety and efficacy of its product candidates and gain
                  approval of its product candidates on a timely basis, if at
                  all; competition with respect to market opportunities;
                  unexpected safety or efficacy data observed during preclinical
                  studies or clinical trials; actions of regulatory agencies,
                  which may affect the initiation, timing and progress of
                  clinical trials or future regulatory approval; Immunocore’s
                  need for and ability to obtain additional funding, on
                  favorable terms or at all, including as a result of rising
                  inflation, interest rates and general market conditions, and
                  the impacts thereon of the COVID-19 pandemic, war in Ukraine
                  and global geopolitical tension; Immunocore’s ability to
                  obtain, maintain and enforce intellectual property protection
                  for KIMMTRAK or any product candidates it is developing; and
                  the success of Immunocore’s current and future collaborations,
                  partnerships or licensing arrangements. These and other risks
                  and uncertainties are described in greater detail in the
                  section titled "Risk Factors" in Immunocore’s filings with the
                  Securities and Exchange Commission, including Immunocore’s
                  most recent Annual Report on Form 20-F for the year ended
                  December 31, 2021 filed with the Securities and Exchange
                  Commission on March 3, 2022, as well as discussions of
                  potential risks, uncertainties, and other important factors in
                  the Company’s subsequent filings with the Securities and
                  Exchange Commission. All information in this press release is
                  as of the date of the release, and the Company undertakes no
                  duty to update this information, except as required by law.
                </p>
                <p align="justify"><strong>Contact Information</strong></p>
                <p align="justify"><strong>Immunocore </strong></p>
                <p align="justify">
                  Sébastien Desprez, Head of Communications<br />T: +44 (0)
                  7458030732<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  ><br />Follow on Twitter: @Immunocore
                </p>
                <p align="justify"><strong>Investor Relations</strong></p>
                <p align="justify">
                  Clayton Robertson, Head of Investor Relations<br />T: +1 (215)
                  384-4781<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  >
                </p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDk2Mzc4OSM0MDE4NjM1MTkjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/YTYyYWQ0MzAtODQ0MC00YmY0LTk3ZWQtM2EyMGVjNWQyZmIzLTEyMTc4MTE=/tiny/Immunocore-Holdings-plc.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings plc
                <p class="spr-ir-news-article-date">Released May 30, 2024</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param:
      "immunocore-converts-phase-23-tebe-am-clinical-trial-into-registrational-phase-3-trial-evaluating-kimmtrak-for-previously-treated-advanced-cutaneous-melanoma",
    title: `Immunocore converts Phase 2/3 TEBE-AM clinical trial into registrational
      Phase 3 trial evaluating KIMMTRAK for previously treated advanced
      cutaneous melanoma :: Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore converts Phase 2/3 TEBE-AM clinical trial into
                  registrational Phase 3 trial evaluating KIMMTRAK for
                  previously treated advanced cutaneous melanoma
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2024-05-29T07:00:00" class="date">
                    May 29, 2024 7:00am EDT
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-05-29_Immunocore_converts_Phase_2_3_TEBE_AM_clinical_90.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore converts Phase 2/3 TEBE-AM clinical trial into registrational Phase 3 trial evaluating KIMMTRAK for previously treated advanced cutaneous melanoma"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <p align="center">
                  Immunocore converts Phase 2/3 TEBE-AM clinical trial into
                  registrational Phase 3 trial evaluating KIMMTRAK for
                  previously treated advanced cutaneous melanoma
                </p>
                <p align="center">
                  <strong
                    ><em
                      >Following recent consultation with FDA, all patients
                      randomized from start of TEBE-AM Phase 2/3 trial will be
                      included in the Phase 3 intent-to-treat population</em
                    ></strong
                  >
                </p>
                <p align="center">
                  <strong
                    ><em
                      >Phase 3 will continue three arms: KIMMTRAK monotherapy,
                      KIMMTRAK in combination with pembrolizumab, and
                      control</em
                    ></strong
                  >
                </p>
                <p align="center">
                  <strong
                    ><em
                      >Expected to accelerate time to final Phase 3 overall
                      survival analysis</em
                    ></strong
                  >
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, PA &amp; ROCKVILLE,
                  MD, US, 29 May 2024)
                  <a
                    href="https://www.globenewswire.com/Tracker?data=dWJli34Xty2__3TVOaSALgLLcB51V291jzXVJ227MsD2w81QYxjw6D0VxX_p0tcV_Soc4qsWHXdu00uGfk_zHsj4D_aiY6H_-QZhj0sb3TcGBgsHRjjS8OxYM7pwg25jInYUixPFbxpv5-FupkqpVR1E08C1KsO_2SvpNqnW-vh6l0fe8PrQ0kXjqcEF7zMz"
                    rel="nofollow"
                    target="_blank"
                    >Immunocore Holdings plc (Nasdaq: IMCR) (“Immunocore” or the
                    “Company”)</a
                  >, a commercial-stage biotechnology company pioneering and
                  delivering transformative immunomodulating medicines to
                  radically improve outcomes for patients with cancer,
                  infectious diseases and autoimmune diseases, today announced
                  that the TEBE-AM Phase 2/3 clinical trial has been converted
                  into a Phase 3 trial.
                </p>
                <p align="justify">
                  The Phase 2/3 TEBE-AM trial was designed to evaluate
                  KIMMTRAK<sup>®</sup> (tebentafusp-tebn), as monotherapy and in
                  combination with pembrolizumab, versus a control arm, for the
                  treatment of patients with previously treated advanced
                  cutaneous melanoma. The trial was originally designed as an
                  adaptive Phase 2/3 trial with the optionality to review Phase
                  2 data and drop an arm. Following consultation with the FDA,
                  the Company has decided to conduct the trial solely as a Phase
                  3 with the primary endpoint of overall survival. As a result
                  of the recent rapid accrual, the Company projects that the
                  Phase 3 trial would be mostly enrolled by the time the Phase 2
                  overall survival would have matured. In addition, the three
                  arm Phase 3 will allow more robust testing of KIMMTRAK as
                  monotherapy and in combination versus a control arm. Finally,
                  with all patients randomized to date to be included in the
                  intent-to-treat population, the time to final analysis of the
                  Phase 3 trial will be accelerated.
                </p>
                <p align="justify">
                  <strong
                    >Mark Moyer, SVP, Regulatory Affairs, Immunocore </strong
                  >said: “The decision to launch a registrational trial in
                  cutaneous melanoma was based on KIMMTRAK’s overall survival
                  benefit in uveal melanoma, and promising clinical activity as
                  monotherapy and in combination with immune checkpoint therapy
                  in Phase 1 cutaneous melanoma trials. To allow robust testing
                  of two KIMMTRAK regimens and to accelerate the time to primary
                  analysis, we decided, in consultation with the FDA, to amend
                  the protocol into a single Phase 3 registrational study.”
                </p>
                <p align="justify">
                  The registrational TEBE-AM trial was initiated following
                  results from a Phase 1b trial of tebentafusp in combination
                  with checkpoint inhibitors in metastatic cutaneous melanoma
                  (mCM). The Phase 1b data showed that the maximum target doses
                  of tebentafusp (68 mcg) plus durvalumab (20 mg/kg) were well
                  tolerated, and that, in mCM patients who progressed on prior
                  anti-PD(L)1, the combination demonstrated a one-year overall
                  survival rate of ~75%.
                </p>
                <p align="justify">
                  <strong>About ImmTAC</strong><sup><strong>®</strong></sup
                  ><strong> molecules for cancer </strong>
                </p>
                <p align="justify">
                  Immunocore’s proprietary T cell receptor (TCR) technology
                  generates a novel class of bispecific biologics called ImmTAC
                  (Immune mobilizing monoclonal TCRs Against Cancer) molecules
                  that are designed to redirect the immune system to recognize
                  and kill cancerous cells. ImmTAC molecules are soluble TCRs
                  engineered to recognize intracellular cancer antigens with
                  ultra-high affinity and selectively kill these cancer cells
                  via an anti-CD3 immune-activating effector function. Based on
                  the demonstrated mechanism of T cell infiltration into human
                  tumors, the ImmTAC mechanism of action holds the potential to
                  treat hematologic and solid tumors, regardless of mutational
                  burden or immune infiltration, including immune “cold” low
                  mutation rate tumors.
                </p>
                <p align="justify">
                  <strong
                    >About TEBE-AM - Phase 3 registrational trial with
                    tebentafusp in previously treated advanced cutaneous
                    melanoma
                  </strong>
                </p>
                <p align="justify">
                  The Phase 3 TEBE-AM trial is randomizing patients with
                  second-line or later cutaneous melanoma who have progressed on
                  an anti-PD1, received prior ipilimumab and, if applicable,
                  received a BRAF kinase inhibitor. Patients are randomized to
                  one of three arms, including tebentafusp, as monotherapy or in
                  combination with an anti-PD1, and a control arm. The primary
                  endpoint is overall survival.
                </p>
                <p align="justify"><strong>About Cutaneous Melanoma</strong></p>
                <p align="justify">
                  Cutaneous melanoma (CM) is the most common form of melanoma.
                  It is the most aggressive skin carcinoma and is associated
                  with the vast majority of skin cancer-related mortality. The
                  majority of patients with CM are diagnosed before metastasis
                  and survival remains poor for the large proportion of patients
                  with metastatic disease. Despite recent progress in advanced
                  melanoma therapy, there is still an unmet need for new
                  therapies that improve first-line response rates and duration
                  of response as well as for patients who are refractory to
                  first-line treatments.
                </p>
                <p align="justify"><strong>About Uveal Melanoma </strong></p>
                <p align="justify">
                  Uveal melanoma is a rare and aggressive form of melanoma,
                  which affects the eye. Although it is the most common primary
                  intraocular malignancy in adults, the diagnosis is rare, and
                  up to 50% of people with uveal melanoma will eventually
                  develop metastatic disease. Unresectable or metastatic uveal
                  melanoma typically has a poor prognosis and had no approved
                  treatment until KIMMTRAK.
                </p>
                <p align="justify">
                  <strong>About KIMMTRAK</strong><sup><strong>®</strong></sup>
                </p>
                <p align="justify">
                  KIMMTRAK is a novel bispecific protein comprised of a soluble
                  T cell receptor fused to an anti-CD3 immune-effector function.
                  KIMMTRAK specifically targets gp100, a lineage antigen
                  expressed in melanocytes and melanoma. This is the first
                  molecule developed using Immunocore’s ImmTAC technology
                  platform designed to redirect and activate T cells to
                  recognize and kill tumor cells. KIMMTRAK has been approved for
                  the treatment of HLA-A*02:01-positive adult patients with
                  unresectable or metastatic uveal melanoma in the United
                  States, European Union, Canada, Australia, and the United
                  Kingdom.
                </p>
                <p align="justify">
                  <strong>IMPORTANT SAFETY INFORMATION </strong>
                </p>
                <p align="justify">
                  Cytokine Release Syndrome (CRS), which may be serious or
                  life-threatening, occurred in patients receiving KIMMTRAK.
                  Monitor for at least 16 hours following first three infusions
                  and then as clinically indicated. Manifestations of CRS may
                  include fever, hypotension, hypoxia, chills, nausea, vomiting,
                  rash, elevated transaminases, fatigue, and headache. CRS
                  occurred in 89% of patients who received KIMMTRAK with 0.8%
                  being grade 3 or 4. Ensure immediate access to medications and
                  resuscitative equipment to manage CRS. Ensure patients are
                  euvolemic prior to initiating the infusions. Closely monitor
                  patients for signs or symptoms of CRS following infusions of
                  KIMMTRAK. Monitor fluid status, vital signs, and oxygenation
                  level and provide appropriate therapy. Withhold or discontinue
                  KIMMTRAK depending on persistence and severity of CRS.
                </p>
                <p align="justify"><strong>Skin Reactions </strong></p>
                <p align="justify">
                  Skin reactions, including rash, pruritus, and cutaneous edema
                  occurred in 91% of patients treated with KIMMTRAK. Monitor
                  patients for skin reactions. If skin reactions occur, treat
                  with antihistamine and topical or systemic steroids based on
                  persistence and severity of symptoms. Withhold or permanently
                  discontinue KIMMTRAK depending on the severity of skin
                  reactions.
                </p>
                <p align="justify"><strong>Elevated Liver Enzymes </strong></p>
                <p align="justify">
                  Elevations in liver enzymes occurred in 65% of patients
                  treated with KIMMTRAK. Monitor alanine aminotransferase (ALT),
                  aspartate aminotransferase (AST), and total blood bilirubin
                  prior to the start of and during treatment with KIMMTRAK.
                  Withhold KIMMTRAK according to severity.
                </p>
                <p align="justify"><strong>Embryo-Fetal Toxicity </strong></p>
                <p align="justify">
                  KIMMTRAK may cause fetal harm. Advise pregnant patients of
                  potential risk to the fetus and patients of reproductive
                  potential to use effective contraception during treatment with
                  KIMMTRAK and 1 week after the last dose. <br />The most common
                  adverse reactions (≥30%) in patients who received KIMMTRAK
                  were cytokine release syndrome, rash, pyrexia, pruritus,
                  fatigue, nausea, chills, abdominal pain, edema, hypotension,
                  dry skin, headache, and vomiting. The most common (≥50%)
                  laboratory abnormalities were decreased lymphocyte count,
                  increased creatinine, increased glucose, increased AST,
                  increased ALT, decreased hemoglobin, and decreased phosphate.
                  <br />For more information, please see full Summary of Product
                  Characteristics (SmPC) or full U.S. Prescribing Information
                  (including BOXED WARNING for CRS).
                </p>
                <p align="justify"><strong>About KIMMTRAKConnect </strong></p>
                <p align="justify">
                  Immunocore is committed to helping patients who need KIMMTRAK
                  obtain access via our KIMMTRAKConnect program. The program
                  provides services with dedicated nurse case managers who
                  provide personalized support, including educational resources,
                  financial assistance, and site of care coordination. To learn
                  more, visit KIMMTRAKConnect.com or call 844-775-2273.
                </p>
                <p align="justify"><strong>About Immunocore</strong></p>
                <p align="justify">
                  Immunocore is a commercial-stage biotechnology company
                  pioneering the development of a novel class of TCR bispecific
                  immunotherapies called ImmTAX – Immune mobilizing monoclonal
                  TCRs Against X disease – designed to treat a broad range of
                  diseases, including cancer, autoimmune, and infectious
                  disease. Leveraging its proprietary, flexible, off-the-shelf
                  ImmTAX platform, Immunocore is developing a deep pipeline in
                  multiple therapeutic areas, including nine active clinical and
                  pre-clinical programs​ in oncology, infectious diseases, and
                  autoimmune diseases. The Company’s most advanced oncology TCR
                  therapeutic, KIMMTRAK has been approved for the treatment of
                  HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p align="justify">
                  <strong>Forward Looking Statements </strong>
                </p>
                <p align="justify">
                  This press release contains “forward-looking statements”
                  within the meaning of the safe harbor provisions of the
                  Private Securities Litigation Reform Act of 1995. Words such
                  as “may,” “will,” “believe,” “expect,” “plan,” “anticipate,”
                  “estimate,” and similar expressions (as well as other words or
                  expressions referencing future events or circumstances) are
                  intended to identify forward-looking statements. All
                  statements, other than statements of historical facts,
                  included in this press release are forward-looking statements.
                  These statements include, but are not limited to, statements
                  regarding the expected clinical benefits of KIMMTRAK; the
                  potential of KIMMTRAK for patients with uveal and cutaneous
                  melanoma; and the Company’s expectations regarding the design,
                  progress, timing, randomization and scope of the Phase 3 trial
                  evaluating KIMMTRAK for previously treated advanced cutaneous
                  melanoma. Any forward-looking statements are based on
                  management’s current expectations and beliefs of future events
                  and are subject to a number of risks and uncertainties that
                  could cause actual events or results to differ materially and
                  adversely from those set forth in or implied by such
                  forward-looking statements, many of which are beyond the
                  Company’s control. These risks and uncertainties include, but
                  are not limited to, the impact of worsening macroeconomic
                  conditions on the Company’s business, financial position,
                  strategy and anticipated milestones, including Immunocore’s
                  ability to conduct ongoing and planned clinical trials;
                  Immunocore’s ability to obtain a clinical supply of current or
                  future product candidates or commercial supply of KIMMTRAK or
                  any future approved products, including as a result of health
                  epidemics or pandemics, war in Ukraine, the conflict between
                  Hamas and Israel, the broader risk of a regional conflict in
                  the Middle East, or global geopolitical tension; Immunocore’s
                  ability to obtain and maintain regulatory approval of its
                  product candidates, including KIMMTRAK; Immunocore’s ability
                  and plans in continuing to establish and expand a commercial
                  infrastructure and to successfully launch, market and sell
                  KIMMTRAK and any future approved products; Immunocore’s
                  ability to successfully expand the approved indications for
                  KIMMTRAK or obtain marketing approval for KIMMTRAK in
                  additional geographies in the future; the delay of any current
                  or planned clinical trials, whether due to patient enrollment
                  delays or otherwise; Immunocore’s ability to successfully
                  demonstrate the safety and efficacy of its product candidates
                  and gain approval of its product candidates on a timely basis,
                  if at all; competition with respect to market opportunities;
                  unexpected safety or efficacy data observed during preclinical
                  studies or clinical trials; actions of regulatory agencies,
                  which may affect the initiation, timing and progress of
                  clinical trials or future regulatory approval; Immunocore’s
                  need for and ability to obtain additional funding, on
                  favorable terms or at all, including as a result of worsening
                  macroeconomic conditions, including inflation, interest rates
                  and unfavorable general market conditions, and the impacts
                  thereon of the war in Ukraine, the conflict between Hamas and
                  Israel, and global geopolitical tension; Immunocore’s ability
                  to obtain, maintain and enforce intellectual property
                  protection for KIMMTRAK or any of its product candidates it or
                  its collaborators are developing; and the success of
                  Immunocore’s current and future collaborations, partnerships
                  or licensing arrangements. These and other risks and
                  uncertainties are described in greater detail in the section
                  titled "Risk Factors" in Immunocore’s filings with the
                  Securities and Exchange Commission, including Immunocore’s
                  most recent Annual Report on Form 10-K for the year ended
                  December 31, 2023 filed with the Securities and Exchange
                  Commission on February 28, 2024, as well as discussions of
                  potential risks, uncertainties, and other important factors in
                  the Company’s subsequent filings with the Securities and
                  Exchange Commission. All information in this press release is
                  as of the date of the release, and the Company undertakes no
                  duty to update this information, except as required by law.
                </p>
                <p align="justify"><strong>Contact Information</strong></p>
                <p align="justify"><strong>Communications</strong></p>
                <p align="justify">
                  Sébastien Desprez, Head of Communications<br />T: +44 (0)
                  7458030732<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  ><br />Follow on Twitter: @Immunocore
                </p>
                <p align="justify"><strong>Investor Relations</strong></p>
                <p align="justify">
                  Clayton Robertson, Head of Investor Relations<br />T: +1 (215)
                  384-4781<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  >
                </p>
                <p></p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDk2MzMyMCM0MDE4NjIyMjYjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/MTVlNzE1ZGMtY2U4Yy00MDgyLWIzMDAtZDk5MTAyNWYxMWQ4LTEyMTc4MTE=/tiny/Immunocore-Holdings-plc.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings plc
                <p class="spr-ir-news-article-date">Released May 29, 2024</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param:
      "immunocore-reports-first-quarter-financial-results-and-provides-a-business-update",
    title: `Immunocore reports first quarter financial results and provides a business
      update :: Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore reports first quarter financial results and
                  provides a business update
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2024-05-08T07:00:00" class="date">
                    May 08, 2024 7:00am EDT
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-05-08_Immunocore_reports_first_quarter_financial_89.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore reports first quarter financial results and provides a business update"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <div class="related-documents box hidden-print">
                  <div class="text">
                    <h2 class="related-docs-title">Related Documents</h2>
                    <div class="text">
                      <div class=" ">
                        <div>
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-24-024784/ef20026074_10q.htm"
                                  title="10-Q Filing Viewer"
                                  class="fancybox fancybox.iframe"
                                  rel="noopener"
                                  >10-Q <span class="sr-only">Filing</span></a
                                >
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-24-024784/ef20026074_10q.htm"
                                  title="10-Q Filing Viewer"
                                  rel="noopener"
                                  class="link fancybox fancybox.iframe"
                                  ><span class="eqicon-HTML"></span>HTML</a
                                >
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-24-024784/0001140361-24-024784.pdf"
                                  title="10-Q"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                  ><span class="eqicon-PDF"></span>PDF</a
                                >
                              </div>
                            </div>
                          </div>
                          <div class="result-line">
                            <div class="row justify-content-between">
                              <div class="col-auto file-title">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-24-024784/0001140361-24-024784-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  target="_blank"
                                  rel="noopener"
                                  >XBRL</a
                                >
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-24-024784/0001140361-24-024784-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                  ><span class="eqicon-ZIP"></span>ZIP</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p align="center">
                  <strong
                    >Immunocore reports first quarter financial results and
                    provides a business update</strong
                  >
                </p>
                <p align="center">
                  <em>KIMMTRAK</em><sup><em>®</em></sup
                  ><em>
                    (tebentafusp-tebn) net revenues of $70.3 million in Q1 2024;
                    continuing to expand global access with 7 additional
                    launches since January 2024</em
                  >
                </p>
                <p align="center">
                  <em
                    >Phase 1/2 brenetafusp (IMC-F106C; PRAME-A02) clinical data
                    in post-checkpoint late-line cutaneous melanoma selected for
                    oral presentation at ASCO 2024</em
                  >
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, May 8, 2024)
                  <a
                    href="https://www.globenewswire.com/Tracker?data=owyQNAO21ZaUY_mUzLC01LBtETkKRw7e032hGqvecwlLbabLvcscAGtfIDYkhfoMJwBa1a5DITQ2Q0FbjJipGBzXGQwYOmZtbnHvk9Fmn0k6ih83F0c1kx0IFhqwKI7h3Kdc64mCDNdcqEnN_nmZSkggl0Zmt2Ul3vSwOfZDc7KxxM7oLlVaFBT_e9C3iT-E"
                    rel="nofollow"
                    target="_blank"
                    >Immunocore Holdings plc (Nasdaq: IMCR) (“Immunocore” or the
                    “Company”)</a
                  >, a commercial-stage biotechnology company pioneering and
                  delivering transformative immunomodulating medicines to
                  radically improve outcomes for patients with cancer,
                  infectious diseases and autoimmune diseases, today announced
                  its financial results for the first quarter ended March 31,
                  2024 and provided a business update.
                </p>
                <p align="justify">
                  “With our differentiated pipeline, we continue to work with a
                  sense of urgency to bring KIMMTRAK to more patients; start the
                  first registrational trial for brenetafusp, our PRAME ImmTAC
                  therapy; and explore innovative new TCR treatments across
                  oncology, infectious diseases, and autoimmune diseases,” said
                  <strong
                    >Bahija Jallal, Chief Executive Officer of
                    Immunocore</strong
                  >.
                </p>
                <p align="justify">
                  “We continue to gain insights into our ImmTAC platform,
                  drawing on data from over 1,000 patients treated in our
                  clinical programs. I am particularly pleased that the
                  brenetafusp melanoma data has been selected for an oral
                  presentation at ASCO,” said
                  <strong
                    >Mohammed Dar, Head of Clinical Development and Chief
                    Medical Officer.</strong
                  >
                  “In addition, our KIMMTRAK registrational trial in late-line
                  cutaneous melanoma, TEBE-AM, is recruiting ahead of schedule.”
                </p>
                <p align="justify">
                  <strong
                    >First Quarter 2024 Highlights (including
                    post-period)</strong
                  >
                </p>
                <p align="justify">
                  <strong>Financial Results</strong><br />Total net product
                  revenue (or “net sales”) arising from the sales of
                  KIMMTRAK<sup>®</sup> (tebentafusp) was $70.3 million in the
                  first quarter of 2024, an increase of 36% over first quarter
                  of 2023, of which $50.0 million was generated in the United
                  States, $19.0 million in Europe (net of an increase in
                  estimated reserves of $5.4 million) and $1.4 million in
                  international regions.
                </p>
                <p align="justify">
                  Research &amp; development expenses for the three months ended
                  March 31, 2024 were $57.5 million, compared to $36.6 million
                  for the same period in 2023. Selling, general and
                  administrative (SG&amp;A) expenses for the three months ended
                  March 31, 2024 were $39.3 million, compared to $32.6 million
                  for the same period in 2023.
                </p>
                <p align="justify">
                  Net loss for the first quarter of 2024 was $24.4 million
                  compared to a net loss of $19.4 million in the same period in
                  2023.
                </p>
                <p align="justify">
                  The first quarter basic and diluted loss per share was $0.49,
                  compared to $0.40 for the first quarter of 2023.
                </p>
                <p align="justify">
                  Cash and cash equivalents at March 31, 2024 were $832.8
                  million. This includes net cash proceeds of $390.2 million
                  from the Company’s offering of convertible notes in February
                  2024. The Company plans to use $50 million from the net
                  proceeds to repay its existing loan by the end of 2024.
                </p>
                <p align="justify">
                  <strong>KIMMTRAK</strong><br /><em
                    >The Company’s lead product, KIMMTRAK</em
                  ><sup><em>®</em></sup
                  ><em>
                    (tebentafusp-tebn), is approved in 38 countries and has been
                    launched in 17 countries globally to date for people with
                    HLA-A02+ metastatic uveal melanoma (mUM). KIMMTRAK continues
                    to be the standard of care in most markets where it is
                    launched. The Company sees three key growth areas for the
                    KIMMTRAK opportunity, including: continued global expansion
                    in mUM, as well as the potential expansion into adjuvant
                    uveal melanoma and 2L+ advanced cutaneous melanoma (CM).
                  </em>
                </p>
                <p align="justify">
                  <strong><em>Metastatic uveal melanoma</em></strong>
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    In Q1 2024, KIMMTRAK net product sales were $70.3 million.
                  </li>
                  <li style="text-align: justify">
                    Launched KIMMTRAK in 7 additional countries (Australia,
                    Canada, Spain, Bulgaria, Luxembourg, Czech Republic,
                    Lithuania), since January 2024, for a total of 17 countries.
                  </li>
                  <li style="text-align: justify">
                    Continued to drive global launches, early patient
                    identification, and market share growth in key markets.
                  </li>
                  <li style="text-align: justify">
                    Three posters accepted at ASCO 2024.
                  </li>
                </ul>
                <p align="justify">
                  <strong><em>2L + advanced cutaneous melanoma </em></strong>
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    Randomization in TEBE-AM Phase 2/3 is ahead of schedule.<br /><br />
                  </li>
                </ul>
                <p align="justify">
                  <strong><em>Adjuvant uveal (or ocular) melanoma</em></strong>
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    Randomization in the ATOM Phase 3 trial, led by the European
                    Organisation for Research and Treatment of Cancer (EORTC),
                    expected to start in the second half of 2024.<br /><br />
                  </li>
                </ul>
                <p align="justify">
                  <strong>PRAME franchise </strong><br /><em
                    >Brenetafusp (IMC-F106C) is the Company’s lead PRAME-A02
                    ImmTAC bispecific protein being investigated in solid
                    tumors. The Company is evaluating brenetafusp, in
                    combination with nivolumab, in a Phase 3 registrational
                    trial (PRISM-MEL-301) in patients with first-line advanced
                    cutaneous melanoma (CM). Brenetafusp is also being tested in
                    monotherapy and combination in a Phase 1/2 clinical trial
                    across multiple tumor types, including platinum resistant
                    ovarian, non-small cell lung, and endometrial carcinoma. The
                    Company’s PRAME franchise also includes two new PRAME ImmTAC
                    candidates: IMC-P115C (PRAME-A02 HLE), a half-life extended
                    version of brenetafusp for improved dosing convenience, and
                    IMC-T119C (PRAME-A24), which is suitable for people with
                    HLA-A24 allele.
                  </em>
                </p>
                <p align="justify">
                  <strong
                    ><em
                      >PRISM-MEL-301 – First PRAME Phase 3 clinical trial with
                      brenetafusp in first-line advanced cutaneous melanoma</em
                    ></strong
                  >
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    Randomization of first patient in PRISM-MEL-301 expected in
                    the second quarter of 2024.
                  </li>
                  <li style="text-align: justify">
                    In February 2024, the Company entered into a clinical trial
                    collaboration and supply agreement with Bristol Myers Squibb
                    (NYSE:BMY) to investigate brenetafusp in combination with
                    nivolumab, in first-line advanced CM. Immunocore will
                    sponsor and fund the study (PRISM-MEL-301), and Bristol
                    Myers Squibb will provide nivolumab.
                  </li>
                </ul>
                <p align="justify">
                  <strong
                    ><em
                      >Phase 1/2 clinical trial of brenetafusp (PRAME-A02) in
                      multiple solid tumors</em
                    ></strong
                  >
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    Data from the Phase 1/2 trial with brenetafusp in patients
                    with late-line CM selected for oral presentation on May 31,
                    2024 at the annual ASCO meeting. The Company will also host
                    an analyst and investor event on the same day.
                  </li>
                  <li style="text-align: justify">
                    Additional clinical data from the ongoing monotherapy and
                    combination cohorts is expected to be reported throughout
                    2024 including ovarian (expected by 3Q 2024), and non-small
                    cell lung carcinoma (expected by 4Q 2024).
                  </li>
                </ul>
                <p align="justify">
                  <strong
                    ><em
                      >IMC-P115C (PRAME-A02 Half-Life Extended) &amp; IMC-T119C
                      (PRAME-A24)</em
                    ></strong
                  >
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    Remain on track for Investigational New Drug (IND) or
                    Clinical Trial Application (CTA) submissions for IMC-P115C
                    by mid-2024 and for IMC-T119C in the fourth quarter of 2024.
                    <br /><br />
                  </li>
                </ul>
                <p align="justify">
                  <strong>Additional Oncology Candidates</strong>
                </p>
                <p align="justify">
                  <strong
                    ><em
                      >IMC-R117C (first PIWIL1-A02 targeted immunotherapy) for
                      colorectal and other gastrointestinal cancers</em
                    ></strong
                  >
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    The CTA for IMC-R117C was accepted in April 2024 by the EMA,
                    and the Company expects a Phase 1 clinical trial to start in
                    the second half of 2024.<br /><br />
                  </li>
                </ul>
                <p align="justify">
                  <strong
                    >ImmTAV candidates for a functional cure in infectious
                    diseases</strong
                  ><br /><em
                    >The Company’s bispecific TCR technology platform has
                    potential to offer a new approach for the treatment of
                    chronic infections and aims to eliminate evidence of
                    remaining virus in circulation after the patient stops
                    taking medication - known as a "functional cure". Two
                    investigational candidates are in Phase 1 clinical trials
                    for people living with human immunodeficiency virus (HIV)
                    and people with chronic Hepatitis B infection (HBV).</em
                  >
                </p>
                <p align="justify">
                  <strong
                    ><em
                      >Phase 1 trial of IMC-M113V (gag A02) for people living
                      with HIV</em
                    ></strong
                  >
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    Patient enrollment continues into the multiple ascending
                    dose (MAD) part of a Phase 1 clinical trial to identify a
                    safe and tolerable dose.
                  </li>
                  <li style="text-align: justify">
                    This clinical trial will also evaluate whether IMC-M113V
                    could lead to reduction in the viral reservoir and, after
                    stopping all therapies (antiretroviral therapies and
                    IMC-M113V), delay or prevent HIV rebound.
                  </li>
                  <li style="text-align: justify">
                    The Company expects to present the MAD data in the second
                    half of 2024.
                  </li>
                  <li style="text-align: justify">
                    In February 2024, the Company presented two pre-clinical
                    posters at the 2024 Conference on Retroviruses and
                    Opportunistic Infections (CROI).
                  </li>
                </ul>
                <p align="justify">
                  <strong
                    ><em
                      >Phase 1 trial of IMC-I109V (Envelope A02) for people
                      living with HBV or HBV-positive hepatocellular
                      carcinoma</em
                    ></strong
                  >
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    Patient enrollment continues into the single ascending dose
                    portion of the clinical trial.<br /><br />
                  </li>
                </ul>
                <p align="justify">
                  <strong
                    >Tissue-specific down modulation of the immune system for
                    autoimmune diseases</strong
                  ><br /><em
                    >The Company is expanding its platform into autoimmune
                    diseases with two new, first-in-class bispecific candidates
                    recently entering its pipeline. The key differentiator of
                    the Company’s ImmTAAI (Immune Modulating Monoclonal TCRs
                    Against AutoImmune disease) platform is tissue-specific down
                    modulation of the immune system whereby, when tethered to
                    the tissue of interest, the new candidates suppress
                    pathogenic T cells via PD1 receptor agonism.
                  </em>
                </p>
                <p align="justify">
                  <strong
                    ><em
                      >IMC-S118AI (pre-pro insulin A02 x PD1), intended for
                      disease-modifying treatment in type 1 diabetes
                    </em></strong
                  >
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    IMC-S118AI recognizes a peptide from pre-proinsulin
                    presented by HLA-A02 on beta cells, coupled with a PD1
                    agonist effector arm.
                  </li>
                  <li style="text-align: justify">
                    IMC-S118AI is advancing towards GMP manufacturing in 2024.
                  </li>
                </ul>
                <p align="justify">
                  <strong
                    ><em
                      >Undisclosed non-HLA restricted (universal) candidate for
                      inflammatory dermatological diseases
                    </em></strong
                  >
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    The candidate is an antigen presenting cell (APC) tethered
                    ImmTAAI and is not HLA restricted (i.e. universal for all
                    populations).<br /><br />
                  </li>
                </ul>
                <p align="justify">
                  <strong>Financial Results</strong><br />Basic and diluted loss
                  per share was $0.49 for the quarter ended March 31, 2024, as
                  compared to a basic and diluted loss per share of $0.40 for
                  the same period in 2023. Net loss for the quarter ended March
                  31, 2024 was $24.4 million, as compared to $19.4 million for
                  the same period in 2023.
                </p>
                <p align="justify">
                  For the first quarter ended March 31, 2024, the Company
                  generated net sales of $70.3 million compared to $51.6 million
                  for the same period in 2023, due to revenue from KIMMTRAK, of
                  which $50.0 million was in the United States, $19.0 million
                  (net of an increase in estimated reserves of $5.4 million) was
                  in Europe, and $1.4 million was in the international regions.
                  The increase in net sales was due primarily to increased
                  volume in the United States and global country expansion, as
                  the Company continued its commercialization efforts.
                </p>
                <p align="justify">
                  For the first quarter ended March 31, 2024, research and
                  development (R&amp;D) expenses were $57.5 million, compared to
                  $36.6 million for the same period in 2023. These increases
                  were primarily driven by expenses incurred for the PRAME
                  programs, including the initiation of the Company’s Phase 3
                  clinical trial.
                </p>
                <p align="justify">
                  For the quarter ended March 31, 2024, SG&amp;A expenses were
                  $39.3 million, compared to $32.6 million for the same period
                  in 2023. This increase was primarily related to additional
                  employees engaged in business support functions, including
                  medical and regulatory activities, to support our growing
                  pipeline and commercial activities.
                </p>
                <p align="justify">
                  Cash and cash equivalents were $832.8 million as of March 31,
                  2024, as compared to $442.6 million as of December 31, 2023.
                  In February 2024, the Company raised net cash proceeds of
                  $390.2 million from a convertible notes offering with a
                  six-year term and 2.50% interest rate. The Company plans to
                  use $50 million from the net proceeds to repay its existing
                  Pharmakon loan by the end of 2024.
                </p>
                <p align="justify">
                  As of December 31, 2023, the Company no longer qualified as a
                  foreign private issuer for U.S. public company reporting
                  purposes. Effective January 1, 2024, it now files periodic
                  reports on U.S. domestic filer forms with the Securities and
                  Exchange Commission (SEC) and complies with other rules as
                  required, including but not limited to presenting its
                  financial results in press releases and Annual Report on Form
                  10-K in accordance with U.S. GAAP, with such change being
                  applied retrospectively including for the quarter ended March
                  31, 2023. See the Company’s Annual Report on Form 10-K, and
                  its Form 10-Q filed today with the SEC, for more information.
                </p>
                <p align="center">##</p>
                <p align="justify">
                  <strong>About ImmTAC<sup>®</sup> molecules for cancer</strong>
                </p>
                <p align="justify">
                  Immunocore’s proprietary T cell receptor (TCR) technology
                  generates a novel class of bispecific biologics called ImmTAC
                  (Immune mobilizing monoclonal TCRs Against Cancer) molecules
                  that are designed to redirect the immune system to recognize
                  and kill cancerous cells. ImmTAC molecules are soluble TCRs
                  engineered to recognize intracellular cancer antigens with
                  ultra-high affinity and selectively kill these cancer cells
                  via an anti-CD3 immune-activating effector function. Based on
                  the demonstrated mechanism of T cell infiltration into human
                  tumors, the ImmTAC mechanism of action holds the potential to
                  treat hematologic and solid tumors, regardless of mutational
                  burden or immune infiltration, including immune “cold” low
                  mutation rate tumors.
                </p>
                <p align="justify">
                  <strong
                    >About ImmTAV molecules and infectious diseases</strong
                  >
                </p>
                <p align="justify">
                  ImmTAV (Immune mobilizing monoclonal TCRs Against Virus)
                  molecules are novel bispecifics that, like ImmTAC (Immune
                  mobilizing monoclonal TCRs Against Cancer) molecules, are
                  designed to enable the immune system to recognize and
                  eliminate virally infected cells.
                </p>
                <p align="justify">
                  Immunocore is advancing clinical candidates to cure patients
                  with HIV and hepatitis B virus (HBV). The Company aims to
                  achieve sustained control of HIV after patients stop
                  anti-retroviral therapy (ART), without the risk of virological
                  relapse or onward transmission. This is known as ‘functional
                  cure’. For the treatment of HBV, the Company aims to achieve
                  sustained loss of circulating viral antigens and markers of
                  viral replication after stopping medication for people living
                  with chronic HBV.
                </p>
                <p align="justify">
                  <strong
                    >About ImmTAAI molecules and autoimmune diseases</strong
                  >
                </p>
                <p align="justify">
                  ImmTAAI (Immune mobilizing monoclonal TCRs Against Autoimmune)
                  molecules are novel bispecifics that are designed for
                  tissue-specific down modulation of the immune system. When
                  tethered to the tissue of interest, ImmTAAI candidates
                  suppress pathogenic T cells via PD1 receptor agonism. The
                  Company is currently advancing two candidates for autoimmune
                  conditions, including Type 1 Diabetes and inflammatory
                  dermatological diseases.
                </p>
                <p align="justify">
                  <strong
                    >About PRISM-MEL-301 – Phase 3 trial with brenetafusp
                    (IMC-F106C, PRAME-A02) in 1L advanced cutaneous
                    melanoma</strong
                  >
                </p>
                <p align="justify">
                  The Phase 3 registrational trial will randomize patients with
                  previously untreated, HLA-A*02:01-positive, advanced melanoma
                  to brenetafusp + nivolumab versus nivolumab or nivolumab +
                  relatlimab, depending on the country where the patient is
                  enrolled. The trial will initially randomize to three arms:
                  two brenetafusp dose regimens (40 mcg and 160 mcg) and control
                  arm and will discontinue one of the brenetafusp dose regimens
                  after an initial review of the first 60 patients randomized to
                  the two experimental arms (90 patients randomized total). The
                  primary endpoint of the trial is progression free survival
                  (PFS) by blinded independent central review (BICR), with
                  secondary endpoints of overall survival (OS) and overall
                  response rate (ORR).
                </p>
                <p align="justify">
                  <strong>About the IMC-F106C-101 Phase 1/2 trial</strong>
                </p>
                <p align="justify">
                  IMC-F106C-101 is a first-in-human, Phase 1/2 dose escalation
                  clinical trial in patients with multiple solid tumor cancers
                  including non-small cell lung cancer (NSCLC), small-cell lung
                  cancer (SCLC), endometrial, ovarian, cutaneous melanoma, and
                  breast cancers. The Phase 1 dose escalation trial was designed
                  to determine the maximum tolerated dose (MTD), as well as to
                  evaluate the safety, preliminary anti-tumor activity and
                  pharmacokinetics of IMC-F106C (brenetafusp), a bispecific
                  protein built on Immunocore’s ImmTAC technology, and the
                  Company’s first molecule to target the PRAME antigen. The
                  Company has initiated patient enrollment into four expansion
                  arms in cutaneous melanoma, ovarian, NSCLC, and endometrial
                  carcinomas. The IMC-F106C-101 trial is adaptive and includes
                  the option for Phase 2 expansion, allowing for approximately
                  100 patients treated per tumor type in the Phase 1 and 2
                  expansion arms. Dose escalation continues in additional solid
                  tumors as well as plans for combination arms with
                  standards-of-care, including checkpoint inhibitors,
                  chemotherapy, and tebentafusp.
                </p>
                <p align="justify">
                  About TEBE-AM - Phase 2/3 trial with tebentafusp (gp100xCD3)
                  in second-line or later cutaneous melanoma
                </p>
                <p align="justify">
                  The clinical trial is randomizing patients with second-line or
                  later cutaneous melanoma who have progressed on an anti-PD1,
                  received prior ipilimumab and, if applicable, received a BRAF
                  kinase inhibitor. Patients will be randomized to one of three
                  arms including tebentafusp, as monotherapy or in combination
                  with an anti-PD1, and a control arm. The Phase 2 portion of
                  the trial will include 40 patients per arm.
                </p>
                <p align="justify">
                  <strong>About the ATOM Phase 3 trial</strong>
                </p>
                <p align="justify">
                  The EORTC-led Phase 3 clinical trial will include sites in 10
                  EU countries and the United States and will randomize patients
                  with HLA-A*02:01-positive high-risk primary uveal melanoma
                  after definitive treatment, by surgery or radiotherapy, and no
                  evidence of metastatic disease on imaging. The clinical trial
                  is expected to enroll a total of 290 patients who will be
                  randomized 1:1 to one of two arms: KIMMTRAK as monotherapy or
                  observation. The primary endpoint of the trial is relapse-free
                  survival (RFS), with secondary objectives of overall survival
                  and safety and tolerability of tebentafusp. Exploratory
                  objectives include the comparison of the health-related
                  quality of life between the treatment arms and the evaluation
                  of the role of circulating tumor DNA as a biomarker for the
                  presence of residual disease.
                </p>
                <p align="justify"><strong>About Uveal Melanoma</strong></p>
                <p align="justify">
                  Uveal melanoma is a rare and aggressive form of melanoma,
                  which affects the eye. Although it is the most common primary
                  intraocular malignancy in adults, the diagnosis is rare, and
                  up to 50% of people with uveal melanoma will eventually
                  develop metastatic disease. Unresectable or metastatic uveal
                  melanoma typically has a poor prognosis and had no approved
                  treatment until KIMMTRAK.
                </p>
                <p align="justify">
                  <strong>About KIMMTRAK<sup>®</sup></strong>
                </p>
                <p align="justify">
                  KIMMTRAK is a novel bispecific protein comprised of a soluble
                  T cell receptor fused to an anti-CD3 immune-effector function.
                  KIMMTRAK specifically targets gp100, a lineage antigen
                  expressed in melanocytes and melanoma. This is the first
                  molecule developed using Immunocore’s ImmTAC technology
                  platform designed to redirect and activate T cells to
                  recognize and kill tumor cells. KIMMTRAK has been approved for
                  the treatment of HLA-A*02:01-positive adult patients with
                  unresectable or metastatic uveal melanoma in the United
                  States, European Union, Canada, Australia, and the United
                  Kingdom.
                </p>
                <p align="justify">IMPORTANT SAFETY INFORMATION</p>
                <p align="justify">
                  <strong
                    >Cytokine Release Syndrome (CRS), which may be serious or
                    life-threatening, occurred in patients receiving KIMMTRAK.
                    Monitor for at least 16 hours following first three
                    infusions and then as clinically indicated.</strong
                  >
                  Manifestations of CRS may include fever, hypotension, hypoxia,
                  chills, nausea, vomiting, rash, elevated transaminases,
                  fatigue, and headache. CRS occurred in 89% of patients who
                  received KIMMTRAK with 0.8% being grade 3 or 4. Ensure
                  immediate access to medications and resuscitative equipment to
                  manage CRS. Ensure patients are euvolemic prior to initiating
                  the infusions. Closely monitor patients for signs or symptoms
                  of CRS following infusions of KIMMTRAK. Monitor fluid status,
                  vital signs, and oxygenation level and provide appropriate
                  therapy. Withhold or discontinue KIMMTRAK depending on
                  persistence and severity of CRS.
                </p>
                <p align="justify"><strong>Skin Reactions</strong></p>
                <p align="justify">
                  Skin reactions, including rash, pruritus, and cutaneous edema
                  occurred in 91% of patients treated with KIMMTRAK. Monitor
                  patients for skin reactions. If skin reactions occur, treat
                  with antihistamine and topical or systemic steroids based on
                  persistence and severity of symptoms. Withhold or permanently
                  discontinue KIMMTRAK depending on the severity of skin
                  reactions.
                </p>
                <p align="justify"><strong>Elevated Liver Enzymes</strong></p>
                <p align="justify">
                  Elevations in liver enzymes occurred in 65% of patients
                  treated with KIMMTRAK. Monitor alanine aminotransferase (ALT),
                  aspartate aminotransferase (AST), and total blood bilirubin
                  prior to the start of and during treatment with KIMMTRAK.
                  Withhold KIMMTRAK according to severity.
                </p>
                <p align="justify">
                  <strong>Embryo-Fetal Toxicity</strong><br />KIMMTRAK may cause
                  fetal harm. Advise pregnant patients of potential risk to the
                  fetus and patients of reproductive potential to use effective
                  contraception during treatment with KIMMTRAK and 1 week after
                  the last dose.
                </p>
                <p align="justify">
                  The most common adverse reactions (≥30%) in patients who
                  received KIMMTRAK were cytokine release syndrome, rash,
                  pyrexia, pruritus, fatigue, nausea, chills, abdominal pain,
                  edema, hypotension, dry skin, headache, and vomiting. The most
                  common (≥50%) laboratory abnormalities were decreased
                  lymphocyte count, increased creatinine, increased glucose,
                  increased AST, increased ALT, decreased hemoglobin, and
                  decreased phosphate.
                </p>
                <p align="justify">
                  For more information, please see full Summary of Product
                  Characteristics (SmPC) or full U.S. Prescribing Information
                  (including BOXED WARNING for CRS).
                </p>
                <p align="justify">
                  <strong>About KIMMTRAKConnect</strong><br />Immunocore is
                  committed to helping patients who need KIMMTRAK obtain access
                  via our KIMMTRAKConnect program. The program provides services
                  with dedicated nurse case managers who provide personalized
                  support, including educational resources, financial
                  assistance, and site of care coordination. To learn more,
                  visit KIMMTRAKConnect.com or call 844-775-2273.
                </p>
                <p align="justify"><strong>About Immunocore</strong></p>
                <p align="justify">
                  Immunocore is a commercial-stage biotechnology company
                  pioneering the development of a novel class of TCR bispecific
                  immunotherapies called ImmTAX – Immune mobilizing monoclonal
                  TCRs Against X disease – designed to treat a broad range of
                  diseases, including cancer, autoimmune, and infectious
                  disease. Leveraging its proprietary, flexible, off-the-shelf
                  ImmTAX platform, Immunocore is developing a deep pipeline in
                  multiple therapeutic areas, including nine active clinical and
                  pre-clinical programs​ in oncology, infectious diseases, and
                  autoimmune diseases. The Company’s most advanced oncology TCR
                  therapeutic, KIMMTRAK has been approved for the treatment of
                  HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p align="justify">
                  <strong>Forward Looking Statements</strong>
                </p>
                <p align="justify">
                  This press release contains “forward-looking statements”
                  within the meaning of the safe harbor provisions of the
                  Private Securities Litigation Reform Act of 1995. Words such
                  as “may”, “will”, “believe”, “expect”, “plan”, “anticipate”,
                  “estimate”, and similar expressions (as well as other words or
                  expressions referencing future events or circumstances) are
                  intended to identify forward-looking statements. All
                  statements, other than statements of historical facts,
                  included in this press release are forward-looking statements.
                  These statements include, but are not limited to, statements
                  regarding the commercial performance of KIMMTRAK, including
                  expanded access to KIMMTRAK to more patients in the United
                  States, Europe and globally, expected additional market
                  launches; early patient identification, and market share
                  growth; the potential benefits and advantages Immunocore’s
                  products and product candidates, including KIMMTRAK and
                  brenetafusp, are expected to provide for patients, including
                  the potential of KIMMTRAK for expansion into other indications
                  such as cutaneous and adjuvant uveal melanoma; expectations
                  regarding the design, progress, timing, enrollment,
                  randomization, scope, expansion, funding, and results of the
                  Company’s ongoing and planned clinical trials, those of the
                  Company’s collaboration partners or the combined clinical
                  trials with the Company’s collaboration partners; statements
                  regarding the benefits of the Company’s collaboration with
                  Bristol-Meyers Squibb; the timing and sufficiency of clinical
                  trial outcomes to support potential approval of any of the
                  Company’s product candidates or those of, or combined with,
                  its collaboration partners; the Company’s goals to develop and
                  commercialize product candidates based on its KIMMTRAK
                  platform alone or with collaboration partners; the expected
                  submission of investigational new drug applications or
                  clinical trial applications; the potential regulatory
                  approval, expected clinical benefits and availability of the
                  Company’s product candidates; and the use of proceeds from the
                  convertible notes offering and pro forma cash position after
                  the estimated use of proceeds. Any forward-looking statements
                  are based on management’s current expectations and beliefs of
                  future events and are subject to a number of risks and
                  uncertainties that could cause actual events or results to
                  differ materially and adversely from those set forth in or
                  implied by such forward-looking statements, many of which are
                  beyond the Company’s control. These risks and uncertainties
                  include, but are not limited to, the impact of worsening
                  macroeconomic conditions on the Company’s business, financial
                  position, strategy and anticipated milestones, including
                  Immunocore’s ability to conduct ongoing and planned clinical
                  trials; Immunocore’s ability to obtain a clinical supply of
                  current or future product candidates or commercial supply of
                  KIMMTRAK or any future approved products, including as a
                  result of health epidemics or pandemics, war in Ukraine, the
                  conflict between Hamas and Israel, the broader risk of a
                  regional conflict in the Middle East, or global geopolitical
                  tension; Immunocore’s ability to obtain and maintain
                  regulatory approval of its product candidates, including
                  KIMMTRAK; Immunocore’s ability and plans in continuing to
                  establish and expand a commercial infrastructure and to
                  successfully launch, market and sell KIMMTRAK and any future
                  approved products; Immunocore’s ability to successfully expand
                  the approved indications for KIMMTRAK or obtain marketing
                  approval for KIMMTRAK in additional geographies in the future;
                  the delay of any current or planned clinical trials, whether
                  due to patient enrollment delays or otherwise; Immunocore’s
                  ability to successfully demonstrate the safety and efficacy of
                  its product candidates and gain approval of its product
                  candidates on a timely basis, if at all; competition with
                  respect to market opportunities; unexpected safety or efficacy
                  data observed during preclinical studies or clinical trials;
                  actions of regulatory agencies, which may affect the
                  initiation, timing and progress of clinical trials or future
                  regulatory approval; Immunocore’s need for and ability to
                  obtain additional funding, on favorable terms or at all,
                  including as a result of worsening macroeconomic conditions,
                  including changes inflation and interest rates and unfavorable
                  general market conditions, and the impacts thereon of the war
                  in Ukraine, the conflict between Hamas and Israel, and global
                  geopolitical tension; Immunocore’s ability to obtain, maintain
                  and enforce intellectual property protection for KIMMTRAK or
                  any of its product candidates it or its collaborators are
                  developing; and the success of Immunocore’s current and future
                  collaborations, partnerships or licensing arrangements,
                  including the risk that Immunocore may not realize the
                  anticipated benefits of its collaboration with Bristol Myers
                  Squibb. These and other risks and uncertainties are described
                  in greater detail in the section titled "Risk Factors" in
                  Immunocore’s filings with the Securities and Exchange
                  Commission, including Immunocore’s most recent Annual Report
                  on Form 10-K for the year ended December 31, 2023 filed with
                  the Securities and Exchange Commission on February 28, 2024,
                  as well as discussions of potential risks, uncertainties, and
                  other important factors in the Company’s most recent Form 10-Q
                  and other subsequent filings with the SEC. All information in
                  this press release is as of the date of the release, and the
                  Company undertakes no duty to update this information, except
                  as required by law.
                </p>
                <p align="justify"><strong>Contact Information</strong></p>
                <p align="justify"><strong>Immunocore </strong></p>
                <p align="justify">
                  Sébastien Desprez, Head of Communications<br />T: +44 (0)
                  7458030732<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  ><br />Follow on Twitter: @Immunocore
                </p>
                <p align="justify"><strong>Investor Relations</strong></p>
                <p align="justify">
                  Clayton Robertson, Head of Investor Relations<br />T: +1 (215)
                  384-4781<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  >
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 425.6pt;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Immunocore Holdings PLC</strong>
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Consolidated Statement of Operations</strong>
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong
                        >Comparison of the Quarters ended March 31, 2024 and
                        2023</strong
                      >
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$'000</strong>
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 104.4px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Quarter Ended</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 17.74px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>March 31, 2024</strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>March 31, 2023</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Product revenue
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $70,342
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $51,581
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Collaboration revenue
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      160
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      3,078
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Total revenue</strong>
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>70,502 </strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 54,659 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Cost of product revenue
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (246)
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (216)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Research and development expense
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (57,459)
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (36,572)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Selling, general, &amp; administrative expense
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (39,287)
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (32,567)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Operating loss</strong>
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>(26,490)</strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>(14,696)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Interest income
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      8,246
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      3,128
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Interest expense
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (3,239)
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (1,250)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Foreign currency (loss)
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (2,406)
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (6,013)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Other expense, net
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (190)
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (325)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Net loss before income taxes
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>(24,079)</strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>(19,156)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Income tax expense
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (357)
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (293)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Net loss </strong>
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> $(24,436)</strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$(19,449)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Other Comprehensive income:</strong>
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Exchange differences on translation of foreign operations
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      897
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      7,434
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Total Comprehensive loss</strong>
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$(23,539)</strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$(12,015)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Net loss per share</strong>
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$(0.49)</strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$(0.40)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 463.07px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <em
                        >Basic and diluted weighted average number of shares</em
                      >
                    </td>
                    <td
                      style="
                        width: 17.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <br /><br /><em>49,877,218 </em>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <em> 48,183,771 </em>
                    </td>
                  </tr>
                </table>
                <p><br /></p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 471pt;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Immunocore Holdings PLC</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Consolidated Balance Sheets</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>As of </strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$'000</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 90.67px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Mar '24</strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Dec '23</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>ASSETS</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Current assets</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Cash and cash equivalents
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $832,821
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $442,626
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Accounts receivable, net
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      57,754
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      52,093
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Prepaid expenses and other current assets
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      31,296
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      29,600
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Inventory
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      4,167
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      4,501
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Total current assets</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>926,038 </strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 528,820 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Property, plant and equipment, net
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      8,380
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      9,215
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Operating lease, right of use assets, net
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      32,812
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      33,520
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Deferred tax assets, net
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      10,761
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      10,973
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Other non-current assets
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      15,996
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      14,473
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Total assets</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$993,987 </strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> $597,001 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Liabilities and shareholders’ equity</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Current liabilities</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Accounts payables
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $15,501
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $17,798
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Accrued expenses &amp; other current liabilities
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      138,549
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      119,835
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Operating lease liabilities, current
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      1,243
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      1,388
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Total current liabilities</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>155,293 </strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 139,021 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Accrued expenses, non-current
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      2,162
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      978
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Deferred revenue, non-current
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      5,468
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      5,515
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Operating lease liabilities, non-current
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      33,986
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      34,633
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Interest-bearing loans and borrowings
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      437,544
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      48,011
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Total liabilities</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>634,453 </strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 228,158 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Shareholders' equity</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Common stock
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      135
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      134
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Deferred stock
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      1
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      1
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Additional paid-in capital
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      1,163,872
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      1,149,643
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Accumulated deficit
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (769,110)
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (744,674)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Accumulated other comprehensive (loss)
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (35,364)
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (36,261)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Total shareholders' equity</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>359,534 </strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 368,843 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong
                        >Total liabilities and shareholders' equity</strong
                      >
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$993,987 </strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> $597,001 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <br /><br /><br /><br /><strong
                        >Immunocore Holdings PLC</strong
                      >
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong
                        >Summary Consolidated Statement of Cash Flows</strong
                      >
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>For the Quarter Ended March 31,</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$'000</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 90.67px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 2024</strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>2023</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Cash and cash equivalents, beg of year
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $442,626
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $402,472
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Net cash provided by (used in) operating activities
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (4,587)
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      10,539
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Net cash (used in) investing activities
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (430)
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (3,001)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Net cash provided by financing activities
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      396,012
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      6,139
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      Net foreign exchange difference on cash held
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (800)
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      2,228
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 450.67px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Cash and cash equivalents, end of year</strong>
                    </td>
                    <td
                      style="
                        width: 90.67px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$832,821 </strong>
                    </td>
                    <td
                      style="
                        width: 86.67px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> $418,377 </strong>
                    </td>
                  </tr>
                </table>
                <p></p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDk0OTY4MCM0MDE4NDIxNjUjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/ODRhM2U1YzMtM2YwZC00M2I2LThkMzAtZWE1MGZlZDI0Y2YyLTEyMTc4MTE=/tiny/Immunocore-Holdings-plc.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings plc
                <p class="spr-ir-news-article-date">Released May 8, 2024</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param:
      "immunocore-announces-upcoming-presentation-and-posters-at-asco-2024",
    title: `Immunocore announces upcoming presentation and posters at ASCO 2024 ::
      Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore announces upcoming presentation and posters at ASCO
                  2024
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2024-04-24T10:00:00" class="date">
                    April 24, 2024 10:00am EDT
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-04-24_Immunocore_announces_upcoming_presentation_and_88.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore announces upcoming presentation and posters at ASCO 2024"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <p align="center">
                  <strong
                    >Immunocore announces upcoming presentation and posters at
                    ASCO 2024</strong
                  >
                </p>
                <p align="center">
                  <strong
                    ><em
                      >Phase 1 expansion data in immune checkpoints pre-treated
                      cutaneous melanoma for brenetafusp (IMC-F106C targeting
                      PRAME) to be presented during oral abstract session</em
                    ></strong
                  >
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, 24 April 2024)
                  <a
                    href="https://www.globenewswire.com/Tracker?data=ioDWiVwbunJrBJEYAp15tS_uS4vnm_3GKb4ohOhzkVUGt7SPh-n4AYXt6MDWt7mwH4gQUrdxWgXd-Sg9nh4RuwwOUFUusEDfdxajIWO7_91CYGuF-ZckQgl0cajO4QlnDijUqj7cO4VA0EcRvzyz_7HhsYQT0GEyVuWCnYAuq7Dns0kiBKbNhhPLrSHt_DWO"
                    rel="nofollow"
                    target="_blank"
                    >Immunocore Holdings plc (Nasdaq: IMCR) (“Immunocore” or the
                    “Company”)</a
                  >, a commercial-stage biotechnology company pioneering and
                  delivering transformative immunomodulating medicines to
                  radically improve outcomes for patients with cancer,
                  infectious diseases and autoimmune diseases, will present
                  Phase 1 expansion data for brenetafusp (IMC-F106C), the first
                  off-the-shelf ImmTAC therapy targeting PRAME, in patients with
                  late-line cutaneous melanoma, all previously treated with
                  anti-PD1 and the vast majority having received ipilimumab, at
                  the 2024 American Society of Oncology (ASCO) Annual Meeting
                  commencing on 31 May.
                </p>
                <p align="justify">
                  The Company will also present four posters, including one
                  trial-in-progress poster of the Phase 3 PRISM-MEL301 trial
                  with brenetafusp in combination with nivolumab versus standard
                  nivolumab regimens in HLA-A*02:01+ patients with first-line
                  advanced melanoma, and three posters sharing clinical and
                  translational data about KIMMTRAK in metastatic uveal
                  melanoma.
                </p>
                <p align="justify">
                  <strong>Presentation and poster details</strong>
                </p>
                <p align="justify">
                  Title: Phase 1 safety and efficacy of IMC-F106C, a PRAME×CD3
                  ImmTAC bispecific, in post-checkpoint cutaneous melanoma<br />Presenting
                  author: Omid Hamid<br />Session: Oral Abstract Session –
                  Melanoma/Skin Cancers, Friday 31 May 2024; 2:45-5:45 p.m. CT /
                  1:45-4:45 p.m. ET
                </p>
                <p align="justify">
                  Title: A Phase 3 trial of IMC-F106C (PRAME × CD3) plus
                  nivolumab versus standard nivolumab regimens in HLA-A*02:01+
                  patients with previously untreated advanced melanoma
                  (PRISM-MEL-301)<br />Presenting author: Georgina Long<br />Session:
                  Poster Session – Melanoma/Skin Cancers, Saturday 1 June 2024,
                  1:30-4:30 p.m. CT / 12:30-3:30 p.m. ET
                </p>
                <p align="justify">
                  Title: Stable disease with confirmed tumor reduction has a
                  similar clinical outcome as RECIST partial response for
                  tebentafusp in metastatic uveal melanoma<br />Presenting
                  author: Alexandra Ikeguchi<br />Session: Poster Session –
                  Melanoma/Skin Cancers, Saturday 1 June 2024, 1:30-4:30 p.m. CT
                  / 12:30-3:30 p.m. ET
                </p>
                <p align="justify">
                  Title: Association between clinical and disease
                  characteristics and detectable or undetectable baseline ctDNA
                  in patients with metastatic uveal melanoma<br />Presenting
                  author: Paul Nathan<br />Session: Poster Session –
                  Melanoma/Skin Cancers, Saturday 1 June 2024, 1:30-4:30 p.m. CT
                  / 12:30-3:30 p.m. ET
                </p>
                <p align="justify">
                  Title: Baseline and serial ctDNA dynamics predicts outcomes in
                  patients treated with first-line tebentafusp including those
                  who were and were not treated beyond progression<br />Presenting
                  author: Ryan Sullivan<br />Session: Poster Session –
                  Melanoma/Skin Cancers, Saturday 1 June 2024, 1:30-4:30 p.m. CT
                  / 12:30-3:30 p.m. ET
                </p>
                <p align="justify">
                  <strong>Conference Call</strong><br />Immunocore will host an
                  investor and analyst event and webcasted conference call on
                  Friday 31 May 2024 at 6:15 p.m. CT with Dr. Diwakar Davar. The
                  webcast will be available under ‘News &amp; Events’ in the
                  Investor Relations section of Immunocore Holdings’ website at
                  www.immunocore.com.
                </p>
                <p align="center">##</p>
                <p align="justify">
                  About ImmTAC<sup>®</sup> molecules for cancer
                </p>
                <p align="justify">
                  Immunocore’s proprietary T cell receptor (TCR) technology
                  generates a novel class of bispecific biologics called ImmTAC
                  (Immune mobilizing monoclonal TCRs Against Cancer) molecules
                  that are designed to redirect the immune system to recognize
                  and kill cancerous cells. ImmTAC molecules are soluble TCRs
                  engineered to recognize intracellular cancer antigens with
                  ultra-high affinity and selectively kill these cancer cells
                  via an anti-CD3 immune-activating effector function. Based on
                  the demonstrated mechanism of T cell infiltration into human
                  tumors, the ImmTAC mechanism of action holds the potential to
                  treat hematologic and solid tumors, regardless of mutational
                  burden or immune infiltration, including immune “cold” low
                  mutation rate tumors.
                </p>
                <p align="justify">About KIMMTRAK</p>
                <p align="justify">
                  KIMMTRAK is a novel bispecific protein comprised of a soluble
                  T cell receptor fused to an anti-CD3 immune-effector function.
                  KIMMTRAK specifically targets gp100, a lineage antigen
                  expressed in melanocytes and melanoma. This is the first
                  molecule developed using Immunocore’s ImmTAC technology
                  platform designed to redirect and activate T cells to
                  recognize and kill tumor cells. KIMMTRAK has been approved for
                  the treatment of HLA-A*02:01-positive adult patients with
                  unresectable or metastatic uveal melanoma in the United
                  States, European Union, Canada, Australia, and the United
                  Kingdom.
                </p>
                <p align="justify">
                  About PRISM-MEL301 – Phase 3 trial with brenetafusp
                  (IMC-F106C, PRAMExCD3) in 1L advanced cutaneous melanoma
                </p>
                <p align="justify">
                  The Phase 3 registrational trial will randomize patients with
                  previously untreated, HLA-A*02:01-positive, advanced melanoma
                  to IMC-F106C + nivolumab versus nivolumab or nivolumab +
                  relatlimab, depending on the country where the patient is
                  enrolled. The study will initially randomize to three arms:
                  two IMC-F106C dose regimens (40 mcg and 160 mcg) and control
                  arm and will discontinue one of the IMC-F106C dose regimens
                  after an initial review of the first 60 patients randomized to
                  the two experimental arms (90 patients randomized total). The
                  primary endpoint of the trial is progression free survival
                  (PFS) by blinded independent central review (BICR), with
                  secondary endpoints of overall survival (OS) and overall
                  response rate (ORR).
                </p>
                <p align="justify">About the IMC-F106C-101 Phase 1/2 trial</p>
                <p align="justify">
                  IMC-F106C-101 is a first-in-human, Phase 1/2 dose escalation
                  trial in patients with multiple solid tumor cancers including
                  non-small cell lung cancer (NSCLC), small-cell lung cancer
                  (SCLC), endometrial, ovarian, cutaneous melanoma, and breast
                  cancers. The Phase 1 dose escalation trial was designed to
                  determine the maximum tolerated dose (MTD), as well as to
                  evaluate the safety, preliminary anti-tumor activity and
                  pharmacokinetics of brenetafusp (IMC-F106C), a bispecific
                  protein built on Immunocore’s ImmTAC technology, and the
                  Company’s first molecule to target the PRAME antigen. The
                  Company has initiated patient enrollment into four expansion
                  arms in cutaneous melanoma, ovarian, NSCLC, and endometrial
                  carcinomas. The IMC-F106C-101 trial is adaptive and includes
                  the option for Phase 2 expansion, allowing for approximately
                  100 patients treated per tumor type in the Phase 1 and 2
                  expansion arms. Dose escalation continues in additional solid
                  tumors as well as plans for combination arms with
                  standards-of-care, including checkpoint inhibitors,
                  chemotherapy, and tebentafusp.
                </p>
                <p align="justify">About Immunocore</p>
                <p align="justify">
                  Immunocore is a commercial-stage biotechnology company
                  pioneering the development of a novel class of TCR bispecific
                  immunotherapies called ImmTAX – Immune mobilizing monoclonal
                  TCRs Against X disease – designed to treat a broad range of
                  diseases, including cancer, autoimmune, and infectious
                  disease. Leveraging its proprietary, flexible, off-the-shelf
                  ImmTAX platform, Immunocore is developing a deep pipeline in
                  multiple therapeutic areas, including five clinical stage
                  programs in oncology and infectious disease, advanced
                  pre-clinical programs in autoimmune disease and multiple
                  earlier pre-clinical programs. The Company’s most advanced
                  oncology TCR therapeutic, KIMMTRAK has been approved for the
                  treatment of HLA-A*02:01-positive adult patients with
                  unresectable or metastatic uveal melanoma in the United
                  States, European Union, Canada, Australia, and the United
                  Kingdom.
                </p>
                <p align="justify">Forward Looking Statements</p>
                <p align="justify">
                  This press release contains “forward-looking statements”
                  within the meaning of the safe harbor provisions of the
                  Private Securities Litigation Reform Act of 1995. Words such
                  as “may,” “will,” “believe,” “expect,” “plan,” “anticipate,”
                  and similar expressions (as well as other words or expressions
                  referencing future events or circumstances) are intended to
                  identify forward-looking statements. All statements, other
                  than statements of historical facts, included in this press
                  release are forward-looking statements. These statements
                  include, but are not limited to, statements regarding the
                  expected clinical benefits of KIMMTRAK, brenetafusp and the
                  Company’s other product candidates, including progression free
                  survival and extended overall survival benefit; expectations
                  regarding receipt of regulatory approvals and completion of
                  related procedures; the value proposition of Immunocore’s
                  products and product candidates, including KIMMTRAK and
                  brenetafusp; future development plans of Immunocore’s products
                  and product candidates, including KIMMTRAK and brenetafusp;
                  expectations regarding the design, progress, timing, scope and
                  results of Immunocore’s existing and planned clinical trials,
                  including the Phase 3 PRISM-MEL301 trial with brenetafusp plus
                  nivolumab versus standard nivolumab in 1L advanced cutaneous
                  melanoma and the Phase 1/2 dose escalation trial with
                  brenetafusp in patients with multiple solid tumor cancers
                  including non-small cell lung cancer (NSCLC), small-cell lung
                  cancer (SCLC), endometrial, ovarian, cutaneous melanoma, and
                  breast cancers . Any forward-looking statements are based on
                  management’s current expectations and beliefs of future events
                  and are subject to a number of risks and uncertainties that
                  could cause actual events or results to differ materially and
                  adversely from those set forth in or implied by such
                  forward-looking statements, many of which are beyond the
                  Company’s control. These risks and uncertainties include, but
                  are not limited to, the impact of the worsening macroeconomic
                  conditions on the Company’s business, financial position,
                  strategy and anticipated milestones, including Immunocore’s
                  ability to conduct ongoing and planned clinical trials;
                  Immunocore’s ability to obtain a clinical supply of current or
                  future product candidates or commercial supply of KIMMTRAK or
                  any future approved products, including as a result of health
                  epidemics or pandemics, war in Ukraine, the conflict between
                  Hamas and Israel, or global geopolitical tension; Immunocore’s
                  ability to obtain and maintain regulatory approval of its
                  product candidates, including KIMMTRAK; Immunocore’s ability
                  and plans in continuing to establish and expand a commercial
                  infrastructure and to successfully launch, market and sell
                  KIMMTRAK and any future approved products; Immunocore’s
                  ability to successfully expand the approved indications for
                  KIMMTRAK or obtain marketing approval for KIMMTRAK in
                  additional geographies in the future; the delay of any current
                  or planned clinical trials, whether due to patient enrollment
                  delays or otherwise; Immunocore’s ability to successfully
                  demonstrate the safety and efficacy of its product candidates
                  and gain approval of its product candidates on a timely basis,
                  if at all; competition with respect to market opportunities;
                  unexpected safety or efficacy data observed during preclinical
                  studies or clinical trials; actions of regulatory agencies,
                  which may affect the initiation, timing and progress of
                  clinical trials or future regulatory approval; Immunocore’s
                  need for and ability to obtain additional funding, on
                  favorable terms or at all, including as a result of the
                  worsening macroeconomic conditions, including inflation,
                  interest rates and unfavorable general market conditions, and
                  the impacts thereon of the war in Ukraine, the conflict
                  between Hamas and Israel, and global geopolitical tension;
                  Immunocore’s ability to obtain, maintain and enforce
                  intellectual property protection for KIMMTRAK or any of its
                  product candidates it or its collaborators are developing; and
                  the success of Immunocore’s current and future collaborations,
                  partnerships or licensing arrangements. These and other risks
                  and uncertainties are described in greater detail in the
                  section titled "Risk Factors" in Immunocore’s filings with the
                  Securities and Exchange Commission, including Immunocore’s
                  most recent Annual Report on Form 10-K for the year ended
                  December 31, 2023 filed with the Securities and Exchange
                  Commission on February 28, 2024, as well as discussions of
                  potential risks, uncertainties, and other important factors in
                  the Company’s subsequent filings with the Securities and
                  Exchange Commission. All information in this press release is
                  as of the date of the release, and the Company undertakes no
                  duty to update this information, except as required by law.
                </p>
                <p align="justify">Contact Information</p>
                <p align="justify"><strong>Immunocore </strong></p>
                <p align="justify">
                  Sébastien Desprez, Head of Communications<br />T: +44 (0)
                  7458030732<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  ><br />Follow on Twitter: @Immunocore
                </p>
                <p align="justify"><strong>Investor Relations</strong></p>
                <p align="justify">
                  Clayton Robertson, Head of Investor Relations<br />T: +1 (215)
                  384-4781<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  >
                </p>
                <p></p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDk0NjQzNCM0MDE4MzM0MDIjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/ZjE5NmQzZTQtMTIxOC00OGY5LTljZTItODViNjQ0OGQ4MTBiLTEyMTc4MTE=/tiny/Immunocore-Holdings-plc.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings plc
                <p class="spr-ir-news-article-date">Released April 24, 2024</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param: "immunocore-to-present-at-upcoming-investor-conferences",
    title: `Immunocore to present at upcoming investor conferences :: Immunocore
      Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore to present at upcoming investor conferences
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2024-04-04T16:00:00" class="date">
                    April 04, 2024 4:00pm EDT
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-04-04_Immunocore_to_present_at_upcoming_investor_87.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore to present at upcoming investor conferences"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <p align="center">
                  <strong
                    >Immunocore to present at upcoming investor conferences
                  </strong>
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, 04 April 2024) Immunocore Holdings plc
                  (Nasdaq: IMCR) (“Immunocore” or the “Company”), a
                  commercial-stage biotechnology company pioneering and
                  delivering transformative immunomodulating medicines to
                  radically improve outcomes for patients with cancer,
                  infectious diseases and autoimmune diseases, today announced
                  that management will participate in the following investor
                  conferences in April:<br /><br />
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    <strong>Needham Virtual Healthcare Conference </strong
                    ><br />Fireside Chat: Wednesday, April 10, 2024, at 11:00
                    a.m. EDT<br />
                  </li>
                </ul>
                <ul type="disc">
                  <li style="text-align: justify">
                    <strong>Canaccord Horizons in Oncology </strong><br />Panel:
                    T-Cell Engagers for Solid Tumors: Monday, April 15, 2024, at
                    4:00 p.m. EDT<br />
                  </li>
                </ul>
                <ul type="disc">
                  <li style="text-align: justify">
                    <strong>Kempen Life Sciences Conference</strong><br />1x1
                    and small group meetings: Tuesday, April 16, 2024<br /><br />
                  </li>
                </ul>
                <p align="justify">
                  Where relevant, the presentations will be webcast live and can
                  be accessed by visiting ‘Events’, under ‘News &amp; Events’,
                  via the ‘Investors’ section of Immunocore’s website at
                  www.immunocore.com. Following the event, a replay of the
                  presentations will be made available for a limited time.
                </p>
                <p align="justify">
                  <strong>About Immunocore</strong> <br />Immunocore is a
                  commercial-stage biotechnology company pioneering the
                  development of a novel class of TCR bispecific immunotherapies
                  called ImmTAX – Immune mobilizing monoclonal TCRs Against X
                  disease – designed to treat a broad range of diseases,
                  including cancer, autoimmune, and infectious disease.
                  Leveraging its proprietary, flexible, off-the-shelf ImmTAX
                  platform, Immunocore is developing a deep pipeline in multiple
                  therapeutic areas, including five clinical stage programs in
                  oncology and infectious disease, advanced pre-clinical
                  programs in autoimmune disease and multiple earlier
                  pre-clinical programs. The Company’s most advanced oncology
                  TCR therapeutic, KIMMTRAK (tebentafusp) has been approved for
                  the treatment of HLA-A*02:01-positive adult patients with
                  unresectable or metastatic uveal melanoma in the United
                  States, European Union, Canada, Australia, and the United
                  Kingdom.
                </p>
                <p>
                  <strong>CONTACT:</strong>  <br /><strong>Immunocore </strong>
                  <br />Sébastien Desprez, Head of Communications <br />T: +44
                  (0) 7458030732 <br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >mailto:</a
                  >info@immunocore.us <br />Follow on Twitter:
                  @Immunocore
                </p>
                <p>
                  <strong>Investor Relations  </strong> <br />Clayton Robertson,
                  Head of Investor Relations <br />T: +1 215-384-4781 <br />E:
                  info@immunocore.us
                </p>
                <p></p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDkzMzA0NyM0MDE4MTM5NTgjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/YTk2MGEzMjAtZjMyZi00ZTE3LThlOGYtYzg1NmY1ZDU3MDE3LTEyMTc4MTE=/tiny/Immunocore-Holdings-Limited.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings Limited
                <p class="spr-ir-news-article-date">Released April 4, 2024</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param: "immunocore-presented-two-posters-at-croi-2024",
    title: `Immunocore presented two posters at CROI 2024 :: Immunocore Holdings plc
      (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore presented two posters at CROI 2024
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2024-03-05T16:30:00" class="date">
                    March 05, 2024 4:30pm EST
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-03-05_Immunocore_presented_two_posters_at_CROI_86.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore presented two posters at CROI 2024"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <p align="center">
                  <strong>Immunocore presented two posters at CROI 2024</strong>
                </p>
                <p align="center">
                  <em
                    >Preclinical data highlights importance of TCR biology in
                    development of new HIV treatments</em
                  >
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, 05 March 2024) Immunocore Holdings plc
                  (Nasdaq: IMCR) (“Immunocore” or the “Company”), a
                  commercial-stage biotechnology company pioneering and
                  delivering transformative immunomodulating medicines to
                  radically improve outcomes for patients with cancer,
                  infectious diseases and autoimmune diseases, presented two
                  posters at the 2024 Conference on Retroviruses and
                  Opportunistic Infections (CROI).
                </p>
                <p><strong>Poster details</strong></p>
                <p>
                  <strong>Title:</strong> Labelled high affinity TCRs for
                  detection of HIV epitopes on infected cells: How low can we
                  go?
                </p>
                <p>
                  <strong>Presenting author:</strong> Zoe Wallace<br /><strong
                    >Session:</strong
                  >
                  Immune pressure on HIV reservoirs – Poster session-D6<br /><strong
                    >Date &amp; time:</strong
                  >
                  4 March 2024, 14:30-16:00 MST
                </p>
                <p>
                  <strong>Title:</strong> Instability of the HLA-E peptidome of
                  HIV presents a major barrier to therapeutic targeting
                </p>
                <p>
                  <strong>Presenting author:</strong> Zoe Wallace<br /><strong
                    >Session:</strong
                  >
                  HIV immunotherapy and immunoprophylaxis – Poster session-C5<br /><strong
                    >Date &amp; time:</strong
                  >
                  5 March 2024, 14:30-16:00 MST
                </p>
                <p align="center"><strong>##</strong></p>
                <p align="justify">
                  <strong>About ImmTAV molecules and infectious diseases</strong
                  ><br />ImmTAV (Immune mobilising monoclonal TCRs Against
                  Virus) molecules are novel bispecifics that, like ImmTAC
                  (Immune mobilising monoclonal TCRs Against Cancer) molecules,
                  are designed to enable the immune system to recognize and
                  eliminate virally infected cells.
                </p>
                <p align="justify">
                  Immunocore is advancing clinical candidates to cure patients
                  with human immunodeficiency virus (HIV) and hepatitis B virus
                  (HBV). The Company aims to achieve sustained control of HIV
                  after people living with HIV stop anti-retroviral therapy
                  (ART), without the risk of virological relapse or onward
                  transmission. This is known as ‘functional cure’. For the
                  treatment of HBV, the Company aims to achieve sustained loss
                  of circulating viral antigens and markers of viral replication
                  after stopping medication for people living with chronic HBV.
                </p>
                <p align="justify">
                  <strong>About Immunocore</strong><br />Immunocore is a
                  commercial-stage biotechnology company pioneering the
                  development of a novel class of TCR bispecific immunotherapies
                  called ImmTAX – Immune mobilizing monoclonal TCRs Against X
                  disease – designed to treat a broad range of diseases,
                  including cancer, autoimmune, and infectious disease.
                  Leveraging its proprietary, flexible, off-the-shelf ImmTAX
                  platform, Immunocore is developing a deep pipeline in multiple
                  therapeutic areas, including five clinical stage programs in
                  oncology and infectious disease, advanced pre-clinical
                  programs in autoimmune disease and multiple earlier
                  pre-clinical programs. The Company’s most advanced oncology
                  TCR therapeutic, KIMMTRAK has been approved for the treatment
                  of HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p>
                  <strong>CONTACT:</strong> <br /><strong>Immunocore </strong
                  ><br />Sébastien Desprez, Head of Communications<br />T: +44
                  (0) 7458030732<br />E: info@immunocore.us<br />Follow
                  on Twitter: @Immunocore
                </p>
                <p>
                  <strong>Investor Relations </strong><br />Clayton Robertson,
                  Head of Investor Relations<br />T: +1 215-384-4781<br />E:
                  info@immunocore.us
                </p>
                <p></p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDkyNzM0NyM0MDE3NzgyNjkjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/NzAxYzgzOTktNzViNS00MDI4LWJjYTMtNGRmNzMzOGVjNTBmLTEyMTc4MTE=/tiny/Immunocore-Holdings-Limited.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings Limited
                <p class="spr-ir-news-article-date">Released March 5, 2024</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param: "immunocore-to-present-at-upcoming-investor-conferences",
    title: `Immunocore to present at upcoming investor conferences :: Immunocore
      Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore to present at upcoming investor conferences
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2024-03-01T07:00:00" class="date">
                    March 01, 2024 7:00am EST
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-03-01_Immunocore_to_present_at_upcoming_investor_85.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore to present at upcoming investor conferences"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <p align="center">
                  <strong
                    >Immunocore to present at upcoming investor conferences
                  </strong>
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, 01 March 2024) Immunocore Holdings plc
                  (Nasdaq: IMCR) (“Immunocore” or the “Company”), a
                  commercial-stage biotechnology company pioneering and
                  delivering transformative immunomodulating medicines to
                  radically improve outcomes for patients with cancer,
                  infectious diseases and autoimmune diseases, today announced
                  that management will participate in the following investor
                  conferences in March:
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    <strong>TD Cowen 44</strong><sup><strong>th</strong></sup
                    ><strong> Annual Healthcare Conference </strong><br /><br />
                  </li>
                </ul>
                <p align="justify">
                  Fireside Chat: Tuesday, March 5, 2024, at 11:10 a.m. EST
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    <strong
                      >Leerink Partners Global Biopharma Conference </strong
                    ><br /><br />
                  </li>
                </ul>
                <p align="justify">
                  1x1 and small group meetings: Tuesday, March 12, 2024
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    <strong>Jefferies Biotech on the Bay</strong><br /><br />
                  </li>
                </ul>
                <p align="justify">
                  1x1 and small group meetings: Wednesday, March 13, 2024
                </p>
                <ul type="disc">
                  <li>
                    <strong>Barclays 26</strong><sup><strong>th</strong></sup
                    ><strong> Annual Global Healthcare Conference </strong
                    ><br />Fireside Chat: Thursday, March 14, 2024, at 9:30 a.m.
                    EST<br /><br />
                  </li>
                </ul>
                <p align="justify">
                  Where relevant, the presentations will be webcast live and can
                  be accessed by visiting ‘Events’, under ‘News &amp; Events’,
                  via the ‘Investors’ section of Immunocore’s website at
                  www.immunocore.com. Following the event, a replay of the
                  presentations will be made available for a limited time.
                </p>
                <p align="justify">
                  <strong>About Immunocore</strong> <br />Immunocore is a
                  commercial-stage biotechnology company pioneering the
                  development of a novel class of TCR bispecific immunotherapies
                  called ImmTAX – Immune mobilizing monoclonal TCRs Against X
                  disease – designed to treat a broad range of diseases,
                  including cancer, autoimmune, and infectious disease.
                  Leveraging its proprietary, flexible, off-the-shelf ImmTAX
                  platform, Immunocore is developing a deep pipeline in multiple
                  therapeutic areas, including five clinical stage programs in
                  oncology and infectious disease, advanced pre-clinical
                  programs in autoimmune disease and multiple earlier
                  pre-clinical programs. The Company’s most advanced oncology
                  TCR therapeutic, KIMMTRAK has been approved for the treatment
                  of HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p>
                  <strong>CONTACT:</strong>  <br /><strong>Immunocore </strong>
                  <br />Sébastien Desprez, Head of Communications <br />T: +44
                  (0) 7458030732 <br />E: info@immunocore.us
                  <br />Follow on Twitter: @Immunocore
                </p>
                <p>
                  <strong>Investor Relations  </strong> <br />Clayton Robertson,
                  Head of Investor Relations <br />T: +1 215-384-4781 <br />E:
                  info@immunocore.us
                </p>
                <p></p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDkyNjM0MiM0MDE3NzU1MjgjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/NTllNWIwZDEtMTJiMC00YTliLWI5ZmUtY2EwM2QwNzI4ZjBjLTEyMTc4MTE=/tiny/Immunocore-Holdings-Limited.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings Limited
                <p class="spr-ir-news-article-date">Released March 1, 2024</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param:
      "immunocore-reports-fourth-quarter-and-full-year-2023-financial-results-and-provides-a-business-update",
    title: `Immunocore reports fourth quarter and full year 2023 financial results and
      provides a business update :: Immunocore Holdings plc (IMCR)`,
    html: `<div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore reports fourth quarter and full year 2023 financial
                  results and provides a business update
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2024-02-28T07:34:00" class="date">
                    February 28, 2024 7:34am EST
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-02-28_Immunocore_reports_fourth_quarter_and_full_year_84.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore reports fourth quarter and full year 2023 financial results and provides a business update"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <div class="related-documents box hidden-print">
                  <div class="text">
                    <h2 class="related-docs-title">Related Documents</h2>
                    <div class="text">
                      <div class=" ">
                        <div>
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_10k.htm"
                                  title="10-K Filing Viewer"
                                  class="fancybox fancybox.iframe"
                                  rel="noopener"
                                  >10-K <span class="sr-only">Filing</span></a
                                >
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_10k.htm"
                                  title="10-K Filing Viewer"
                                  rel="noopener"
                                  class="link fancybox fancybox.iframe"
                                  ><span class="eqicon-HTML"></span>HTML</a
                                >
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-24-009921/0001140361-24-009921.pdf"
                                  title="10-K"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                  ><span class="eqicon-PDF"></span>PDF</a
                                >
                              </div>
                            </div>
                          </div>
                          <div class="result-line">
                            <div class="row justify-content-between">
                              <div class="col-auto file-title">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-24-009921/0001140361-24-009921-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  target="_blank"
                                  rel="noopener"
                                  >XBRL</a
                                >
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-24-009921/0001140361-24-009921-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                  ><span class="eqicon-ZIP"></span>ZIP</a
                                >
                              </div>
                            </div>
                          </div>
                          <div class="result-line">
                            <div class="row justify-content-between">
                              <div class="col-auto file-title">
                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2288/21935/annual_report/2023+PLC+Annual+Report+FINAL+CLEAN+10Apr2024.pdf"
                                  target="_blank"
                                  rel="noopener"
                                  >Annual Report</a
                                >
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2288/21935/annual_report/2023+PLC+Annual+Report+FINAL+CLEAN+10Apr2024.pdf"
                                  target="_blank"
                                  rel="noopener"
                                  aria-label="PDF: Annual Report"
                                  class="link"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p align="center">
                  <strong
                    >Immunocore reports fourth quarter and full year 2023
                    financial results and provides a business update</strong
                  >
                </p>
                <p align="center">
                  <em
                    >KIMMTRAK (tebentafusp) net revenues of $67.6 million in Q4
                    2023 and $238.7 million in 2023; increasing commercial
                    access to KIMMTRAK globally, and pursuing future growth
                    opportunities with two registrational trials in advanced
                    cutaneous melanoma and adjuvant uveal melanoma</em
                  >
                </p>
                <p align="center">
                  <em
                    >Clinical trial collaboration and supply agreement with
                    Bristol Myers Squibb to evaluate IMC-F106C (PRAME HLA-A02)
                    with nivolumab in registrational Phase 3 first-line advanced
                    cutaneous melanoma trial; PRISM-MEL301 trial to start in 1Q
                    2024
                  </em>
                </p>
                <p align="center">
                  <em
                    >Multiple clinical readouts expected to start in 2Q 2024 for
                    IMC-F106C from Phase 1/2 clinical trial monotherapy and
                    combination arms; CTA/IND submission for IMC-P115C (PRAME
                    HLA-A02 HLE) and </em
                  ><br /><em
                    >IMC-T119C (PRAME HLA-A24) programs on track for 2024</em
                  >
                </p>
                <p align="center">
                  <em
                    >IMC-R117C, a first-in-class ImmTAC targeting PIWIL1, to
                    begin Phase 1 trial in colorectal and other gastrointestinal
                    cancers in 2H 2024</em
                  >
                </p>
                <p align="center">
                  <em
                    >Data from IMC-M113V Phase 1 clinical trial in people living
                    with HIV expected in 2H 2024
                  </em>
                </p>
                <p align="center">
                  <em
                    >Advancing novel TCR bispecific clinical candidates for
                    autoimmune diseases
                  </em>
                </p>
                <p align="center">
                  <em
                    >Cash and cash equivalents of $442.6 million as of December
                    31, 2023; subsequent February 2024 Convertible Senior Notes
                    offering adds $389.3 million net proceeds</em
                  >
                </p>
                <p align="center">
                  <em>Conference call today, February 28</em
                  ><sup><em>th</em></sup
                  ><em> at 8:00 AM ET, 1:00 PM GMT</em>
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, 28 February 2024) Immunocore Holdings plc
                  (Nasdaq: IMCR) (“Immunocore” or the “Company”), a
                  commercial-stage biotechnology company pioneering and
                  delivering transformative immunomodulating medicines to
                  radically improve outcomes for patients with cancer,
                  infectious diseases and autoimmune diseases, today announced
                  its financial results for the fourth quarter and year ended
                  December 31, 2023 and provided a business update.
                </p>
                <p>
                  “In the last 5 years, Immunocore has transformed from a
                  research organization to a revenue-generating, sustainable
                  company,” said
                  <strong>Bahija Jallal, CEO of Immunocore</strong>. “We look
                  forward to the next 5 years, when we maximize the potential of
                  KIMMTRAK, expect to launch our PRAME ImmTAC therapy, and
                  advance our clinical candidates across oncology, infectious
                  diseases and autoimmune diseases."
                </p>
                <p>
                  “Throughout the last year, we expanded the reach of KIMMTRAK
                  in metastatic uveal melanoma with additional approvals,
                  launches, and sales growth across all territories,” said
                  <strong>Ralph Torbay, Head of Commercial</strong>. “We believe
                  KIMMTRAK, the world’s first approved TCR therapy, could
                  benefit thousands more patients and look forward to broadening
                  indications with ongoing late-stage clinical trials in
                  cutaneous and adjuvant uveal melanoma.”
                </p>
                <p align="justify">
                  <strong
                    >Full Year and Fourth Quarter 2023 Highlights (including
                    post-period)
                  </strong>
                </p>
                <p align="justify">
                  <strong><em>Financial Results </em></strong>
                </p>
                <p align="justify">
                  Total net product revenue (or “net sales) arising from the
                  sales of KIMMTRAK (tebentafusp) was $67.6 million in the
                  fourth quarter of 2023, of which $49.1 million was generated
                  in the United States, $18.3 million in Europe and $0.2 million
                  in international regions. For the year ended December 31,
                  2023, the Company generated net sales from the sale of
                  KIMMTRAK in the amount of $238.7 million, of which $169.8
                  million was in the United States, $67.6 million in Europe and
                  $1.3 million in international regions.
                </p>
                <p align="justify">
                  Research &amp; development expenses for the year 2023 were
                  $163.5 million, compared to $101.9 million for the year 2022.
                  Selling, general and administrative (SG&amp;A) expenses for
                  the year 2023 were $144.5 million, compared to $123.1 million
                  for the year 2022.
                </p>
                <p align="justify">
                  Net loss for the fourth quarter of 2023 was $19.7 million
                  compared to a net loss of $30.0 million in the same period in
                  2022, and full year net loss for 2023 was $55.3 million
                  compared to a full year net loss of $52.5 million in 2022.
                </p>
                <p align="justify">
                  The fourth quarter basic and diluted loss per share was $0.40,
                  compared to $0.64 for the fourth quarter of 2022. Basic and
                  diluted loss per share for 2023 was $1.13, compared to $1.15
                  for 2022.
                </p>
                <p align="justify">
                  Cash and cash equivalents at December 31, 2023 were $442.6
                  million. In February 2024, the Company raised net cash
                  proceeds of $389.3 million from an offering of convertible
                  notes with a six-year term and 2.50% interest rate
                  (Convertible Notes).
                </p>
                <p align="justify">
                  <strong>KIMMTRAK expansion strategy</strong>
                </p>
                <p>
                  <strong
                    ><em
                      >KIMMTRAK® (tebentafusp-tebn) for metastatic uveal
                      melanoma</em
                    ></strong
                  >
                </p>
                <p align="justify">
                  Since the start of the 2024, KIMMTRAK has been launched in two
                  additional countries (Australia and Canada), for a total of 12
                  launched and 38 approved countries. KIMMTRAK continues to be
                  the standard of care for HLA-A02+ patients with metastatic
                  uveal melanoma (mUM) in all of the countries in which it has
                  been launched. In 2024, the Company plans to reach more
                  patients in the United States, Europe and globally, as it
                  continues to drive global launches and focuses on supporting
                  early patient identification and treatment.
                </p>
                <p>
                  <strong
                    ><em
                      >KIMMTRAK Phase 2/3 clinical trial in 2L+ advanced
                      cutaneous melanoma</em
                    ></strong
                  >
                </p>
                <p align="justify">
                  The Company also continues to enroll patients into a Phase 2/3
                  clinical trial (TEBE-AM) to investigate the potential of
                  KIMMTRAK in 2L+ advanced cutaneous melanoma. Topline data from
                  the Phase 2 portion of the trial is expected to be available
                  by the fourth quarter of 2024.
                </p>
                <p>
                  <strong
                    ><em
                      >KIMMTRAK Phase 3 clinical trial in adjuvant uveal (or
                      ocular) melanoma</em
                    ></strong
                  >
                </p>
                <p align="justify">
                  In 2023, the Company signed an agreement for a European
                  Organisation for Research and Treatment of Cancer
                  (EORTC)-sponsored trial to study KIMMTRAK as adjuvant therapy
                  for uveal (or ocular) melanoma (ATOM). The Company anticipates
                  that the EORTC will randomize the first patient in the second
                  half of 2024.
                </p>
                <p align="justify"><strong>PRAME franchise </strong></p>
                <p align="justify">
                  <strong
                    ><em
                      >PRISM-MEL301 – First PRAME Phase 3 clinical trial with
                      IMC-F106C in first-line advanced cutaneous melanoma</em
                    ></strong
                  >
                </p>
                <p align="justify">
                  In February 2024, the Company entered into a clinical trial
                  collaboration and supply agreement with Bristol Myers Squibb
                  (NYSE:BMY) to investigate Immunocore’s ImmTAC bispecific TCR
                  candidate targeting PRAME HLA-A02, IMC-F106C, in combination
                  with Bristol Myers Squibb’s nivolumab, in first-line advanced
                  cutaneous melanoma. Under the terms of the collaboration,
                  Immunocore will sponsor and fund the registrational Phase 3
                  clinical trial of IMC-F106C in combination with nivolumab in
                  first-line advanced cutaneous melanoma (PRISM-MEL-301), and
                  Bristol Myers Squibb will provide nivolumab.
                </p>
                <p align="justify">
                  In August 2023, the Company announced plans to start a
                  registrational Phase 3 clinical trial with IMC-F106C in
                  first-line advanced cutaneous melanoma (CM). The Company
                  decided to advance IMC-F106C into a Phase 3 first-line CM
                  clinical trial in combination with nivolumab with a primary
                  endpoint of progression-free survival (PFS), based on the
                  Company’s analysis of the ongoing Phase 1 data in previously
                  treated CM which demonstrated monotherapy clinical activity
                  including partial responses (PR), durable tumor reduction,
                  disease control (PR and SD), PFS and circulating tumor DNA
                  (ctDNA) reduction (consistent with prior reported data for
                  IMC-F106C and tebentafusp). Additional rationale includes
                  safety in combination with checkpoints (from the ongoing Phase
                  1 data and prior experience with tebentafusp) and evidence
                  from across the platform for increased clinical activity in
                  earlier line patients compared to later line. As such,
                  PRISM-MEL-301, the first PRAME Phase 3 clinical trial with
                  IMC-F106C, will randomize patients with HLA-A*02:01-positive,
                  first-line advanced CM to IMC-F106C + nivolumab versus a
                  control arm of either nivolumab or nivolumab + relatlimab,
                  depending on the country where the patient is enrolled. The
                  Company plans to randomize the first patient in this trial in
                  the first quarter of 2024.
                </p>
                <p align="justify">
                  <strong
                    ><em
                      >Phase 1/2 clinical trial of IMC-F106C targeting PRAME-A02
                      in multiple solid tumors</em
                    ></strong
                  >
                </p>
                <p align="justify">
                  In addition to progressing IMC-F106C into a registrational
                  trial in cutaneous melanoma, the Company is continuing to
                  enroll patients in the monotherapy and combination arms of the
                  Phase 1/2 clinical trial across multiple tumor types,
                  including expansion arms for patients with advanced ovarian,
                  non-small cell lung, and endometrial carcinoma. The initial
                  data from the Phase 1 clinical trial of IMC-F106C, the first
                  PRAME x CD3 ImmTAC bispecific protein, was presented at the
                  2022 European Society for Medical Oncology (ESMO) Congress in
                  September 2022. Durable Response Evaluation Criteria in Solid
                  Tumors (RECIST) responses and reduction in ctDNA were observed
                  across multiple solid tumors. In August 2023, the Company
                  provided an updated analysis of the original 18 melanoma
                  patients (initially presented at ESMO in September 2022),
                  which continued to show promising durability of the clinical
                  activity (range of duration of partial response from 6 months
                  to 17 months). The Company expects to report clinical data
                  from the ongoing monotherapy and combination cohorts
                  throughout 2024 including cutaneous melanoma (expected in Q2
                  2024), ovarian (expected by Q3 2024), and non-small cell lung
                  carcinoma (expected by Q4 2024).
                </p>
                <p align="justify">
                  <strong
                    ><em
                      >IMC-P115C (PRAME HLA-A02 Half-Life Extended) &amp;
                      IMC-T119C (PRAME HLA-A24)
                    </em></strong
                  >
                </p>
                <p>
                  The Company is expanding the PRAME franchise with two new
                  PRAME ImmTAC candidates, IMC-P115C (PRAME HLA-A02 HLE) and
                  IMC-T119C (PRAME HLA-A24) for solid tumors, which are both on
                  track for investigational new drug (IND) or clinical trial
                  application (CTA) submissions for IMC-P115C in the middle of
                  2024 and the fourth quarter of 2024 for IMC-T119C.
                </p>
                <p align="justify">
                  <strong
                    >IMC-R117C (PIWIL1) for colorectal and other
                    gastrointestinal cancers</strong
                  >
                </p>
                <p align="justify">
                  The Company has leveraged its proprietary peptide (ImmSPECT)
                  database to validate a novel target, PIWIL1. PIWIL1 is
                  believed to play a role in tumor progression and is expressed
                  across a range of tumors, including colorectal which is
                  historically insensitive to immune checkpoints, as well as
                  other gastrointestinal cancers. PIWIL1 is also reported to be
                  a negative prognostic marker and the Company believes
                  IMC-R117C is the first PIWIL1-targeted immunotherapy. The
                  Company submitted a CTA to regulatory authorities in December
                  2023, and expects the trial to start in the second half of
                  2024.
                </p>
                <p align="justify">
                  <strong
                    >Enrolling ImmTAV candidates for a functional cure in
                    infectious diseases</strong
                  >
                </p>
                <p align="justify">
                  The Company continues to enroll people living with HIV in the
                  multiple ascending dose (MAD) part of a Phase 1 clinical trial
                  with IMC-M113V, to identify a safe and tolerable dosing
                  schedule. This trial will also test whether IMC-M113V could
                  lead to reduction in the viral reservoir and, after stopping
                  all therapies (antiretroviral therapies and IMC-M113V), delay
                  or prevent HIV rebound (known as functional cure). The MAD
                  part of the trial will enroll up to 28 participants. The
                  Company expects to present a data update from the Phase 1
                  clinical trial in the second half of 2024.
                </p>
                <p>
                  In 2023, the Company amended the design of the ongoing Phase 1
                  clinical trial with IMC-I109V for people living with HBV to
                  include HBV-positive hepatocellular carcinoma. The Company
                  continues to enroll patients into the single ascending dose
                  portion of the trial in 2024.
                </p>
                <p align="justify">
                  <strong
                    >Tissue-specific down modulation of the immune system for
                    autoimmune diseases
                  </strong>
                </p>
                <p align="justify">
                  The Company is expanding its platform into autoimmune diseases
                  with two first-in-class new bispecific candidates entering the
                  Company’s pipeline. The key differentiator of the ImmTAAI
                  platform is tissue-specific down modulation of the immune
                  system. When tethered to the tissue of interest, the new
                  candidates supress pathogenic T cells via PD1 receptor
                  agonism.
                </p>
                <p align="justify">
                  The first candidate, IMC-S118AI (PPIxPD1), is targeted
                  specifically to pancreatic beta cells and is intended for
                  disease-modifying treatment in type 1 diabetes. IMC-S118AI
                  recognizes a peptide from pre-proinsulin presented by HLA-A02
                  on beta cells coupled with a PD1 agonist effector arm.
                  IMC-S118AI is advancing towards GMP manufacturing in 2024.
                </p>
                <p align="justify">
                  The second target is present in the skin and intended to treat
                  inflammatory dermatological diseases. The candidate is an
                  antigen presenting cell (APC) tethered ImmTAAI and is not HLA
                  restricted (e.g. universal for all populations).
                </p>
                <p align="justify"><strong>Financial Results</strong></p>
                <p align="justify">
                  Basic and diluted loss per share was $0.40 and $1.13 for the
                  quarter and year ended December 31, 2023, respectively, as
                  compared to a basic and diluted loss per share of $0.64 and
                  $1.15, respectively, for the same periods in 2022. Net loss
                  for the quarter and year ended December 31, 2023, was $19.7
                  million and $55.3 million, respectively, as compared to $30.0
                  million and $52.5 million, respectively, for the same periods
                  in 2022.
                </p>
                <p align="justify">
                  For the fourth quarter and year ended December 31, 2023, we
                  generated net sales of $67.6 million and $238.7 million,
                  respectively, due to the sale of KIMMTRAK and tebentafusp, of
                  which $49.1 million and $169.8 million, respectively was in
                  the United States, $18.3 million and $67.6 million,
                  respectively, was in Europe, and $0.2 million and $1.3
                  million, respectively, was in the international regions. The
                  increase in net sales was due primarily to increased volume in
                  the United States and global country expansion, as we
                  continued our commercialization efforts.
                </p>
                <p align="justify">
                  For the fourth quarter and year ended December 31, 2023, our
                  research and development (R&amp;D) expenses were $45.6 million
                  and $163.5 million, respectively as compared to $31.1 million
                  and $101.9 million for the quarter and year ended December 31,
                  2022. These increases were driven by expenses incurred for our
                  PRAME programs, increases in headcount-related expenses as a
                  result of higher number of employees and associated staff
                  costs, increases related to consumables and facilities costs,
                  and decreased R&amp;D tax credits due to us no longer
                  qualifying as a ‘small and medium enterprise’ (SME) under the
                  UK R&amp;D tax regulations. The Company expects R&amp;D
                  expenses to increase in future periods as the Company advances
                  its trials and further develops clinical and preclinical
                  pipeline candidates.
                </p>
                <p align="justify">
                  For the quarter and year ended December 31, 2023, our SG&amp;A
                  expenses were $41.4 million and $144.5 million, respectively,
                  compared to $35.4 million and $123.1 million for the quarter
                  and year ended December 31, 2022. These increases were related
                  to an increase in headcount costs, higher selling and
                  distribution costs following regulatory approval of KIMMTRAK,
                  and costs associated with expansion as a growing publicly
                  listed and commercial company.
                </p>
                <p align="justify">
                  Cash and cash equivalents were $442.6 million as of December
                  31, 2023, as compared to $402.5 million as of December 31,
                  2022. In February 2024, the Company raised net cash proceeds
                  of $389.3 million from a Convertible Notes offering with a
                  six-year term and 2.50% interest rate. The Company plans to
                  use $50 million from the net proceeds to repay its existing
                  Pharmakon loan by the end of 2024. The Company estimates Pro
                  Forma for the net cash proceeds from the Convertible Notes
                  offering in February 2024, and the Pharmakon loan repayment,
                  its cash and cash equivalents at year-end 2023, were
                  approximately $782 million.
                </p>
                <p align="justify">
                  As of December 31, 2023, the Company no longer qualified as a
                  foreign private issuer for U.S. publicly company reporting
                  purposes. Effective January 1, 2024, it now files periodic
                  reports on U.S. domestic filer forms with the Securities and
                  Exchange Commission (SEC) and comply with other rules as
                  required, including but not limited to presenting its
                  financial results in press releases and Annual Report on Form
                  10-K in accordance with U.S. GAAP, with such change being
                  applied retrospectively. See the Company’s Annual Report on
                  Form 10-K filed today with the SEC for more information.
                </p>
                <p align="justify"><strong>Audio Webcast</strong></p>
                <p align="justify">
                  Immunocore will host a conference call today, February 28,
                  2024 at 8:00 A.M. ET/ 1:00 PM GMT, to discuss the fourth
                  quarter and full year 2023 financial results and provide a
                  business update. The call will also be available via webcast
                  by visiting the Events &amp; Presentations section on
                  Immunocore’s website. A replay of this webcast will be
                  available for 30 days.
                </p>
                <p align="justify">
                  Conference Call Details:<br />U.S. (toll-free):
                  877-405-1239<br />International (toll): +1 201-389-0851
                </p>
                <p align="center"><strong>##</strong></p>
                <p align="justify">
                  <strong>About ImmTAC® molecules for cancer</strong
                  ><br />Immunocore’s proprietary T cell receptor (TCR)
                  technology generates a novel class of bispecific biologics
                  called ImmTAC (Immune mobilizing monoclonal TCRs Against
                  Cancer) molecules that are designed to redirect the immune
                  system to recognize and kill cancerous cells. ImmTAC molecules
                  are soluble TCRs engineered to recognize intracellular cancer
                  antigens with ultra-high affinity and selectively kill these
                  cancer cells via an anti-CD3 immune-activating effector
                  function. Based on the demonstrated mechanism of T cell
                  infiltration into human tumors, the ImmTAC mechanism of action
                  holds the potential to treat hematologic and solid tumors,
                  regardless of mutational burden or immune infiltration,
                  including immune “cold” low mutation rate tumors.
                </p>
                <p align="justify">
                  <strong>About ImmTAV molecules and infectious diseases</strong
                  ><br />ImmTAV (Immune mobilising monoclonal TCRs Against
                  Virus) molecules are novel bispecifics that, like ImmTAC
                  (Immune mobilising monoclonal TCRs Against Cancer) molecules,
                  are designed to enable the immune system to recognize and
                  eliminate virally infected cells.
                </p>
                <p align="justify">
                  Immunocore is advancing clinical candidates to cure patients
                  with HIV and hepatitis B virus (HBV). The Company aims to
                  achieve sustained control of HIV after patients stop
                  anti-retroviral therapy (ART), without the risk of virological
                  relapse or onward transmission. This is known as ‘functional
                  cure’. For the treatment of HBV, the Company aims to achieve
                  sustained loss of circulating viral antigens and markers of
                  viral replication after stopping medication for people living
                  with chronic HBV.
                </p>
                <p align="justify">
                  <strong
                    >About ImmTAAI molecules and autoimmune diseases </strong
                  ><br />ImmTAAI (Immune mobilising monoclonal TCRs Against
                  Autoimmune) molecules are novel bispecifics that are designed
                  for tissue-specific down modulation of the immune system. When
                  tethered to the tissue of interest, ImmTAAI candidates supress
                  pathogenic T cells via PD1 receptor agonism. The Company is
                  currently advancing two candidates for autoimmune conditions,
                  including Type 1 Diabetes and inflammatory dermatological
                  diseases.
                </p>
                <p align="justify">
                  <strong
                    >About PRISM-MEL301 – Phase 3 trial with IMC-F106C
                    (PRAMExCD3) in 1L advanced cutaneous melanoma</strong
                  ><br />The Phase 3 registrational trial will randomize
                  patients with previously untreated, HLA-A*02:01-positive,
                  advanced melanoma to IMC-F106C + nivolumab versus nivolumab or
                  nivolumab + relatlimab, depending on the country where the
                  patient is enrolled. The study will initially randomize to
                  three arms: two IMC-F106C dose regimens (40 mcg and 160 mcg)
                  and control arm and will discontinue one of the IMC-F106C dose
                  regimens after an initial review of the first 60 patients
                  randomized to the two experimental arms (90 patients
                  randomized total). The primary endpoint of the trial is
                  progression free survival (PFS) by blinded independent central
                  review (BICR), with secondary endpoints of overall survival
                  (OS) and overall response rate (ORR).
                </p>
                <p align="justify">
                  <strong>About the IMC-F106C-101 Phase 1/2 trial</strong
                  ><br />IMC-F106C-101 is a first-in-human, Phase 1/2 dose
                  escalation trial in patients with multiple solid tumor cancers
                  including non-small cell lung cancer (NSCLC), small-cell lung
                  cancer (SCLC), endometrial, ovarian, cutaneous melanoma, and
                  breast cancers. The Phase 1 dose escalation trial was designed
                  to determine the maximum tolerated dose (MTD), as well as to
                  evaluate the safety, preliminary anti-tumor activity and
                  pharmacokinetics of IMC-F106C, a bispecific protein built on
                  Immunocore’s ImmTAC technology, and the Company’s first
                  molecule to target the PRAME antigen. The Company has
                  initiated patient enrollment into four expansion arms in
                  cutaneous melanoma, ovarian, NSCLC, and endometrial
                  carcinomas. The IMC-F106C-101 trial is adaptive and includes
                  the option for Phase 2 expansion, allowing for approximately
                  100 patients treated per tumor type in the Phase 1 and 2
                  expansion arms. Dose escalation continues in additional solid
                  tumors as well as plans for combination arms with
                  standards-of-care, including checkpoint inhibitors,
                  chemotherapy, and tebentafusp.
                </p>
                <p align="justify">
                  <strong
                    >About TEBE-AM - Phase 2/3 trial with tebentafusp
                    (gp100xCD3) in second-line or later cutaneous
                    melanoma</strong
                  ><br />The trial is randomizing patients with second-line or
                  later cutaneous melanoma who have progressed on an anti-PD1,
                  received prior ipilimumab and, if applicable, received a BRAF
                  kinase inhibitor. Patients will be randomized to one of three
                  arms including tebentafusp, as monotherapy or in combination
                  with an anti-PD1, and a control arm. The Phase 2 portion of
                  the trial will include 40 patients per arm.
                </p>
                <p align="justify">
                  <strong>About the ATOM Phase 3 trial </strong><br />The
                  EORTC-led Phase 3 clinical trial will include sites in 10 EU
                  countries and the United States and will randomize patients
                  with HLA-A*02:01-positive high-risk primary uveal melanoma
                  after definitive treatment, by surgery or radiotherapy, and no
                  evidence of metastatic disease on imaging. The trial is
                  expected to enroll a total of 290 patients who will be
                  randomized 1:1 to one of two arms: KIMMTRAK as monotherapy or
                  observation. The primary endpoint of the trial is relapse-free
                  survival (RFS), with secondary objectives of overall survival
                  and safety and tolerability of tebentafusp. Exploratory
                  objectives include the comparison of the health-related
                  quality of life between the treatment arms and the evaluation
                  of the role of circulating tumor DNA as a biomarker for the
                  presence of residual disease.
                </p>
                <p align="justify">
                  <strong>About Uveal Melanoma</strong><br />Uveal melanoma is a
                  rare and aggressive form of melanoma, which affects the eye.
                  Although it is the most common primary intraocular malignancy
                  in adults, the diagnosis is rare, and up to 50% of people with
                  uveal melanoma will eventually develop metastatic disease.
                  Unresectable or metastatic uveal melanoma typically has a poor
                  prognosis and had no approved treatment until KIMMTRAK.
                </p>
                <p align="justify">
                  <strong>About KIMMTRAK®</strong><br />KIMMTRAK is a novel
                  bispecific protein comprised of a soluble T cell receptor
                  fused to an anti-CD3 immune-effector function. KIMMTRAK
                  specifically targets gp100, a lineage antigen expressed in
                  melanocytes and melanoma. This is the first molecule developed
                  using Immunocore’s ImmTAC technology platform designed to
                  redirect and activate T cells to recognise and kill tumour
                  cells. KIMMTRAK has been approved for the treatment of
                  HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p align="justify">
                  <strong>IMPORTANT SAFETY INFORMATION</strong>
                </p>
                <p align="justify">
                  <strong
                    >Cytokine Release Syndrome (CRS), which may be serious or
                    life-threatening, occurred in patients receiving KIMMTRAK.
                    Monitor for at least 16 hours following first three
                    infusions and then as clinically indicated.</strong
                  >
                  Manifestations of CRS may include fever, hypotension, hypoxia,
                  chills, nausea, vomiting, rash, elevated transaminases,
                  fatigue, and headache<em>. </em>CRS occurred in 89% of
                  patients who received KIMMTRAK with 0.8% being grade 3 or 4.
                  Ensure immediate access to medications and resuscitative
                  equipment to manage CRS. Ensure patients are euvolemic prior
                  to initiating the infusions. Closely monitor patients for
                  signs or symptoms of CRS following infusions of KIMMTRAK.
                  Monitor fluid status, vital signs, and oxygenation level and
                  provide appropriate therapy. Withhold or discontinue KIMMTRAK
                  depending on persistence and severity of CRS.
                </p>
                <p align="justify"><strong>Skin Reactions</strong></p>
                <p align="justify">
                  Skin reactions, including rash, pruritus, and cutaneous edema
                  occurred in 91% of patients treated with KIMMTRAK. Monitor
                  patients for skin reactions. If skin reactions occur, treat
                  with antihistamine and topical or systemic steroids based on
                  persistence and severity of symptoms. Withhold or permanently
                  discontinue KIMMTRAK depending on the severity of skin
                  reactions.
                </p>
                <p align="justify"><strong>Elevated Liver Enzymes</strong></p>
                <p align="justify">
                  Elevations in liver enzymes occurred in 65% of patients
                  treated with KIMMTRAK. Monitor alanine aminotransferase (ALT),
                  aspartate aminotransferase (AST), and total blood bilirubin
                  prior to the start of and during treatment with KIMMTRAK.
                  Withhold KIMMTRAK according to severity.
                </p>
                <p align="justify">
                  <strong>Embryo-Fetal Toxicity</strong><br />KIMMTRAK may cause
                  fetal harm. Advise pregnant patients of potential risk to the
                  fetus and patients of reproductive potential to use effective
                  contraception during treatment with KIMMTRAK and 1 week after
                  the last dose.
                </p>
                <p align="justify">
                  The most common adverse reactions (≥30%) in patients who
                  received KIMMTRAK were cytokine release syndrome, rash,
                  pyrexia, pruritus, fatigue, nausea, chills, abdominal pain,
                  edema, hypotension, dry skin, headache, and vomiting. The most
                  common (≥50%) laboratory abnormalities were decreased
                  lymphocyte count, increased creatinine, increased glucose,
                  increased AST, increased ALT, decreased hemoglobin, and
                  decreased phosphate.
                </p>
                <p align="justify">
                  For more information, please see full Summary of Product
                  Characteristics (SmPC) or full U.S. Prescribing Information
                  (including BOXED WARNING for CRS).
                </p>
                <p align="justify">
                  <strong>About KIMMTRAKConnect</strong><br />Immunocore is
                  committed<strong> </strong>to helping patients who need
                  KIMMTRAK obtain access via our KIMMTRAKConnect program. The
                  program provides services with dedicated nurse case managers
                  who provide personalized support, including educational
                  resources, financial assistance, and site of care
                  coordination. To learn more, visit KIMMTRAKConnect.com or call
                  844-775-2273.
                </p>
                <p align="justify">
                  <strong>About Immunocore</strong><br />Immunocore is a
                  commercial-stage biotechnology company pioneering the
                  development of a novel class of TCR bispecific immunotherapies
                  called ImmTAX – Immune mobilizing monoclonal TCRs Against X
                  disease – designed to treat a broad range of diseases,
                  including cancer, autoimmune, and infectious disease.
                  Leveraging its proprietary, flexible, off-the-shelf ImmTAX
                  platform, Immunocore is developing a deep pipeline in multiple
                  therapeutic areas, including five clinical stage programs in
                  oncology and infectious disease, advanced pre-clinical
                  programs in autoimmune disease and multiple earlier
                  pre-clinical programs. The Company’s most advanced oncology
                  TCR therapeutic, KIMMTRAK has been approved for the treatment
                  of HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p>
                  <strong>Forward Looking Statements</strong> <br />This press
                  release contains “forward-looking statements” within the
                  meaning of the safe harbor provisions of the Private
                  Securities Litigation Reform Act of 1995. Words such as “may”,
                  “will”, “believe”, “expect”, “plan”, “anticipate” and similar
                  expressions (as well as other words or expressions referencing
                  future events or circumstances) are intended to identify
                  forward-looking statements. All statements, other than
                  statements of historical facts, included in this press release
                  are forward-looking statements. These statements include, but
                  are not limited to, statements regarding the commercial
                  performance of KIMMTRAK, including expanded access to KIMMTRAK
                  to more patients in the United States, Europe and globally;
                  the potential benefits and advantages KIMMTRAK will provide
                  for patients, including its potential for expansion into other
                  indications such as cutaneous and adjuvant uveal melanoma;
                  expectations regarding the design, progress, timing,
                  enrollment, randomization, scope, expansion, funding, and
                  results of the Company’s existing and planned clinical trials,
                  those of the Company’s collaboration partners or the combined
                  clinical trials with the Company’s collaboration partners;
                  statements regarding the benefits of the Company’s
                  collaboration with Bristol-Meyers Squibb; the timing and
                  sufficiency of clinical trial outcomes to support potential
                  approval of any of the Company’s product candidates or those
                  of, or combined with, its collaboration partners; the
                  Company’s goals to develop and commercialize product
                  candidates based on its KIMMTRAK platform alone or with
                  collaboration partners; the expected submission of
                  investigational new drug applications or clinical trial
                  applications; the potential regulatory approval, expected
                  clinical benefits and availability of the Company’s product
                  candidates; the use of proceeds from the Convertible Notes
                  offering; and the Company’s expectations regarding its cash
                  runway. Any forward-looking statements are based on
                  management’s current expectations and beliefs of future events
                  and are subject to a number of risks and uncertainties that
                  could cause actual events or results to differ materially and
                  adversely from those set forth in or implied by such
                  forward-looking statements, many of which are beyond the
                  Company’s control. These risks and uncertainties include, but
                  are not limited to, the impact of worsening macroeconomic
                  conditions on the Company’s business, financial position,
                  strategy and anticipated milestones, including Immunocore’s
                  ability to conduct ongoing and planned clinical trials;
                  Immunocore’s ability to obtain a clinical supply of current or
                  future product candidates or commercial supply of KIMMTRAK or
                  any future approved products, including as a result of health
                  epidemics or pandemics, war in Ukraine, the conflict between
                  Hamas and Israel, or global geopolitical tension; Immunocore’s
                  ability to obtain and maintain regulatory approval of its
                  product candidates, including KIMMTRAK; Immunocore’s ability
                  and plans in continuing to establish and expand a commercial
                  infrastructure and to successfully launch, market and sell
                  KIMMTRAK and any future approved products; Immunocore’s
                  ability to successfully expand the approved indications for
                  KIMMTRAK or obtain marketing approval for KIMMTRAK in
                  additional geographies in the future; the delay of any current
                  or planned clinical trials, whether due to patient enrollment
                  delays or otherwise; Immunocore’s ability to successfully
                  demonstrate the safety and efficacy of its product candidates
                  and gain approval of its product candidates on a timely basis,
                  if at all; competition with respect to market opportunities;
                  unexpected safety or efficacy data observed during preclinical
                  studies or clinical trials; actions of regulatory agencies,
                  which may affect the initiation, timing and progress of
                  clinical trials or future regulatory approval; Immunocore’s
                  need for and ability to obtain additional funding, on
                  favorable terms or at all, including as a result of worsening
                  macroeconomic conditions, including changes inflation and
                  interest rates and unfavorable general market conditions, and
                  the impacts thereon of the war in Ukraine, the conflict
                  between Hamas and Israel, and global geopolitical tension;
                  Immunocore’s ability to obtain, maintain and enforce
                  intellectual property protection for KIMMTRAK or any of its
                  product candidates it or its collaborators are developing; and
                  the success of Immunocore’s current and future collaborations,
                  partnerships or licensing arrangements, including the risk
                  that Immunocore may not realize the anticipated benefits of
                  its collaboration with Bristol Myers Squibb. These and other
                  risks and uncertainties are described in greater detail in the
                  section titled "Risk Factors" in Immunocore’s filings with the
                  Securities and Exchange Commission, including Immunocore’s
                  most recent Annual Report on Form 10-K for the year ended
                  December 31, 2023 filed with the Securities and Exchange
                  Commission on February 28, 2024, as well as discussions of
                  potential risks, uncertainties, and other important factors in
                  the Company’s subsequent filings with the SEC. All information
                  in this press release is as of the date of the release, and
                  the Company undertakes no duty to update this information,
                  except as required by law.
                </p>
                <p>
                  <strong>CONTACT:</strong> <br /><strong>Immunocore </strong
                  ><br />Sébastien Desprez, Head of Communications<br />T: +44
                  (0) 7458030732<br />E: info@immunocore.us
                  <br />Follow on Twitter: @Immunocore
                </p>
                <p>
                  <strong>Investor Relations </strong><br />Clayton Robertson,
                  Head of Investor Relations<br />T: +1 215-384-4781<br />E:
                  info@immunocore.us
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td style="width: 228px; vertical-align: bottom">
                      <strong>Immunocore Holdings PLC</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 228px; vertical-align: bottom">
                      <strong>Consolidated Statement of Operations</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 330px; vertical-align: bottom"
                    >
                      <strong
                        >Comparison of the Years ended December 31, 2023 and
                        2022</strong
                      >
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 228px; vertical-align: bottom">
                      <strong>$'000</strong>
                    </td>
                    <td></td>
                  </tr>
                </table>
                <p><br /></p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td style="vertical-align: bottom"></td>
                    <td
                      colspan="4"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Quarter Ended</strong>
                    </td>
                    <td
                      style="
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="4"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>Year Ended</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        max-width: 55%;
                        width: 55%;
                        min-width: 55%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        max-width: 11%;
                        width: 11%;
                        min-width: 11%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>December 31, 2023</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        max-width: 11%;
                        width: 11%;
                        min-width: 11%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>December 31, 2022</strong>
                    </td>
                    <td
                      style="
                        max-width: 1%;
                        width: 1%;
                        min-width: 1%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        max-width: 11%;
                        width: 11%;
                        min-width: 11%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>December 31, 2023</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        max-width: 11%;
                        width: 11%;
                        min-width: 11%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>December 31, 2022</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">Product revenue</td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      67,592
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      51,506
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      238,735
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      130,013
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">Pre-product revenue</td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (1,084)
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      -
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      10,674
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <em>Total revenue from sale of therapies</em>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>67,592 </strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 50,422 </strong>
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 238,735 </strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 140,687 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Collaboration revenue
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      2,570
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      6,890
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      10,693
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      33,674
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>Total revenue</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>70,162 </strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 57,312 </strong>
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 249,428 </strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 174,361 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Cost of product revenue
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (200)
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (131)
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (1,037)
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (1,089)
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Research and development costs
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (45,565)
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (31,144)
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (163,545)
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (101,921)
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Selling, general, &amp; administrative exps
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (41,449)
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (35,392)
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (144,495)
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (123,059)
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>Operating loss</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>(17,052)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>(9,355)</strong>
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>(59,649)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>(51,708)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">Interest income</td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      5,439
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      2,916
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      17,986
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      3,756
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">Interest expense</td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (1,308)
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (1,518)
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (5,154)
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (5,409)
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Foreign currency (loss) gain
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (12,529)
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (14,206)
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (13,176)
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      14,157
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">Other expense, net</td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (191)
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (1,686)
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (897)
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (1,679)
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Net loss before income taxes
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>(25,641)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>(23,849)</strong>
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>(60,890)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>(40,883)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Income tax credit/(expense)
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      5,911
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (6,172)
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      5,603
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (11,660)
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>Net loss </strong>
                    </td>
                    <td
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> (19,730)</strong>
                    </td>
                    <td
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> (30,021)</strong>
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> (55,287)</strong>
                    </td>
                    <td
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> (52,543)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>Net loss per share</strong>
                    </td>
                    <td
                      style="
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> (0.40)</strong>
                    </td>
                    <td
                      style="
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> (0.63)</strong>
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> (1.13)</strong>
                    </td>
                    <td
                      style="
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> (1.15)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <em>Basic weighted average number of shares</em>
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <em>49,533,622 </em>
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <em> 48,000,101 </em>
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <em> 48,888,975 </em>
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <em> 45,714,923 </em>
                    </td>
                  </tr>
                </table>
                <p><br /></p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td style="width: 648px; vertical-align: bottom">
                      <strong>Consolidated Balance Sheets</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 648px; vertical-align: bottom">
                      <strong>As of December 31,</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 648px; vertical-align: bottom">
                      <strong>$'000</strong>
                    </td>
                  </tr>
                </table>
                <p><br /></p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        max-width: 78%;
                        width: 78%;
                        min-width: 78%;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        max-width: 11%;
                        width: 11%;
                        min-width: 11%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>2023</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        max-width: 11%;
                        width: 11%;
                        min-width: 11%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>ASSETS</strong>
                    </td>
                    <td colspan="2" style="vertical-align: middle"></td>
                    <td colspan="2" style="vertical-align: middle"></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>Current assets</strong>
                    </td>
                    <td colspan="2" style="vertical-align: middle"></td>
                    <td colspan="2" style="vertical-align: middle"></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Cash and cash equivalents
                    </td>
                    <td style="vertical-align: middle">$</td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      442,626
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      402,472
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Accounts receivable, net
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      52,093
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      33,584
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Prepaid expenses and other current assets
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      29,600
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      37,229
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">Inventory</td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      4,501
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      692
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>Total current assets</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>528,820 </strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 473,977 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Property, plant and equipment, net
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      9,215
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      7,833
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Operating lease, right of use assets, net
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      33,520
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      30,944
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Deferred tax assets, net
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      10,973
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      5,121
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Other non-current assets
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      14,473
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      8,887
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>Total assets</strong>
                    </td>
                    <td
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 597,001 </strong>
                    </td>
                    <td
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 526,762 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle"></td>
                    <td colspan="2" style="vertical-align: middle"></td>
                    <td colspan="2" style="vertical-align: middle"></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: bottom">
                      <strong>Liabilities and shareholders’ equity</strong>
                    </td>
                    <td colspan="2" style="vertical-align: middle"></td>
                    <td colspan="2" style="vertical-align: middle"></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>Current liabilities</strong>
                    </td>
                    <td colspan="2" style="vertical-align: middle"></td>
                    <td colspan="2" style="vertical-align: middle"></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">Accounts payables</td>
                    <td style="vertical-align: middle">$</td>
                    <td
                      colspan="1"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      17,798
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      $
                    </td>
                    <td
                      colspan="1"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      14,450
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Accrued expenses &amp; other current liabilities
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      119,835
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      76,747
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Deferred revenue, current
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      -
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      7,756
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Operating lease liabilities, current
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      1,388
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      1,882
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>Total current liabilities</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>139,021 </strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 100,835 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Accrued expenses, non-current
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      978
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      2,215
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Deferred revenue, non-current
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      5,515
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      5,242
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Operating lease liabilities, non-current
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      34,633
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      31,760
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Interest-bearing loans and borrowings
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      48,011
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      47,807
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>Total liabilities</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>228,158 </strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 187,859 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>Shareholders' equity</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">Common stock</td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      134
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      129
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">Deferred stock</td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      1
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      1
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Additional paid-in capital
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      1,149,643
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      1,082,833
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">Accumulated deficit</td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (744,674)
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (689,387)
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Accumulated other comprehensive income
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (36,261)
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      (54,673)
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>Total shareholders' equity</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>368,843 </strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 338,903 </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong
                        >Total liabilities and shareholders' equity</strong
                      >
                    </td>
                    <td
                      style="
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 597,001 </strong>
                    </td>
                    <td
                      style="
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong> 526,762 </strong>
                    </td>
                  </tr>
                </table>
                <p><br /></p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        max-width: 78%;
                        width: 78%;
                        min-width: 78%;
                        vertical-align: bottom;
                      "
                    >
                      <strong
                        >Summary Consolidated Statement of Cash Flows</strong
                      ><br /><br /><strong
                        >For the Years Ended December 31,</strong
                      >
                    </td>
                    <td
                      colspan="2"
                      style="
                        max-width: 11%;
                        width: 11%;
                        min-width: 11%;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        max-width: 11%;
                        width: 11%;
                        min-width: 11%;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: bottom">
                      <strong>$'000</strong>
                    </td>
                    <td colspan="2" style="vertical-align: bottom"></td>
                    <td colspan="2" style="vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: bottom"></td>
                    <td colspan="2" style="vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2023</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td colspan="2" style="vertical-align: bottom"></td>
                    <td colspan="2" style="vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Cash and cash equivalents, beg of year
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      $
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      402,472
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      $
                    </td>
                    <td
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      321,082
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Net cash provided by (used in) operating activities
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,940
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (49,209)
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Net cash (used in) investing activities
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (5,425)
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (2,197)
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Net cash provided by financing activities
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      34,346
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      145,442
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      Net foreign exchange difference on cash held
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      8,293
                    </td>
                    <td
                      colspan="2"
                      style="
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (12,646)
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: middle">
                      <strong>Cash and cash equivalents, end of year</strong>
                    </td>
                    <td
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong> 442,626 </strong>
                    </td>
                    <td
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      colspan="1"
                      style="
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong> 402,472 </strong>
                    </td>
                  </tr>
                </table>
                <p><br /></p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDkyNjIyOCM0MDE3NzUyNDMjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/YTA4Y2NmZjUtMmQ0Ny00ZDg5LTkzZTQtZGJiOGZlYzkwYmJlLTEyMTc4MTE=/tiny/Immunocore-Holdings-Limited.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings Limited
                <p class="spr-ir-news-article-date">
                  Released February 28, 2024
                </p>
              </article>
            </main>
          </div>
        </div>
      </div>`,
  },
  {
    param:
      "immunocore-reports-2022-financial-results-and-provides-business-update",
    title: `Immunocore Reports 2022 Financial Results and Provides Business Update ::
      Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore Reports 2022 Financial Results and Provides
                  Business Update
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2023-03-01T07:00:00" class="date">
                    March 01, 2023 7:00am EST
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2023-03-01_Immunocore_Reports_2022_Financial_Results_and_12.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore Reports 2022 Financial Results and Provides Business Update"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <div class="related-documents box hidden-print">
                  <div class="text">
                    <h2 class="related-docs-title">Related Documents</h2>
                    <div class="text">
                      <div class=" ">
                        <div>
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-23-009313/brhc10048769_20f.htm"
                                  title="20-F Filing Viewer"
                                  class="fancybox fancybox.iframe"
                                  rel="noopener"
                                  >20-F <span class="sr-only">Filing</span></a
                                >
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-23-009313/brhc10048769_20f.htm"
                                  title="20-F Filing Viewer"
                                  rel="noopener"
                                  class="link fancybox fancybox.iframe"
                                  ><span class="eqicon-HTML"></span>HTML</a
                                >
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-23-009313/0001140361-23-009313.pdf"
                                  title="20-F"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                  ><span class="eqicon-PDF"></span>PDF</a
                                >
                              </div>
                            </div>
                          </div>
                          <div class="result-line">
                            <div class="row justify-content-between">
                              <div class="col-auto file-title">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-23-009313/0001140361-23-009313-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  target="_blank"
                                  rel="noopener"
                                  >XBRL</a
                                >
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-23-009313/0001140361-23-009313-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                  ><span class="eqicon-ZIP"></span>ZIP</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p align="center">
                  <strong>Immunocore Reports </strong><strong>2022</strong
                  ><strong>
                    Financial Results and Provides Business Update</strong
                  >
                </p>
                <p align="center">
                  <em>KIMMTRAK / tebentafusp </em><em>net </em
                  ><em>revenues of £</em><em>42</em> <em>million ($</em
                  ><em>51</em> <em>million) in </em><em>Q4 </em><em>2022</em
                  ><em> and</em> <em>£</em><em>117</em><em> million ($</em
                  ><em>141</em> <em>million)</em> <em>in 2022</em
                  ><em>; approved in over 30 countries and </em><em>nearly</em
                  ><em> 200 patients on global early access program</em>
                </p>
                <p align="center">
                  <em>Enrolling</em> <em>IMC-</em><em>F106C (PRAME</em><em>-</em
                  ><em
                    >HLA-A02) in monotherapy and combination arms of Phase 1/2
                    clinical trial</em
                  ><em> and e</em><em>xpanding</em
                  ><em> PRAME franchise, including first-in-class</em>
                  <em>PRAME-</em><em>HLA-A24</em><em> target</em
                  ><em> and a PRAME HLA-A02 half-life </em><em>extension</em>
                </p>
                <p align="center">
                  <em>IND planned </em><em>for f</em><em>irst</em
                  ><em
                    >-in-class ImmTAC targeting PIWIL1 for colorectal and other
                    gastrointestinal cancers</em
                  ><em> in </em><em>Q4 2023</em>
                </p>
                <p align="center">
                  <em>Completed single ascending dose escalation</em
                  ><em> part</em> <em>of </em
                  ><em>IMC-M113V Phase 1 trial in people living with HIV</em
                  ><em> and </em><em>presented </em><em>data</em
                  ><em> at CROI</em><em> 2023</em
                  ><em
                    >; multiple ascending dose portion of trial is enrolling</em
                  >
                </p>
                <p align="center">
                  <em>Cash</em><em> and cash equivalents of </em
                  ><em>$401.6 million</em> <em>as of December 31, 202</em
                  ><em>2</em><em>; cash runway into </em><em>2026 with </em
                  ><em>projected</em><em> KIMMTRAK revenues </em>
                </p>
                <p align="center">
                  <em>Conference call today, </em><em>March</em> <em>1</em
                  ><sup><em>st</em></sup> <em>at 8:00 AM ET, 1:00 PM </em
                  ><em>GM</em><em>T</em>
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, 1 March, 2023) Immunocore Holdings plc
                  (Nasdaq: IMCR), a commercial-stage biotechnology company
                  pioneering the development of a novel class of T cell receptor
                  (TCR) bispecific immunotherapies designed to treat a broad
                  range of diseases, including cancer, infectious diseases and
                  autoimmune conditions, today announced its financial results
                  for the fourth quarter and year ended December 31, 2022, and
                  provided a business update.
                </p>
                <p align="justify">
                  “We are proud that KIMMTRAK, the world’s first commercialized
                  TCR therapy, has been approved in over 30 countries for
                  patients with metastatic uveal melanoma. In 2023, we will make
                  it available to even more patients with launches planned in
                  additional countries and through enrollment of cutaneous
                  melanoma patients into our registrational tebentafusp trial,”
                  said
                  <strong
                    >Bahija Jallal, Chief Executive Officer of
                    Immunocore</strong
                  >. “We are also focused on progressing our PRAME clinical
                  programs with ongoing expansion arms in multiple tumor types.
                  Our platform continues to deliver first-in-class oncology
                  programs, like PIWIL1, and several infectious disease
                  candidates.”
                </p>
                <p>
                  <strong>Full Year</strong> <strong>and Fourth Quarter </strong
                  ><strong>2022 Highlights (including post-period)</strong>
                </p>
                <p>
                  <strong><em>Financial </em></strong
                  ><strong><em>Results </em></strong>
                </p>
                <p align="justify">
                  Total net product and net pre-product revenue (or “net sales”)
                  arising from the sales of KIMMTRAK (tebentafusp) were £42.3
                  million (or $51.1 million) in the fourth quarter of 2022, of
                  which £32.1 million (or $38.8 million) was in the United
                  States, £10.1 million (or $12.2 million) in Europe and £0.1
                  million (or $0.1 million) in international regions. For the
                  year ended December 31, 2022, the Company generated total
                  revenue from the sale of KIMMTRAK and tebentafusp in the
                  amount of £116.8 million (or $141.1 million), of which £80.4
                  million (or $97.2 million) was in the United States, £35.5
                  million (or $42.9 million) in Europe and £0.9 million (or $1.1
                  million) in international regions.
                </p>
                <p align="justify">
                  R&amp;D expenses for the year 2022 were £89.2 million (or
                  $107.9 million), compared to £73.2 million for the year 2021.
                  Selling and Administrative expenses for the year 2022 were
                  £93.7 million (or $113.2 million), compared to £88.4 million
                  for the year 2021.
                </p>
                <p align="justify">
                  Net loss for the fourth quarter of 2022 was £25.2 million (or
                  $30.4 million) compared to a net loss of £39.4 million in the
                  same period in 2021, and full year net loss for 2022 was
                  £41.24 million (or $49.8 million) compared to a full year net
                  loss of £131.5 million in 2021.
                </p>
                <p align="justify">
                  The fourth quarter basic and diluted loss per share for was
                  $0.63, compared to $1.21 on December 31, 2021. Basic and
                  diluted loss per share for the year ended December 31, 2022
                  was $1.09, compared to $4.19 for 2021.
                </p>
                <p align="justify">
                  Cash and cash equivalents for the year ended December 31, 2022
                  were £332.5 million (or $401.6 million). The Company’s current
                  cash position and projected KIMMTRAK revenue provides a
                  projected cash runway into 2026.
                </p>
                <p>
                  <strong><em>KIMMTRAK® (</em></strong
                  ><strong><em>tebentafusp-tebn</em></strong
                  ><strong><em>)</em></strong
                  ><strong><em> for metastatic uveal melanoma </em></strong>
                </p>
                <p align="justify">
                  During the fourth quarter of 2022, the Company continued to
                  add new accounts prescribing KIMMTRAK in the United States,
                  Germany, and France. As of December 31, 2022 there were 240
                  new accounts prescribing KIMMTRAK in the United States, which
                  brings the capture rate of these accounts, according to the
                  Company’s internal estimates, to 50% of potentially eligible
                  patients. There were 100 new accounts prescribing KIMMTRAK in
                  Germany and France, which brings the capture rate to
                  approximately 80% of the eligible patient population. In the
                  United States, Germany, and France the commercial team is
                  focused on treating patients closer to home. In the three
                  countries where KIMMTRAK is commercially available the
                  real-world mean duration of treatment for KIMMTRAK continues
                  increasing to the 9 months observed in the Phase 2 and 3
                  clinical trials.
                </p>
                <p align="justify">
                  During the fourth quarter of 2022, the Company received
                  numerous awards and positive recognitions for KIMMTRAK’s
                  clinical benefit. In November, the Company was awarded the
                  SCRIP award in the UK in the ‘Best New Drug’ category, and in
                  December, the prestigious Prix Galien France 2022 award in the
                  ‘Medicine in Innovative Therapeutics’ category, for KIMMTRAK.
                  KIMMTRAK's clinical benefit to patients was recognized with
                  Germany’s G-BA (Gemeinsamer Bundesausschuss) granting the
                  therapy a considerable added benefit rating, as well as in
                  France, where the HAS transparency committee granted an ASMR
                  III and a SMR rating of important. These recommendations build
                  upon the positive recommendations by American Society of
                  Clinical Oncology (ASCO) and National Comprehensive Cancer
                  Network (NCCN) in the second quarter of 2022. KIMMTRAK was
                  approved by the U.S. Food and Drug Administration; the
                  European Commission; and health authorities in the United
                  Kingdom, Australia and Canada in the first half of 2022.
                </p>
                <p align="justify">
                  The Company is undergoing reimbursement discussions in a
                  number of countries, including Germany and France where the
                  Company is currently receiving revenues. The Company expects
                  reimbursement decisions in one additional major European
                  country by mid-2023 and up to five smaller countries by end of
                  2023.
                </p>
                <p align="justify">
                  In November 2022, the Company and Medison Pharma Ltd.
                  (“Medison”) amended and restated their exclusive distribution
                  agreement for KIMMTRAK originally entered into in September
                  2021. Medison is the exclusive distribution partner for
                  KIMMTRAK in Canada, Australia, New Zealand, Israel, Central
                  and Eastern Europe, and following this amendment South and
                  Central America, and the Caribbean.
                </p>
                <p>
                  <strong><em>T</em></strong
                  ><strong><em>ebentafusp </em></strong
                  ><strong><em>Phase 2/3</em></strong>
                  <strong><em>trial </em></strong
                  ><strong><em>in advanced melanoma </em></strong>
                </p>
                <p align="justify">
                  The Company is screening patients in its Phase 2/3 clinical
                  trial of tebentafusp in patients with previously treated
                  advanced melanoma. The trial will enroll patients with
                  advanced melanoma, excluding uveal melanoma, who have
                  progressed on an anti-PD1, received prior ipilimumab and, if
                  applicable, received a tyrosine kinase inhibitor (TKI).
                  Patients will be randomized to one of three arms including
                  tebentafusp, as monotherapy or in combination with an
                  anti-PD1, and a control arm. The Phase 2 portion of the trial
                  will include 40 patients per arm and has a dual primary
                  endpoint of overall survival (OS) and circulating tumor DNA
                  (ctDNA) reduction.
                </p>
                <p>
                  <strong><em>IMC-F106C targeting PRAME</em></strong
                  ><strong><em>-A02 in multiple solid tumors </em></strong>
                </p>
                <p align="justify">
                  The Company’s planned global expansion of the clinical trial
                  footprint for PRAME-A02 studies is underway, with additional
                  patients now being recruited into the Phase 1/2 monotherapy
                  and combination expansion arms in order to characterize the
                  breadth of clinical activity across multiple tumor types.
                  Initial Phase 1 data with IMC-F106C targeting PRAME
                  (PRAME-A02) was presented at the European Society for Medical
                  Oncology (ESMO) Congress 2022, and the Company initiated four
                  expansion arms—cutaneous melanoma, ovarian, non-small cell
                  lung cancer (NSCLC), and endometrial cancers. The combinations
                  with standards-of-care (checkpoint inhibitors, chemotherapy,
                  and tebentafusp) will position the Company to explore
                  IMC-F106C in earlier lines of treatment. The Company expects
                  to report data from the monotherapy and combination arms by
                  the first half of 2024.
                </p>
                <p>
                  <strong><em>Expansion of PRAME franchise: </em></strong
                  ><strong
                    ><em
                      >IMC-T119C (PRAME-A24) &amp; IMC-P115C (PRAME-A02
                    </em></strong
                  ><strong><em>HLE</em></strong
                  ><strong><em>)</em></strong>
                </p>
                <p align="justify">
                  In January 2023, the Company revealed the addition of two new
                  PRAME ImmTAC candidates for solid tumors to the pipeline.
                  Building on enthusiasm for IMC-F106C targeting PRAME HLA-A02,
                  the Company has expanded its franchise targeting PRAME.
                </p>
                <p align="justify">
                  IMC-F106C is an ImmTAC targeting PRAME for patients with
                  HLA-A02, which is expressed in approximately 40% of Western
                  populations (United States, Canada, EU). In order to expand
                  the potential of TCR therapy targeting PRAME, the Company is
                  developing IMC-T119C, a first-in-class ImmTAC product
                  candidate targeting a PRAME peptide presented by HLA-A24.
                  HLA-24 is an HLA-type that is estimated to be present in 60%
                  of people in Japan and 15-20% in Western populations.
                </p>
                <p align="justify">
                  In addition, the Company is developing IMC-P115C, a half-life
                  extended (HLE) ImmTAC product candidate targeting PRAME-A02,
                  with the aim of improving patient convenience. IMC-P115C
                  targets the same PRAME-A02 peptide and uses the same CD3 end
                  and TCR specificity as IMC-F106C.
                </p>
                <p align="justify">
                  The Company plans to submit investigational new drug
                  applications (INDs) for these two ImmTAC candidates in 2024.
                </p>
                <p>
                  <strong
                    ><em
                      >First-in-class ImmTAC candidate – IMC-R117C (PIWIL1)
                    </em></strong
                  >
                </p>
                <p align="justify">
                  In January 2023, the Company announced an ImmTAC targeting a
                  novel protein for colorectal and other gastrointestinal
                  cancers. The Company has leveraged its proprietary peptidomic
                  database to validate a novel target, PIWIL1. PIWIL1 is
                  believed to play a role in tumor progression and is expressed
                  across a range of tumors including colorectal, which is
                  historically insensitive to immune checkpoints, as well as
                  gastro-esophageal, and pancreatic cancer. PIWIL1 is also
                  reported to be a negative prognostic marker. The Company
                  believes IMC-R117C is the first PIWIL1 targeted immunotherapy
                  and plans to submit an IND in the fourth quarter of 2023.
                </p>
                <p>
                  <strong><em>IMC-C103C targeting MAGE-A4</em></strong>
                </p>
                <p align="justify">
                  In December 2022, the Company presented Phase 1 data for
                  IMC-C103C in a poster presentation at the ESMO Immuno-Oncology
                  2022 Congress.
                </p>
                <p>
                  In February 2023, Genentech accepted Immunocore’s proposal to
                  cease co-funding the development of MAGE-A4 HLA-A02 targeted
                  programs, except for Immunocore’s equal share of the wind-down
                  costs of the IMC-C103C Phase 1 clinical trial. The clinical
                  trial with IMC-C103C is nearing completion and Immunocore does
                  not plan to enroll additional patients. Immunocore is eligible
                  to receive development and commercial milestone payments plus
                  royalties from Genentech on any sales of MAGE-A4 HLA-A02
                  targeted products arising under the Genentech agreement.
                </p>
                <p>
                  <strong><em>ImmTAV</em></strong
                  ><strong><em>® clinical programs</em></strong>
                </p>
                <p align="justify">
                  In February 2023, the Company presented initial safety and
                  pharmacodynamic activity data with IMC-C113V, the first
                  soluble TCR therapy for people living with Human
                  Immunodeficiency Virus (HIV), at the Conference on
                  Retroviruses and Opportunistic Infections (CROI) 2023.
                  IMC-M113V is an immunotherapeutic approach designed to
                  specifically eliminate CD4+ cells that are persistently
                  infected with HIV (‘reservoirs’). All doses (1.6 mcg, 5 mcg,
                  and 15 mcg) of IMC-M113V were well tolerated and not
                  associated with cytokine release syndrome or neurotoxicity of
                  any grade. There were no serious adverse events, nor
                  significant changes in hematology or chemistry. Plasma viral
                  load remained suppressed throughout dosing and follow-up. In
                  addition, transient, dose-dependent increases in serum IL6
                  occurred 8-24 hours post-infusion. Five out of the ten
                  participants who received the 15-mcg dose showed a &gt;4-fold
                  rise in IL6, which had been prespecified as indicative of
                  pharmacodynamic activity based on prior experience from
                  clinical trials with KIMMTRAK.
                </p>
                <p align="justify">
                  The Company has started enrolling people living with HIV in
                  the multiple ascending dose (MAD) part of the trial, to
                  identify a safe and tolerable dosing schedule that could lead
                  to reduction in the viral reservoir and control of HIV after
                  stopping antiretroviral therapies (ART), or functional cure.
                  The MAD trial will enroll up to 28 participants.
                </p>
                <p align="justify">
                  In the second quarter of 2022, the Company presented data from
                  the first three patients in the first-in-human clinical trial
                  of IMC-I109V for chronic hepatitis B at the EASL International
                  Liver Congress. In this first cohort, the three patients
                  received a single dose of 0.8 mcg, based on the minimum
                  anticipated biological effect level (MABEL). The dose in this
                  initial cohort was well tolerated and was not associated with
                  adverse events and resulted in a transient, small decrease in
                  serum HBsAg with concomitant minor increase in alanine
                  transaminase (ALT). The Company is enrolling patients in the
                  single ascending dose portion of the trial.
                </p>
                <p><strong>Corporate and financial updates</strong></p>
                <p align="justify">
                  In December 2022, the Company entered into an agreement with
                  Gadeta B.V., to develop the first gamma delta (γδ) TCR ImmTAC
                  for solid tumors, including colorectal cancer. Immunocore will
                  collaborate with Gadeta on ‘201 γδ-TCR target discovery and
                  has an option to develop ImmTAC therapies derived from the
                  ‘201 TCR as part of the research collaboration.
                </p>
                <p><strong>Financial Results</strong></p>
                <p align="justify">
                  Basic and diluted loss per share was £0.54 (or $0.63) and
                  £0.90 (or $1.09) for the quarter and year ended December 31,
                  2022, respectively, as compared to a basic and diluted loss
                  per share of £0.90 and £3.10, respectively, for the same
                  periods in 2021. Total operating loss for the quarter and year
                  ended December 31, 2022, was £22.3 million (or $27.0 million)
                  and £39.6 million (or $47.8 million), respectively, as
                  compared to £37.8 million and £135.2 million respectively for
                  the same periods in 2021.
                </p>
                <p align="justify">
                  For the fourth quarter and year ended December 31, 2022, we
                  generated total revenue from the sale of therapies of £42.3
                  million ($51.1 million) and £116.8 million ($141.1 million),
                  respectively, due to the sale of KIMMTRAK and tebentafusp, of
                  which £32.1 million ($38.8 million) and £80.4 million ($97.2
                  million), respectively was in the United States, £10.1 million
                  ($12.2 million) and £35.5 million ($42.9 million),
                  respectively, was in Europe, and £0.1 million ($0.1 million)
                  and £0.9 million ($1.1 million), respectively, was in the rest
                  of the world. We received marketing approval for KIMMTRAK in
                  the United States, Europe and other territories in the year
                  ended December 31, 2022, and did not have marketing approval
                  for, and thus no product revenue from, KIMMTRAK in the year
                  ended December 31, 2021.
                </p>
                <p align="justify">
                  For the fourth quarter and year ended December 31, 2022, our
                  research and development (“R&amp;D”) expenses were £27.1
                  ($32.8 million) and £89.2 million (or $107.9 million),
                  respectively as compared to £20.1 million and £73.2 million
                  for the quarter and year ended December 31, 2021. These
                  increases were due to increased expenses in connection with
                  our IMC-F106C program and increased headcount and laboratory
                  costs. The Company expects R&amp;D expenses to increase in
                  future periods as the Company advances its trials and further
                  develops clinical and preclinical pipeline candidates.
                </p>
                <p align="justify">
                  For the quarter and year ended December 31, 2022, our selling
                  and administrative expenses were £43.1 million ($52.1 million)
                  and £93.7 million (or $113.2 million), respectively, compared
                  to £24.4 million and £88.4 million for the quarter and year
                  ended December 31, 2021. These increases were related to
                  higher selling and distribution costs following regulatory
                  approval of KIMMTRAK and an increase in headcount costs
                  (excluding share-based payment charges, which reduced in
                  2022). Our administrative expenses also increased in the
                  fourth quarter of 2022 due to approximately $15 million of
                  non-cash foreign exchange losses, primarily on U.S. dollar
                  balances.
                </p>
                <p align="justify">
                  Cash and cash equivalents were £332.5 million (or $401.6
                  million) as of December 31, 2022, as compared to £237.9
                  million (or $321.1 million) as of December 31, 2021. We expect
                  that our existing cash, along with anticipated revenue from
                  KIMMTRAK, will be sufficient to fund our planned operating
                  expenses, financial commitments and other cash requirements
                  into 2026.
                </p>
                <p align="justify">
                  <em
                    >We maintain our books and records in pounds sterling. For
                    the convenience of the reader, we have translated pound
                    sterling amounts as of and for the period ended December 31, </em
                  ><em>2022</em
                  ><em> into U.S. dollars at a rate of £1.00 to $1.2077.</em>
                </p>
                <p align="justify"><strong>Audio Webcast</strong></p>
                <p align="justify">
                  Immunocore will host a conference call today, March 1, 2023 at
                  8:00 A.M. ET/ 1:00 PM GMT, to discuss the fourth quarter and
                  full year 2022 financial results and provide a business
                  update. The call will also be available via webcast by
                  visiting the Events &amp; Presentations section on
                  Immunocore’s website. A replay of this webcast will be
                  available for 30 days.
                </p>
                <p>
                  <strong>Conference Call Details:</strong><br />U.S.
                  (toll-free): 877-405-1239<br />International (toll): +1
                  201-389-0851
                </p>
                <p align="center">###</p>
                <p>
                  <strong>About KIMMTRAK</strong><sup><strong>®</strong></sup
                  ><br />KIMMTRAK is a novel bispecific protein comprised of a
                  soluble T cell receptor fused to an anti-CD3 immune-effector
                  function. KIMMTRAK specifically targets gp100, a lineage
                  antigen expressed in melanocytes and melanoma. This is the
                  first molecule developed using Immunocore’s ImmTAC technology
                  platform designed to redirect and activate T cells to
                  recognize and kill tumor cells. KIMMTRAK has been approved for
                  the treatment of HLA-A*02:01-positive adult patients with
                  unresectable or metastatic uveal melanoma in the United
                  States, European Union, Canada, Australia, and the United
                  Kingdom.
                </p>
                <p>
                  <strong>About Phase 3 IMCgp100-202 Trial</strong
                  ><br />IMCgp100-202 (NCT03070392) is a randomized pivotal
                  trial that evaluated overall survival (OS) of KIMMTRAK
                  compared to investigator’s choice (either pembrolizumab,
                  ipilimumab, or dacarbazine) in HLA-A*02:01-positive adult
                  patients with previously untreated mUM. KIMMTRAK demonstrated
                  an unprecedented OS benefit with a Hazard Ratio (HR) in the
                  intent-to-treat population favoring KIMMTRAK, HR=0.51 (95% CI:
                  0.37, 0.71); p&lt; 0.0001, over investigator’s choice (82%
                  pembrolizumab; 13% ipilimumab; 6% dacarbazine).
                </p>
                <p><strong>IMPORTANT SAFETY INFORMATION</strong></p>
                <p>
                  <strong
                    >Cytokine Release Syndrome (CRS), which may be serious or
                    life-threatening, occurred in patients receiving KIMMTRAK.
                    Monitor for at least 16 hours following first three
                    infusions and then as clinically indicated.</strong
                  >
                  Manifestations of CRS may include fever, hypotension, hypoxia,
                  chills, nausea, vomiting, rash, elevated transaminases,
                  fatigue, and headache<em>. </em>CRS occurred in 89% of
                  patients who received KIMMTRAK with 0.8% being grade 3 or 4.
                  Ensure immediate access to medications and resuscitative
                  equipment to manage CRS. Ensure patients are euvolemic prior
                  to initiating the infusions. Closely monitor patients for
                  signs or symptoms of CRS following infusions of KIMMTRAK.
                  Monitor fluid status, vital signs, and oxygenation level and
                  provide appropriate therapy. Withhold or discontinue KIMMTRAK
                  depending on persistence and severity of CRS.
                </p>
                <p><strong>Skin Reactions</strong></p>
                <p>
                  Skin reactions, including rash, pruritus, and cutaneous edema
                  occurred in 91% of patients treated with KIMMTRAK. Monitor
                  patients for skin reactions. If skin reactions occur, treat
                  with antihistamine and topical or systemic steroids based on
                  persistence and severity of symptoms. Withhold or permanently
                  discontinue KIMMTRAK depending on the severity of skin
                  reactions.
                </p>
                <p><strong>Elevated Liver Enzymes</strong></p>
                <p>
                  Elevations in liver enzymes occurred in 65% of patients
                  treated with KIMMTRAK. Monitor alanine aminotransferase (ALT),
                  aspartate aminotransferase (AST), and total blood bilirubin
                  prior to the start of and during treatment with KIMMTRAK.
                  Withhold KIMMTRAK according to severity.
                </p>
                <p>
                  <strong>Embryo-Fetal Toxicity</strong><br />KIMMTRAK may cause
                  fetal harm. Advise pregnant patients of potential risk to the
                  fetus and patients of reproductive potential to use effective
                  contraception during treatment with KIMMTRAK and 1 week after
                  the last dose.
                </p>
                <p>
                  The most common adverse reactions (≥30%) in patients who
                  received KIMMTRAK were cytokine release syndrome, rash,
                  pyrexia, pruritus, fatigue, nausea, chills, abdominal pain,
                  edema, hypotension, dry skin, headache, and vomiting. The most
                  common (≥50%) laboratory abnormalities were decreased
                  lymphocyte count, increased creatinine, increased glucose,
                  increased AST, increased ALT, decreased hemoglobin, and
                  decreased phosphate.
                </p>
                <p>
                  For more information, please see full Summary of Product
                  Characteristics (SmPC) or full U.S. Prescribing Information
                  (including BOXED WARNING for CRS).
                </p>
                <p>
                  <strong>About </strong><strong>KIMMTRAKConnect</strong
                  ><br />Immunocore is committed<strong> </strong>to helping
                  patients who need KIMMTRAK obtain access via our
                  KIMMTRAKConnect program. The program provides services with
                  dedicated nurse case managers who provide personalized
                  support, including educational resources, financial
                  assistance, and site of care coordination. To learn more,
                  visit KIMMTRAKConnect.com or call 844-775-2273.
                </p>
                <p>
                  <strong>About Immunocore</strong><br />Immunocore is a
                  commercial-stage biotechnology company pioneering the
                  development of a novel class of TCR bispecific immunotherapies
                  called ImmTAX – Immune mobilizing monoclonal TCRs Against X
                  disease – designed to treat a broad range of diseases,
                  including cancer, autoimmune, and infectious disease.
                  Leveraging its proprietary, flexible, off-the-shelf ImmTAX
                  platform, Immunocore is developing a deep pipeline in multiple
                  therapeutic areas, including five clinical stage programs in
                  oncology and infectious disease, advanced pre-clinical
                  programs in autoimmune disease and multiple earlier
                  pre-clinical programs. The Company’s most advanced oncology
                  TCR therapeutic, KIMMTRAK has been approved for the treatment
                  of HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p>
                  <strong>Forward Looking Statements</strong> <br />This press
                  release contains “forward-looking statements” within the
                  meaning of the safe harbor provisions of the Private
                  Securities Litigation Reform Act of 1995. Words such as “may,”
                  “can,” “will,” “believe,” “expect,” “plan,” “anticipate,” and
                  similar expressions (as well as other words or expressions
                  referencing future events or circumstances) are intended to
                  identify forward-looking statements. All statements, other
                  than statements of historical facts, included in this press
                  release are forward-looking statements. These statements
                  include, but are not limited to, statements regarding the
                  Company’s 2023 financial outlook, milestone expectations,
                  future expenses and revenue and financial performance; the
                  therapeutic potential and expected clinical benefits,
                  including overall survival benefit, of Immunocore’s products
                  and product candidates, including KIMMTRAK, IMC-F106C,
                  IMC-T119C, IMC-P115C, IMC-R117C, and IMC-M113V; expected
                  capture rate for KIMMTRAK; expected expansion plans for
                  KIMMTRAK; the Company’s belief that IMC-T119C is
                  first-in-class ImmTAC; the Company’s belief that IMC-R117C is
                  first in class and first PIWIL targeted immunotherapy for
                  colorectal and other gastrointestinal cancers; the development
                  and expansion of Immunocore’s pipeline and the design,
                  progress, timing, enrollment, scope, expansion and results of
                  Immunocore’s existing and planned clinical trials, including
                  statements regarding the ongoing enrollment of patients in the
                  Phase 2/3 trial to investigate the potential of tebentafusp in
                  advanced cutaneous melanoma, the continued expansion of,
                  enrollment of additional patients in, and timing for reporting
                  data from the monotherapy and combination arms of the
                  IMC-F106C-101 trial, the planned IND timing for IMC-R117C,
                  IMC-T119C and IMC-P115C; Immunocore’s ability to obtain and
                  maintain regulatory approval for its products and product
                  candidates; expectations regarding the potential market
                  opportunity and potential commercial performance of KIMMTRAK
                  and Immunocore’s other product candidates, if approved; the
                  ability of and timing to obtain reimbursement decisions;
                  statements regarding partnerships, including the amended
                  partnership with Medison and the partnership with Genentech,
                  and potential milestone payments under such partnerships; the
                  continued launch of KIMMTRAK in additional countries and the
                  potential to secure majority market share of patients with
                  metastatic uveal melanoma; the establishment of KIMMTRAK
                  globally as a first line treatment for metastatic uveal
                  melanoma; the planned exploration of patient convenience; and
                  Immunocore’s anticipated cash runway. Any forward-looking
                  statements are based on management’s current expectations of
                  future events and are subject to a number of risks and
                  uncertainties that could cause actual results to differ
                  materially and adversely from those set forth in or implied by
                  such forward-looking statements, many of which are beyond
                  Immunocore’s control. <br />These risks and uncertainties
                  include, but are not limited to, the impact of worsening
                  macroeconomic conditions and the ongoing and evolving COVID-19
                  pandemic on Immunocore’s business, strategy, clinical trials,
                  financial position and anticipated milestones, including
                  Immunocore’s ability to conduct ongoing and planned clinical
                  trials; Immunocore’s ability to obtain a clinical supply of
                  current or future product candidates, or commercial supply of
                  KIMMTRAK or any future approved products, including as a
                  result of supply chain disruptions, the COVID-19 pandemic, the
                  war in Ukraine or global geopolitical tension; Immunocore’s
                  ability to obtain and maintain regulatory approvals for its
                  product candidates; Immunocore’s ability to develop,
                  manufacture and commercialize its product candidates;
                  Immunocore’s ability and plans in continuing to establish and
                  expand a commercial infrastructure and to successfully launch,
                  market and sell KIMMTRAK and any future approved products;
                  Immunocore’s ability to successfully expand the approved
                  indications for KIMMTRAK or obtain marketing approval for
                  KIMMTRAK in additional geographies in the future; the delay of
                  any current or planned clinical trials, whether due to the
                  COVID-19 pandemic, patient enrollment delays or otherwise;
                  Immunocore’s ability to successfully demonstrate the safety
                  and efficacy of its product candidates and gain approval of
                  its product candidates on a timely basis, if at all;
                  competition with respect to market opportunities; unexpected
                  safety or efficacy data observed during pre-clinical studies
                  or clinical trials; actions of regulatory agencies, which may
                  affect the initiation, timing and progress of Immunocore’s
                  clinical trials or future regulatory approval; Immunocore’s
                  need for and ability to obtain additional funding, on
                  favorable terms or at all, including as a result of worsening
                  macroeconomic conditions such as rising inflation and interest
                  rates, volatility in the capital markets and related market
                  uncertainty, the COVID-19 pandemic, the war in Ukraine and
                  global geopolitical tension; Immunocore’s ability to obtain,
                  maintain and enforce intellectual property protection for
                  KIMMTRAK or any product candidates it is developing; clinical
                  trial site activation or enrollment rates that are lower than
                  expected; and the success of Immunocore’s current and future
                  collaborations, partnerships or licensing arrangements. These
                  and other risks and uncertainties are described in greater
                  detail in the section titled "Risk Factors" in Immunocore’s
                  filings with the Securities and Exchange Commission, including
                  Immunocore’s most recent Annual Report on Form 20-F for the
                  year ended December 31, 2021 filed with the Securities and
                  Exchange Commission on March 3, 2022, as well as discussions
                  of potential risks, uncertainties, and other important factors
                  in the Company’s subsequent filings with the Securities and
                  Exchange Commission. All information in this press release is
                  as of the date of the release, and the Company undertakes no
                  duty to update this information, except as required by law.
                </p>
                <p>
                  <strong>CONTACT:</strong> <br /><strong>Immunocore</strong
                  ><br />Sébastien Desprez, Head of Communications<br />T: +44
                  (0) 7458030732<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >mailto:</a
                  >info@immunocore.us <br />Follow on Twitter:
                  @Immunocore
                </p>
                <p>
                  <strong>Consilium</strong
                  ><strong>
                    Strategic Communications (corporate and financial)</strong
                  ><br />Mary-Jane Elliott/ Chris Welsh/Jessica Hodgson<br />T:
                  +44 (0)203 709 5700<br />E:
                  <a
                    href="info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  >
                </p>
                <p>
                  <strong>Investor Relations </strong><br />Clayton Robertson,
                  Head of Investor Relations<br />T: +1 215-384-4781<br />E:
                  info@immunocore.us
                </p>
                <p><strong>Consolidated Statement of Loss</strong></p>
                <p>
                  <strong>Comparison of the Years Ended December 31, </strong
                  ><strong>202</strong><strong>2</strong
                  ><strong> and 202</strong><strong>1</strong><strong>:</strong>
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td style="width: 417.27px; vertical-align: middle"></td>
                    <td
                      colspan="15"
                      style="
                        width: 206.74px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>Year ended December 31,</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 417.27px; vertical-align: middle">​</td>
                    <td
                      colspan="9"
                      style="
                        width: 134.54px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="4"
                      style="
                        width: 65.4px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 417.27px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 5.6px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$’</strong><strong>000</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong><strong>’</strong><strong>000</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong><strong>’</strong><strong>000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Product revenue
                    </td>
                    <td style="width: 5.6px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      130,610
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      108,148
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Pre-product revenue
                    </td>
                    <td
                      style="
                        width: 5.6px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      10,460
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      8,661
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      3,010
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      <strong>Total revenue from sale of therapies</strong>
                    </td>
                    <td
                      style="
                        width: 5.6px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>141,070</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>116,809</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>3,010</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Collaboration revenue
                    </td>
                    <td
                      style="
                        width: 5.6px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      32,521
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      26,928
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      23,510
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      <strong>Total revenue</strong>
                    </td>
                    <td
                      style="
                        width: 5.6px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>173,591</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>143,737</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>26,520</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Cost of product revenue
                    </td>
                    <td style="width: 5.6px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      (548)
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      (454)
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Net other operating income / (loss)
                    </td>
                    <td style="width: 5.6px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      4
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      3
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      (57)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Research and development costs
                    </td>
                    <td style="width: 5.6px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      (107,691)
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      (89,170)
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      (73,226)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Selling and administrative expenses
                    </td>
                    <td
                      style="
                        width: 5.6px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (113,189)
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (93,723)
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (88,399)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      <strong>Operating loss</strong>
                    </td>
                    <td
                      style="
                        width: 5.6px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(47,833)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(39,607)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(135,162)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Finance income
                    </td>
                    <td style="width: 5.6px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      3,809
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      3,154
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      47
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Finance costs
                    </td>
                    <td
                      style="
                        width: 5.6px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (9,290)
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (7,692)
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (5,813)
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 417.27px; vertical-align: middle">
                      <strong>Non-operating expense</strong>
                    </td>
                    <td
                      style="
                        width: 5.6px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(5,481)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(4,538)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(5,766)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      <strong>Loss before taxes</strong>
                    </td>
                    <td style="width: 5.6px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(53,314)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(44,145)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(140,928)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Income tax credit
                    </td>
                    <td
                      style="
                        width: 5.6px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      3,528
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      2,921
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      9,405
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      <strong>Loss for the period</strong>
                    </td>
                    <td
                      style="
                        width: 5.6px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(49,786)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(41,224)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(131,523)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="
                        width: 417.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      <strong>Basic and diluted loss per share</strong>
                    </td>
                    <td
                      style="
                        width: 5.6px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 57.27px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1.0</strong><strong>9)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 58.07px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(0.90)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 6.8px; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 6.8px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 58.6px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(3.10)</strong>
                    </td>
                  </tr>
                </table>
                <p><strong>Consolidated Statement of Loss</strong></p>
                <p>
                  <strong>Comparison of the </strong><strong>Quarters </strong
                  ><strong>Ended December 31, </strong><strong>202</strong
                  ><strong>2</strong><strong> and 202</strong><strong>1</strong
                  ><strong>:</strong>
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td style="width: 33.94%; vertical-align: middle"></td>
                    <td
                      colspan="8"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>Quarter ended December 31,</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33.94%; vertical-align: middle">​</td>
                    <td
                      colspan="5"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td
                      style="
                        width: 0.46%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33.94%; vertical-align: middle"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.59%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$’</strong><strong>000</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.57%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong><strong>’</strong><strong>000</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.6%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong><strong>’</strong><strong>000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Product revenue
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.59%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      52,197
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.57%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      43,220
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.6%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Pre-product revenue
                    </td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.59%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,117)
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.57%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (925)
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.6%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      3,010
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      <strong>Total revenue from sale of therapies</strong>
                    </td>
                    <td
                      style="
                        width: 0.46%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.59%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>51,080</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.57%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>42,295</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.6%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>3,010</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Collaboration revenue
                    </td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.59%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      6,964
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.57%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      5,767
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.6%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      3,583
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      <strong>Total revenue</strong>
                    </td>
                    <td
                      style="
                        width: 0.46%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.59%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>58,044</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.57%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>48,062</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.6%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>6,593</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Cost of product revenue
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.59%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (132)
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.57%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (109)
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.6%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Net other operating income
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.59%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.57%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.6%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      13
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Research and development costs
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.59%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (32,774)
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.57%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (27,138)
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.6%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (20,072)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Selling and administrative expenses
                    </td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.59%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (52,103)
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.57%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (43,143)
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.6%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (24,366)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      <strong>Operating loss</strong>
                    </td>
                    <td
                      style="
                        width: 0.46%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.59%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(26,963)</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.57%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(22,326)</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.6%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(37,832)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Finance income
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.59%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,934
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.57%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,429
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.6%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      5
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Finance costs
                    </td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.59%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (3,837)
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.57%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (3,177)
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.6%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,348)
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33.94%; vertical-align: middle">
                      <strong>Non-operating expense</strong>
                    </td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.59%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(903)</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.57%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(748)</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.6%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,343)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      <strong>Loss before taxes</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.59%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(27,866)</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.57%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(23,074)</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.6%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(39,175)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      Income tax expense
                    </td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.59%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (2,571)
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.57%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (2,129)
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.6%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (214)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      <strong>Loss for the period</strong>
                    </td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.59%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(30,437)</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.57%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(25,203)</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.6%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(39,389)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 33.94%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: middle;
                      "
                    >
                      <strong>Basic and diluted loss per share</strong>
                    </td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.59%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(0.63)</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.57%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(</strong><strong>0.54)</strong>
                    </td>
                    <td style="width: 0.46%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.46%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.6%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(0.90)</strong>
                    </td>
                  </tr>
                </table>
                <p></p>
                <p align="justify"><strong>Cash Flows</strong></p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 471.6pt;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        width: 371.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="8"
                      style="
                        width: 257.34px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>Year ended December 31,</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 371.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="5"
                      style="
                        width: 164.87px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td
                      style="
                        width: 10.6px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 81.8px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 371.47px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 8.07px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 65.6px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$’000</strong>
                    </td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.34px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong><strong>’</strong><strong>000</strong>
                    </td>
                    <td
                      style="
                        width: 10.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 70.4px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong><strong>’</strong><strong>000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 371.47px; vertical-align: middle">
                      Cash and cash equivalents at beginning of the year
                    </td>
                    <td style="width: 8.07px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 65.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      287,295
                    </td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      237,886
                    </td>
                    <td
                      style="
                        width: 10.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 70.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      129,716
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 371.47px; vertical-align: middle">
                      Net cash flows used in operating activities
                    </td>
                    <td style="width: 8.07px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 65.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (31,269)
                    </td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (25,891)
                    </td>
                    <td
                      style="
                        width: 10.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 70.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (96,110)
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 371.47px; vertical-align: middle">
                      Net cash flows from / (used in) investing activities
                    </td>
                    <td style="width: 8.07px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 65.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      233
                    </td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      193
                    </td>
                    <td
                      style="
                        width: 10.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 70.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (367)
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 371.47px; vertical-align: middle">
                      Net cash flows from financing activities
                    </td>
                    <td style="width: 8.07px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 65.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      138,226
                    </td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      114,454
                    </td>
                    <td
                      style="
                        width: 10.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 70.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      204,631
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 371.47px; vertical-align: middle">
                      Net foreign exchange difference on cash held
                    </td>
                    <td
                      style="
                        width: 8.07px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 65.6px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      7,122
                    </td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.34px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      5,897
                    </td>
                    <td
                      style="
                        width: 10.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 70.4px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      16
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 371.47px; vertical-align: middle">
                      <strong
                        >Cash and cash equivalents at end of the year</strong
                      >
                    </td>
                    <td
                      style="
                        width: 8.07px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 65.6px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>401,607</strong>
                    </td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.34px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>332,539</strong>
                    </td>
                    <td
                      style="
                        width: 10.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.4px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 70.4px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>237,886</strong>
                    </td>
                  </tr>
                </table>
                <p>
                  <strong>Consolidated Statements of </strong
                  ><strong>Financial Position as </strong><strong>at</strong
                  ><strong> December 31,</strong>
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 492.4pt;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td style="width: 436.47px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 67.8px; vertical-align: bottom"
                    ></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 67.94px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 71.6px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£’000</strong>
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£’000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      <strong>Non-current assets</strong>
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Property, plant and equipment
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      6,472
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      8,944
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Intangible assets
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      410
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Right of use assets
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,173
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      22,593
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Other non-current assets
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      7,342
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,935
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Deferred tax asset
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,240
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,575
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      <strong>Total non-current assets</strong>
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>43,637</strong>
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>39,047</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      <strong>Current assets</strong>
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Inventory
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      943
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Trade and other receivables
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      46,711
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      15,208
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Tax receivable
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      11,688
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      9,632
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Cash and cash equivalents
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      332,539
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      237,886
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      <strong>Total current assets</strong>
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>391,881</strong>
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>262,726</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      <strong>Total assets</strong>
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>435,518</strong>
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>301,773</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      <strong>Equity</strong>
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Share capital
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      97
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      88
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Share premium
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      123,751
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      212,238
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Foreign currency translation reserve
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (3,097)
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      89
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Other reserves
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      337,847
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      386,167
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Share-based payment reserve
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      81,411
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      54,357
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Accumulated deficit
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (261,253)
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (481,392)
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      <strong>Total equity</strong>
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>278,756</strong>
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>171,547</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      <strong>Non-current liabilities</strong>
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Non-current accruals
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,479
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Interest-bearing loans and borrowings
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      39,500
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      37,226
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Deferred revenue
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,331
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      6,408
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Lease liabilities
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      28,248
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,355
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Provisions
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      114
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      57
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      <strong>Total non-current liabilities</strong>
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>73,672</strong>
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>69,046</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      <strong>Current liabilities</strong>
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Trade and other payables
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      75,076
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      35,436
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Deferred revenue
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      6,408
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      24,450
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Lease liabilities
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,555
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,255
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      Provisions
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      51
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      39
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      <strong>Total current liabilities</strong>
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>83,090</strong>
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>61,180</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      <strong>Total liabilities</strong>
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>156,762</strong>
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>130,226</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 436.47px; vertical-align: top">
                      <strong>Total equity and liabilities</strong>
                    </td>
                    <td style="width: 6.4px; vertical-align: bottom"></td>
                    <td style="width: 61.4px; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 6.4px;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 57.94px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>435,518</strong>
                    </td>
                    <td
                      style="
                        width: 6.34px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10.34px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 61.2px;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>301,773</strong>
                    </td>
                  </tr>
                </table>
                <p></p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDc5NTUyMSM0MDE1MDIwNjQjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/ZjFhMGE1YmYtNjJkZC00MzE2LWJlOTMtNGQxY2VjNjczN2EzLTEyMTc4MTE=/tiny/Immunocore-Holdings-Limited.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings Limited
                <p class="spr-ir-news-article-date">Released March 1, 2023</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param:
      "immunocore-reports-third-quarter-2022-financial-results-and-provides-business-update",
    title: `Immunocore Reports Third Quarter 2022 Financial Results and Provides
      Business Update :: Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore Reports Third Quarter 2022 Financial Results and
                  Provides Business Update
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2022-11-09T07:00:00" class="date">
                    November 09, 2022 7:00am EST
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2022-11-09_Immunocore_Reports_Third_Quarter_2022_Financial_25.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore Reports Third Quarter 2022 Financial Results and Provides Business Update"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <div class="related-documents box hidden-print">
                  <div class="text">
                    <h2 class="related-docs-title">Related Documents</h2>
                    <div class="text">
                      <div class=" ">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>

                <p align="right">
                  <img
                    alt=""
                    data-mce-src="/api/ImageRender/DownloadFile?resourceId=b1a6b78b-ab89-4f48-9061-401efe63220a&amp;size=3"
                    height="31.0px"
                    name="GNW_RichHtml_IMG"
                    src="https://ml-eu.globenewswire.com/Resource/Download/b1a6b78b-ab89-4f48-9061-401efe63220a"
                    width="249.0px"
                  />
                </p>
                <p align="center">
                  <strong
                    >Immunocore Reports Third Quarter 2022 Financial Results and
                    Provides Business Update</strong
                  >
                </p>
                <p align="center">
                  <em>Net KIMMTRAK / </em><em>tebentafusp</em
                  ><em>
                    revenues of £36.3 million ($40.4 million) in Q3 2022</em
                  >
                </p>
                <p align="center">
                  <em
                    >Promising clinical activity data for IMC-F106C, the first
                    off-the-shelf TCR therapy targeting PRAME, presented at ESMO
                    2022</em
                  >
                </p>
                <p align="center">
                  <em
                    >Cash and cash equivalents of £347 million ($387 million) as
                    of September 30, 2022
                  </em>
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, 09 November 2022) Immunocore Holdings plc
                  (Nasdaq: IMCR) (“Immunocore” or the “Company”), a
                  commercial-stage biotechnology company pioneering the
                  development of a novel class of T cell receptor (TCR)
                  bispecific immunotherapies designed to treat a broad range of
                  diseases, including cancer, autoimmune and infectious
                  diseases, today announced its financial results for the third
                  quarter ended September 30, 2022 and provided a business
                  update.
                </p>
                <p align="justify">
                  “We are proud to have delivered the world’s first soluble TCR
                  therapy to patients, and to have achieved such uptake in
                  academic and community treatment centers,”
                  <strong>commented</strong>
                  <strong
                    >Bahija Jallal, Chief Executive Officer of
                    Immunocore.</strong
                  >
                  “The promising clinical data from our PRAME candidate,
                  presented at ESMO Congress 2022, has demonstrated the
                  potential of our platform in multiple tumor types and
                  confirmed that there is high and homogeneous expression of the
                  antigen across these tumors. We are recruiting patients in the
                  expansion arms of the Phase 1/2 trial to further assess
                  efficacy.”
                </p>
                <p align="justify">
                  “With the strong commercial performance of KIMMTRAK, our PIPE
                  financing in the third quarter, and the refinancing of the
                  existing debt facility on improved terms, we are
                  well-positioned to confidently deliver the next stages of the
                  Company’s growth, including the further development of the
                  PRAME clinical program,” <strong>commented</strong>
                  <strong
                    >Brian Di Donato, Chief Financial Officer &amp; Head of
                    Strategy of Immunocore.</strong
                  >
                </p>
                <p align="justify">
                  <strong
                    >Third Quarter 2022 Highlights (including
                    post-period)</strong
                  >
                </p>
                <p align="justify">
                  <strong><em>KIMMTRAK® (</em></strong
                  ><strong><em>tebentafusp-tebn</em></strong
                  ><strong><em>)</em></strong>
                </p>
                <p align="justify">
                  Total net product and net pre-product revenue arising from the
                  sale of KIMMTRAK and tebentafusp was £36.3 million (or $40.4
                  million) in the three months ended September 30, 2022, an
                  increase of 20% in USD over 2Q 2022 (converted using
                  respective end-of-period convenience rates), and £74.5 million
                  (or $83.0 million) in the nine months ended September 30,
                  2022.
                </p>
                <p align="justify">
                  During the third quarter of 2022, the Company continued to add
                  new accounts prescribing KIMMTRAK in the United States,
                  Germany, and France. As of September 30, there were 180 new
                  accounts prescribing KIMMTRAK in the United States, which
                  brings the capture rate of these accounts, according to the
                  company’s estimates, to 50% of potentially eligible patients.
                  There were 80 new accounts prescribing KIMMTRAK in Germany and
                  France, which brings the capture rate to approximately 70% of
                  the eligible patient population. In September, the Company
                  began selling KIMMTRAK as a commercial product in France, and
                  these net sales are reflected in product revenue.
                </p>
                <p align="justify">
                  KIMMTRAK's clinical benefit to patients continues to be
                  recognized, with the G-BA (Gemeinsamer Bundesausschuss)
                  granting a considerable added benefit rating to KIMMTRAK.
                  KIMMTRAK is one of only two orphan oncology medicines for rare
                  diseases to receive a considerable added benefit rating – the
                  second-highest possible – in more than ten years of the German
                  reimbursement process, Arzneimittelmarkt-Neuordnungsgesetz
                  (AMNOG). This recommendation builds upon the positive
                  recommendations by American Society of Clinical Oncology
                  (ASCO) and National Comprehensive Cancer Network (NCCN) in the
                  second quarter of this year.
                </p>
                <p align="justify">
                  In November, the Company and Medison Pharma Ltd. (“Medison”)
                  amended and restated their exclusive distribution agreement
                  for KIMMTRAK originally entered into in September 2021.
                  Medison is the exclusive distribution partner for KIMMTRAK in
                  Canada, Australia, New Zealand, Israel, Central and Eastern
                  Europe, and following this amendment South and Central
                  America, and the Caribbean.
                </p>
                <p align="justify">
                  <strong><em>KIMMTRAK (</em></strong
                  ><strong><em>tebentafusp</em></strong
                  ><strong><em>) developmental programs</em></strong>
                </p>
                <p align="justify">
                  In August, the Company announced its plans to evaluate
                  tebentafusp in a randomized Phase 2/3 trial in previously
                  treated advanced melanoma. The trial will enroll patients with
                  advanced melanoma, excluding uveal melanoma, who have
                  progressed on an anti-PD1, received prior ipilimumab and, if
                  applicable, received a tyrosine kinase inhibitor (TKI). The
                  Phase 2 portion of the trial will include 40 patients per arm
                  and has a dual primary endpoint of overall survival (OS) and
                  circulating tumor DNA (ctDNA) reduction. The Company is on
                  track to start the trial in the fourth quarter of 2022.
                </p>
                <p align="justify">
                  In September, the Company presented four posters at the
                  European Society for Medical Oncology (ESMO) Congress 2022:
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    A propensity score weighted comparison of tebentafusp or
                    pembrolizumab versus combination ipilimumab and nivolumab in
                    untreated metastatic uveal melanoma
                  </li>
                  <li style="text-align: justify">
                    Safety and efficacy of infrequent tebentafusp treatment
                    omissions in patients with metastatic uveal melanoma
                  </li>
                  <li style="text-align: justify">
                    Long-term survivors on tebentafusp in phase 2 trial of
                    previously treated patients with metastatic uveal melanoma
                  </li>
                  <li style="text-align: justify">
                    ImmTAC redirect T cells against patient-derived tumor
                    organoids and three-dimensional melanospheres; effects
                    augmented by type I interferons
                  </li>
                </ul>
                <p align="justify">
                  In November, the Company had two posters accepted for
                  presentation at the Society for Immunotherapy of Cancer’s
                  (SITC) 37<sup>th</sup> Annual Meeting. SITC 2022 is being held
                  November 8-12, 2022 in Boston Massachusetts. The titles of the
                  company’s poster presentations are as follows:
                </p>
                <ul type="disc">
                  <li style="text-align: justify">
                    Molecular features in tumors at time of progression on
                    tebentafusp associated with overall survival (OS)
                  </li>
                  <li style="text-align: justify">
                    Tebentafusp induced T and B cell epitope spread in patients
                    with advanced melanoma
                  </li>
                </ul>
                <p align="justify">
                  <strong><em>IMC-F106C Targeting PRAME</em></strong>
                </p>
                <p align="justify">
                  In September, the initial Phase 1 data from the dose
                  escalation study of IMC-F106C, the first off-the-shelf PRAME x
                  CD3 ImmTAC bispecific protein, was presented as a proffered
                  paper (oral presentation) during an “Investigational
                  Immunotherapy” session at the European Society for Medical
                  Oncology (ESMO) Congress. IMC-F106C demonstrated a
                  well-tolerated safety profile. Durable RECIST responses and
                  reduction in circulating tumor DNA (ctDNA) were observed
                  across multiple solid tumors. Doses of ≥ 20 mcg were
                  clinically active and had consistent and robust interferon
                  gamma induction, a specific marker of T cell activation.
                </p>
                <p align="justify">
                  The Company has initiated patient enrollment into four
                  expansion arms in cutaneous melanoma, ovarian, non-small cell
                  lung cancer (NSCLC), and endometrial cancers. The Company will
                  also study IMC-F106C in combination with standards-of-care,
                  including with tebentafusp.
                </p>
                <p align="justify">
                  <strong><em>IMC-C103C Targeting MAGE-A4</em></strong>
                </p>
                <p>
                  Data from the Phase 1 ovarian expansion arm of the dose
                  escalation study with IMC-C103C, the MAGE-A4 x CD3 ImmTAC
                  bispecific protein, was accepted for a poster presentation at
                  the ESMO Immuno-Oncology Congress 2022, in December. In this
                  expansion arm, the Company enrolled all comers and evaluated
                  MAGE expression retrospectively.
                </p>
                <p>
                  In the initial dose escalation data reported at ESMO I-O in
                  December 2021, there were 15 response evaluable ovarian
                  carcinoma patients in the active dose range (≥90 mcg). Only
                  half were positive for MAGE-A4, with a median H score of 35
                  out of 300, and one patient had a confirmed partial response.
                </p>
                <p align="justify">
                  <strong><em>ImmTAV</em></strong
                  ><strong><em>® clinical programs</em></strong>
                </p>
                <p align="justify">
                  In July, the Company dosed the first patient in the
                  first-in-human clinical trial of IMC-M113V, a new class of
                  bispecific protein immunotherapy that is being developed for
                  the treatment of patients with human immunodeficiency virus
                  (HIV) infection. IMC-M113V is an immunotherapeutic approach
                  designed to specifically eliminate CD4+ cells that are
                  persistently infected with HIV (‘reservoirs’). IMC-M113V
                  targets a peptide derived from the Gag protein that is
                  presented by HLA*A02 on the surface of HIV infected cells.
                  Reduction of the number of these cells is one way to
                  potentially achieve a state of viral suppression in the
                  absence of anti-retroviral medications, or a ‘functional
                  cure’.
                </p>
                <p align="justify">
                  <strong><em>Corporate and financial updates</em></strong>
                </p>
                <p align="justify">
                  For the third quarter ended September 30, 2022, Immunocore
                  reported net KIMMTRAK / tebentafusp revenues of £36.3 million
                  (or $40.4 million). U.S. net product revenue from the sale of
                  KIMMTRAK in the second quarter was £22.5 million (or $25.1
                  million), and European revenue (primarily in German and
                  France) from the sale of KIMMTRAK and early access tebentafusp
                  was £13.0 million (or $14.5 million).
                </p>
                <p>
                  Third quarter net KIMMTRAK / tebentafusp revenues of £36.3
                  million (or $40.4 million) increased by 31% (or 20%) compared
                  to our previously reported second quarter KIMMTRAK /
                  tebentafusp revenues of £27.7 million (or $33.7 million).<sup
                    >1</sup
                  >
                </p>
                <p align="justify">
                  In July, the Company announced a private investment in public
                  equity (“PIPE”) financing with four existing investors for net
                  proceeds of $139.6 million. This financing, along with
                  anticipated revenue from KIMMTRAK and cash and cash
                  equivalents on hand, are expected to fund the Company through
                  2025.
                </p>
                <p align="justify">
                  The Company has entered into a loan agreement with investment
                  funds managed by Pharmakon Advisors, LP, providing the Company
                  with up to $100 million committed. The initial $50 million
                  drawn from the credit facility will be used to refinance the
                  Company's existing debt with Oxford Finance, LLC on improved
                  terms and the remaining $50 million, if and when drawn, is
                  intended to be used to support the continued development and
                  commercialization of the Company’s pipeline and for other
                  general purposes.
                </p>
                <p align="justify">
                  <strong>Anticipated Upcoming Milestones 2022</strong>
                </p>
                <p align="justify">
                  <em>KIMMTRAK</em><br />Q4 2022 – start the Phase 2/3 clinical
                  trial in previously treated advanced melanoma
                </p>
                <p align="justify">
                  <em>ImmTAC</em><em> pipeline</em><br />Q4 2022 – report
                  initial data from IMC-C103C (MAGE-A4) Phase 1 ovarian
                  expansion arm
                </p>
                <p align="justify"><strong>Financial Results</strong></p>
                <p align="justify">
                  Basic and diluted earnings per share for the three months
                  ended September 30, 2022, was £0.13 (or $0.14) and £0.12 (or
                  $0.13), respectively, compared to a basic and diluted loss per
                  share of (£0.69) for the three months ended September 30,
                  2021. Basic and diluted loss per share for the nine months
                  ended September 30, 2022, was (£0.36) (or ($0.40)), compared
                  to (£2.19) for the nine months ended September 30, 2021.
                </p>
                <p align="justify">
                  Total operating loss for the nine months ended September 30,
                  2022, was £17.3 million (or $19.2 million), compared to £97.3
                  million for the nine months ended September 30, 2021. For the
                  three months ended September 30, 2022, we generated an
                  operating profit of £6.2 million (or $6.9 million) compared to
                  an operating loss of £31.0 million for the three months ended
                  September 30, 2021. The operating profit of £6.2 million (or
                  $6.9 million), for the three months ended September 30, 2022,
                  reflects foreign exchange gains of £15.2 million (or $16.9
                  million) due to the significant changes arising in the
                  exchange rates between pounds sterling and U.S. dollars during
                  this period.
                </p>
                <p align="justify">
                  Total net product and net pre-product revenue arising from the
                  sale of KIMMTRAK and tebentafusp was £36.3 million (or $40.4
                  million) in the three months ended September 30, 2022, and
                  £74.5 million (or $83.0 million) in the nine months ended
                  September 30, 2022. In the three and nine months ended
                  September 30, 2021, we recorded pre-product revenue of £0.5
                  million.
                </p>
                <p align="justify">
                  For the three and nine months ended September 30, 2022, our
                  research and development expenses were £23.3 million (or $25.9
                  million) and £62.0 million (or $69.1 million), respectively,
                  as compared to £16.8 million and £53.2 million for the three
                  and nine months ended September 30, 2021, respectively. For
                  the three and nine months ended September 30, 2022, our
                  selling and administrative expenses were £11.7 million (or
                  $13.0 million) and £50.6 million (or $56.3 million) compared
                  to £20.0 million and £64.0 million for the three and nine
                  months ended September 30, 2021, respectively.
                </p>
                <p align="justify">
                  Cash and cash equivalents were £347.2 million or $386.6
                  million as of September 30, 2022 compared to £237.9 million as
                  of December 31, 2021.
                </p>
                <p align="center">##</p>
                <p align="justify">
                  <strong>About </strong><strong>ImmTAV</strong
                  ><strong> molecules and infectious diseases</strong
                  ><br />ImmTAV (Immune mobilising monoclonal TCRs Against
                  Virus) molecules are novel bispecific molecules that, like
                  ImmTAC (Immune mobilising monoclonal TCRs Against Cancer)
                  molecules, are designed to enable the immune system to
                  recognize and eliminate virally infected cells.
                </p>
                <p align="justify">
                  Immunocore is advancing clinical candidates to cure patients
                  with HIV and hepatitis B virus (HBV). The Company aims to
                  achieve sustained control of HIV after patients stop
                  anti-retroviral therapy (ART), without the risk of virological
                  relapse or onward transmission. This is known as ‘functional
                  cure’. For the treatment of HBV, the Company aims to achieve
                  sustained loss of circulating viral antigens and markers of
                  viral replication after stopping medication for people living
                  with chronic HBV.
                </p>
                <p align="justify">
                  <strong>About </strong><strong>ImmTAC</strong
                  ><strong>® molecules for cancer</strong><br />Immunocore’s
                  proprietary T cell receptor (TCR) technology generates a novel
                  class of bispecific biologics called ImmTAC (Immune mobilizing
                  monoclonal TCRs Against Cancer) molecules that are designed to
                  redirect the immune system to recognize and kill cancerous
                  cells. ImmTAC molecules are soluble TCRs engineered to
                  recognize intracellular cancer antigens with ultra-high
                  affinity and selectively kill these cancer cells via an
                  anti-CD3 immune-activating effector function. Based on the
                  demonstrated mechanism of T cell infiltration into human
                  tumors, the ImmTAC mechanism of action holds the potential to
                  treat hematologic and solid tumors, regardless of mutational
                  burden or immune infiltration, including immune “cold” low
                  mutation rate tumors.
                </p>
                <p align="justify">
                  <strong>About TEBE-AM Phase 2/3 Trial</strong
                  ><br />IMCgp100-203 (also known as TEBE-AM) is a randomized
                  Phase 2/3 trial in previously treated advanced melanoma that
                  will evaluate the effect of KIMMTRAK (tebentafsup) on overall
                  survival (OS). The trial will enroll patients with advanced
                  melanoma, excluding uveal melanoma, that have progressed on an
                  anti-PD1, received prior ipilimumab and, if applicable,
                  received a tyrosine kinase inhibitor (TKI). The Phase 2/3
                  trial will randomize to one of three arms including KIMMTRAK,
                  as monotherapy or in combination with an anti-PD1, and a
                  control arm. Patients randomized to the control arm will
                  immediately enter overall survival (OS) follow-up where they
                  may be treated per the investigator decision including other
                  clinical trials. This design effectively randomizes patients
                  to “real world” treatment since clinical trials are the
                  preferred option. The Phase 2 portion of the trial will
                  include 40 patients per arm and has a dual primary endpoint of
                  OS and circulating tumor DNA (ctDNA) reduction. The Phase 3
                  portion currently plans to enroll 170 patients per arm and has
                  a primary endpoint of OS. However, the design of the Phase 3
                  portion including eligibility, whether to discontinue an arm
                  and powering may be adapted based on results from the Phase 2
                  portion.
                </p>
                <p>
                  <strong>About the IMC-F106C-101 Phase 1/2 Trial </strong
                  ><br />IMC-F106C-101 is a first-in-human, Phase 1/2 dose
                  escalation trial in patients with multiple solid tumor cancers
                  including non-small cell lung cancer (NSCLC), small-cell lung
                  cancer (SCLC), endometrial, ovarian, cutaneous melanoma, and
                  breast cancers. The Phase 1 dose escalation trial was designed
                  to determine the maximum tolerated dose (MTD), as well as to
                  evaluate the safety, preliminary anti-tumor activity and
                  pharmacokinetics of IMC-F106C, a bispecific protein built on
                  Immunocore’s ImmTAC® technology, and the Company’s first
                  molecule to target the PRAME antigen. The Company has
                  initiated patient enrollment into four expansion arms in
                  cutaneous melanoma, ovarian, NSCLC, and endometrial cancers.
                  The IMC-F106C-101 trial is adaptive and includes the option
                  for Phase 2 expansion, allowing for approximately 100 patients
                  treated per tumor type in the Phase 1 and 2 expansion arms.
                  Dose escalation continues in additional solid tumors as well
                  as plans for combination arms with standards-of-care.
                </p>
                <p align="justify">
                  <strong>About Uveal Melanoma</strong><br />Uveal melanoma is a
                  rare and aggressive form of melanoma, which affects the eye.
                  Although it is the most common primary intraocular malignancy
                  in adults, the diagnosis is rare, and up to 50% of people with
                  uveal melanoma will eventually develop metastatic disease.
                  Unresectable or metastatic uveal melanoma typically has a poor
                  prognosis and had no approved treatment until KIMMTRAK.
                </p>
                <p align="justify">
                  <strong>About KIMMTRAK®</strong><br />KIMMTRAK is a novel
                  bispecific protein comprised of a soluble T cell receptor
                  fused to an anti-CD3 immune-effector function. KIMMTRAK
                  specifically targets gp100, a lineage antigen expressed in
                  melanocytes and melanoma. This is the first molecule developed
                  using Immunocore’s ImmTAC technology platform designed to
                  redirect and activate T cells to recognise and kill tumour
                  cells. KIMMTRAK has been approved for the treatment of
                  HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p align="justify">
                  <strong>About Phase 3 IMCgp100-202 Trial</strong
                  ><br />IMCgp100-202 (NCT03070392) is a randomized pivotal
                  trial that evaluated overall survival (OS) of KIMMTRAK
                  compared to investigator’s choice (either pembrolizumab,
                  ipilimumab, or dacarbazine) in HLA-A*02:01-positive adult
                  patients with previously untreated mUM. KIMMTRAK demonstrated
                  an unprecedented OS benefit with a Hazard Ratio (HR) in the
                  intent-to-treat population favoring KIMMTRAK, HR=0.51 (95% CI:
                  0.37, 0.71); p&lt; 0.0001, over investigator’s choice (82%
                  pembrolizumab; 13% ipilimumab; 6% dacarbazine).
                </p>
                <p align="justify">
                  <strong>IMPORTANT SAFETY INFORMATION</strong>
                </p>
                <p align="justify">
                  <strong
                    >Cytokine Release Syndrome (CRS), which may be serious or
                    life-threatening, occurred in patients receiving KIMMTRAK.
                    Monitor for at least 16 hours following first three
                    infusions and then as clinically indicated.</strong
                  >
                  Manifestations of CRS may include fever, hypotension, hypoxia,
                  chills, nausea, vomiting, rash, elevated transaminases,
                  fatigue, and headache<em>. </em>CRS occurred in 89% of
                  patients who received KIMMTRAK with 0.8% being grade 3 or 4.
                  Ensure immediate access to medications and resuscitative
                  equipment to manage CRS. Ensure patients are euvolemic prior
                  to initiating the infusions. Closely monitor patients for
                  signs or symptoms of CRS following infusions of KIMMTRAK.
                  Monitor fluid status, vital signs, and oxygenation level and
                  provide appropriate therapy. Withhold or discontinue KIMMTRAK
                  depending on persistence and severity of CRS.
                </p>
                <p><strong>Skin Reactions</strong></p>
                <p align="justify">
                  Skin reactions, including rash, pruritus, and cutaneous edema
                  occurred in 91% of patients treated with KIMMTRAK. Monitor
                  patients for skin reactions. If skin reactions occur, treat
                  with antihistamine and topical or systemic steroids based on
                  persistence and severity of symptoms. Withhold or permanently
                  discontinue KIMMTRAK depending on the severity of skin
                  reactions.
                </p>
                <p><strong>Elevated Liver Enzymes</strong></p>
                <p align="justify">
                  Elevations in liver enzymes occurred in 65% of patients
                  treated with KIMMTRAK. Monitor alanine aminotransferase (ALT),
                  aspartate aminotransferase (AST), and total blood bilirubin
                  prior to the start of and during treatment with KIMMTRAK.
                  Withhold KIMMTRAK according to severity.
                </p>
                <p>
                  <strong>Embryo-</strong><strong>Fetal</strong
                  ><strong> Toxicity</strong>
                </p>
                <p align="justify">
                  KIMMTRAK may cause fetal harm. Advise pregnant patients of
                  potential risk to the fetus and patients of reproductive
                  potential to use effective contraception during treatment with
                  KIMMTRAK and 1 week after the last dose.
                </p>
                <p align="justify">
                  The most common adverse reactions (≥30%) in patients who
                  received KIMMTRAK were cytokine release syndrome, rash,
                  pyrexia, pruritus, fatigue, nausea, chills, abdominal pain,
                  edema, hypotension, dry skin, headache, and vomiting. The most
                  common (≥50%) laboratory abnormalities were decreased
                  lymphocyte count, increased creatinine, increased glucose,
                  increased AST, increased ALT, decreased hemoglobin, and
                  decreased phosphate.
                </p>
                <p align="justify">
                  For more information, please see full Summary of Product
                  Characteristics (SmPC) or full U.S. Prescribing Information
                  (including BOXED WARNING for CRS).
                </p>
                <p align="justify">
                  <strong>About </strong><strong>KIMMTRAKConnect</strong
                  ><br />Immunocore is committed<strong> </strong>to helping
                  patients who need KIMMTRAK obtain access via our
                  KIMMTRAKConnect program. The program provides services with
                  dedicated nurse case managers who provide personalized
                  support, including educational resources, financial
                  assistance, and site of care coordination. To learn more,
                  visit KIMMTRAKConnect.com or call 844-775-2273.
                </p>
                <p align="justify">
                  <strong>About Immunocore</strong><br />Immunocore is a
                  commercial-stage biotechnology company pioneering the
                  development of a novel class of TCR bispecific immunotherapies
                  called ImmTAX – Immune mobilizing monoclonal TCRs Against X
                  disease – designed to treat a broad range of diseases,
                  including cancer, autoimmune, and infectious disease.
                  Leveraging its proprietary, flexible, off-the-shelf ImmTAX
                  platform, Immunocore is developing a deep pipeline in multiple
                  therapeutic areas, including five clinical stage programs in
                  oncology and infectious disease, advanced pre-clinical
                  programs in autoimmune disease and multiple earlier
                  pre-clinical programs. The Company’s most advanced oncology
                  TCR therapeutic, KIMMTRAK has been approved for the treatment
                  of HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p align="justify">
                  <strong>Forward Looking Statements</strong><br />This press
                  release contains “forward-looking statements” within the
                  meaning of the safe harbor provisions of the Private
                  Securities Litigation Reform Act of 1995. Words such as “may,”
                  “will,” “believe,” “expect,” “plan,” “anticipate,” and similar
                  expressions (as well as other words or expressions referencing
                  future events or circumstances) are intended to identify
                  forward-looking statements. All statements, other than
                  statements of historical facts, included in this press release
                  are forward-looking statements. These statements include, but
                  are not limited to, statements regarding the marketing,
                  therapeutic potential, and expected clinical benefits of our
                  product candidates, including extended overall survival
                  benefit; expectations regarding Immunocore’s cash runway; the
                  value proposition of Immunocore’s product candidates,
                  including expectations regarding the potential market
                  opportunity; physician’s feedback and endorsements; the
                  commercial performance of KIMMTRAK; expectations regarding the
                  design, progress, timing, scope, expansion, and results of
                  Immunocore’s existing and planned clinical trials, including
                  the timing for enrolling further patients in the IMC-M113V
                  clinical trial, starting the randomized Phase 2/3 clinical
                  trial in previously treated advanced melanoma and reporting
                  initial data from the MAGE-A4 Phase 1 ovarian expansion arm.
                  Any forward-looking statements are based on management’s
                  current expectations and beliefs of future events and are
                  subject to a number of risks and uncertainties that could
                  cause actual events or results to differ materially and
                  adversely from those set forth in or implied by such
                  forward-looking statements, many of which are beyond the
                  Company’s control. These risks and uncertainties include, but
                  are not limited to, the impact of worsening macroeconomic
                  conditions and the ongoing and evolving COVID-19 pandemic on
                  the Company’s business, financial position, strategy and
                  anticipated milestones, including Immunocore’s ability to
                  conduct ongoing and planned clinical trials; Immunocore’s
                  ability to obtain a clinical supply of current or future
                  product candidates or commercial supply of KIMMTRAK or any
                  future approved products, including as a result of the
                  COVID-19 pandemic, war in Ukraine or global geopolitical
                  tension; Immunocore’s ability to obtain and maintain
                  regulatory approval of its product candidates, including
                  KIMMTRAK; Immunocore’s ability and plans in continuing to
                  establish and expand a commercial infrastructure and to
                  successfully launch, market and sell KIMMTRAK and any future
                  approved products; Immunocore’s ability to successfully expand
                  the approved indications for KIMMTRAK or obtain marketing
                  approval for KIMMTRAK in additional geographies in the future;
                  the delay of any current or planned clinical trials, whether
                  due to the COVID-19 pandemic, patient enrollment delays or
                  otherwise; Immunocore’s ability to successfully demonstrate
                  the safety and efficacy of its product candidates and gain
                  approval of its product candidates on a timely basis, if at
                  all; competition with respect to market opportunities;
                  unexpected safety or efficacy data observed during preclinical
                  studies or clinical trials; actions of regulatory agencies,
                  which may affect the initiation, timing and progress of
                  clinical trials or future regulatory approval; Immunocore’s
                  need for and ability to obtain additional funding, on
                  favorable terms or at all, including as a result of worsening
                  macroeconomic conditions, including rising inflation and
                  interest rates and general market conditions, and the impacts
                  thereon of the COVID-19 pandemic, war in Ukraine and global
                  geopolitical tension; Immunocore’s ability to obtain, maintain
                  and enforce intellectual property protection for KIMMTRAK or
                  any product candidates it is developing; and the success of
                  Immunocore’s current and future collaborations, partnerships
                  or licensing arrangements. These and other risks and
                  uncertainties are described in greater detail in the section
                  titled “Risk Factors” in Immunocore’s filings with the
                  Securities and Exchange Commission, including Immunocore’s
                  most recent Annual Report on Form 20-F for the year ended
                  December 31, 2021 filed with the Securities and Exchange
                  Commission on March 3, 2022, as well as discussions of
                  potential risks, uncertainties, and other important factors in
                  the Company’s subsequent filings with the Securities and
                  Exchange Commission. All information in this press release is
                  as of the date of the release, and the Company undertakes no
                  duty to update this information, except as required by law.
                </p>
                <p><strong>CONTACT:</strong></p>
                <p>
                  <strong>Immunocore</strong><br />Sébastien Desprez, Head of
                  Communications<br />T: +44 (0) 7458030732<br />E:
                  info@immunocore.us<br />Follow on Twitter:
                  @Immunocore
                </p>
                <p>
                  <strong>Consilium</strong
                  ><strong>
                    Strategic Communications (corporate and financial)</strong
                  ><br />Mary-Jane Elliott/ Chris Welsh/Jessica Hodgson<br />T:
                  +44 (0)203 709 5700<br />E: info@immunocore.us
                </p>
                <p>
                  <strong>Investor Relations</strong><br />Clayton Robertson,
                  Head of Investor Relations<br />T: +1 (215) 384-4781<br />E:
                  info@immunocore.us
                </p>
                <p><strong>Consolidated Statement of Loss</strong></p>
                <p align="justify">
                  <strong>Comparison of the Three Months Ended </strong
                  ><strong>September 30</strong><strong>, </strong
                  ><strong>2022</strong><strong> and </strong
                  ><strong>2021</strong>
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="8"
                      style="
                        width: 17.64%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Three Months Ended </strong
                      ><strong>September 30</strong><strong>,</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="5"
                      style="
                        width: 11.28%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td style="width: 0.98%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.37%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.38%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$’000</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.91%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£’000</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.37%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£’000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Product revenue, net
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      37,023
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      33,252
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Pre-product, revenue, net
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      3,397
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      3,051
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      474
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Collaboration revenue
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      5,451
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,896
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      5,450
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total revenue</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>45,871</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>41,199</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>5,924</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Cost of product revenue
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (70)
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (63)
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Research and development expenses
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (25,943)
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (23,301)
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (16,798)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Selling and administrative expenses
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (12,986)
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (11,663)
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (20,048)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net other operating (loss) income
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (28)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Operating loss</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>6,872</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>6,172</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(30,950)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Finance income
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      665
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      597
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      8
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Finance costs
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,987)
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,785)
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,317)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Non-operating expense</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,322)</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,188)</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,309)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Loss before taxes</strong>
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>5,550</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>4,984</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(32,259)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Income tax credit
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,385
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,244
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,125
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Loss for the period</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>6,935</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>6,228</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(30,134)</strong>
                    </td>
                  </tr>
                </table>
                <p align="justify">
                  <strong
                    >Condensed Consolidated Statement of Cash Flows for Each
                    Period Presented:</strong
                  >
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="8"
                      style="
                        width: 18.74%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Nine Months Ended</strong>
                      <strong>September 30</strong><strong>,</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.75%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.77%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td style="width: 0.98%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.25%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.75%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$’000</strong>
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.77%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£’000</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.25%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£’000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.95%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.8%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.77%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.26%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Cash and cash equivalents at beginning of year
                    </td>
                    <td style="width: 0.95%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.8%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      264,862
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.96%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.81%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      237,886
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.26%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      129,716
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net cash flows used in operating activities
                    </td>
                    <td style="width: 0.95%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.8%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (36,963)
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.96%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.81%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (33,198)
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.26%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (79,778)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net cash flows (used in) / from investing activities
                    </td>
                    <td style="width: 0.95%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.8%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (138)
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.96%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.81%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (124)
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.26%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (102)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net cash flows (used in) / from financing activities
                    </td>
                    <td style="width: 0.95%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.8%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      128,758
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.96%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.81%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      115,644
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.26%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      206,691
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net foreign exchange difference on cash held
                    </td>
                    <td
                      style="
                        width: 0.95%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.8%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      30,041
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.96%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.81%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      26,982
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.99%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.26%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      24
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Cash and cash equivalents at end of period
                    </td>
                    <td
                      style="
                        width: 0.95%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.8%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      386,560
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.96%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.81%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      347,190
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.99%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.26%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      256,551
                    </td>
                  </tr>
                </table>
                <p><br /></p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>September 30</strong><strong>,</strong
                      ><br /><br /><strong>2022</strong><br /><br /><strong
                        >£’000</strong
                      >
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>December 31</strong><strong>,</strong
                      ><br /><br /><strong>2021</strong><br /><br /><strong
                        >£’000</strong
                      >
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Non-current assets</strong>
                    </td>
                    <td
                      style="
                        width: 0.75%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.45%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Property, plant and equipment
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      6,580
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      8,944
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Right of use assets
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      23,963
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      22,593
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Other non-current assets
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      6,749
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,935
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Deferred tax asset
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      3,860
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,575
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total non-current assets</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>41,152</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>39,047</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Current assets</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Inventory
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      854
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Trade and other receivables
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      40,968
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      15,208
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Tax receivable
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      14,510
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      9,632
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Cash and cash equivalents
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      347,189
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      237,886
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total current assets</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>403,521</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>262,726</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total assets</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>444,673</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>301,773</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Equity</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Share capital
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      96
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      88
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Share premium
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      120,147
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      212,238
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Foreign currency translation reserve
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,759)
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      89
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Other reserves
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      337,847
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      386,167
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Share-based payment reserve
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      74,538
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      54,357
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Accumulated deficit
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (236,050)
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (481,392)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total equity</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>294,819</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>171,547</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Non-current liabilities</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Interest-bearing loans and borrowings
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      45,563
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      37,226
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Deferred revenue
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      6,408
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Lease liabilities
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      26,965
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,355
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Provisions
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      108
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      57
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total non-current liabilities</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>72,636</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>69,046</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Current liabilities</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Trade and other payables
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      64,928
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      35,436
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Deferred revenue
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      10,681
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      24,450
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Lease liabilities
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,553
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,255
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Provisions
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      56
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      39
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total current liabilities</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>77,218</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>61,180</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total liabilities</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>149,854</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>130,226</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total equity and liabilities</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>444,673</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>301,773</strong>
                    </td>
                  </tr>
                </table>
                <p><br /></p>
                <p>
                  <sup>1</sup> U.S dollar figures are derived using the
                  convenience rate of £1.00 to $1.1134 at September 30, 2022 for
                  the third quarter and £1.00 to $1.2162 at June 30, 2022 for
                  the second quarter.
                </p>
                <p></p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDc1NTg1OCM0MDE0MTU4OTEjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/NGQ0ZjQ5YjktMGYwMS00YmJlLTlhNDktZTNmMTRjYWRkODc0LTEyMTc4MTE=/tiny/Immunocore-Holdings-Limited.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings Limited
                <p class="spr-ir-news-article-date">
                  Released November 9, 2022
                </p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param:
      "immunocore-reports-second-quarter-2022-financial-results-and-provides-business-update",
    title: `Immunocore Reports Second Quarter 2022 Financial Results and Provides
      Business Update :: Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore Reports Second Quarter 2022 Financial Results and
                  Provides Business Update
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2022-08-10T07:00:00" class="date">
                    August 10, 2022 7:00am EDT
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2022-08-10_Immunocore_Reports_Second_Quarter_2022_Financial_27.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore Reports Second Quarter 2022 Financial Results and Provides Business Update"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <div class="related-documents box hidden-print">
                  <div class="text">
                    <h2 class="related-docs-title">Related Documents</h2>
                    <div class="text">
                      <div class=" ">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>

                <p align="center">
                  <strong
                    >Immunocore Reports Second Quarter 2022 Financial Results
                    and Provides Business Update</strong
                  >
                </p>
                <p align="center">
                  <em>KIMMTRAK® (</em><em>tebentafusp</em
                  ><em
                    >) now approved in over 30 countries with commercial
                    launches underway in U.S. and Germany, and paid access in
                    France</em
                  >
                </p>
                <p align="center">
                  <em>Net KIMMTRAK / </em><em>tebentafusp</em
                  ><em>
                    revenues of £27.7 million ($33.7 million) in Q2 2022</em
                  >
                </p>
                <p align="center">
                  <em
                    >Transitioned all patients in Germany early access program
                    to commercial supply in May of 2022</em
                  >
                </p>
                <p align="center">
                  <em>Protocol finalized for randomized Phase 2/3 trial of </em
                  ><em>tebentafusp</em
                  ><em>
                    in advanced melanoma with first patient randomized planned
                    for Q4 2022</em
                  >
                </p>
                <p align="center">
                  <em
                    >Initial Phase 1 data from IMC-F106C, first PRAME x CD3 </em
                  ><em>ImmTAC</em
                  ><em>
                    bispecific, accepted for oral presentation at ESMO Congress
                    2022 on September 9</em
                  ><sup><em>th</em></sup>
                </p>
                <p align="center">
                  <em
                    >Cash and cash equivalents of £208 million ($253 million) as
                    of June 30, 2022. Subsequently raised £117 million ($140
                    million) of PIPE proceeds in July</em
                  >
                </p>
                <p align="center">
                  <em>Conference call today, August 10</em><sup><em>th</em></sup
                  ><em> at 8:00 AM EDT, 1:00 PM BST</em>
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, 10 August 2022) Immunocore Holdings plc
                  (Nasdaq: IMCR) (“Immunocore” or the “Company”), a
                  commercial-stage biotechnology company pioneering the
                  development of a novel class of T cell receptor (TCR)
                  bispecific immunotherapies designed to treat a broad range of
                  diseases, including cancer, autoimmune and infectious
                  diseases, today announced its financial results for the second
                  quarter ended June 30, 2022 and provided a business update.
                </p>
                <p align="justify">
                  “The first half of 2022 has been one of robust execution,
                  including delivering multiple KIMMTRAK® commercial launches.
                  In addition, in July, we executed a PIPE transaction with four
                  of our largest shareholders, which allows us to accelerate the
                  development of our early- and late-stage pipeline and extend
                  our cash runway through 2025,” <strong>commented</strong>
                  <strong
                    >Bahija Jallal, Chief Executive Officer of
                    Immunocore.</strong
                  >
                  “The Immunocore team has pioneered the development from bench
                  to bedside of the world’s first TCR treatment, which is now
                  approved in over 30 countries. We are applying the learnings
                  from KIMMTRAK to develop our other clinical-stage bispecific T
                  cell engagers in oncology including ImmTACs targeting PRAME
                  and MAGE-A4, and infectious disease ImmTAVs for HBV and HIV.”
                </p>
                <p align="justify">
                  “The promising survival benefit for KIMMTRAK in metastatic
                  cutaneous melanoma (mCM) has provided confidence to initiate a
                  randomized Phase 2/3 trial in patients with previously
                  treated, advanced melanoma,”<strong>
                    said David Berman, Head of Research &amp; Development of
                    Immunocore.</strong
                  >
                  “At IO-ESMO last year, we demonstrated that our ImmTAC
                  platform against MAGE-A4 can deliver durable clinical
                  responses in solid tumors. At ESMO this year, the first
                  clinical data for an ImmTAC targeting PRAME, a protein broadly
                  expressed in multiple solid tumors will be presented.”
                </p>
                <p align="justify">
                  <strong
                    >Second Quarter 2022 Highlights (including
                    post-period)</strong
                  >
                </p>
                <p align="justify">
                  <strong><em>KIMMTRAK® (</em></strong
                  ><strong><em>tebentafusp-tebn</em></strong
                  ><strong><em>):</em></strong>
                </p>
                <p align="justify">
                  In April, the European Commission approved KIMMTRAK
                  (tebentafusp) for the treatment of HLA-A*02:01-positive adult
                  patients with unresectable or metastatic uveal melanoma (mUM).
                  With this approval, KIMMTRAK has received marketing
                  authorisation in all European Union (EU) member states and,
                  following completion of related national procedures, KIMMTRAK
                  will also be eligible for sale in Iceland, Liechtenstein, and
                  Norway.
                </p>
                <p align="justify">
                  In April, KIMMTRAK was added as a recommended Category 1
                  treatment in the latest National Comprehensive Cancer Network®
                  (NCCN®) Clinical Practice Guidelines in Oncology for mUM. NCCN
                  publishes evidence-based guidelines that are followed by many
                  healthcare professionals in the United States and globally.
                </p>
                <p align="justify">
                  In May, the first patient in Germany was infused with
                  KIMMTRAK, less than one week from price listing. The Company
                  also successfully transitioned all patients (more than 50
                  patients) from the early access program (EAP) in Germany onto
                  commercial supply in the month of May.
                </p>
                <p align="justify">
                  In June, the Company presented post-hoc analyses from its
                  Phase 3 clinical trial of KIMMTRAK in mUM at the 2022 American
                  Society of Clinical Oncology (ASCO) Annual Meeting. In an
                  analysis of the Phase 3 trial, an overall survival (OS)
                  benefit observed for tebentafusp among mUM patients who have
                  initial radiographic progression demonstrates that
                  radiographic assessment underestimates the benefit. In another
                  post hoc analysis of the Phase 3 trial, the vast majority of
                  patients treated with tebentafusp (84%) either did not require
                  corticosteroids (74%) or only received them on a single day
                  (10%). Corticosteroid use following the pre-specified adverse
                  event (AE) guidelines was not associated with any significant
                  impact on efficacy of KIMMTRAK.
                </p>
                <p align="justify">
                  In June, KIMMTRAK, for the treatment of mUM, was added to the
                  ASCO Rapid Recommendations Updates to the ASCO Guidelines.
                  This recommendation was based on the Phase 3 trial and the FDA
                  approval. Prior to this update, there were no recommendations
                  by ASCO for any systemic therapy in uveal melanoma.
                </p>
                <p align="justify">
                  In June, the UK Medicines and Healthcare products Regulatory
                  Agency (MHRA), Australian Therapeutic Goods Administration
                  (TGA) and Health Canada approved KIMMTRAK for the treatment of
                  HLA-A*02:01-positive adult patients with unresectable or mUM.
                </p>
                <p align="justify">
                  <strong><em>KIMMTRAK (</em></strong
                  ><strong><em>tebentafusp</em></strong
                  ><strong><em>) developmental programs:</em></strong>
                </p>
                <p>
                  In June, the Company announced a clinical trial collaboration
                  and supply agreement with Sanofi to evaluate Sanofi’s product
                  candidate SAR444245, non-alpha IL-2, in combination with
                  KIMMTRAK in patients with mCM. Under the terms of the
                  agreement, we provide KIMMTRAK at our own cost, and Sanofi is
                  responsible for clinical development and will assume costs
                  associated with the study.
                </p>
                <p align="justify">
                  In June, the Company presented updated clinical data from its
                  Phase 1b clinical trial of tebentafusp in mCM in an oral
                  presentation at the 2022 ASCO Annual Meeting. In combination
                  with checkpoint inhibitors in mCM, the maximum target doses of
                  tebentafusp (68 mcg) plus durvalumab (20 mg/kg) were well
                  tolerated. In mCM patients who progressed on prior
                  anti-PD(L)1, tebentafusp with durvalumab continues to
                  demonstrate promising overall survival (OS) (1-yr ~75%)
                  compared to recent benchmarks (1-yr ~55%).
                </p>
                <p align="justify">
                  Today, the Company announced its plans to evaluate tebentafusp
                  in a randomized Phase 2/3 trial in previously treated advanced
                  melanoma which was designed with input from global melanoma
                  experts and from the US FDA. The trial will enroll patients
                  with advanced melanoma, excluding uveal melanoma, who have
                  progressed on an anti-PD1, received prior ipilimumab and, if
                  applicable, received a tyrosine kinase inhibitor (TKI). This
                  population remains a significant unmet need where the
                  preferred option is enrollment in clinical trials. Patients
                  will be randomized to one of three arms including KIMMTRAK, as
                  monotherapy or in combination with an anti-PD1, and a control
                  arm. Patients randomized to the control arm will immediately
                  enter overall survival (OS) follow-up where they may be
                  treated per the investigator decision including other clinical
                  trials. This innovative design effectively randomizes patients
                  to “real world” treatment since clinical trials are the
                  preferred option. The Phase 2 portion of the trial will
                  include 40 patients per arm and has a dual primary endpoint of
                  OS and circulating tumor DNA (ctDNA) reduction. The Phase 3
                  portion currently plans to enroll 170 patients per arm and has
                  a primary endpoint of OS. The design of the Phase 3
                  trial—including lines of prior therapy, whether to discontinue
                  an arm, and powering assumptions—may be adapted based on
                  results from the Phase 2 portion. The Company plans to start
                  the randomization of the trial in the fourth quarter of 2022.
                </p>
                <p align="justify">
                  <strong><em>IMC-F106C Targeting PRAME</em></strong>
                </p>
                <p align="justify">
                  The initial Phase 1 data from the dose escalation study of
                  IMC-F106C, the first PRAME x CD3 ImmTAC bispecific protein,
                  was accepted for proffered paper (oral presentation) during
                  the “Investigational Immunotherapy” session on Friday,
                  September 9, 2022, at the European Society for Medical
                  Oncology (ESMO) in Paris, France. PRAME is overexpressed in
                  many solid tumors including NSCLC, SCLC, endometrial, ovarian,
                  melanoma and certain breast cancers. The company plans to
                  report data from at least 20 PRAME positive and efficacy
                  evaluable patients. Dr. Omid Hamid, Chief, Translational
                  Research and Immunotherapy &amp; Director, Melanoma
                  Therapeutics, of The Angeles Clinic, will present the initial
                  results from the Phase 1 study at 4:50 PM CEST. The company
                  will also host an in-person and webcasted investor and analyst
                  event at 6:30 PM CEST / 12:30 PM ET Friday, September
                  9<sup>th</sup>.
                </p>
                <p align="justify">
                  <strong><em>ImmTAV</em></strong
                  ><strong><em>® clinical programs:</em></strong>
                </p>
                <p align="justify">
                  In June, the Company presented data from the first three
                  patients in the first-in-human clinical trial of IMC-I109V for
                  chronic hepatitis B at the EASL International Liver Congress.
                  IMC-I109V is designed to overcome T cell dysfunction by
                  recruiting non-exhausted T cells to eliminate hepatocytes
                  harbouring covalently closed circular DNA or integrated HBV
                  DNA. Elimination of these cells is necessary to achieve a
                  state of ‘functional cure’ defined as sustained HBsAg loss in
                  addition to undetectable HBV DNA 6 months post-treatment. In
                  this first cohort, the three patients received a single dose
                  of 0.8 mcg, based on the minimum anticipated biological effect
                  level (MABEL). The dose in this initial cohort was well
                  tolerated and was not associated with adverse events and
                  resulted in a transient, small decrease in serum HBsAg with
                  concomitant minor increase in alanine transaminase (ALT).
                </p>
                <p align="justify">
                  In July, the Company dosed the first patient in the
                  first-in-human clinical trial of IMC-M113V, a new class of
                  bispecific protein immunotherapy that is being developed for
                  the treatment of patients with human immunodeficiency virus
                  (HIV) infection. IMC-M113V is an immunotherapeutic approach
                  designed to specifically eliminate CD4+ cells that are
                  persistently infected with HIV (‘reservoirs’). IMC-M113V
                  targets a peptide derived from the Gag protein that is
                  presented by HLA*A02 on the surface of HIV infected cells.
                  Reduction of the number of these cells is one way to
                  potentially achieve a state of viral suppression in the
                  absence of anti-retroviral medications, or a ‘functional
                  cure’.
                </p>
                <p align="justify">
                  <strong><em>Corporate and financial updates:</em></strong>
                </p>
                <p align="justify">
                  For the second quarter ended, June 30, 2022, Immunocore
                  reported net KIMMTRAK / tebentafusp revenues of £27.7 million
                  (or $33.7 million). U.S. net product revenue from the sale of
                  KIMMTRAK in the second quarter was £18.1 million (or $22.1
                  million), Europe net product revenue from the sale of KIMMTRAK
                  (primarily in Germany) was £5.9 million (or $7.1 million), and
                  France net pre-product revenue from the sale of tebentafusp
                  was £3.7 million (or $4.5 million).
                </p>
                <p align="justify">
                  In July, the Company announced a private investment in public
                  equity (“PIPE”) financing with four existing investors for net
                  proceeds of $139.6 million. This financing, along with
                  anticipated revenue from KIMMTRAK and cash and cash
                  equivalents on hand, are expected to fund the Company through
                  2025.
                </p>
                <p align="justify">
                  In June, Siddharth (Sid) Kaul was appointed as a non-executive
                  member of the Company’s Board of Directors and will serve as a
                  member of the Audit and Remuneration committees. Sid is a
                  seasoned finance professional with deep expertise within the
                  life sciences industry. He retired as Group Treasurer and Head
                  of Business Planning and Analysis at Novartis in 2021 after a
                  17-year career at the company, where his previous roles
                  included serving as Novartis’ Chief Financial Officer, Pharma
                  Europe and Chief Financial Officer, Pharma U.S.
                </p>
                <p align="justify">
                  <strong>Anticipated Upcoming Milestones</strong>
                </p>
                <p align="justify">
                  <em>KIMMTRAK</em><br />Q4 2022 – start randomized Phase 2/3
                  clinical trial in previously treated advanced melanoma
                </p>
                <p align="justify">
                  <em>ImmTAC</em><em> pipeline</em><br />Q3 2022 – report
                  initial data from IMC-F106C (PRAME) Phase 1 trial in multiple
                  solid tumors at ESMO Congress 2022 in September
                </p>
                <p align="justify">
                  Q4 2022 – report complete data from IMC-C103C (MAGE-A4) Phase
                  1 trial in multiple solid tumors and initial data from ovarian
                  expansion arm
                </p>
                <p align="justify"><strong>Financial Results</strong></p>
                <p align="justify">
                  Basic and diluted loss per share was £0.14 (or $0.17) and
                  £0.51 (or $0.62) for the three and six months ended June 30,
                  2022, respectively, compared to £0.75 and £1.51 for the three
                  and six months ended June 30, 2021, respectively. Total
                  operating loss for the three and six months ended June 30,
                  2022 was £7.0 million (or $8.5 million) and £23.5 million (or
                  $28.5 million), respectively, compared to £34.5 million and
                  £66.4 million, respectively, for the same periods in 2021.
                </p>
                <p align="justify">
                  Total net product and net pre-product revenue arising from the
                  sale of KIMMTRAK and tebentafusp was £27.7 million (or $33.7
                  million) in the three months ended June 30, 2022, and £38.2
                  million (or $46.5 million) in the six months ended June 30,
                  2022. In comparison, no product or pre-product revenue was
                  recorded in these territories in the three and six months
                  ended June 30, 2021. U.S. net product revenue from the sale of
                  KIMMTRAK in the second quarter was £18.1 million (or $22.1
                  million), Europe net product revenue from the sale of KIMMTRAK
                  (primarily in Germany) was £5.9 million (or $7.1 million), and
                  France pre-product revenue from the sale of tebentafusp was
                  £3.7 million (or $4.5 million).
                </p>
                <p align="justify">
                  For the three and six months ended June 30, 2022, our research
                  and development expenses were £20.2 million (or $24.5 million)
                  and £38.7 million (or $47.1 million), respectively, as
                  compared to £16.5 million and £36.4 million for the three and
                  six months ended June 30, 2021, respectively. For the three
                  and six months ended June 30, 2022, our selling and
                  administrative expenses were £18.8 million (or $22.9 million)
                  and £38.9 million (or $47.3 million), respectively, compared
                  to £23.8 million and £44.0 million for the three and six
                  months ended June 30, 2021, respectively.
                </p>
                <p align="justify">
                  Cash and cash equivalents were £208.1 million or $253.0
                  million as of June 30, 2022 compared to £237.9 million as of
                  December 31, 2021. We subsequently raised a further £116.7
                  million (or $140 million) in the July 2022 PIPE before
                  deductions for estimated attributable expense of £0.3 million
                  (or $0.4 million).
                </p>
                <p>
                  <em
                    >We maintain our books and records in pounds sterling. For
                    the convenience of the reader, we have translated pound
                    sterling amounts as of and for the period ended June 30, </em
                  ><em>2022</em
                  ><em> into U.S. dollars at a rate of £1.00 to $1.2162.</em>
                </p>
                <p><strong>Audio Webcast</strong></p>
                <p>
                  Immunocore will host a conference call today, August 10, 2022
                  at 8:00 A.M. EDT/ 1:00 PM BST, to discuss the second quarter
                  2022 financial results and provide a business update. The call
                  will also be available via webcast by visiting the Events
                  &amp; Presentations section on Immunocore’s website. A replay
                  of this webcast will be available for 30 days.
                </p>
                <p>
                  <strong>Conference Call Details</strong>:<br />U.S.
                  (toll-free): 877-869-3847<br />International (toll): +1
                  201-689-8261
                </p>
                <p align="center">##</p>
                <p align="justify">
                  <strong>About </strong><strong>ImmTAV</strong
                  ><strong> molecules and infectious diseases</strong
                  ><br />ImmTAV (Immune mobilising monoclonal TCRs Against
                  Virus) molecules are novel bispecific molecules that, like
                  ImmTAC (Immune mobilising monoclonal TCRs Against Cancer)
                  molecules, are designed to enable the immune system to
                  recognize and eliminate virally infected cells.
                </p>
                <p align="justify">
                  Immunocore is advancing clinical candidates to cure patients
                  with HIV and hepatitis B virus (HBV). The Company aims to
                  achieve sustained control of HIV after patients stop
                  anti-retroviral therapy (ART), without the risk of virological
                  relapse or onward transmission. This is known as ‘functional
                  cure’. For the treatment of HBV, the Company aims to achieve
                  sustained loss of circulating viral antigens and markers of
                  viral replication after stopping medication for people living
                  with chronic HBV.
                </p>
                <p align="justify">
                  <strong>About </strong><strong>ImmTAC</strong
                  ><strong>® molecules for cancer</strong><br />Immunocore’s
                  proprietary T cell receptor (TCR) technology generates a novel
                  class of bispecific biologics called ImmTAC (Immune mobilizing
                  monoclonal TCRs Against Cancer) molecules that are designed to
                  redirect the immune system to recognize and kill cancerous
                  cells. ImmTAC molecules are soluble TCRs engineered to
                  recognize intracellular cancer antigens with ultra-high
                  affinity and selectively kill these cancer cells via an
                  anti-CD3 immune-activating effector function. Based on the
                  demonstrated mechanism of T cell infiltration into human
                  tumors, the ImmTAC mechanism of action holds the potential to
                  treat hematologic and solid tumors, regardless of mutational
                  burden or immune infiltration, including immune “cold” low
                  mutation rate tumors.
                </p>
                <p align="justify">
                  <strong>About TEBE-AM Phase 2 /3 Trial</strong
                  ><br />IMCgp100-203 is a randomized Phase 2/3 trial in
                  previously treated advanced melanoma that will evaluate the
                  overall survival (OS) of KIMMTRAK (tebentafusp). The trial
                  will enroll patients with advanced melanoma that have
                  progressed on an anti-PD1, received prior ipilimumab and, if
                  applicable, received a tyrosine kinase inhibitor (TKI). The
                  Phase 2/3 trial will randomize to one of three arms including
                  KIMMTRAK, as monotherapy or in combination with an anti-PD1,
                  and a control arm. Patients randomized to the control arm will
                  immediately enter overall survival (OS) follow-up where they
                  may be treated per the investigator decision including other
                  clinical trials. This design effectively randomizes patients
                  to “real world” treatment since clinical trials are the
                  preferred option. The Phase 2 portion of the trial will
                  include 40 patients per arm and has a dual primary endpoint of
                  OS and circulating tumor DNA (ctDNA) reduction. The Phase 3
                  portion currently plans to enroll 170 patients per arm and has
                  a primary endpoint of OS. However, the design of the Phase 3
                  including eligibility, whether to discontinue an arm and
                  powering may be adapted based on results from the Phase 2
                  portion.
                </p>
                <p align="justify">
                  <strong>About Uveal Melanoma</strong><br />Uveal melanoma is a
                  rare and aggressive form of melanoma, which affects the eye.
                  Although it is the most common primary intraocular malignancy
                  in adults, the diagnosis is rare, and up to 50% of people with
                  uveal melanoma will eventually develop metastatic disease.
                  Unresectable or metastatic uveal melanoma typically has a poor
                  prognosis and had no approved treatment until KIMMTRAK.
                </p>
                <p align="justify">
                  <strong>About KIMMTRAK®</strong><br />KIMMTRAK is a novel
                  bispecific protein comprised of a soluble T cell receptor
                  fused to an anti-CD3 immune-effector function. KIMMTRAK
                  specifically targets gp100, a lineage antigen expressed in
                  melanocytes and melanoma. This is the first molecule developed
                  using Immunocore’s ImmTAC technology platform designed to
                  redirect and activate T cells to recognise and kill tumour
                  cells. KIMMTRAK has been approved for the treatment of
                  HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p align="justify">
                  <strong>About Phase 3 IMCgp100-202 Trial</strong
                  ><br />IMCgp100-202 (NCT03070392) is a randomized pivotal
                  trial that evaluated overall survival (OS) of KIMMTRAK
                  compared to investigator’s choice (either pembrolizumab,
                  ipilimumab, or dacarbazine) in HLA-A*02:01-positive adult
                  patients with previously untreated mUM. KIMMTRAK demonstrated
                  an unprecedented OS benefit with a Hazard Ratio (HR) in the
                  intent-to-treat population favoring KIMMTRAK, HR=0.51 (95% CI:
                  0.37, 0.71); p&lt; 0.0001, over investigator’s choice (82%
                  pembrolizumab; 13% ipilimumab; 6% dacarbazine).
                </p>
                <p align="justify">
                  <strong>IMPORTANT SAFETY INFORMATION</strong>
                </p>
                <p align="justify">
                  <strong
                    >Cytokine Release Syndrome (CRS), which may be serious or
                    life-threatening, occurred in patients receiving KIMMTRAK.
                    Monitor for at least 16 hours following first three
                    infusions and then as clinically indicated.</strong
                  >
                  Manifestations of CRS may include fever, hypotension, hypoxia,
                  chills, nausea, vomiting, rash, elevated transaminases,
                  fatigue, and headache<em>. </em>CRS occurred in 89% of
                  patients who received KIMMTRAK with 0.8% being grade 3 or 4.
                  Ensure immediate access to medications and resuscitative
                  equipment to manage CRS. Ensure patients are euvolemic prior
                  to initiating the infusions. Closely monitor patients for
                  signs or symptoms of CRS following infusions of KIMMTRAK.
                  Monitor fluid status, vital signs, and oxygenation level and
                  provide appropriate therapy. Withhold or discontinue KIMMTRAK
                  depending on persistence and severity of CRS.
                </p>
                <p><strong>Skin Reactions</strong></p>
                <p align="justify">
                  Skin reactions, including rash, pruritus, and cutaneous edema
                  occurred in 91% of patients treated with KIMMTRAK. Monitor
                  patients for skin reactions. If skin reactions occur, treat
                  with antihistamine and topical or systemic steroids based on
                  persistence and severity of symptoms. Withhold or permanently
                  discontinue KIMMTRAK depending on the severity of skin
                  reactions.
                </p>
                <p><strong>Elevated Liver Enzymes</strong></p>
                <p align="justify">
                  Elevations in liver enzymes occurred in 65% of patients
                  treated with KIMMTRAK. Monitor alanine aminotransferase (ALT),
                  aspartate aminotransferase (AST), and total blood bilirubin
                  prior to the start of and during treatment with KIMMTRAK.
                  Withhold KIMMTRAK according to severity.
                </p>
                <p>
                  <strong>Embryo-</strong><strong>Fetal</strong
                  ><strong> Toxicity</strong>
                </p>
                <p align="justify">
                  KIMMTRAK may cause fetal harm. Advise pregnant patients of
                  potential risk to the fetus and patients of reproductive
                  potential to use effective contraception during treatment with
                  KIMMTRAK and 1 week after the last dose.
                </p>
                <p align="justify">
                  The most common adverse reactions (≥30%) in patients who
                  received KIMMTRAK were cytokine release syndrome, rash,
                  pyrexia, pruritus, fatigue, nausea, chills, abdominal pain,
                  edema, hypotension, dry skin, headache, and vomiting. The most
                  common (≥50%) laboratory abnormalities were decreased
                  lymphocyte count, increased creatinine, increased glucose,
                  increased AST, increased ALT, decreased hemoglobin, and
                  decreased phosphate.
                </p>
                <p align="justify">
                  For more information, please see full Summary of Product
                  Characteristics (SmPC) or full U.S. Prescribing Information
                  (including BOXED WARNING for CRS).
                </p>
                <p align="justify">
                  <strong>About </strong><strong>KIMMTRAKConnect</strong
                  ><br />Immunocore is committed<strong> </strong>to helping
                  patients who need KIMMTRAK obtain access via our
                  KIMMTRAKConnect program. The program provides services with
                  dedicated nurse case managers who provide personalized
                  support, including educational resources, financial
                  assistance, and site of care coordination. To learn more,
                  visit KIMMTRAKConnect.com or call 844-775-2273.
                </p>
                <p align="justify">
                  <strong>About Immunocore</strong><br />Immunocore is a
                  commercial-stage biotechnology company pioneering the
                  development of a novel class of TCR bispecific immunotherapies
                  called ImmTAX – Immune mobilizing monoclonal TCRs Against X
                  disease – designed to treat a broad range of diseases,
                  including cancer, autoimmune, and infectious disease.
                  Leveraging its proprietary, flexible, off-the-shelf ImmTAX
                  platform, Immunocore is developing a deep pipeline in multiple
                  therapeutic areas, including five clinical stage programs in
                  oncology and infectious disease, advanced pre-clinical
                  programs in autoimmune disease and multiple earlier
                  pre-clinical programs. The Company’s most advanced oncology
                  TCR therapeutic, KIMMTRAK has been approved for the treatment
                  of HLA-A*02:01-positive adult patients with unresectable or
                  metastatic uveal melanoma in the United States, European
                  Union, Canada, Australia, and the United Kingdom.
                </p>
                <p align="justify">
                  <strong>Forward Looking Statements</strong><br />This press
                  release contains “forward-looking statements” within the
                  meaning of the safe harbor provisions of the Private
                  Securities Litigation Reform Act of 1995. Words such as “may,”
                  “will,” “believe,” “expect,” “plan,” “anticipate,” and similar
                  expressions (as well as other words or expressions referencing
                  future events or circumstances) are intended to identify
                  forward-looking statements. All statements, other than
                  statements of historical facts, included in this press release
                  are forward-looking statements. These statements include, but
                  are not limited to, statements regarding the marketing and
                  therapeutic potential of KIMMTRAK for mUM; the expected
                  clinical benefits of KIMMTRAK and the Company’s other product
                  candidates, including extended overall survival benefit;
                  expectations regarding commercialization of KIMMTRAK in the
                  United States, Germany and France as well as in other EU
                  member states; expectations regarding receipt of regulatory
                  approvals and completion of related procedures; expectations
                  regarding the success and performance of obligations under
                  Immunocore’s collaboration agreements with third parties;
                  expectations regarding Immunocore’s cash runway; Immunocore’s
                  sales and marketing plans, including with respect to the
                  United States, Germany and France; the potential for and
                  timing of commercial availability of KIMMTRAK in additional
                  countries and the ability to reach patients in a timely
                  manner; the value proposition of Immunocore’s product
                  candidates, including KIMMTRAK in mUM and its benefit as an
                  orphan indication, including expectations regarding the
                  potential market opportunity; physician’s feedback,
                  endorsements, guidelines and interest in prescribing KIMMTRAK
                  as the standard of care for mUM; Immunocore’s efforts to
                  expand patients’ access to medicine; future development plans
                  of KIMMTRAK, including the timing or likelihood of expansion
                  into additional markets or geographies; expectations regarding
                  the design, progress, timing, scope and results of
                  Immunocore’s existing and planned clinical trials, including
                  the randomized Phase 2/3 clinical trial in previously treated
                  advanced melanoma and PRAME and MAGE-A4 clinical trials. Any
                  forward-looking statements are based on management’s current
                  expectations and beliefs of future events and are subject to a
                  number of risks and uncertainties that could cause actual
                  events or results to differ materially and adversely from
                  those set forth in or implied by such forward-looking
                  statements, many of which are beyond the Company’s control.
                  These risks and uncertainties include, but are not limited to,
                  the impact of the ongoing and evolving COVID-19 pandemic on
                  the Company’s business, financial position, strategy and
                  anticipated milestones, including Immunocore’s ability to
                  conduct ongoing and planned clinical trials; Immunocore’s
                  ability to obtain a clinical supply of current or future
                  product candidates or commercial supply of KIMMTRAK or any
                  future approved products, including as a result of the
                  COVID-19 pandemic, war in Ukraine or global geopolitical
                  tension; Immunocore’s ability to obtain and maintain
                  regulatory approval of its product candidates, including
                  KIMMTRAK; Immunocore’s ability and plans in continuing to
                  establish and expand a commercial infrastructure and to
                  successfully launch, market and sell KIMMTRAK and any future
                  approved products; Immunocore’s ability to successfully expand
                  the approved indications for KIMMTRAK or obtain marketing
                  approval for KIMMTRAK in additional geographies in the future;
                  the delay of any current or planned clinical trials, whether
                  due to the COVID-19 pandemic, patient enrollment delays or
                  otherwise; Immunocore’s ability to successfully demonstrate
                  the safety and efficacy of its product candidates and gain
                  approval of its product candidates on a timely basis, if at
                  all; competition with respect to market opportunities;
                  unexpected safety or efficacy data observed during preclinical
                  studies or clinical trials; actions of regulatory agencies,
                  which may affect the initiation, timing and progress of
                  clinical trials or future regulatory approval; Immunocore’s
                  need for and ability to obtain additional funding, on
                  favorable terms or at all, including as a result of rising
                  inflation, interest rates and general market conditions, and
                  the impacts thereon of the COVID-19 pandemic, war in Ukraine
                  and global geopolitical tension; Immunocore’s ability to
                  obtain, maintain and enforce intellectual property protection
                  for KIMMTRAK or any product candidates it is developing; and
                  the success of Immunocore’s current and future collaborations,
                  partnerships or licensing arrangements. These and other risks
                  and uncertainties are described in greater detail in the
                  section titled "Risk Factors" in Immunocore’s filings with the
                  Securities and Exchange Commission, including Immunocore’s
                  most recent Annual Report on Form 20-F for the year ended
                  December 31, 2021 filed with the Securities and Exchange
                  Commission on March 3, 2022, as well as discussions of
                  potential risks, uncertainties, and other important factors in
                  the Company’s subsequent filings with the Securities and
                  Exchange Commission. All information in this press release is
                  as of the date of the release, and the Company undertakes no
                  duty to update this information, except as required by law.
                </p>
                <p><strong>CONTACT:</strong></p>
                <p>
                  <strong>Immunocore</strong><br />Sébastien Desprez, Head of
                  Communications<br />T: +44 (0) 7458030732<br />E:
                  info@immunocore.us<br />Follow on Twitter:
                  @Immunocore
                </p>
                <p>
                  <strong>Consilium</strong
                  ><strong>
                    Strategic Communications (corporate and financial)</strong
                  ><br />Mary-Jane Elliott/ Chris Welsh/Jessica Hodgson<br />T:
                  +44 (0)203 709 5700<br />E: info@immunocore.us
                </p>
                <p>
                  <strong>Investor Relations</strong><br />Clayton Robertson,
                  Head of Investor Relations<br />T: +1 (215) 384-4781<br />E:
                  info@immunocore.us
                </p>
                <p align="center">7</p>
                <p align="justify">
                  <em
                    >We maintain our books and records in pounds sterling. For
                    the convenience of the reader, we have translated pound
                    sterling amounts as of and for the period ended June 30, </em
                  ><em>2022</em
                  ><em> into U.S. dollars at a rate of £1.00 to $1.2162.</em>
                </p>
                <p>
                  <strong>Condensed Consolidated Statements of Loss</strong>
                </p>
                <p align="justify">
                  <strong>Comparison of the Three Months Ended June 30, </strong
                  ><strong>2022</strong><strong> and 2021</strong>
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="10"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Three Months Ended June 30,</strong>
                    </td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="6"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 32%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>’000</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong>
                    </td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>’000</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong>
                    </td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>’000</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Product revenue, net
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      29,179
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      23,992
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Pre-product, revenue, net
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,510
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      3,708
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total revenue from sale of therapies</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>33,689</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>27,700</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>—</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Collaboration revenue
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      5,232
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,302
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      5,733
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total revenue</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>38,921</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>32,002</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>5,733</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 32%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Cost of product revenue
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (41
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (34
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Research and development expenses
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (24,506
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (20,150
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (16,471
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Selling and administrative expenses
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (22,878
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (18,811
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (23,801
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net other operating income
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      40
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Operating loss</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(8,504</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(6,993</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(34,499</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Finance income
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      144
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      118
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      12
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Finance costs
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,699
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,397
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,288
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Non-operating expense</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,555</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,279</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,276</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Loss before taxes</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(10,059</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(8,272</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(35,775</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Income tax credit
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,616
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,151
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,813
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Loss for the period</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(7,443</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(6,121</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(32,962</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                  </tr>
                </table>
                <p align="justify">
                  <strong>Comparison of the Six Months Ended June 30, </strong
                  ><strong>2022</strong><strong> and 2021</strong>
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="10"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Six Months Ended June 30,</strong>
                    </td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="6"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 32%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>’000</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong>
                    </td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>’000</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong>
                    </td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>’000</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Product revenue, net
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      38,522
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      31,674
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Pre-product revenue, net
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      7,950
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      6,537
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total revenue from sale of therapies</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>46,472</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>38,211</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>—</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Collaboration revenue
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      19,781
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      16,265
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      14,003
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total revenue</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>66,253</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>54,476</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>14,003</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 32%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Cost of product revenue
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (343
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (282
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Research and development expenses
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (47,105
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (38,731
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (36,356
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Selling and administrative expenses
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (47,331
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (38,917
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (43,985
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net other operating income / (expense)
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (42
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Operating loss</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(28,525</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(23,453</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(66,380</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Finance income
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      156
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      128
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      34
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Finance costs
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (3,320
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (2,730
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (3,148
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Non-operating expense</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(3,164</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (2,602
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(3,114</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Loss before taxes</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(31,689</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(26,055</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(69,494</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Income tax credit
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,629
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      3,806
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      7,494
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Loss for the period</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(27,060</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(22,249</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(62,000</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong>)</strong>
                    </td>
                  </tr>
                </table>
                <p align="justify">
                  <strong
                    >Condensed Consolidated Statement of Cash Flows for Each
                    Period Presented:</strong
                  >
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="10"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Six Months Ended June 30,</strong>
                    </td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 32%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>’000</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong>
                    </td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>’000</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong>
                    </td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>’000</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 32%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Cash and cash equivalents at beginning of year
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      289,317
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      237,886
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      129,716
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net cash flows used in operating activities
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (48,669
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (40,017
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (58,575
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net cash flows (used in) / from investing activities
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (416
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (342
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      44
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net cash flows (used in) / from financing activities
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (2,274
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,870
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      207,761
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net foreign exchange difference on cash held
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      15,089
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      12,407
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (76
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Cash and cash equivalents at end of period
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>253,047</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong> </strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong> </strong>
                    </td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong> </strong>
                    </td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>208,064</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong> </strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">
                      <strong> </strong>
                    </td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong> </strong>
                    </td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>278,870</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                </table>
                <p>
                  <strong
                    >Condensed Consolidated Statements of Financial Position as
                    at</strong
                  >
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>June 30,</strong><br /><br /><strong>2022</strong
                      ><br /><br /><strong>£’000</strong>
                    </td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>December 31,</strong><br /><br /><strong
                        >2021</strong
                      ><br /><br /><strong>£’000</strong>
                    </td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 0px;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Non-current assets</strong>
                    </td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 0px; vertical-align: bottom"
                    ></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 0px; vertical-align: bottom"
                    ></td>
                    <td style="width: 0px; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Property, plant and equipment
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      7,092
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      8,944
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Right of use assets
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      21,853
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      22,593
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Other non-current assets
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      6,243
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,935
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Deferred tax asset
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      3,277
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,575
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total non-current assets</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>38,465</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>39,047</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Current assets</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Inventory
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      535
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Trade and other receivables
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      35,273
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      15,208
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Tax receivable
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      13,231
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      9,632
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Cash and cash equivalents
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      208,064
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      237,886
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total current assets</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>257,103</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>262,726</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total assets</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>295,568</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>301,773</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Equity</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Share capital
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      88
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      88
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Share premium
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      579
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      212,238
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Foreign currency translation reserve
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (29
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      89
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Other reserves
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      337,847
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      386,167
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Share-based payment reserve
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      68,445
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      54,357
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Accumulated deficit
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (242,278
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (481,392
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom">)</td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total equity</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>164,652</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>171,547</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Non-current liabilities</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Interest-bearing loans and borrowings
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      41,536
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      37,226
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Deferred revenue
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      6,408
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Lease liabilities
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      24,738
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,355
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Provisions
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      87
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      57
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total non-current liabilities</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>66,361</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>69,046</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Current liabilities</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Trade and other payables
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      48,133
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      35,436
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Deferred revenue
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      14,953
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      24,450
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Lease liabilities
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,420
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.5%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,255
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Provisions
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      49
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      39
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total current liabilities</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>64,555</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>61,180</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total liabilities</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>130,916</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>130,226</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 38%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total equity and liabilities</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>295,568</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.5%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.5%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>301,773</strong>
                    </td>
                    <td style="width: 0.5%; vertical-align: bottom"></td>
                  </tr>
                </table>
                <p></p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDcwOTEyMSM0MDEzMzg1MjkjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/NDkxZjFiNzUtMWRjMy00MjQ5LTk2ZTgtYzExOGZmNDJlZTlkLTEyMTc4MTE=/tiny/Immunocore-Holdings-Limited.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings Limited
                <p class="spr-ir-news-article-date">Released August 10, 2022</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param:
      "immunocore-reports-first-quarter-2022-financial-results-and-provides-business-update",
    title: `Immunocore Reports First Quarter 2022 Financial Results and Provides
      Business Update :: Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore Reports First Quarter 2022 Financial Results and
                  Provides Business Update
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2022-05-11T07:00:00" class="date">
                    May 11, 2022 7:00am EDT
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2022-05-11_Immunocore_Reports_First_Quarter_2022_Financial_39.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore Reports First Quarter 2022 Financial Results and Provides Business Update"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <div class="related-documents box hidden-print">
                  <div class="text">
                    <h2 class="related-docs-title">Related Documents</h2>
                    <div class="text">
                      <div class=" ">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>

                <p align="center">
                  <strong>Immunocore</strong> <strong>Reports </strong
                  ><strong>First Quarter</strong> <strong>2022 </strong
                  ><strong>Financial Results</strong> <strong>and </strong
                  ><strong>Provides </strong><strong>Business </strong
                  ><strong>Update</strong>
                </p>
                <p align="center">
                  <em> KIMMTRAK® (</em><em>tebentafusp-tebn</em
                  ><em>) approved </em
                  ><em>in the United States and European Union</em
                  ><em>
                    for the treatment of unresectable or metastatic uveal
                    melanoma</em
                  >
                </p>
                <p align="center">
                  <em>Promotional launches </em><em>and sales </em
                  ><em>of KIMMTRAK ongoing in U</em><em>.</em><em>S</em
                  ><em>.</em><em>, Germany, and France </em>
                </p>
                <p align="center">
                  <em>A</em><em>ll patients in U</em><em>.</em><em>S</em
                  ><em>.</em> <em>early access program </em
                  ><em>successfully transitioned</em><em> to commercial </em
                  ><em>supply </em><em>in Q1</em>
                </p>
                <p align="center">
                  <em>Plan to report Phase 1 data from </em><em>ImmTAC</em
                  ><em>
                    clinical candidates targeting PRAME (3Q 2022) and MAGE-A4
                    (4Q 2022) in multiple solid </em
                  ><em>tumors</em><em> this year</em>
                </p>
                <p align="center">
                  <em>Net </em><em>KIMMTRAK </em><em>and pre-product </em
                  ><em>revenue</em><em>s</em> <em>of</em> <em>£10.5</em
                  ><em> million</em> <em>(</em><em>$</em><em>13.</em><em>8</em
                  ><em> million</em><em>)</em> <em>in Q1</em><em> 2022</em>
                  <em>and</em> <em>net cash </em
                  ><em>position of approximately</em><em> $</em><em>271 </em
                  ><em>million as of </em><em>March 3</em><em>1</em
                  ><em>, 2022</em>
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, 11 May 2022)
                  <a
                    href="https://www.globenewswire.com/Tracker?data=Twq9G08b72l3GM5xGLrufJEcMGZlc6VAoqNzvjkpmid-fF9e8QjxmgaXboeNU7sI7wd63Ans9PXzRf6HPweUHQ=="
                    rel="nofollow"
                    target="_blank"
                    >Immunocore</a
                  >
                  Holdings plc (Nasdaq: IMCR) (“Immunocore” or the “Company”), a
                  commercial-stage biotechnology company pioneering the
                  development of a novel class of T cell receptor (TCR)
                  bispecific immunotherapies designed to treat a broad range of
                  diseases, including cancer, autoimmune and infectious
                  diseases, today announced its financial results for the first
                  quarter ended March 31, 2022 and provided a business update.
                </p>
                <p align="justify">
                  <strong
                    >Bahija Jallal, Chief Executive Officer of Immunocore,
                    said</strong
                  >: “This has been an exciting start to the year for
                  Immunocore, during which we have continued to establish
                  ourselves as a pioneer in TCR therapeutics. Our gp100 and CD3
                  targeting ImmTAC, KIMMTRAK, the first in this new class of TCR
                  treatments for cancer and other diseases, has now received
                  regulatory approval for the treatment of unresectable or
                  metastatic uveal melanoma in the United States and European
                  Union. We look forward later this year to exploring KIMMTRAK
                  in cutaneous melanoma and to learning more about the broader
                  potential of our TCR platform with data readouts from our
                  programs targeting PRAME and MAGE.”
                </p>
                <p align="justify">
                  <strong>Ralph Torbay, Head of Commercial, said:</strong>
                  “KIMMTRAK is now approved in 30 countries globally. In the
                  U.S., we have successfully transitioned all early access
                  patients onto commercial product and our team is working
                  closely with healthcare providers to change medical practice
                  and rapidly identify new eligible patients who could benefit
                  from KIMMTRAK. Furthermore, we were delighted that the U.S.
                  National Comprehensive Cancer Network (NCCN) has added
                  KIMMTRAK to the Clinical Practice Guidelines as a Category 1
                  treatment for unresectable or metastatic uveal melanoma,
                  effectively positioning KIMMTRAK as a standard of care. In
                  Europe, KIMMTRAK is now being promoted in Germany and France,
                  and we expect launches to follow in additional priority
                  countries.”
                </p>
                <p align="justify">
                  <strong>First </strong><strong>Q</strong
                  ><strong>uarter</strong> <strong>202</strong
                  ><strong>2</strong>
                  <strong>Highlights (including post-period) </strong>
                </p>
                <p align="justify">
                  <strong><em>KIMMTRAK® (</em></strong
                  ><strong><em>tebentafusp-tebn</em></strong
                  ><strong><em>)</em></strong>
                </p>
                <p align="justify">
                  In January, the U.S. Food and Drug Administration (FDA)
                  approved KIMMTRAK (tebentafusp-tebn) for the treatment of
                  patients with unresectable or metastatic uveal melanoma (mUM).
                  KIMMTRAK is the first TCR therapeutic, the first bispecific T
                  cell engager to treat a solid tumor, and the first and only
                  therapy for the treatment of unresectable or mUM to receive
                  approval from the FDA.
                </p>
                <p align="justify">
                  In February, the Committee for Medicinal Products for Human
                  Use, or CHMP, of the European Medicines Agency, or the EMA,
                  adopted a positive opinion recommending the approval of
                  KIMMTRAK for the treatment of HLA-A*02:01-positive adult
                  patients with unresectable or mUM.
                </p>
                <p align="justify">
                  In March, Immunocore successfully transitioned all patients
                  from U.S. early access program (EAP) onto commercial supply.
                  KIMMTRAK was commercially available less than four weeks after
                  FDA approval.
                </p>
                <p align="justify">
                  For the first quarter ended, March 31, 2022, Immunocore
                  reported combined net KIMMTRAK and pre-product revenues of
                  £10.5 million (or $13.8 million). U.S. net product revenue
                  from the sale of KIMMTRAK in the first quarter was £7.7
                  million (or $10.1 million), this is largely due to the
                  successful transition of patients from the EAP onto commercial
                  supply. Pre-product revenue in France for the first quarter
                  was £2.8 million (or $3.7 million).
                </p>
                <p align="justify">
                  In April, the EC approved KIMMTRAK (tebentafusp) for the
                  treatment of HLA-A*02:01-positive adult patients with
                  unresectable or metastatic uveal melanoma (mUM). With this
                  approval, KIMMTRAK has received marketing authorisation in all
                  European Union, or EU, member states, and following completion
                  of related national procedures, will also be eligible for sale
                  in Iceland, Liechtenstein, and Norway. We plan to pursue
                  regulatory approval for the marketing authorization of
                  KIMMTRAK in all 27 member states of the EU. Following the
                  approval of KIMMTRAK in the EU, the Company plans to
                  transition patients from the early access programs. There are
                  currently over 130 patients on EAP in the EU and UK.
                </p>
                <p>
                  In April, KIMMTRAK was added as a recommended Category 1
                  treatment in the latest National Comprehensive Cancer Network®
                  (NCCN®) Clinical Practice Guidelines in Oncology for
                  metastatic uveal melanoma (mUM). NCCN publishes evidence-based
                  guidelines that are followed by many healthcare professionals
                  in the U.S. and globally.
                </p>
                <p align="justify">
                  In May, the Company began the commercial launch of KIMMTRAK in
                  Germany. The company has begun transitioning patients from the
                  EAP onto commercial supply and enabling the identification of
                  new patients.
                </p>
                <p align="justify">
                  <strong>A</strong><strong>nticipated </strong
                  ><strong>Upcoming </strong><strong>Milestones</strong>
                </p>
                <p align="justify"><em>KIMMTRAK</em><br /></p>
                <ul type="square">
                  <li>
                    Q4 2022 – start randomized clinical trial in metastatic
                    cutaneous melanoma (mCM)
                  </li>
                </ul>
                <p align="justify">
                  <em>ImmTA</em><em>C</em><em> p</em><em>ipeline</em><br />
                </p>
                <ul type="square">
                  <li>
                    Q3 2022 – report initial data from IMC-F106C (PRAME) Phase 1
                    trial in multiple solid tumors
                  </li>
                  <li>
                    Q4 2022 – report complete data from IMC-C103C (MAGE-A4)
                    Phase 1 trial in multiple solid tumors and initial data from
                    ovarian expansion arm
                  </li>
                </ul>
                <p align="justify"><em>ImmTAV</em><em> pipeline</em><br /></p>
                <ul type="square">
                  <li>
                    Q2 2022 – dose first patient in IMC-M113V Phase 1 study in
                    HIV
                  </li>
                </ul>
                <p align="justify"><strong>Financial Results </strong></p>
                <p align="justify">
                  Basic and diluted loss per share was £0.37 (or $0.48) for the
                  three months ended March 31, 2022 compared to £0.76 for the
                  three months ended March 31, 2021. Total operating loss for
                  the three months ended March 31, 2022 was £16.5 million (or
                  $21.6 million) compared to £31.9 million for the same period
                  last year.
                </p>
                <p>
                  Total revenue for the three months ended March 31, 2022 was
                  £22.5 million (or $29.6 million), as compared to £8.3 million
                  for the three months ended March 31, 2021. Revenue in the
                  three months ended March 31, 2022 consisted of net product
                  revenue from the sale of KIMMTRAK in the United States, net
                  pre-product revenue under a compassionate use and an early
                  access program in France, and collaboration revenue. Revenue
                  in the three months ended March 31, 2021 was generated solely
                  from the Group’s collaborations.
                </p>
                <p>
                  Net product revenue from the sale of KIMMTRAK in the three
                  months ended March 31, 2022 was £7.7 million (or $10.1
                  million) following FDA approval in January 2022. This is
                  largely due to the successful transition of patients from the
                  EAP in the U.S. to commercial supply. Net pre-product revenue
                  for the first quarter was £2.8 million (or $3.7 million).
                  Collaboration revenue increased by £3.7 million to £12.0
                  million (or $15.7 million) in the three months ended March 31,
                  2022 compared to £8.3 million for the three months ended March
                  31, 2021, primarily due to the recognition of remaining
                  revenue under the Lilly Collaboration following termination of
                  the agreement in the three months ended March 31, 2022.
                </p>
                <p>
                  For the three months ended March 31, 2022, our research and
                  development (“R&amp;D”) expenses were £18.6 million (or $24.4
                  million), respectively, as compared to £19.9 million for the
                  three months ended March 31, 2021. The reduction was driven by
                  lower R&amp;D costs incurred in relation to tebentafusp
                  following the launch of KIMMTRAK.
                </p>
                <p>
                  For the three months ended March 31, 2022, our administrative
                  expenses were £20.1 million (or $26.4 million), respectively,
                  compared to £20.2 million for the three months ended March 31,
                  2021.
                </p>
                <p align="justify">
                  Cash and cash equivalents were £205.9 million or approximately
                  $270.7 million as of March 31, 2022 compared to £237.9 million
                  as of December 31, 2021.
                </p>
                <p align="center">##</p>
                <p align="justify">
                  <strong>About </strong><strong>KIMMTRAK® </strong
                  ><br />KIMMTRAK is a novel bispecific protein comprised of a
                  soluble T cell receptor fused to an anti-CD3 immune-effector
                  function. KIMMTRAK specifically targets gp100, a lineage
                  antigen expressed in melanocytes and melanoma. This is the
                  first molecule developed using Immunocore’s ImmTAC technology
                  platform designed to redirect and activate T cells to
                  recognize and kill tumor cells. KIMMTRAK has been granted
                  Breakthrough Therapy Designation, Fast Track designation and
                  orphan drug designation by the FDA in the United States,
                  Accelerated Assessment by the EMA, and Promising Innovative
                  Medicine (PIM) designation under the UK Early Access to
                  Medicines Scheme for metastatic uveal melanoma.
                </p>
                <p>
                  <strong>About Phase 3 IMCgp100-202 Trial</strong><br />The
                  IMCgp100-202 (NCT03070392) is a randomized pivotal trial that
                  evaluated overall survival (OS) of KIMMTRAK (tebentafusp-tebn)
                  compared to investigator’s choice (either pembrolizumab,
                  ipilimumab, or dacarbazine) in HLA-A*02:01-positive adult
                  patients with previously untreated mUM. KIMMTRAK demonstrated
                  an unprecedented OS benefit with a Hazard Ratio (HR) in the
                  intent-to-treat population favoring KIMMTRAK, HR=0.51 (95% CI:
                  0.37, 0.71); p&lt; 0.0001, over investigator’s choice (82%
                  pembrolizumab; 13% ipilimumab; 6% dacarbazine).
                </p>
                <p>
                  <strong>IMPORTANT SAFETY INFORMATION</strong
                  ><br /><br /><strong>Cytokine Release Syndrome (CRS),</strong
                  ><strong>
                    which may be serious or life-threatening, occurred in
                    patients receiving</strong
                  ><strong> KIMMTRAK.</strong
                  ><strong>
                    Monitor for at least 16 hours following first three
                    infusions and then as clinically indicated.</strong
                  >
                  Manifestations of CRS may include fever, hypotension, hypoxia,
                  chills, nausea, vomiting, rash, elevated transaminases,
                  fatigue, and headache<em>. </em>CRS occurred in 89% of
                  patients who received KIMMTRAK with 0.8% being grade 3 or 4.
                  Ensure immediate access to medications and resuscitative
                  equipment to manage CRS. Ensure patients are euvolemic prior
                  to initiating the infusions. Closely monitor patients for
                  signs or symptoms of CRS following infusions of KIMMTRAK.
                  Monitor fluid status, vital signs, and oxygenation level and
                  provide appropriate therapy. Withhold or discontinue KIMMTRAK
                  depending on persistence and severity of CRS.
                </p>
                <p><strong>Skin Reactions</strong></p>
                <p>
                  Skin reactions, including rash, pruritus, and cutaneous edema
                  occurred in 91% of patients treated with KIMMTRAK. Monitor
                  patients for skin reactions. If skin reactions occur, treat
                  with antihistamine and topical or systemic steroids based on
                  persistence and severity of symptoms. Withhold or permanently
                  discontinue KIMMTRAK depending on the severity of skin
                  reactions.
                </p>
                <p><strong>Elevated Liver Enzymes</strong></p>
                <p>
                  Elevations in liver enzymes occurred in 65% of patients
                  treated with KIMMTRAK. Monitor alanine aminotransferase (ALT),
                  aspartate aminotransferase (AST), and total blood bilirubin
                  prior to the start of and during treatment with KIMMTRAK.
                  Withhold KIMMTRAK according to severity.
                </p>
                <p><strong>Embryo-Fetal Toxicity</strong></p>
                <p>
                  KIMMTRAK may cause fetal harm. Advise pregnant patients of
                  potential risk to the fetus and patients of reproductive
                  potential to use effective contraception during treatment with
                  KIMMTRAK and 1 week after the last dose.
                </p>
                <p>
                  The most common adverse reactions (≥30%) in patients who
                  received KIMMTRAK were cytokine release syndrome, rash,
                  pyrexia, pruritus, fatigue, nausea, chills, abdominal pain,
                  edema, hypotension, dry skin, headache, and vomiting. The most
                  common (≥50%) laboratory abnormalities were decreased
                  lymphocyte count, increased creatinine, increased glucose,
                  increased AST, increased ALT, decreased hemoglobin, and
                  decreased phosphate.
                </p>
                <p>
                  Please see
                  <a
                    href="https://www.globenewswire.com/Tracker?data=ZsLmOXPQJ4QpBCOkwH96qPDK3OY_CEgj6n63NbXlgHJn-lnwn90059f6wdgxuMZv2Uw2rEgALpRtrObI24pbnG9whXaMNg8DbOn7jUHR5xygz2-QQPLxEIfExvthWgPuxp8C3_oi-id_0pY9YbT4mtvzlE9kulTyWnNIlTiRLZg="
                    rel="nofollow"
                    target="_blank"
                    >full Prescribing Information</a
                  >, including BOXED WARNING for CRS.
                </p>
                <p align="justify">
                  <strong>About </strong><strong>KIMMTRAKConnect</strong>
                  <br />Immunocore is committed to helping patients who need
                  KIMMTRAK obtain access via our KIMMTRAKConnect program. The
                  program provides services with dedicated nurse case managers
                  who provide personalized support, including educational
                  resources, financial assistance, and site of care
                  coordination. To learn more, visit KIMMTRAKConnect.com or call
                  844-775-2273.
                </p>
                <p align="justify">
                  <strong>About </strong><strong>ImmTAC</strong
                  ><strong>® Molecules</strong><br />Immunocore’s proprietary T
                  cell receptor (TCR) technology generates a novel class of
                  bispecific biologics called ImmTAC (Immune mobilizing
                  monoclonal TCRs Against Cancer) molecules that are designed to
                  redirect the immune system to recognize and kill cancerous
                  cells. ImmTAC molecules are soluble TCRs engineered to
                  recognize intracellular cancer antigens with ultra-high
                  affinity and selectively kill these cancer cells via an
                  anti-CD3 immune-activating effector function. Based on the
                  demonstrated mechanism of T cell infiltration into human
                  tumors, the ImmTAC mechanism of action holds the potential to
                  treat hematologic and solid tumors, regardless of mutational
                  burden or immune infiltration, including immune “cold” low
                  mutation rate tumors.
                </p>
                <p align="justify">
                  <strong>About Immunocore </strong><br />Immunocore is a
                  commercial-stage biotechnology company pioneering the
                  development of a novel class of TCR bispecific immunotherapies
                  called ImmTAX – Immune mobilizing monoclonal TCRs Against X
                  disease – designed to treat a broad range of diseases,
                  including cancer, autoimmune, and infectious disease.
                  Leveraging its proprietary, flexible, off-the-shelf ImmTAX
                  platform, Immunocore is developing a deep pipeline in multiple
                  therapeutic areas, including five clinical stage programs in
                  oncology and infectious disease, advanced pre-clinical
                  programs in autoimmune disease and multiple earlier
                  pre-clinical programs. Immunocore’s most advanced oncology TCR
                  therapeutic, KIMMTRAK (tebentafusp-tebn), has been approved by
                  the U.S. FDA for the treatment of HLA-A*02:01-positive adult
                  patients with unresectable or metastatic uveal melanoma (mUM)
                  having demonstrated an overall survival benefit in a
                  randomized Phase 3 clinical trial in metastatic uveal
                  melanoma, a cancer that has historically proven to be
                  insensitive to other immunotherapies.
                </p>
                <p align="justify">
                  <strong>Forward Looking Statements</strong><br />This press
                  release contains “forward-looking statements” within the
                  meaning of the safe harbor provisions of the Private
                  Securities Litigation Reform Act of 1995. All statements,
                  other than statements of historical facts, included in this
                  press release are forward-looking statements. These statements
                  include, but are not limited to, statements regarding the
                  marketing and therapeutic potential of KIMMTRAK for metastatic
                  uveal melanoma (mUM); the expected clinical benefits of
                  KIMMTRAK including extended overall survival benefit;
                  expectations regarding the commercial launch of KIMMTRAK in
                  the United States, Germany and France as well as in other EU
                  member states; Immunocore’s sales and marketing plans in the
                  United States, Germany and France and the successful
                  transition of patients on early access onto commercial supply;
                  the timing of commercial availability of KIMMTRAK in
                  additional countries and the ability to reach patients in a
                  timely manner; the value proposition of KIMMTRAK in mUM and
                  benefit as an orphan indication including expectations
                  regarding the potential market size opportunity; physician’s
                  feedback and physician interest in prescribing KIMMTRAK as the
                  standard of care for mUM; Immunocore’s efforts on expanding
                  patients’ access to medicine; future development plans of
                  KIMMTRAK, including the timing or likelihood of expansion into
                  additional markets or geographies; and expectations regarding
                  the timing of the availability of future clinical trial
                  results including from PRAME and MAGE-A4 clinical trials in
                  multiple solid tumors. Any forward-looking statements are
                  based on management’s current expectations of future events
                  and are subject to a number of risks and uncertainties that
                  could cause actual results to differ materially and adversely
                  from those set forth in or implied by such forward-looking
                  statements, many of which are beyond the Company’s control.
                  These risks and uncertainties include, but are not limited to,
                  the impact of the ongoing COVID-19 pandemic and the Omicron
                  variant on the Company’s business, strategy, clinical trials
                  and financial position; Immunocore’s ability to maintain
                  regulatory approval of KIMMTRAK including the timing or
                  likelihood of expansion into additional markets or
                  geographies; its ability to execute its commercialization
                  strategy for KIMMTRAK; its ability to develop, manufacture and
                  commercialize its other product candidates; commercial supply
                  of KIMMTRAK or any future approved products, and launching,
                  marketing and selling of KIMMTRAK or any future approved
                  products; Immunocore’s ability and plans in continuing to
                  establish and expand a commercial infrastructure and to
                  successfully launch, market and sell KIMMTRAK; actions of
                  regulatory agencies, which may affect the initiation, timing
                  and progress of clinical trials or future regulatory approval;
                  Immunocore’s ability to obtain, maintain and enforce
                  intellectual property protection for KIMMTRAK or any product
                  candidates it is developing; unexpected safety or efficacy
                  data observed during preclinical studies or clinical trials;
                  clinical trial site activation or enrollment rates that are
                  lower than expected; changes in expected or existing
                  competition; Immunocore’s ability to obtain, maintain and
                  enforce intellectual property protection for KIMMTRAK or any
                  product candidates it is developing; and the success of
                  Immunocore’s current and future collaborations, partnerships
                  or licensing arrangements. These and other risks and
                  uncertainties are described in greater detail in the section
                  titled "Risk Factors" in Immunocore’s filings with the
                  Securities and Exchange Commission, including Immunocore’s
                  most recent Annual Report on Form 20-F for the year ended
                  December 31, 2021 filed with the Securities and Exchange
                  Commission on March 3, 2022, as well as discussions of
                  potential risks, uncertainties, and other important factors in
                  the Company’s subsequent filings with the Securities and
                  Exchange Commission. All information in this press release is
                  as of the date of the release, and the Company undertakes no
                  duty to update this information, except as required by law.
                </p>
                <p><strong>CONTACT:</strong></p>
                <p>
                  <strong>Immunocore </strong><br />Debra Nielsen, Head of
                  Communications<br />T: +1 (610) 368-8602<br />E:
                  info@immunocore.us <br />Follow on Twitter:
                  @Immunocore
                </p>
                <p>
                  <strong>Consilium</strong
                  ><strong>
                    Strategic Communications (corporate and financial)</strong
                  ><br />Mary-Jane Elliott/ Chris Welsh/Jessica Hodgson<br />T:
                  +44 (0)203 709 5700<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  >
                </p>
                <p>
                  <strong>Investor Relations </strong><br />Clayton Robertson,
                  Head of Investor Relations<br />T: +1 (215) 384-4781<br />E:
                  info@immunocore.us
                </p>
                <p>
                  <strong>Condensed </strong
                  ><strong>Consolidated Statement of Loss</strong>
                </p>
                <p align="justify">
                  <strong>Comparison of the Three Months Ended </strong
                  ><strong>March 31</strong><strong>, </strong
                  ><strong>2022</strong><strong> and </strong
                  ><strong>2021</strong>
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="8"
                      style="
                        width: 17.64%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Three Months Ended </strong
                      ><strong>March 31</strong><strong>,</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="5"
                      style="
                        width: 11.28%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td style="width: 0.98%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.37%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.38%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$’000</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.91%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£’000</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.37%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£’000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Product revenue, net
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      10,103
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      7,682
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Pre-product revenue, net
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      3,721
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,829
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Collaboration revenue
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      15,734
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      11,963
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      8,270
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total revenue</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>29,558</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>22,474</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>8,270</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Cost of product revenue
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (326)
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (248)
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Research and development expenses
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (24,438)
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (18,581)
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (19,885)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Selling and administrative expenses
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (26,443)
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (20,106)
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (20,184)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net other operating income / (expense)
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (82)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Operating loss</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(21,648)</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(16,460)</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(31,881)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Finance income
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      13
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      10
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      22
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Finance costs
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,753)
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,333)
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,860)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Non-operating expense</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,740)</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,323)</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,838)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Loss before taxes</strong>
                    </td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.41%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(23,388)</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.17%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 3.74%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(17,783)</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.94%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.42%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(33,719)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Income tax credit
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,177
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,655
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,681
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 32.35%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Loss for the period</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.41%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(21,211)</strong>
                    </td>
                    <td
                      style="
                        width: 0.97%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1.17%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 3.74%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(16,128)</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.94%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.42%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(29,038)</strong>
                    </td>
                  </tr>
                </table>
                <p align="justify">
                  <strong
                    >Condensed Consolidated Statement of Cash Flows for Each
                    Period Presented:</strong
                  >
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="8"
                      style="
                        width: 18.74%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Three Months Ended</strong>
                      <strong>March 31</strong><strong>,</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.75%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.77%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2022</strong>
                    </td>
                    <td style="width: 0.98%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.25%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.75%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$’000</strong>
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.77%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£’000</strong>
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.25%;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£’000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.95%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.8%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 5.77%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.26%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Cash and cash equivalents at beginning of year
                    </td>
                    <td style="width: 0.95%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.8%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      312,868
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.96%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.81%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      237,886
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.26%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      129,716
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net cash flows used in operating activities
                    </td>
                    <td style="width: 0.95%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.8%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (40,552)
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.96%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.81%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (30,833)
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.26%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (25,979)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net cash flows (used in) / from investing activities
                    </td>
                    <td style="width: 0.95%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.8%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (175)
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.96%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.81%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (133)
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.26%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net cash flows (used in) / from financing activities
                    </td>
                    <td style="width: 0.95%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.8%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,752)
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.96%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.81%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,332)
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.99%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.26%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      209,373
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Net foreign exchange difference on cash held
                    </td>
                    <td
                      style="
                        width: 0.95%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.8%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      349
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.96%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.81%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      265
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.99%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.26%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (52)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.29%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Cash and cash equivalents at end of period
                    </td>
                    <td
                      style="
                        width: 0.95%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.8%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      270,738
                    </td>
                    <td
                      style="
                        width: 0.99%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.96%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.81%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      205,853
                    </td>
                    <td
                      style="
                        width: 0.98%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.99%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 4.26%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      313,083
                    </td>
                  </tr>
                </table>
                <p align="justify">
                  <strong
                    >Condensed Consolidated Statement of Financial Position as
                    at:</strong
                  >
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>March 31</strong><strong>,</strong
                      ><br /><br /><strong>2022</strong><br /><br /><strong
                        >£’000</strong
                      >
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 0px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>December 31</strong><strong>,</strong
                      ><br /><br /><strong>2021</strong><br /><br /><strong
                        >£’000</strong
                      >
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Non-current assets</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Property, plant and equipment
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      7,849
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      8,944
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Right of use assets
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      22,199
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      22,593
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Other non-current assets
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      5,955
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,935
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Deferred tax asset
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,650
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,575
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total non-current assets</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>38,653</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>39,047</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Current assets</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Inventory
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      496
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Trade and other receivables
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,746
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      15,208
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Tax receivable
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      11,289
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      9,632
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Cash and cash equivalents
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      205,853
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      237,886
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total current assets</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>243,384</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>262,726</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total assets</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>282,037</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>301,773</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Equity</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Share capital
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      88
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      88
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Share premium
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      212,499
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      212,238
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Foreign currency translation reserve
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      294
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      89
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Other reserves
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      386,167
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      386,167
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Share-based payment reserve
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      61,770
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      54,357
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Accumulated deficit
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (497,520)
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (481,392)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total equity</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>163,298</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>171,547</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Non-current liabilities</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Interest-bearing loans and borrowings
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      38,370
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      37,226
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Deferred revenue
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,136
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      6,408
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Lease liabilities
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,043
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,355
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Provisions
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      70
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      57
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total non-current liabilities</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>65,619</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>69,046</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Current liabilities</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Trade and other payables
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      34,695
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      35,436
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Deferred revenue
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      17,089
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      24,450
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Lease liabilities
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 1.01%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.68%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,294
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td style="width: 0.97%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 5.46%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,255
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      Provisions
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      42
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      39
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total current liabilities</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>53,120</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>61,180</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total liabilities</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>118,739</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>130,226</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 30.14%;
                        vertical-align: middle;
                        text-align: left;
                        padding-left: 10px;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total equity and liabilities</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 4.45%;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.01%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.68%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>282,037</strong>
                    </td>
                    <td style="width: 0.75%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 0.97%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 5.46%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>301,773</strong>
                    </td>
                  </tr>
                </table>
                <p></p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDYzMjY2NCM0MDEyMzIwNzYjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/ZmY3YzA4M2UtYjc4NC00MWRjLTg2YjMtMzQ5Y2NiZjQ5N2IxLTEyMTc4MTE=/tiny/Immunocore-Holdings-Limited.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings Limited
                <p class="spr-ir-news-article-date">Released May 11, 2022</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param:
      "immunocore-reports-second-quarter-2021-financial-results-and-provides-business-update",
    title: `Immunocore Reports Second Quarter 2021 Financial Results and Provides
      Business Update :: Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore Reports Second Quarter 2021 Financial Results and
                  Provides Business Update
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2021-08-11T07:00:00" class="date">
                    August 11, 2021 7:00am EDT
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2021-08-11_Immunocore_Reports_Second_Quarter_2021_Financial_59.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore Reports Second Quarter 2021 Financial Results and Provides Business Update"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <div class="related-documents box hidden-print">
                  <div class="text">
                    <h2 class="related-docs-title">Related Documents</h2>
                    <div class="text">
                      <div class=" ">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>

                <p>
                  <strong><em>PRESS RELEASE </em></strong>
                </p>
                <p align="center">
                  <strong>Immunocore</strong> <strong>Reports </strong
                  ><strong>Second</strong><strong> Quarter</strong
                  ><strong> 202</strong><strong>1</strong
                  ><strong> Financial Results </strong><strong>and </strong
                  ><strong>Provides </strong><strong>Business </strong
                  ><strong>Update </strong>
                </p>
                <p align="center">
                  <em>Biologics License Application (BLA)</em
                  ><em> submission for tebentafusp</em
                  ><em> in metastatic uveal melanoma</em
                  ><em> remains on track </em><em>for completion</em
                  ><em> in </em><em>Q3</em><em>; </em><em>FDA has granted </em
                  ><em>tebentafusp</em> <em>Real Time Oncology Review</em>
                  <em>(RTOR)</em><em> and </em
                  ><em>the application will be part of the </em
                  ><em>Project Orbis </em><em>initiative</em>
                </p>
                <p align="center">
                  <em>S</em><em>ubmission</em><em> of a M</em
                  ><em>arket Authorization Application (M</em><em>AA</em
                  ><em>)</em><em> to the </em
                  ><em>European Medicines Agency (</em><em>EMA</em><em>)</em
                  ><em
                    >, and the United Kingdom’s Medicines and Healthcare
                    Regulatory Agency</em
                  ><em> (</em><em>MHRA</em><em>)</em> <em>accelerated to Q3</em
                  ><em>; </em><em>EMA</em>
                  <em
                    >granted tebentafusp accelerated assessment procedure for </em
                  ><em>this </em><em>MAA</em> <br />
                  <br /><em
                    >Dose escalation of IMC-C103C targeting MAGE-A4 and
                    IMC-F106C </em
                  ><em>targeting PRAME</em><em> continues</em
                  ><em> as planned</em><em>; </em><em>i</em
                  ><em>nitial Phase 1 MAGE-A4 data </em><em>planned</em
                  ><em> for </em><em>Q4 </em>
                </p>
                <p align="center">
                  <em>Cash position of</em><em> approximately</em> <em>$</em
                  ><em>385</em> <em>million as of </em><em>June</em><em> 3</em
                  ><em>0</em><em>, 202</em><em>1</em>
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, 11 August 2021)
                  <a
                    href="https://www.globenewswire.com/Tracker?data=8LFsvYpeXDTIKoeIlzVn431EK9L7gH4q_yHxRc-U_UyWZ0-WwMukmdHxkB5z5YBGCIfTCQPXe0AF0tPARtjQ4Q=="
                    rel="nofollow"
                    target="_blank"
                    >Immunocore</a
                  >
                  Holdings plc (Nasdaq: IMCR), a late-stage biotechnology
                  company pioneering the development of a novel class of T cell
                  receptor (TCR) bispecific immunotherapies designed to treat a
                  broad range of diseases, including cancer, infectious and
                  autoimmune disease, today announced its financial results for
                  the quarter and six months ended June 30, 2021 and provides a
                  portfolio update.
                </p>
                <p align="justify">
                  Immunocore’s recent and second quarter highlights include the
                  acceleration of tebentafusp regulatory submissions in the EU
                  and UK; continued dose escalation of MAGE-A4 and PRAME
                  targeting ImmTACs<sup>®</sup>; and the initiation of a single
                  ascending dose trial for its ImmTAV<sup>®</sup> bispecific
                  molecule for chronic hepatitis B (HBV).
                </p>
                <p align="justify">
                  <strong
                    >Bahija Jallal, Chief Executive Officer of Immunocore,
                    said:</strong
                  ><strong> “</strong
                  ><em
                    >The team at Immunocore is focused on bringing our
                    pioneering science to patients as quickly as possible. We
                    remain on track to complete the tebentafusp BLA submission
                    in the US in the third quarter and are </em
                  ><em>pleased to have accelerated our submissions in Europe</em
                  ><em>,</em
                  ><em>
                    while also initiating our global early access program</em
                  ><em>
                    to make tebentafusp available to patients who need it now.”
                  </em>
                </p>
                <p align="justify">
                  <em>“</em><em>With </em><em>the </em><em>positive </em
                  ><em>phase 3 results for </em><em>tebentafusp</em
                  ><em> in metastatic uveal melanoma</em
                  ><em>, we now have demonstrated </em><em>the </em
                  ><em>first</em><em> ever</em
                  ><em> overall survival benefit for any TCR therapeutic</em
                  ><em>,</em><em>” </em><strong>said</strong>
                  <strong
                    >David Berman, Head of Research and Development. “</strong
                  ><em>Our </em><em>Phase 1 programs targeting MAGE</em
                  ><em>-A</em><em>4 and PRAME continue to dose escalate</em
                  ><em>, with</em><em> both </em><em>now </em><em>at</em
                  ><em> biologically active</em><em> doses</em><em>.</em>
                  <em>T</em><em>he start of our first clinical trial </em
                  ><em>in chronic </em><em>hepatitis B</em><em> further</em
                  ><em> highlights </em
                  ><em>our confidence in the potential of our ImmTAX </em
                  ><em>platform</em
                  ><em> across a broad range of indications including </em
                  ><em>cancer and infectious diseas</em><em>e.</em><em>” </em>
                </p>
                <p align="justify">
                  <strong>Second</strong
                  ><strong>
                    Quarter 2021 Highlights (including post-period)</strong
                  >
                </p>
                <p align="justify">
                  <strong><em>T</em></strong
                  ><strong><em>ebentafusp</em></strong>
                </p>
                <p>
                  In July, the European Medicines Agency (EMA) granted
                  tebentafusp accelerated assessment procedure for this
                  Marketing Authorization Application (MAA). Accelerated
                  assessment potentially reduces the time frame for the EMA
                  Committee for Medicinal Products for Human Use (CHMP) and
                  Committee for Advanced Therapies (CAT) to review a MAA for an
                  Advanced Therapy Medicinal Product (ATMP). The U.S. Food and
                  Drug Administration (FDA) will review the Biologics License
                  application (BLA) for tebentafusp (IMCgp100) under the
                  Real-Time Oncology Review (RTOR) pilot program, an initiative
                  of the FDA’s Oncology Center of Excellence designed to
                  expedite the delivery of safe and effective cancer treatments
                  to patients. Tebentafusp is also being reviewed under the
                  FDA’s Project Orbis initiative, which enables concurrent
                  review by the health authorities in partner countries that
                  have requested participation. Previously, the FDA has granted
                  Breakthrough Therapy Designation (BTD) to tebentafusp
                  (IMCgp100) for the treatment of HLA-A*02:01-positive adult
                  patients with unresectable or metastatic uveal melanoma (mUM).
                </p>
                <p align="justify">
                  In June, the Company presented a subset analysis from the
                  Phase 3 study exploring the overall survival benefit from
                  tebentafusp in patients with best RECIST* response of
                  progressive disease (PD) at the American Society of Clinical
                  Oncology (ASCO) Annual Meeting. In patients with a best
                  response of PD in the Phase 3 trial, the overall survival (OS)
                  was superior for the tebentafusp arm versus the investigator’s
                  choice arm with a hazard ratio (HR) of 0.43 (95% CI
                  0.27-0.68). More than half of tebentafusp patients with best
                  response PD were treated beyond initial progression and no new
                  safety signals were observed. In addition, analysis from the
                  Phase 2 tebentafusp trial suggests that at least one-third of
                  patients on tebentafusp with a best response of PD have a
                  reduction in circulating tumor DNA and that this may be
                  associated with longer OS.
                </p>
                <p align="justify">
                  In April, the Company launched a global early access program
                  for tebentafusp in mUM.
                </p>
                <p align="justify">
                  In April, the Company’s Phase 3 data of tebentafusp in mUM was
                  also the subject of an oral presentation in the Phase 3
                  clinical trials plenary session at the AACR Virtual Annual
                  Meeting 2021. Tebentafusp demonstrated a statistically
                  significant and clinically meaningful improvement in overall
                  survival (OS) as a first-line treatment in mUM. In the
                  intent-to-treat population, tebentafusp demonstrated a median
                  overall survival of 21.7 months compared to 16.0 months for
                  investigator’s choice and with 73% of patients alive at 1 year
                  for tebentafusp vs. 58% for investigator’s choice. The OS
                  Hazard Ratio (HR) favored tebentafusp, HR=0.51 (95% CI: 0.37,
                  0.71); p&lt; 0.0001, over investigator’s choice (82%
                  pembrolizumab; 12% ipilimumab; 6% dacarbazine). In addition,
                  tebentafusp resulted in a statistically significant longer
                  PFS. Treatment-related adverse events were manageable and
                  consistent with the proposed mechanism.
                </p>
                <p align="justify">
                  The Company remains on track to complete submission of a BLA
                  to the FDA in the third quarter of 2021. Additionally, the
                  Company has accelerated the submission of a MAA to the EMA,
                  and the United Kingdom’s Medicines and Healthcare Regulatory
                  Agency, or MHRA, to the third quarter of 2021.
                </p>
                <p align="justify">
                  <strong><em>Additional </em></strong
                  ><strong><em>C</em></strong
                  ><strong><em>linical Programs </em></strong>
                </p>
                <p align="justify">
                  <em>IMC-C103C </em><em>targeting </em><em>MAGE-A4 </em>
                </p>
                <p align="justify">
                  In the second quarter, the Company continued to dose escalate
                  IMC-C103C, an ImmTAC molecule targeting an HLA-A*02:01 MAGE-A4
                  antigen, in a first-in-human, Phase 1/2 dose escalation trial
                  in patients with solid tumor cancers including non-small-cell
                  lung cancer (NSCLC), gastric, head and neck, ovarian and
                  synovial sarcoma. As of June 30, 2021, the Company has
                  enrolled 39 patients in the Phase 1 study. Early
                  pharmacodynamic data indicate that IMC-C103C monotherapy is
                  demonstrating biological activity at the doses currently under
                  evaluation. The Company plans to report this initial Phase 1
                  data in the fourth quarter of 2021.
                </p>
                <p align="justify">
                  <em>IMC-F106C</em> <em>targeting </em><em>PRAME </em>
                </p>
                <p align="justify">
                  In the second quarter, the Company continued to dose escalate
                  IMC-F106C, an ImmTAC molecule targeting an HLA-A*02:01 PRAME
                  antigen, in a first-in-human, Phase 1/2 dose escalation trial
                  in patients with multiple solid tumor cancers. PRAME is
                  overexpressed in many solid tumors including NSCLC, SCLC,
                  endometrial, ovarian, melanoma and breast cancers. As of June
                  30, 2021, the company has enrolled 23 patients in the Phase 1
                  study. Early pharmacodynamic data indicate that IMC-F106C
                  monotherapy is demonstrating biological activity at the doses
                  currently under evaluation. The Company plans to report this
                  initial Phase 1 data in mid-2022.
                </p>
                <p align="justify">
                  <em>IMC-</em><em>I</em><em>109V</em> <em>targeting</em>
                  <em>HBV</em>
                </p>
                <p align="justify">
                  In the second quarter, the Company initiated dosing in the
                  IMC-I109V global Phase 1 single ascending dose trial.
                  IMC-I109V is the first candidate in development using
                  Immunocore’s immune‐mobilising monoclonal T cell receptors
                  against virus (ImmTAV®) platform to enter clinical trials.
                  IMC-I109V targets a conserved Hepatitis B virus (HBV) envelope
                  antigen and is being developed as a potential functional cure.
                </p>
                <p align="justify"><strong>Financial Results </strong></p>
                <p align="justify">
                  Basic and diluted loss per share was £0.75 or $1.04 for the
                  three months ended June 30, 2021 as compared to an adjusted
                  £0.63 for the three months ended June 30, 2020. Basic and
                  diluted loss per share was £1.51 or $2.08 for the six months
                  ended June 30, 2021 compared to an adjusted £1.39 for the six
                  months ended June 30, 2020. Total operating loss for the three
                  months ended June 30, 2021 was £34.5 million or $47.6 million
                  compared to £20.5 million for the same period last year,
                  largely due to an increase in employee costs associated with
                  non-cash share-based payment charge. Total operating loss for
                  the six months ended June 30, 2021 was £66.4 million or $91.6
                  million compared to £42.6 million for the same period last
                  year, largely due to an increase in employee costs associated
                  with non-cash share-based payment charge.
                </p>
                <p align="justify">
                  For the three and six months ended June 30, 2021, revenue from
                  collaboration agreements was £5.7 million or $7.9 million and
                  £14.0 million or $19.3 million, respectively, as compared to
                  £7.8 million and £16.0 million, respectively, for the three
                  and six months ended June 30, 2020. For the three and six
                  months ended June 30, 2021, research and development expenses
                  were £16.5 million or $22.7 million and £36.4 million or $50.2
                  million, respectively, as compared to £16.4 million and £37.2
                  million, respectively, for the three and six months ended June
                  30, 2020. For the three and six months ended June 30, 2021,
                  administrative expenses were £23.8 million or $32.9 million
                  and £44.0 million or $60.7 million, respectively, compared to
                  £12.3 million and £21.9 million respectively, for the three
                  and six months ended June 30, 2020 including a £5.1 million
                  and £13.0 million increase, respectively, in the non-cash
                  share-based payment charge.
                </p>
                <p align="justify">
                  Cash and cash equivalents are £278.9 million or approximately
                  $385 million as of June 30, 2021 compared to £129.7 million as
                  of December 31, 2020.
                </p>
                <p align="center">##</p>
                <p align="justify"><strong>About Tebentafusp</strong></p>
                <p align="justify">
                  Tebentafusp is a novel bispecific protein comprised of a
                  soluble T cell receptor fused to an anti-CD3 immune-effector
                  function. Tebentafusp specifically targets gp100, a lineage
                  antigen expressed in melanocytes and melanoma, and is the
                  first molecule developed using Immunocore’s ImmTAC technology
                  platform designed to redirect and activate T cells to
                  recognise and kill tumour cells. Tebentafusp has been granted
                  Breakthrough Therapy Designation, Fast Track designation and
                  orphan drug designation by the FDA in the United States and
                  Promising Innovative Medicine (PIM) designation under the UK
                  Early Access to Medicines Scheme for metastatic uveal
                  melanoma. The European Medicine Agency (EMA) has granted the
                  Tebentafusp Marketing Authorization Application (MAA) for an
                  Accelerated Assessment procedure based on the Committee for
                  Medicinal Products for Human Use (CHMP) agreement that
                  tebentafusp is a product of major interest for public health
                  and therapeutic innovation. Tebentafusp is also being reviewed
                  under the FDA’s Project Orbis initiative, which enables
                  concurrent review by the health authorities in partner
                  countries that have requested participation. For more
                  information about enrolling tebentafusp clinical trials for
                  metastatic uveal melanoma, please visit ClinicalTrials.gov
                  (NCT03070392).
                </p>
                <p align="justify"><strong>About Immunocore</strong></p>
                <p>
                  Immunocore is a late-stage biotechnology company pioneering
                  the development of a novel class of TCR bispecific
                  immunotherapies called ImmTAX – Immune mobilising monoclonal
                  TCRs Against X disease – designed to treat a broad range of
                  diseases, including cancer, infectious and autoimmune.
                  Leveraging its proprietary, flexible, off-the-shelf ImmTAX
                  platform, Immunocore is developing a deep pipeline in multiple
                  therapeutic areas, including five clinical stage programs in
                  oncology and infectious disease, advanced pre-clinical
                  programs in autoimmune disease and multiple earlier
                  pre-clinical programs. Immunocore’s most advanced oncology
                  therapeutic candidate, tebentafusp, has demonstrated an
                  overall survival benefit in a randomized Phase 3 clinical
                  trial in metastatic uveal melanoma, a cancer that has
                  historically proven to be insensitive to other
                  immunotherapies.
                </p>
                <p align="justify">
                  <strong>Forward Looking Statements</strong>
                </p>
                <p>
                  This press release contains “forward-looking statements”
                  within the meaning of the Private Securities Litigation Reform
                  Act of 1995, including, but are not limited to, statements
                  regarding the Company’s business strategy including its
                  proposed regulatory plans for tebentafusp, the efficacy,
                  safety and therapeutic potential of tebentafusp, the planned
                  timing of a complete BLA submission for tebentafusp for the
                  treatment of mUM in the third quarter of 2021, the potential
                  approval and commercial launch of tebentafusp for mUM, the
                  design, progress, timing, scope and results of the Company’s
                  clinical trials including IMC-C103C, IMC-F106C and IMC-I109V,
                  the anticipated achievement of upcoming clinical milestones,
                  the potential benefit of Breakthrough Therapy Designation or
                  Orphan Drug Designation for tebentafusp, and the Company’s
                  anticipated cash runway. Any forward-looking statements are
                  based on management’s current expectations of future events
                  and are subject to a number of risks and uncertainties that
                  could cause actual results to differ materially and adversely
                  from those set forth in or implied by such forward-looking
                  statements, many of which are beyond the Company’s control.
                  These risks and uncertainties include, but are not limited to,
                  the impacts of the COVID-19 pandemic on the Company’s
                  business, clinical trials and financial position; unexpected
                  safety or efficacy data observed during preclinical studies or
                  clinical trials; clinical trial site activation or enrollment
                  rates that are lower than expected; the risk that initial or
                  interim results from a clinical trial may not be predictive of
                  the final results of the trial or the results of future
                  trials; changes in expected or existing competition; changes
                  in the regulatory environment; and the uncertainties and
                  timing of the regulatory approval process. For a discussion of
                  other risks and uncertainties, and other important factors,
                  any of which could cause our actual results to differ from
                  those contained in the forward-looking statements, see the
                  section titled “Risk Factors” in the Company’s Annual Report
                  on Form 20-F for the year ended December 31, 2020 filed with
                  the Securities and Exchange Commission on March 25, 2021, as
                  well as discussions of potential risks, uncertainties, and
                  other important factors in the Company’s subsequent filings
                  with the Securities and Exchange Commission. All information
                  in this press release is as of the date of the release, and
                  the Company undertakes no duty to update this information,
                  except as required by law.
                </p>
                <p><strong>CONTACT:</strong></p>
                <p>
                  <strong>Immunocore </strong><br />Debra Nielsen, Head of
                  Communications<br />T: +1 (610) 368-8602<br />E:
                  info@immunocore.us <br />Follow on Twitter:
                  @Immunocore
                </p>
                <p>
                  <strong
                    >Consilium Strategic Communications (corporate and
                    financial)</strong
                  ><br />Mary-Jane Elliott/ Chris Welsh/ Jessica Hodgson
                  <br />T: +44 (0)203 709 5700<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  >
                </p>
                <p>
                  <strong>Investor Relations </strong><br />Clayton Robertson,
                  Head of Investor Relations<br />T: +1 215-384-4781<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  >
                </p>
                <p><strong>Consolidated Statement of Loss</strong></p>
                <p>
                  <strong>Comparison of the Three Months Ended </strong
                  ><strong>June </strong><strong>3</strong><strong>0</strong
                  ><strong>, 2021 and 2020</strong><strong>:</strong>
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 473.4pt;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      colspan="2"
                      style="width: 264.74px; vertical-align: bottom"
                    >
                      <strong> </strong>
                    </td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.47px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="12"
                      style="
                        width: 345.67px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>Three Months Ended June 30,</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 264.74px; vertical-align: bottom"
                    >
                      <strong> </strong>
                    </td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.47px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="7"
                      style="
                        width: 245.54px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 17.2px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 10.94px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 72px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2020</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 255.34px; vertical-align: bottom">
                      <strong> </strong>
                    </td>
                    <td style="width: 9.4px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 9.34px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 95.34px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$000</strong>
                    </td>
                    <td
                      style="
                        width: 11px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="4"
                      style="
                        width: 148.14px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£000</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 9.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 9.34px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 72px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 255.34px; vertical-align: bottom">
                      Revenue
                    </td>
                    <td style="width: 9.4px; vertical-align: middle">​</td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="
                        width: 95.34px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      7,915
                    </td>
                    <td
                      style="
                        width: 11px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="width: 11.54px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="4"
                      style="
                        width: 148.14px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      5,733
                    </td>
                    <td
                      colspan="2"
                      style="width: 9.8px; vertical-align: middle"
                    ></td>
                    <td style="width: 9.34px; vertical-align: middle"></td>
                    <td style="width: 72px; vertical-align: bottom">7,787</td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 264.74px; vertical-align: bottom"
                    >
                      Research and development expenses
                    </td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 94.87px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (22,740)
                    </td>
                    <td
                      colspan="2"
                      style="width: 11.54px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 25.27px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 122.87px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (16,471)
                    </td>
                    <td
                      colspan="2"
                      style="width: 9.8px; vertical-align: middle"
                    ></td>
                    <td style="width: 9.34px; vertical-align: middle"></td>
                    <td style="width: 72px; vertical-align: bottom">
                      (16,378)
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 264.74px; vertical-align: bottom"
                    >
                      Administrative expenses
                    </td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 94.87px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (32,860)
                    </td>
                    <td
                      colspan="2"
                      style="width: 11.54px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 25.27px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 122.87px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (23,801)
                    </td>
                    <td
                      colspan="2"
                      style="width: 9.8px; vertical-align: middle"
                    ></td>
                    <td style="width: 9.34px; vertical-align: middle"></td>
                    <td style="width: 72px; vertical-align: bottom">
                      (12,250)
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 264.74px; vertical-align: bottom"
                    >
                      Net other operating income
                    </td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 94.87px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      55
                    </td>
                    <td
                      colspan="2"
                      style="width: 11.54px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 25.27px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 122.87px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      40
                    </td>
                    <td
                      colspan="2"
                      style="width: 9.8px; vertical-align: middle"
                    ></td>
                    <td style="width: 9.34px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 72px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      346
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 264.74px; vertical-align: bottom"
                    >
                      <strong>Operating loss</strong>
                    </td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 94.87px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(47,630)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 11.54px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 25.27px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 122.87px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(34,499)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 9.8px; vertical-align: middle"
                    ></td>
                    <td style="width: 9.34px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 72px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(20,495)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 264.74px; vertical-align: bottom"
                    >
                      Finance income
                    </td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 94.87px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      17
                    </td>
                    <td style="width: 9.6px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="width: 18.94px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 122.14px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      12
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 17.2px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="width: 10.94px; vertical-align: middle"
                    ></td>
                    <td style="width: 72px; vertical-align: bottom">222</td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 264.74px; vertical-align: bottom"
                    >
                      Finance costs
                    </td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 94.87px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,778)
                    </td>
                    <td
                      colspan="2"
                      style="width: 11.54px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 25.27px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 122.87px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,288)
                    </td>
                    <td
                      colspan="2"
                      style="width: 9.8px; vertical-align: middle"
                    ></td>
                    <td style="width: 9.34px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 72px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (635)
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 264.74px; vertical-align: bottom"
                    >
                      <strong>Non-operating expense</strong>
                    </td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 94.87px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,761)</strong>
                    </td>
                    <td style="width: 9.6px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="width: 18.94px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 122.14px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,276)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 17.2px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="width: 10.94px; vertical-align: middle"
                    ></td>
                    <td
                      style="
                        width: 72px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(413)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 264.74px; vertical-align: bottom"
                    >
                      <strong>Loss before taxes</strong>
                    </td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 94.87px;
                        border-top: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(49,391)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 11.54px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 25.27px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 122.87px;
                        border-top: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(35,775)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 9.8px; vertical-align: middle"
                    ></td>
                    <td style="width: 9.34px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 72px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(20,908)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 264.74px; vertical-align: bottom"
                    >
                      Income tax credit
                    </td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 94.87px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      3,884
                    </td>
                    <td style="width: 9.6px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="width: 18.94px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 122.14px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,813
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 17.2px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="width: 10.94px; vertical-align: middle"
                    ></td>
                    <td
                      style="
                        width: 72px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      3,691
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 264.74px; vertical-align: bottom"
                    >
                      <strong>Loss for the period</strong>
                    </td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 94.87px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(45,507)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 11.54px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 25.27px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 122.87px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(32,962)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 9.8px; vertical-align: middle"
                    ></td>
                    <td style="width: 9.34px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 72px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(17,217)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 264.74px; vertical-align: bottom"
                    >
                      <strong>Basic and diluted </strong
                      ><strong>loss per</strong><strong> share</strong>
                    </td>
                    <td style="width: 9.34px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 94.87px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$</strong><strong>(1.04)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 11.54px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 25.27px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 122.87px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(0.</strong><strong>7</strong><strong>5</strong
                      ><strong>)</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 9.8px; vertical-align: middle"
                    ></td>
                    <td style="width: 9.34px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 72px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(0.</strong><strong>63</strong><strong>)</strong>
                    </td>
                  </tr>
                </table>
                <p>
                  <strong
                    >Comparison of the Six Months Ended June 30, 2021 and
                    2020</strong
                  ><strong>:</strong>
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 473.25pt;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      colspan="2"
                      style="width: 258.14px; vertical-align: bottom"
                    ></td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.6px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="14"
                      style="
                        width: 345.34px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>Six Months Ended June 30,</strong>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 258.14px; vertical-align: bottom"
                    ></td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.6px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="5"
                      style="
                        width: 240px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                    <td
                      colspan="4"
                      style="
                        width: 13.74px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="4"
                      style="
                        width: 14.2px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.4px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2020</strong>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 249px; vertical-align: bottom"></td>
                    <td style="width: 9.14px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 9px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 96.4px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$000</strong>
                    </td>
                    <td
                      style="
                        width: 11px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 29.6px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 120.67px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£000</strong>
                    </td>
                    <td
                      colspan="4"
                      style="
                        width: 9.4px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 12.47px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.4px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£000</strong>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 249px; vertical-align: bottom">
                      Revenue
                    </td>
                    <td style="width: 9.14px; vertical-align: middle">​</td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 96.4px; vertical-align: bottom"
                    >
                      19,333
                    </td>
                    <td style="width: 11px; vertical-align: middle"></td>
                    <td style="width: 29.6px; vertical-align: middle"></td>
                    <td
                      colspan="3"
                      style="width: 120.67px; vertical-align: bottom"
                    >
                      14,003
                    </td>
                    <td
                      colspan="4"
                      style="width: 9.4px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="3"
                      style="width: 12.47px; vertical-align: middle"
                    ></td>
                    <td style="width: 77.4px; vertical-align: bottom">
                      16,042
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 258.14px; vertical-align: bottom"
                    >
                      Research and development expenses
                    </td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="width: 95.8px; vertical-align: bottom"
                    >
                      (50,193)
                    </td>
                    <td style="width: 29.6px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 3.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 118.54px; vertical-align: bottom"
                    >
                      (36,356)
                    </td>
                    <td
                      colspan="4"
                      style="width: 9.4px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 11.14px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 78.74px; vertical-align: bottom"
                    >
                      (37,157)
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 258.14px; vertical-align: bottom"
                    >
                      Administrative expenses
                    </td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="width: 95.8px; vertical-align: bottom"
                    >
                      (60,726)
                    </td>
                    <td style="width: 29.6px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 3.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 118.54px; vertical-align: bottom"
                    >
                      (43,985)
                    </td>
                    <td
                      colspan="4"
                      style="width: 9.4px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 11.14px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 78.74px; vertical-align: bottom"
                    >
                      (21,855)
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 258.14px; vertical-align: bottom"
                    >
                      Net other operating (expense) / income
                    </td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="
                        width: 95.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (58)
                    </td>
                    <td style="width: 29.6px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 3.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="
                        width: 118.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (42)
                    </td>
                    <td
                      colspan="4"
                      style="width: 9.4px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 11.14px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 78.74px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      356
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 258.14px; vertical-align: bottom"
                    >
                      <strong>Operating loss</strong>
                    </td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="
                        width: 95.8px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(91,644)</strong>
                    </td>
                    <td style="width: 29.6px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 3.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="
                        width: 118.54px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(66,380)</strong>
                    </td>
                    <td
                      colspan="4"
                      style="width: 9.4px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 11.14px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 78.74px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(42,614)</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 258.14px; vertical-align: bottom"
                    >
                      Finance income
                    </td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="width: 95.8px; vertical-align: bottom"
                    >
                      47
                    </td>
                    <td style="width: 29.6px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 3.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="4"
                      style="width: 120.34px; vertical-align: bottom"
                    >
                      34
                    </td>
                    <td
                      colspan="4"
                      style="width: 11.34px; vertical-align: middle"
                    ></td>
                    <td style="width: 7.4px; vertical-align: middle"></td>
                    <td
                      colspan="3"
                      style="width: 84.34px; vertical-align: bottom"
                    >
                      1,605
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 258.14px; vertical-align: bottom"
                    >
                      Finance costs
                    </td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="
                        width: 95.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (4,346)
                    </td>
                    <td style="width: 29.6px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 3.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="
                        width: 118.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (3,148)
                    </td>
                    <td
                      colspan="4"
                      style="width: 9.4px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 11.14px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 78.74px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (1,702)
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 258.14px; vertical-align: bottom"
                    >
                      <strong>Non-operating expense</strong>
                    </td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="
                        width: 95.8px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(4,299)</strong>
                    </td>
                    <td style="width: 29.6px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 3.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="4"
                      style="
                        width: 120.34px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(3,114)</strong>
                    </td>
                    <td
                      colspan="4"
                      style="width: 11.34px; vertical-align: middle"
                    ></td>
                    <td style="width: 7.4px; vertical-align: middle"></td>
                    <td
                      colspan="3"
                      style="
                        width: 84.34px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(97)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 258.14px; vertical-align: bottom"
                    >
                      <strong>Loss before taxes</strong>
                    </td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="
                        width: 95.8px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(95,943)</strong>
                    </td>
                    <td style="width: 29.6px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 3.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="
                        width: 118.54px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(69,494)</strong>
                    </td>
                    <td
                      colspan="4"
                      style="width: 9.4px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 11.14px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 78.74px;
                        border-top: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(42,711)</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 258.14px; vertical-align: bottom"
                    >
                      Income tax credit
                    </td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="
                        width: 95.8px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      10,346
                    </td>
                    <td style="width: 29.6px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 3.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="4"
                      style="
                        width: 120.34px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      7,494
                    </td>
                    <td
                      colspan="4"
                      style="width: 11.34px; vertical-align: middle"
                    ></td>
                    <td style="width: 7.4px; vertical-align: middle"></td>
                    <td
                      colspan="3"
                      style="
                        width: 84.34px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      6,855
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 258.14px; vertical-align: bottom"
                    >
                      <strong>Loss for the period</strong>
                    </td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="
                        width: 95.8px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(85,597)</strong>
                    </td>
                    <td style="width: 29.6px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 3.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="
                        width: 118.54px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(62,000)</strong>
                    </td>
                    <td
                      colspan="4"
                      style="width: 9.4px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 11.14px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 78.74px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(35,856)</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 258.14px; vertical-align: bottom"
                    >
                      <strong>Basic and diluted </strong
                      ><strong>loss per</strong><strong> share</strong>
                    </td>
                    <td style="width: 9px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="
                        width: 95.8px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$(</strong><strong>2.08</strong><strong>)</strong>
                    </td>
                    <td style="width: 29.6px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 3.47px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="
                        width: 118.54px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(</strong><strong>1.51</strong><strong>)</strong>
                    </td>
                    <td
                      colspan="4"
                      style="width: 9.4px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="width: 11.14px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 78.74px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(</strong><strong>1.39</strong><strong>)</strong>
                    </td>
                    <td></td>
                  </tr>
                </table>
                <p>
                  <strong>Condensed </strong
                  ><strong
                    >Consolidated Statement of Cash Flows for Each Period
                    Presented:
                  </strong>
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 393.05pt;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td style="width: 261px; vertical-align: bottom">
                      <strong> </strong>
                    </td>
                    <td style="width: 11.67px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.6px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="7"
                      style="
                        width: 228.27px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Six Months Ended June 30,</strong>
                    </td>
                    <td
                      style="
                        width: 11.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 261px; vertical-align: bottom">
                      <strong> </strong>
                    </td>
                    <td style="width: 11.67px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.6px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 63.54px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                    <td
                      style="
                        width: 11.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 11.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 61.54px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                    <td
                      style="
                        width: 11.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 11.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 56.94px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2020</strong>
                    </td>
                    <td
                      style="
                        width: 11.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 261px; vertical-align: bottom">
                      <strong> </strong>
                    </td>
                    <td style="width: 11.67px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 11.6px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 63.54px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$000</strong>
                    </td>
                    <td
                      style="
                        width: 11.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 11.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 61.54px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£000</strong>
                    </td>
                    <td
                      style="
                        width: 11.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 11.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 56.94px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£000</strong>
                    </td>
                    <td
                      style="
                        width: 11.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 261px; vertical-align: bottom">
                      <strong> </strong>
                    </td>
                    <td style="width: 11.67px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 63.54px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 11.54px; vertical-align: middle">​</td>
                    <td style="width: 11.54px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 61.54px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong> (unaudited)</strong>
                    </td>
                    <td style="width: 11.54px; vertical-align: middle">​</td>
                    <td style="width: 11.54px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 56.94px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong> </strong>
                    </td>
                    <td style="width: 11.54px; vertical-align: middle">​</td>
                  </tr>
                  <tr>
                    <td style="width: 261px; vertical-align: bottom">
                      Cash and cash equivalents at beginning of year
                    </td>
                    <td style="width: 11.67px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle"></td>
                    <td style="width: 63.54px; vertical-align: bottom">
                      179,085
                    </td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 61.54px; vertical-align: bottom">
                      129,716
                    </td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 56.94px; vertical-align: bottom">
                      73,966
                    </td>
                    <td style="width: 11.54px; vertical-align: middle">​</td>
                  </tr>
                  <tr>
                    <td style="width: 261px; vertical-align: bottom">
                      Net cash flows used in operating activities
                    </td>
                    <td style="width: 11.67px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle">​</td>
                    <td style="width: 63.54px; vertical-align: bottom">
                      (80,867)
                    </td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 61.54px; vertical-align: bottom">
                      (58,575)
                    </td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 56.94px; vertical-align: bottom">
                      (40,645)
                    </td>
                    <td style="width: 11.54px; vertical-align: middle">​</td>
                  </tr>
                  <tr>
                    <td style="width: 261px; vertical-align: bottom">
                      Net cash flows from / (used in) investing activities
                    </td>
                    <td style="width: 11.67px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle">​</td>
                    <td style="width: 63.54px; vertical-align: bottom">61</td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 61.54px; vertical-align: bottom">44</td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 56.94px; vertical-align: bottom">
                      (1,684)
                    </td>
                    <td style="width: 11.54px; vertical-align: middle">​</td>
                  </tr>
                  <tr>
                    <td style="width: 261px; vertical-align: bottom">
                      Net cash flows from financing activities
                    </td>
                    <td style="width: 11.67px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle">​</td>
                    <td style="width: 63.54px; vertical-align: bottom">
                      286,834
                    </td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 61.54px; vertical-align: bottom">
                      207,761
                    </td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 56.94px; vertical-align: bottom">
                      25,054
                    </td>
                    <td style="width: 11.54px; vertical-align: middle">​</td>
                  </tr>
                  <tr>
                    <td style="width: 261px; vertical-align: bottom">
                      Net foreign exchange difference on cash held
                    </td>
                    <td style="width: 11.67px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 63.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (105)
                    </td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 61.54px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      (76)
                    </td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 56.94px;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      118
                    </td>
                    <td style="width: 11.54px; vertical-align: middle">​</td>
                  </tr>
                  <tr>
                    <td style="width: 261px; vertical-align: bottom">
                      Cash and cash equivalents at end of period
                    </td>
                    <td style="width: 11.67px; vertical-align: middle">​</td>
                    <td style="width: 11.6px; vertical-align: middle">​</td>
                    <td
                      style="
                        width: 63.54px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      385,008
                    </td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 61.54px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      278,870
                    </td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td style="width: 11.54px; vertical-align: middle"></td>
                    <td
                      style="
                        width: 56.94px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      56,809
                    </td>
                    <td style="width: 11.54px; vertical-align: middle">​</td>
                  </tr>
                </table>
                <p align="center">
                  <strong
                    >Consolidated Statements of Financial Position for Each
                    Period Presented:
                  </strong>
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 437.7pt;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      <strong> </strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="
                        width: 11.94px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>June 30,</strong><br /><br /><strong>2021</strong
                      ><br /><br /><strong>£’000</strong>
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        border-bottom: solid black 1pt;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>December 31,</strong><br /><br /><strong
                        >2020</strong
                      ><br /><br /><strong>£’000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      <strong>Non-current assets</strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Property, plant and equipment
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      10,988
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      13,754
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Right of use assets
                    </td>
                    <td style="width: 10.54px; vertical-align: middle"></td>
                    <td style="width: 10.87px; vertical-align: middle"></td>
                    <td style="width: 10.74px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      22,391
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      23,093
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Investment in sub-lease
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      470
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      776
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      Other non-current financial assets
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="width: 22.67px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 75.34px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      5,476
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 22.8px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.74px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,410
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      Deferred tax asset
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="width: 22.67px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 75.34px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,192
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 22.8px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.74px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,230
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      <strong>Total non-current assets</strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="width: 22.67px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 75.34px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>41,517</strong>
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 22.8px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.74px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>44,263</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      <strong>Current assets</strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="
                        width: 87.27px;
                        border-top: double black 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        border-top: double black 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 89.4px;
                        border-top: double black 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Trade and other receivables
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      12,198
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      10,280
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Tax receivable
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      20,428
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      12,935
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      Cash and cash equivalents
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="width: 22.67px; vertical-align: middle"
                    ></td>
                    <td
                      style="
                        width: 75.34px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      278,870
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 22.8px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.74px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      129,716
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      <strong>Total current assets</strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="width: 22.67px; vertical-align: middle"
                    ></td>
                    <td
                      style="
                        width: 75.34px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>311,496</strong>
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 22.8px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.74px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>152,931</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      <strong>Total assets</strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="width: 22.67px; vertical-align: middle"
                    ></td>
                    <td
                      style="
                        width: 75.34px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>353,013</strong>
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 22.8px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.74px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>197,194</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      <strong>Equity</strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="
                        width: 87.27px;
                        border-top: double black 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        border-top: double black 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 89.4px;
                        border-top: double black 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Share capital
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      88
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      64
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Share premium
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      211,286
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​-
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      -
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Foreign currency translation reserve
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      33
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      163
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Other reserves
                    </td>
                    <td style="width: 10.54px; vertical-align: middle"></td>
                    <td style="width: 10.87px; vertical-align: middle"></td>
                    <td style="width: 10.74px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      386,167
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      386,167
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      Share-based payment reserve
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="width: 22.67px; vertical-align: middle"
                    ></td>
                    <td
                      style="
                        width: 75.34px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      36,434
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 22.8px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.74px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      18,821
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      Accumulated deficit
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="width: 22.67px; vertical-align: middle"
                    ></td>
                    <td
                      style="
                        width: 75.34px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (411,869)
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​)
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 22.8px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.74px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (349,869)
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      <strong>Total equity</strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="width: 22.67px; vertical-align: middle"
                    ></td>
                    <td
                      style="
                        width: 75.34px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>222,139</strong>
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 22.8px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.74px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>55,346</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      <strong>Non-current liabilities</strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="
                        width: 87.27px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 89.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Interest-bearing loans and borrowings
                    </td>
                    <td style="width: 10.54px; vertical-align: middle"></td>
                    <td style="width: 10.87px; vertical-align: middle"></td>
                    <td style="width: 10.74px; vertical-align: middle"></td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      36,135
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      36,654
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      Deferred liabilities
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="width: 22.67px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 75.34px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      14,953
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 22.8px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.74px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      24,868
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      Lease liabilities
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="width: 22.67px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 75.34px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      24,923
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 22.8px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.74px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,190
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      Provisions
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="width: 22.67px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 75.34px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      175
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 22.8px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.74px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      138
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      <strong>Total non-current liabilities</strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="3"
                      style="width: 22.67px; vertical-align: middle"
                    ></td>
                    <td
                      style="
                        width: 75.34px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>76,186</strong>
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 22.8px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 77.74px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>86,850</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      <strong>Current liabilities</strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Interest-bearing loans and borrowings
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      546
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      ---
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Trade and other payables
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      27,027
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,728
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Deferred liabilities
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,395
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      27,118
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="width: 339.8px; vertical-align: bottom"
                    >
                      Lease liabilities
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td style="width: 10.74px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 11.94px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 87.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,605
                    </td>
                    <td
                      style="
                        width: 11.14px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 11.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 89.54px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,043
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      Provisions
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 20.07px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 77.94px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      115
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 18px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 82.54px;
                        border-bottom: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      109
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      <strong>Total current liabilities</strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 20.07px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 77.94px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>54,688</strong>
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 18px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 82.54px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>54,998</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      <strong>Total liabilities</strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 20.07px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 77.94px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>130,874</strong>
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 18px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 82.54px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>141,848</strong>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 329.27px; vertical-align: bottom">
                      <strong>Total equity and liabilities</strong>
                    </td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.54px; vertical-align: middle">​</td>
                    <td style="width: 10.87px; vertical-align: middle">​</td>
                    <td
                      colspan="2"
                      style="width: 20.07px; vertical-align: middle"
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 77.94px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>353,013</strong>
                    </td>
                    <td
                      style="
                        width: 12.07px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 18px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      colspan="2"
                      style="
                        width: 82.54px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>197,194</strong>
                    </td>
                    <td></td>
                  </tr>
                </table>
                <p></p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDUzMDk5MiM0MDEwMTYxNTQjMjIwNjI1OA=="
                />
                <br /><img
                  src="https://ml-eu.globenewswire.com/media/NmFlMDYxOTktODZlYS00ZWNkLWJhNDItMDc4M2RiMjBkYjI2LTEyMTc4MTE=/tiny/Immunocore-Holdings-Limited.png"
                />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings Limited
                <p class="spr-ir-news-article-date">Released August 11, 2021</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param: "immunocore-reports-first-quarter-2021-financial-results",
    title: `Immunocore Reports First Quarter 2021 Financial Results :: Immunocore
      Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore Reports First Quarter 2021 Financial Results
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2021-05-12T07:00:00" class="date">
                    May 12, 2021 7:00am EDT
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2021-05-12_Immunocore_Reports_First_Quarter_2021_Financial_63.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore Reports First Quarter 2021 Financial Results"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <div class="related-documents box hidden-print">
                  <div class="text">
                    <h2 class="related-docs-title">Related Documents</h2>
                    <div class="text">
                      <div class=" ">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>

                <p>
                  <strong><em>PRESS RELEASE </em></strong>
                </p>
                <p align="center">
                  <strong>Immunocore</strong> <strong>Reports </strong
                  ><strong>First Quarter</strong><strong> 202</strong
                  ><strong>1</strong><strong> Financial Results </strong>
                </p>
                <p align="center">
                  <em>Biologics License Application (BLA) </em
                  ><em>submission </em><em>to the </em><em>U.S. Food </em
                  ><em>and Drug Administration (</em><em>FDA</em><em>)</em>
                  <em>is expected </em><em>to be </em><em>complet</em
                  ><em>ed </em><em>in third quarter of 2021</em>
                </p>
                <p align="center">
                  <em>Launched</em><em> a global </em><em>early </em><em>a</em
                  ><em>ccess </em><em>p</em><em>rogram </em><em>for </em
                  ><em>tebentafusp</em><em> in metastatic uveal melanoma</em>
                </p>
                <p align="center">
                  <em>Cash position of </em><em>$</em><em>43</em><em>1</em
                  ><em> million as of </em><em>March</em><em> 31, 202</em
                  ><em>1</em><em> includes </em
                  ><em
                    >$287 million in net proceeds from initial public offering
                    and concurrent private placement in February 2021</em
                  >
                </p>
                <p align="justify">
                  (OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                  ROCKVILLE, Md., US, 12 May 2021)
                  <a
                    href="https://www.globenewswire.com/Tracker?data=Gli5xnPp6YF5nQahZcQj39xzFxBvvMsRQVKMcJhXy1nKJXQ2KGqK7yF4c-0IVGxGqFGffkbe53hAy80AIXkppQ=="
                    rel="nofollow"
                    target="_blank"
                    >Immunocore</a
                  >
                  Holdings Plc (Nasdaq: IMCR), a late-stage biotechnology
                  company pioneering the development of a novel class of T cell
                  receptor (TCR) bispecific immunotherapies designed to treat a
                  broad range of diseases, including cancer, infectious and
                  autoimmune disease, today announced its results for the
                  quarter ended March 31, 2021.
                </p>
                <p align="justify">
                  Highlights for the quarter included the presentation of the
                  Phase 3 randomized data from the Company’s lead candidate
                  tebentafusp in the plenary clinical trial session at the
                  American Association for Cancer Research (AACR) Annual
                  Meeting, the launch of a global early access program for
                  tebentafusp, and the successful completion of the Company’s
                  initial public offering resulting in net proceeds of $287
                  million.
                </p>
                <p align="justify">
                  <strong
                    >Bahija Jallal, Chief Executive Officer of
                    Immunocore</strong
                  ><strong>,</strong><strong> said:</strong> <em>“</em><em>T</em
                  ><em>ebentafusp</em>
                  <em
                    >has been demonstrated to prolong survival in patients with
                    metastatic uveal melanoma,</em
                  >
                  <em>a </em
                  ><em
                    >cancer that has historically proven insensitive to
                    chemotherapy and immunotherapies</em
                  ><em>. </em><em>These</em><em> data</em><em>,</em
                  ><em> recently </em><em>presented at AACR</em><em>,</em
                  ><em>
                    represent the first positive Phase 3 clinical trial for a
                    TCR therapeutic and the first </em
                  ><em>time that a bispecific </em
                  ><em
                    >T cell engager has demonstrated a survival benefit in a
                    solid tumor</em
                  ><em
                    >, representing a significant breakthrough in the field of
                    oncology</em
                  ><em>.</em><em>”</em>
                </p>
                <p align="justify">
                  <strong
                    >First Quarter 2021 Highlights (including
                    post-period)</strong
                  >
                </p>
                <p align="justify">
                  <strong><em>T</em></strong
                  ><strong><em>ebentafusp</em></strong>
                </p>
                <p align="justify">
                  In April, one oral presentation and three posters on
                  tebentafusp were accepted at the 2021 American Society of
                  Oncology (ASCO) Annual Meeting being held virtually from June
                  4-8, 2021. Per ASCO’s Embargo &amp; Release Information, full
                  abstracts will be released to the public on ASCO’s Meeting
                  Library at 5:00 p.m. ET on May 19, 2021.
                </p>
                <p align="justify">
                  In April, the Company launched a global early access program
                  for tebentafusp in metastatic uveal melanoma (mUM).
                </p>
                <p align="justify">
                  In April, the Company’s Phase 3 data of tebentafusp in
                  metastatic uveal melanoma was also the subject of an oral
                  presentation in the Phase 3 clinical trials plenary session at
                  the AACR Virtual Annual Meeting 2021. Tebentafusp demonstrated
                  a statistically significant and clinically meaningful
                  improvement in overall survival (OS) as a first-line treatment
                  in mUM. In the intent-to-treat population, tebentafusp
                  demonstrated a median overall survival of 21.7 months compared
                  to 16.0 months for investigator’s choice and with 73% of
                  patients alive at 1 year for tebentafusp vs. 58% for
                  investigator’s choice. The OS Hazard Ratio (HR) favored
                  tebentafusp, HR=0.51 (95% CI: 0.37, 0.71); p&lt; 0.0001, over
                  investigator’s choice (82% pembrolizumab; 12% ipilimumab; 6%
                  dacarbazine). In addition, tebentafusp resulted in a
                  statistically significant longer PFS. Treatment-related
                  adverse events were manageable and consistent with the
                  proposed mechanism.
                </p>
                <p align="justify">
                  In February, tebentafusp was granted Breakthrough Therapy
                  Designation by the U.S. Food &amp; Drug Administration (FDA)
                  for unresectable or metastatic uveal melanoma. Additionally,
                  the European Commission, upon recommendation of the European
                  Medicines Agency’s (EMA) Committee for Orphan Medicinal
                  Products (COMP) awarded tebentafusp Orphan Drug Designation
                  for the treatment of uveal melanoma. Medicines that meet the
                  EMA’s Orphan Drug Designation criteria qualify for several
                  incentives, including 10 years of market exclusivity, protocol
                  assistance, and potentially reduced fees for regulatory
                  activities.
                </p>
                <p align="justify">
                  Immunocore will be working to complete its BLA submission to
                  the FDA in the third quarter of 2021, followed by submission
                  of a Marketing Authorization Application to the EMA.
                </p>
                <p align="justify">
                  <strong><em>Additional </em></strong
                  ><strong><em>C</em></strong
                  ><strong><em>linical Programs </em></strong>
                </p>
                <p align="justify"><em>IMC-C103C </em><em>– MAGE-A4 </em></p>
                <p align="justify">
                  In the first quarter, the Company continued to advance,
                  IMC-C103C, an ImmTAC molecule targeting an HLA-A*02:01 MAGE-A4
                  antigen, in a first-in-human, Phase 1/2 dose escalation trial
                  in patients with solid tumor cancers including non-small-cell
                  lung cancer (NSCLC), gastric, head and neck, ovarian and
                  synovial sarcoma. The Company plans to report Phase 1 initial
                  data from this trial in the fourth quarter of 2021.
                </p>
                <p align="justify"><em>IMC-F106C</em><em> – PRAME </em></p>
                <p align="justify">
                  In the first quarter, the Company continued to advance
                  IMC-F106C, an ImmTAC molecule targeting an HLA-A*02:01 PRAME
                  antigen, in a first-in-human, Phase 1/2 dose escalation trial
                  in patients with multiple solid tumor cancers. PRAME is
                  overexpressed in many solid tumors including NSCLC, SCLC,
                  endometrial, ovarian, and breast cancers. The Company plans to
                  report Phase 1 initial data from this trial in mid-2022.
                </p>
                <p align="justify">
                  <em>IMC-</em><em>I</em><em>109V</em><em> – </em><em>HBV</em>
                </p>
                <p align="justify">
                  In the first quarter, the Company continued to advance
                  IMC-I109V, an ImmTAV molecule targeting a conserved Hepatitis
                  B virus (HBV), envelope antigen, in a global Phase 1 single
                  ascending dose trial. The Company plans to initiate dosing
                  mid-year 2021.
                </p>
                <p align="justify"><strong>Operational Highlights</strong></p>
                <p align="justify">
                  In February, the Company made key appointments to management
                  and Board of Directors. The appointment of Ralph Torbay as
                  Immunocore’s new Head of Commercial and the appointment of Dr.
                  Roy S. Herbst as a member of the Company’s Board of Directors
                  became effective January 28, 2021. Dr. Herbst served as a
                  member of Immunocore’s Scientific Advisory Board (SAB) and is
                  currently Ensign Professor of Medicine (Medical Oncology),
                  Professor of Pharmacology, Chief of Medical Oncology and
                  Associate Cancer Center Director for Translational Research at
                  Yale Cancer Center and Smilow Cancer Hospital.
                </p>
                <p align="justify">
                  In February, the Company completed its initial public offering
                  (IPO) and concurrent private placement. The financing was
                  $312.1 million in aggregate, of which approximately $287
                  million in net proceeds was from the IPO on Nasdaq of
                  11,426,280 American Depositary Shares (ADSs), including the
                  exercise in full by the underwriters of their option to
                  purchase an additional 1,490,384 ADSs, at an IPO price of
                  $26.00 per ADS and $15 million from the completion of the
                  concurrent sale of an additional 576,923 ADSs at the initial
                  offering price of $26.00 per ADS, for gross proceeds of
                  approximately $15.0 million, in a private placement to the
                  Bill &amp; Melinda Gates Foundation.
                </p>
                <p align="justify"><strong>Financial Results </strong></p>
                <p align="justify">
                  Basic and diluted loss per share was a £0.76 or $1.05 for the
                  quarter ended March 31, 2021 compared to an adjusted to £0.74
                  for the quarter ended March 31, 2020. Total operating loss for
                  the quarter was £31.9 million or $44.0 million compared to
                  £22.1 million for the same period last year, largely due to an
                  increase in employee costs associated with non-cash
                  share-based payment charges.
                </p>
                <p align="justify">
                  For the three months ended March 31, 2021, revenue from
                  collaboration agreements was unchanged at £8.3 million or
                  $11.4 million compared to the same period last year. For the
                  three months ended March 31, 2021, research and development
                  expenses were £19.9 million or $27.4 million, as compared to
                  £20.8 million for the three months ended March 31, 2020. For
                  the quarter, administrative expenses were £20.2 million or
                  $27.8 million compared to £9.6 million for the quarter ended
                  March 31, 2020 including a £7.7 million increase in the
                  non-cash share-based payment charges.
                </p>
                <p align="justify">
                  Cash and cash equivalents totaled £313.1 million or
                  approximately $431 million as of March 31, 2021 compared to
                  £68.4 million for the same period last year.
                </p>
                <p align="center">##</p>
                <p align="justify"><strong>About Immunocore</strong></p>
                <p>
                  Immunocore is a late-stage biotechnology company pioneering
                  the development of a novel class of TCR bispecific
                  immunotherapies called ImmTAX – Immune mobilizing monoclonal
                  TCRs Against X disease – designed to treat a broad range of
                  diseases, including cancer, infectious and autoimmune.
                  Leveraging its proprietary, flexible, off-the-shelf ImmTAX
                  platform, Immunocore is developing a deep pipeline in multiple
                  therapeutic areas, including five clinical stage programs in
                  oncology and infectious disease, advanced pre-clinical
                  programs in autoimmune disease and multiple earlier
                  pre-clinical programs. Immunocore’s most advanced oncology
                  therapeutic candidate, tebentafusp, has demonstrated an
                  overall survival benefit in a randomized Phase 3 clinical
                  trial in metastatic uveal melanoma, a cancer that has
                  historically proven to be insensitive to other
                  immunotherapies.
                </p>
                <p align="justify">
                  <strong>Forward Looking Statements</strong>
                </p>
                <p>
                  This press release contains “forward-looking statements”
                  within the meaning of the Private Securities Litigation Reform
                  Act of 1995, including, but are not limited to, statements
                  regarding the Company’s business strategy including its
                  proposed regulatory plans for tebentafusp, the efficacy,
                  safety and therapeutic potential of tebentafusp, the planned
                  submission of a BLA submission for tebentafusp for the
                  treatment of mUM, the potential approval and commercial launch
                  of tebentafusp for mUM, the design, progress, timing, scope
                  and results of the Company’s clinical trials including
                  IMC-C103C, IMC-F106C and IMC-I109V, the potential benefit of
                  Breakthrough Therapy Designation or Orphan Drug Designation
                  for tebentafusp, and the Company’s anticipated cash runway.
                  Any forward-looking statements are based on management’s
                  current expectations of future events and are subject to a
                  number of risks and uncertainties that could cause actual
                  results to differ materially and adversely from those set
                  forth in or implied by such forward-looking statements, many
                  of which are beyond the Company’s control. These risks and
                  uncertainties include, but are not limited to, the impacts of
                  the COVID-19 pandemic on the Company’s business, clinical
                  trials and financial position; unexpected safety or efficacy
                  data observed during preclinical studies or clinical trials;
                  clinical trial site activation or enrollment rates that are
                  lower than expected; changes in expected or existing
                  competition; changes in the regulatory environment; and the
                  uncertainties and timing of the regulatory approval process.
                  For a discussion of other risks and uncertainties, and other
                  important factors, any of which could cause our actual results
                  to differ from those contained in the forward-looking
                  statements, see the section titled “Risk Factors” in the
                  Company’s Annual Report on Form 20-F for the year ended
                  December 31, 2021 filed with the Securities and Exchange
                  Commission on March 25, 2021, as well as discussions of
                  potential risks, uncertainties, and other important factors in
                  the Company’s subsequent filings with the Securities and
                  Exchange Commission. All information in this press release is
                  as of the date of the release, and the Company undertakes no
                  duty to update this information, except as required by law.
                </p>
                <p><strong>CONTACT:</strong></p>
                <p>
                  <strong>Immunocore </strong><br />Debra Nielsen, Head of
                  Communications<br />T: +1 (610) 368-8602<br />E:
                  info@immunocore.us <br />Follow on Twitter:
                  @Immunocore
                </p>
                <p>
                  <strong
                    >Consilium Strategic Communications (corporate and
                    financial)</strong
                  ><br />Mary-Jane Elliott/ Chris Welsh/ Jessica Hodgson
                  <br />T: +44 (0)203 709 5700<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  >
                </p>
                <p>
                  <strong>Investor Relations </strong><br />Clayton Robertson,
                  Head of Investor Relations<br />T: +1 215-384-4781<br />E:
                  <a
                    href="mailto:info@immunocore.us"
                    rel="nofollow"
                    target="_blank"
                    >info@immunocore.us</a
                  >
                </p>
                <p><br /></p>
                <p>
                  <strong
                    >Comparison of the Three Months Ended March 31, 2021 and
                    2020</strong
                  >
                </p>
                <p>
                  <strong><br /></strong>
                </p>
                <p>
                  <strong>C</strong><strong>onsolidated </strong
                  ><strong>S</strong><strong>tatement of </strong
                  ><strong>L</strong><strong>oss for </strong><strong>E</strong
                  ><strong>ach </strong><strong>P</strong><strong>eriod </strong
                  ><strong>P</strong><strong>resented:</strong>
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 470.55pt;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      colspan="5"
                      style="
                        width: 287.2px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>Three Months Ended March 31</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="
                        width: 198.47px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                    <td
                      style="
                        width: 16.14px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 72.6px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>2020</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        border-top: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.54px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>$000</strong>
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>£000</strong>
                    </td>
                    <td
                      style="
                        width: 16.14px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 72.6px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>£000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      colspan="5"
                      style="
                        width: 287.2px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>(unaudited)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Revenue
                    </td>
                    <td
                      style="
                        width: 94.54px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      11,408
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      8,270
                    </td>
                    <td
                      style="
                        width: 16.14px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 72.6px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      8,255
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Research and development expenses
                    </td>
                    <td
                      style="
                        width: 94.54px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (27,431)
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (19,885)
                    </td>
                    <td
                      style="
                        width: 16.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 72.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (20,779)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Administrative expenses
                    </td>
                    <td
                      style="
                        width: 94.54px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (27,844)
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (20,184)
                    </td>
                    <td
                      style="
                        width: 16.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 72.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (9,605)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Net other operating (expense) / income
                    </td>
                    <td
                      style="
                        width: 94.54px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (113)
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (82)
                    </td>
                    <td
                      style="
                        width: 16.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 72.6px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      10
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Operating loss</strong>
                    </td>
                    <td
                      style="
                        width: 94.54px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(43,980)</strong>
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(31,881)</strong>
                    </td>
                    <td
                      style="
                        width: 16.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 72.6px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(22,119)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Finance income
                    </td>
                    <td
                      style="
                        width: 94.54px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      30
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      22
                    </td>
                    <td
                      style="
                        width: 16.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 72.6px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,383
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Finance costs
                    </td>
                    <td
                      style="
                        width: 94.54px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (2,565)
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,860)
                    </td>
                    <td
                      style="
                        width: 16.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 72.6px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,067)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Non-operating (expense) / income</strong>
                    </td>
                    <td
                      style="
                        width: 94.54px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(2,535)</strong>
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,838)</strong>
                    </td>
                    <td
                      style="
                        width: 16.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 72.6px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>316</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Loss before taxes</strong>
                    </td>
                    <td
                      style="
                        width: 94.54px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(46,515)</strong>
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(33,719)</strong>
                    </td>
                    <td
                      style="
                        width: 16.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 72.6px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(21,803)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Income tax credit
                    </td>
                    <td
                      style="
                        width: 94.54px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      6,457
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,681
                    </td>
                    <td
                      style="
                        width: 16.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 72.6px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      3,164
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 340.2px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Loss for the period</strong>
                    </td>
                    <td
                      style="
                        width: 94.54px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(40,058)</strong>
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(29,038)</strong>
                    </td>
                    <td
                      style="
                        width: 16.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 72.6px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(18,639)</strong>
                    </td>
                  </tr>
                </table>
                <p><br /></p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 470.7pt;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        width: 453.6px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 83.87px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>For the three</strong>
                      <strong>months ended</strong><br /><br /><strong
                        >March 31</strong
                      ><br /><strong>2021</strong>
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 74.4px;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>For the three</strong>
                      <strong>months ended</strong><br /><br /><strong
                        >March 31,</strong
                      ><br /><strong>2020</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 453.6px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Loss for the period (£000’s)</strong>
                    </td>
                    <td
                      style="
                        width: 83.87px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>(29,038)</strong>
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 74.4px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(18,639)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 453.6px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Basic and diluted weighted average number of shares
                    </td>
                    <td
                      style="
                        width: 83.87px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      38,451,332
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 74.4px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,263,027
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 453.6px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Basic and diluted loss per share (£) (1)</strong>
                    </td>
                    <td
                      style="
                        width: 83.87px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>(0.76)</strong>
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 74.4px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(0.74)</strong>
                    </td>
                  </tr>
                </table>
                <p>
                  <strong><br /></strong>
                </p>
                <p>
                  <strong>Condensed </strong
                  ><strong
                    >Consolidated Statement of Cash Flows for Each Period
                    Presented:
                  </strong>
                </p>
                <table
                  style="border-collapse: collapse; border-collapse: collapse"
                >
                  <tr>
                    <td
                      style="
                        width: 349.67px;
                        border-top: solid black 1pt;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      colspan="5"
                      style="
                        width: 277.07px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>Three Months Ended March 31,</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 349.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2021</strong>
                    </td>
                    <td
                      style="
                        width: 18.94px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.14px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2020</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 349.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$000</strong>
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£000</strong>
                    </td>
                    <td
                      style="
                        width: 18.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.14px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 349.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 18.87px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong> (unaudited)</strong>
                    </td>
                    <td
                      style="
                        width: 18.94px;
                        border-bottom: solid black 1pt;
                        text-align: left;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.14px;
                        border-top: solid black 1pt;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong> </strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 349.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Cash and cash equivalents at beginning of year
                    </td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      178,943
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      129,716
                    </td>
                    <td
                      style="
                        width: 18.94px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.14px;
                        border-top: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      73,966
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 349.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Net cash flows used in operating activities
                    </td>
                    <td
                      style="
                        width: 85.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (35,838)
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (25,979)
                    </td>
                    <td
                      style="
                        width: 18.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (30,518)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 349.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Net cash flows from / (used in) investing activities
                    </td>
                    <td
                      style="
                        width: 85.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      34
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25
                    </td>
                    <td
                      style="
                        width: 18.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (1,334)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 349.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Net cash flows from financing activities
                    </td>
                    <td
                      style="
                        width: 85.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      288,830
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      209,373
                    </td>
                    <td
                      style="
                        width: 18.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      26,149
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 349.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Net foreign exchange difference on cash held
                    </td>
                    <td
                      style="
                        width: 85.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (72)
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (52)
                    </td>
                    <td
                      style="
                        width: 18.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      114
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 349.67px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Cash and cash equivalents at end of period
                    </td>
                    <td
                      style="
                        width: 85.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>431,897</strong>
                    </td>
                    <td
                      style="
                        width: 18.87px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 85.07px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>313,083</strong>
                    </td>
                    <td
                      style="
                        width: 18.94px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 69.14px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>68,377</strong>
                    </td>
                  </tr>
                </table>
                <p>
                  <strong><br /></strong>
                </p>
                <p>
                  <strong
                    >Consolidated Statements of Financial Position for Each
                    Period Presented:
                  </strong>
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 470.1pt;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>March 31,</strong><br /><strong>2021</strong
                      ><br /><strong>£’000</strong>
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>December 31,</strong><br /><strong>2020</strong
                      ><br /><strong>£’000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Non-current assets</strong>
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Property, plant and equipment
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      12,321
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      13,754
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Right of use assets
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      22,742
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      23,093
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Investment in sub-lease
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      540
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      776
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Other non-current financial assets
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      3,812
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,410
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Deferred tax asset
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,213
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,230
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total non-current assets</strong>
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>41,628</strong>
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>44,263</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Current assets</strong>
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-top: double black 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-top: double black 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Trade and other receivables
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      8,821
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      10,280
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Tax receivable
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      17,615
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      12,935
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Cash and cash equivalents
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      313,083
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      129,716
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total current assets</strong>
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>339,519</strong>
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 94.4px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>152,931</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total assets</strong>
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>381,147</strong>
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: middle;
                      "
                    >
                      ​
                    </td>
                    <td
                      style="
                        width: 94.4px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>197,194</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Equity</strong>
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-top: double black 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-top: double black 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Share capital
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      88
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      64
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Share premium
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      211,286
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      -
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Foreign currency translation reserve
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      71
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      163
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Other reserves
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      386,167
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      386,167
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Share-based payment reserve
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      27,092
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      18,821
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Accumulated deficit
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (378,907)
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (349,869)
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total equity</strong>
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>245,797</strong>
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>55,346</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Non-current liabilities</strong>
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-top: double black 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-top: double black 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Interest-bearing loans and borrowings
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      36,437
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      36,654
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Deferred liabilities
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      19,225
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      24,868
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Lease liabilities
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,035
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,190
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Provisions
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      160
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      138
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total non-current liabilities</strong>
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>80,857</strong>
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>86,850</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Current liabilities</strong>
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-top: double black 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-top: double black 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Interest-bearing loans and borrowings
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      546
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      ---
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Trade and other payables
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      26,359
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,728
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Deferred liabilities
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,710
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      27,118
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Lease liabilities
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,764
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,043
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      Provisions
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      114
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      109
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total current liabilities</strong>
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>54,493</strong>
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-top: solid black 1pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>54,998</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total liabilities</strong>
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>135,350</strong>
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>141,848</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 406.4px;
                        text-align: justify;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>Total equity and liabilities</strong>
                    </td>
                    <td
                      style="
                        width: 110.27px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>381,147</strong>
                    </td>
                    <td
                      style="
                        width: 15.74px;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    ></td>
                    <td
                      style="
                        width: 94.4px;
                        border-top: double black 3pt;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>197,194</strong>
                    </td>
                  </tr>
                </table>
                <p><br /><br /></p>
                <p></p>
                <p></p>
                <img
                  class="__GNW8366DE3E__IMG"
                  src="https://www.globenewswire.com/newsroom/ti?nf=MTAwMDQ5NDUzNCM0MDA5Mzg5MjEjMjIwNjI1OA=="
                  width="1"
                  height="1"
                />
                <br /><img
                  class="__GNW8366DE3E__IMG"
                  src="https://ml-eu.globenewswire.com/release/track/1c366bec-bd16-4234-b852-e681fe7f02cd"
                  width="1"
                  height="1"
                  style="display: none"
                />
                <p class="spr-ir-news-article-date">Released May 12, 2021</p>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param:
      "immunocore-provides-business-update-and-reports-full-year-2020-financial-results",
    title: `Immunocore Provides Business Update and Reports Full Year 2020 Financial
      Results :: Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>News</h2>
                  </li>
                  <li class="active active-landing">
                    <a href="/investors/news/press-releases" target="_self"
                      >Press Releases</a
                    >
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self"
                      >Email Alerts</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article">
                <h1 class="article-heading">
                  Immunocore Provides Business Update and Reports Full Year 2020
                  Financial Results
                </h1>
                <div
                  class="related-documents-line hidden-print row justify-content-between align-items-center"
                >
                  <time datetime="2021-03-25T07:00:00" class="date">
                    March 25, 2021 7:00am EDT
                  </time>
                  <a
                    href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2021-03-25_Immunocore_Provides_Business_Update_and_Reports_66.pdf"
                    class="link pull-right"
                    title="PDF: Immunocore Provides Business Update and Reports Full Year 2020 Financial Results"
                    target="_blank"
                  >
                    <span class="eqicon-PDF"></span>Download as PDF
                  </a>
                </div>

                <div class="related-documents box hidden-print">
                  <div class="text">
                    <h2 class="related-docs-title">Related Documents</h2>
                    <div class="text">
                      <div class=" ">
                        <div>
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-21-009904/brhc10022122_20f.htm"
                                  title="20-F Filing Viewer"
                                  class="fancybox fancybox.iframe"
                                  rel="noopener"
                                  >20-F <span class="sr-only">Filing</span></a
                                >
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-21-009904/brhc10022122_20f.htm"
                                  title="20-F Filing Viewer"
                                  rel="noopener"
                                  class="link fancybox fancybox.iframe"
                                  ><span class="eqicon-HTML"></span>HTML</a
                                >
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-21-009904/0001140361-21-009904.pdf"
                                  title="20-F"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                  ><span class="eqicon-PDF"></span>PDF</a
                                >
                              </div>
                            </div>
                          </div>
                          <div class="result-line">
                            <div class="row justify-content-between">
                              <div class="col-auto file-title">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-21-009904/0001140361-21-009904-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  target="_blank"
                                  rel="noopener"
                                  >XBRL</a
                                >
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/financials-filings/sec-filings/content/0001140361-21-009904/0001140361-21-009904-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                  ><span class="eqicon-ZIP"></span>ZIP</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p><br /></p>
                <p align="center"></p>
                <p><br /></p>
                <p align="center">
                  <em
                    ><strong
                      >Immunocore Provides Business Update and Reports Full Year
                      2020 Financial Results
                    </strong></em
                  >
                </p>
                <p align="center">
                  <em
                    ><em
                      >Breakthrough Therapy Designation granted by the FDA for
                      tebentafusp in unresectable or metastatic uveal
                      melanoma</em
                    ><br /><br />
                    <em
                      >Tebentafusp Phase 3 randomized trial data subject of an
                      oral presentation at <br />
                      the American Association for Cancer Research (AACR) 2021
                      Annual Meeting</em
                    ></em
                  >
                </p>
                <p align="center">
                  <em
                    ><em
                      >Cash position of $177 million as of December 31, 2020
                      with an additional $287 million in net proceeds from
                      initial public offering and concurrent private placement
                      in February 2021</em
                    ></em
                  >
                </p>
                <p align="justify">
                  <em
                    >(OXFORDSHIRE, England &amp; CONSHOHOCKEN, Penn. &amp;
                    ROCKVILLE, Md., US, 25 March 2021)
                    <a
                      href="https://www.globenewswire.com/Tracker?data=ri3upA94elA86O_VRBKY25e_nZKi3zhj2e1aEi0eEDKhiUxwy3yw3b7IvJ0VCVdN_TPcEXhTCPzdpYyLhzC4DQ=="
                      rel="nofollow"
                      target="_blank"
                      >Immunocore</a
                    >
                    (Nasdaq: IMCR), a late-stage biotechnology company
                    pioneering the development of a novel class of T cell
                    receptor (TCR) bispecific immunotherapies designed to treat
                    a broad range of diseases, including cancer, infectious and
                    autoimmune disease, today announced its results for the full
                    year ended December 31, 2020.</em
                  >
                </p>
                <p>
                  <em
                    ><strong
                      >2020 Highlights (including post-period)
                    </strong></em
                  >
                </p>
                <ul type="disc">
                  <li>
                    <em
                      >Lead product candidate tebentafusp demonstrated superior
                      overall survival (OS) in a Phase 3 randomized clinical
                      trial in metastatic uveal melanoma (mUM) where the OS
                      Hazard Ratio (HR) in the intent-to-treat population
                      favored tebentafusp, HR=0.51 (95% CI: 0.36, 0.71).
                      Tebentafusp was granted Breakthrough Therapy Designation
                      by the U.S. Food and Drug Administration (FDA) for
                      unresectable or metastatic uveal melanoma, and the Company
                      anticipates completion of the submission of a Biologics
                      License Application (BLA) in the third quarter of 2021.
                      <br />
                    </em>
                  </li>
                  <li>
                    <em
                      >Continued development of ImmTAC (Immune mobilizing
                      monoclonal T-cell receptors Against Cancer) clinical
                      portfolio for multiple tumor types; IMC-C103C is currently
                      in the dose escalation phase of a Phase 1 clinical trial
                      in MAGE-A4 expressing solid tumors, with initial data
                      expected to be presented in the second half of 2021;
                      IMC-F106C is currently in a Phase 1 study in patients with
                      PRAME-expressing solid tumors, with initial data expected
                      to be presented mid-year 2022. <br />
                    </em>
                  </li>
                  <li>
                    <em
                      >Dosing in a Phase 1 clinical trial for patients with
                      chronic hepatitis B virus (HBV) is anticipated for
                      mid-year 2021. <br />
                    </em>
                  </li>
                  <li>
                    <em
                      >Cash position of $177 million as of December 31, 2020
                      with an additional $287 million in net proceeds raised
                      from the Company’s initial public offering and a
                      concurrent private placement in February 2021.</em
                    >
                  </li>
                </ul>
                <p align="justify">
                  <em>
                    <br /><strong
                      >Bahija Jallal, Chief Executive Officer of Immunocore,
                      said:</strong
                    >
                    <em
                      >“Reflecting on 2020, we have made great strides in the
                      clinical advancement of TCR therapeutics. We believe the
                      tebentafusp clinical data represent the first positive
                      Phase 3 clinical trial for a TCR therapeutic and the first
                      bispecific immune-oncology therapy with demonstrated
                      overall survival advantage in any solid tumor. These
                      results were a culmination of disciplined work by the
                      Immunocore team and strong support by our investors. Our
                      initial public offering in February enables us to
                      accelerate the development of our pipeline of TCR
                      therapeutics and the planned BLA submission of our lead
                      candidate tebentafusp in patients with uveal melanoma, as
                      well as begin early commercialization activities assuming
                      regulatory approval.”
                    </em></em
                  >
                </p>
                <p align="justify">
                  <em><strong>Key Pipeline Updates </strong></em>
                </p>
                <p align="justify">
                  <em
                    ><strong><em>Tebentafusp</em></strong></em
                  >
                </p>
                <p align="justify">
                  <em
                    >In November 2020, the Company announced tebentafusp
                    achieved the primary endpoint of superior overall survival
                    compared to investigator’s choice in a randomized Phase 3
                    clinical trial (IMCgp100-202) in previously untreated
                    metastatic uveal melanoma, a cancer that has historically
                    proven to be insensitive to chemotherapy and other
                    immunotherapies. The 378-patient study was unblinded by an
                    independent data monitoring committee at the first
                    pre-planned interim analysis when the OS Hazard Ratio (HR)
                    in the intent-to-treat population favored tebentafusp,
                    HR=0.51 (95% CI: 0.36, 0.71); p&lt; 0.0001, over
                    investigator’s choice (82% pembrolizumab; 12% ipilimumab; 6%
                    dacarbazine). Immunocore will be working with the FDA to
                    facilitate complete submission of a BLA for tebentafusp in
                    the third quarter of 2021, followed by Marketing
                    Authorization Application submission to the European
                    Medicines Agency (EMA).</em
                  >
                </p>
                <p align="justify">
                  <em
                    >In February 2021, tebentafusp was granted Breakthrough
                    Therapy Designation by the FDA for unresectable or
                    metastatic uveal melanoma. Additionally, in February 2021,
                    the European Commission, upon recommendation of the EMA’s
                    Committee for Orphan Medicinal Products (COMP) awarded
                    tebentafusp Orphan Drug Designation for the treatment of
                    uveal melanoma. Medicines that meet the EMA’s Orphan Drug
                    Designation criteria qualify for several incentives,
                    including ten years of market exclusivity, protocol
                    assistance, and potentially reduced fees for regulatory
                    activities.</em
                  >
                </p>
                <p align="justify">
                  <em
                    >In March 2021, the Company announced that Phase 3 data from
                    IMCgp100-202 Phase 3 clinical trial would be the subject of
                    an oral presentation in the Phase 3 clinical trials plenary
                    session at the AACR Annual Meeting 2021 which will be held
                    virtually on April 10, 2021.</em
                  >
                </p>
                <p align="justify">
                  <em
                    ><strong><em>Additional Clinical Programs </em></strong></em
                  >
                </p>
                <p align="justify">
                  <em><em>IMC-C103C - MAGE-A4</em></em>
                </p>
                <p align="justify">
                  <em
                    >As of year-end 2020, 21 patients had been dosed in the dose
                    escalation portion of the IMC-C103C Phase 1/2 clinical trial
                    in patients with solid tumors. Early pharmacodynamics data
                    indicate that IMC-C103C monotherapy is demonstrating
                    biological activity at the doses currently under evaluation.
                    The Company plans to present Phase 1 data from this trial in
                    the second half of 2021.</em
                  >
                </p>
                <p align="justify">
                  <em
                    >The Company believes that IMC-C103C is the only clinical
                    stage off-the-shelf therapy candidate in development against
                    MAGE-A4—an X-chromosome-linked cancer/testis protein that is
                    broadly expressed across a range of cancer indications,
                    including non-small-cell lung cancer, among others.
                    IMC-C103C is part of a co-development/co-promotion
                    collaboration with Genentech under which Immunocore shares
                    program costs and profits equally.</em
                  >
                </p>
                <p align="justify">
                  <em><em>IMC-F106C – PRAME</em></em>
                </p>
                <p align="justify">
                  <em
                    >As of year-end 2020, nine patients had been dosed in the
                    dose escalation portion of the IMC-F106C Phase 1/2 clinical
                    trial. The trial is designed to study the safety and
                    preliminary activity of IMC-F106C as a monotherapy in
                    patients with PRAME-expressing solid tumors. The Company
                    plans to report initial Phase 1 data from this trial in
                    mid-2022.</em
                  >
                </p>
                <p align="justify">
                  <em
                    >IMC-F106C is an ImmTAC targeting a PRAME derived peptide
                    presented by HLA-A*02:01 and is the first off-the-shelf
                    therapeutic targeting PRAME. PRAME has the highest
                    expression frequency of all cancer/testis antigens across a
                    range of solid and hematologic cancers, notably
                    non-small-cell lung cancer, and its expression is generally
                    identified as a poor prognostic feature. Immunocore retains
                    full rights to IMC-F106C.</em
                  >
                </p>
                <p align="justify">
                  <em><em>IMC-I109V – HBV</em></em>
                </p>
                <p align="justify">
                  <em
                    >In August 2020, the Company announced the publication of
                    novel therapeutic approach with the potential to provide a
                    functional cure for chronic hepatitis B, in leading
                    peer-reviewed journal, <em>Hepatology.</em> These data
                    support on-target efficacy of the lead HBV ImmTAV against
                    HBV-infected hepatocytes. The Company plans to initiate
                    dosing in the single ascending dosing portion of the trial
                    in mid-2021.</em
                  >
                </p>
                <p align="justify">
                  <em><em>IMC-M113V – HIV</em></em>
                </p>
                <p align="justify">
                  <em
                    >In 2020, the Company advanced IMC-M113V through GMP
                    manufacturing and IND supporting pre-clinical studies for
                    human immunosuppression virus (HIV). The Company’s HIV
                    programs are funded by the Bill &amp; Melinda Gates
                    Foundation, and regulatory submission to enable clinical
                    testing is anticipated in the second half of 2021.</em
                  >
                </p>
                <p align="justify">
                  <em><em>GSK-01– NY-ESO</em></em>
                </p>
                <p align="justify">
                  <em
                    >The GSK-01 NY-ESO Phase 1 dose escalation study to
                    determine safety, and which is enrolling several different
                    tumor types, is still ongoing. An expansion phase was
                    planned to initiate once the Phase 1 dose escalation was
                    complete. However, following a portfolio review, Immunocore,
                    in collaboration with GSK, have jointly elected not to plan
                    for or initiate the efficacy determining expansion phase of
                    the trial. The expansion arm was planned to be conducted in
                    synovial sarcoma, an ultra-rare disease which is already
                    addressed by other assets in the Company’s portfolio
                    including MAGE-A4 and PRAME. Consequently, GSK has forgone
                    their option to acquire an exclusive license to the NY-ESO
                    program and Immunocore will retain ownership of the asset.
                    Immunocore plans to present the data from the Phase 1 study
                    in 2022.</em
                  >
                </p>
                <p align="justify">
                  <em><strong>Corporate Updates</strong></em>
                </p>
                <p align="justify">
                  <em
                    ><strong
                      ><em
                        >Fundraising and initial public offering on Nasdaq</em
                      ></strong
                    ></em
                  >
                </p>
                <p align="justify">
                  <em
                    >In February 2021, the Company raised $312.1 million in
                    aggregate financing and approximately $287 million in net
                    proceeds from its initial public offering on Nasdaq of
                    11,426,280 American Depositary Shares (ADSs), including the
                    exercise in full by the underwriters of their option to
                    purchase an additional 1,490,384 ADSs, at an initial public
                    offering price of $26.00 per ADS. In addition to the ADSs
                    sold in the public offering, Immunocore announced the
                    completion of the concurrent sale of an additional 576,923
                    ADSs at the initial offering price of $26.00 per ADS, for
                    gross proceeds of approximately $15 million, in a private
                    placement to the Bill &amp; Melinda Gates Foundation.</em
                  >
                </p>
                <p align="justify">
                  <em
                    >In December 2020, the Company completed a $75 million
                    Series C private financing round led by existing and new
                    investors.</em
                  >
                </p>
                <p align="justify">
                  <em
                    >In November 2020, the Company closed a $100 million senior
                    secured loan facility with Oxford Finance LLC.
                  </em>
                </p>
                <p align="justify">
                  <em
                    >In March 2020, the Company completed a $130 million Series
                    B private financing round.</em
                  >
                </p>
                <p align="justify">
                  <em><strong>Financial Results </strong></em>
                </p>
                <p align="justify">
                  <em
                    >Cash and cash equivalents at December 31, 2020 totaled $177
                    million, before including the $287 million in net proceeds
                    from the initial public offering and concurrent private
                    placement in February 2021. This compared to $97 million at
                    December 31, 2019.</em
                  >
                </p>
                <p align="justify">
                  <em
                    >Revenue for the year ended December 31, 2020 from
                    collaboration agreements was £30.1 million compared to £25.7
                    million for the year ended December 31, 2019.</em
                  >
                </p>
                <p align="justify">
                  <em
                    >For the year ended December 31, 2020, our research and
                    development expenses were £74.8 million compared to £100.0
                    million for the year ended December 31, 2019. For the year
                    ended December 31, 2020, administrative expenses were £45.7
                    million, compared to £44.2 million for the year ended
                    December 31, 2019. Our loss for the year ending December 31,
                    2020 was £74.1 million, compared to million £103.9 million
                    for the year ended December 31, 2019.</em
                  >
                </p>
                <p align="justify">
                  <em
                    >The Company anticipates that its existing cash and cash
                    equivalents, together with the net proceeds from its initial
                    public offering and its debt facility, is sufficient to
                    enable the Company to fund planned operating expenses and
                    capital expenditure requirements through at the least the
                    end of 2022.</em
                  >
                </p>
                <p align="center"><em>##</em></p>
                <p align="justify">
                  <em><strong>About Immunocore </strong></em>
                </p>
                <p align="justify">
                  <em
                    >Immunocore is a late-stage biotechnology company pioneering
                    the development of a novel class of TCR bispecific
                    immunotherapies called ImmTAX – Immune mobilizing monoclonal
                    TCRs Against X disease – designed to treat a broad range of
                    diseases, including cancer, infectious and autoimmune.
                    Leveraging its proprietary, flexible, off-the-shelf ImmTAX
                    platform, Immunocore is developing a deep pipeline in
                    multiple therapeutic areas, including five clinical stage
                    programs in oncology and infectious disease, advanced
                    pre-clinical programs in autoimmune disease and multiple
                    earlier pre-clinical programs. Immunocore’s most advanced
                    oncology therapeutic candidate, tebentafusp, has
                    demonstrated an overall survival benefit in a randomized
                    Phase 3 clinical trial in metastatic uveal melanoma, a
                    cancer that has historically proven to be insensitive to
                    other immunotherapies.</em
                  >
                </p>
                <p align="justify">
                  <em
                    ><strong>Forward Looking Statements</strong><br />This press
                    release contains “forward-looking statements” within the
                    meaning of the Private Securities Litigation Reform Act of
                    1995, including, but are not limited to, statements
                    regarding the efficacy, safety and therapeutic potential of
                    tebentafusp, the design, progress, timing, scope and results
                    of the Company’s trials including IMCgp100-202, the
                    anticipated timing of disclosure of results of clinical
                    trials, plans for initiating future clinical trials and
                    extension studies, the Company’s development programs
                    including the discovery and development of new product
                    candidates, the potential benefit of Breakthrough Therapy
                    Designation or Orphan Drug Designation for tebentafusp, the
                    timing of regulatory filings including estimates regarding
                    the planned submission a BLA for tebentafusp, the likelihood
                    of obtaining regulatory approval of any of the Company’s
                    product candidates including tebentafusp, and the regulatory
                    approval path for tebentafusp. Any forward-looking
                    statements are based on management’s current expectations of
                    future events and are subject to a number of risks and
                    uncertainties that could cause actual results to differ
                    materially and adversely from those set forth in or implied
                    by such forward-looking statements, many of which are beyond
                    the Company’s control. These risks and uncertainties
                    include, but are not limited to, the impacts of the COVID-19
                    pandemic on the Company’s business, clinical trials and
                    financial position; unexpected safety or efficacy data
                    observed during preclinical studies or clinical trials;
                    clinical trial site activation or enrollment rates that are
                    lower than expected; changes in expected or existing
                    competition; changes in the regulatory environment; and the
                    uncertainties and timing of the regulatory approval process.
                    For a discussion of other risks and uncertainties, and other
                    important factors, any of which could cause our actual
                    results to differ from those contained in the
                    forward-looking statements, see the section titled “Risk
                    Factors” in the Company’s final prospectus dated February 4,
                    2021 filed with the Securities and Exchange Commission
                    pursuant to Rule 424(b)(4) on February 8, 2021, as well as
                    discussions of potential risks, uncertainties, and other
                    important factors in the Company’s subsequent filings with
                    the Securities and Exchange Commission. All information in
                    this press release is as of the date of the release, and the
                    Company undertakes no duty to update this information,
                    except as required by law.</em
                  >
                </p>
                <p>
                  <em
                    ><strong>CONTACT:</strong> <br /><strong>Immunocore </strong
                    ><br />Debra Nielsen, Head of Communications<br />T: +1
                    (610) 368-8602<br />E: info@immunocore.us
                    <br />Follow on Twitter: @Immunocore</em
                  >
                </p>
                <p>
                  <em
                    ><strong
                      >Consilium Strategic Communications (corporate and
                      financial)</strong
                    ><br />Mary-Jane Elliott/ Chris Welsh/ Sukaina Virji
                    <br />T: +44 (0)203 709 5700<br />E:
                    <a
                      href="mailto:info@immunocore.us"
                      rel="nofollow"
                      target="_blank"
                      >info@immunocore.us</a
                    ></em
                  >
                </p>
                <p>
                  <em
                    ><strong>Investor Relations <br /> </strong>Clayton
                    Robertson, Head of Investor Relations<br />T: +1
                    215-384-4781<br />E:
                    <a
                      href="mailto:info@immunocore.us"
                      rel="nofollow"
                      target="_blank"
                      >info@immunocore.us</a
                    ></em
                  >
                </p>
                <p align="center">
                  <em
                    ><strong
                      >Immunocore Limited Annual report and consolidated
                      financial statements</strong
                    ><br /><strong>December 31, 2020</strong></em
                  >
                </p>
                <p>
                  <em
                    ><strong
                      >Consolidated Statements of Financial Position as at
                      December 31,</strong
                    ></em
                  >
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 90%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2020</strong><br /><strong>£’000</strong>
                    </td>
                    <td style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>2019</strong><br /><strong>£’000</strong>
                    </td>
                    <td style="vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top">
                      <strong>Non-current assets</strong>
                    </td>
                    <td style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td colspan="2" style="vertical-align: top"></td>
                    <td style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td colspan="2" style="vertical-align: top"></td>
                    <td style="vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Property, plant and equipment
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      13,754
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      18,302
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Right of use assets
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      23,093
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      36,578
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Investment in sub-lease
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      776
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      591
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Other non-current financial assets
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,410
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,390
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Deferred tax asset
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,230
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,507
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      <strong>Total non-current assets</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>44,263</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>61,368</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      <strong>Current assets</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Trade and other receivables
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      10,280
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      9,639
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Tax receivable
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      12,935
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      40,410
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Embedded derivative assets
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      -
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      266
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Cash and cash equivalents
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      129,716
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      73,966
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      <strong>Total current assets</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>152,931</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>124,281</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      <strong>Total assets</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>197,194</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>185,649</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      <strong>Equity</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Share capital
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      -
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Share premium
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      386,230
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      283,250
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Foreign currency translation reserve
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      163
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (32
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom">)</td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Share-based payment reserve
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      18,821
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      10,659
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Accumulated deficit
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (349,869
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom">)</td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (279,106
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom">)</td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      <strong>Total equity</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>55,346</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>14,771</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      <strong>Non-current liabilities</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Interest-bearing loans and borrowings
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      36,654
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      -
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Deferred liabilities
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      24,868
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      47,961
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Lease liabilities
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,190
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      38,299
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Provisions
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      138
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      105
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      <strong>Total non-current liabilities</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>86,850</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>86,365</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      <strong>Current liabilities</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Interest-bearing loans and borrowings
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      -
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      19,157
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Trade and other payables
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,728
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      29,501
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Deferred liabilities
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      27,118
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      28,522
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Tax payable
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      -
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      72
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Lease liabilities
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,043
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,951
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Derivative liabilities
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      -
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      5,127
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      Provisions
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      109
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      183
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      <strong>Total current liabilities</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>54,998</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>84,513</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      <strong>Total liabilities</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>141,848</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>170,878</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 71.12%; vertical-align: top">
                      <strong>Total equity and liabilities</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>197,194</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1.1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 10%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>185,649</strong>
                    </td>
                    <td style="width: 1.1%; vertical-align: bottom"></td>
                  </tr>
                </table>
                <p align="center">
                  <em
                    ><strong>Immunocore Limited</strong><br /><strong
                      >Annual report and consolidated financial
                      statements</strong
                    ><br /><strong>December 31, 2020</strong></em
                  >
                </p>
                <p>
                  <em>
                    <strong
                      >Consolidated Statements of Loss and Other Comprehensive
                      Income</strong
                    ><br /><strong>
                      for the years ended December 31,</strong
                    ></em
                  >
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 91.14%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td></td>
                    <td style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td
                      colspan="4"
                      style="text-align: center; vertical-align: middle"
                    >
                      <strong>2020</strong>
                    </td>
                    <td colspan="2" style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td
                      colspan="5"
                      style="text-align: center; vertical-align: middle"
                    >
                      <strong>2019</strong>
                    </td>
                    <td colspan="2" style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td
                      colspan="5"
                      style="text-align: center; vertical-align: middle"
                    >
                      <strong>2018</strong>
                    </td>
                    <td colspan="2" style="vertical-align: bottom"></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>’000</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>’000</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong>
                    </td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>’000</strong>
                    </td>
                    <td colspan="2" style="vertical-align: bottom"></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">Revenue</td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.52%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      30,114
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      25,669
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      23,654
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">
                      <strong>Total revenue</strong>
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>30,114</strong>
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>25,669</strong>
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>23,654</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">Net other operating income</td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,242
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      185
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      622
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">
                      Research and development costs
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (74,809
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    >
                      )
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (99,991
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    >
                      )
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (83,575
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    >
                      )
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">Administrative expenses</td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.52%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (45,740
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      )
                    </td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (44,183
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      )
                    </td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (34,156
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    >
                      )
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">
                      <strong>Operating loss</strong>
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(86,193</strong>
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    >
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(118,320</strong>
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    >
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(93,455</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    >
                      <strong>)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">Other income</td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      4,979
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">Finance income</td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      2,208
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,510
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      1,140
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">Finance costs</td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.52%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (3,375
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      )
                    </td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (9,379
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      )
                    </td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (842
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    >
                      )
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">
                      <strong>Non-operating (expense) / income</strong>
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.52%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(1,167</strong>
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>)</strong>
                    </td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(7,869</strong>
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>)</strong>
                    </td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>5,277</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">
                      <strong>Loss before taxation</strong>
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(87,360</strong>
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    >
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(126,189</strong>
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    >
                      <strong>)</strong>
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(88,178</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    >
                      <strong>)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">Income tax credit</td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.52%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      13,267
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      22,258
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      16,548
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">
                      <strong>Loss for the year</strong>
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.52%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(74,093</strong>
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>)</strong>
                    </td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(103,931</strong>
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>)</strong>
                    </td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(71,630</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    >
                      <strong>)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">
                      <strong>Other comprehensive (expense) / income</strong>
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">
                      Exchange differences on translation of foreign operations
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      195
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (99
                    </td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    >
                      )
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      72
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">
                      Income tax effect relating to the components of other
                      comprehensive income
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.52%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      —
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      3,634
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">
                      <strong
                        >Total other comprehensive (expense) / income for the
                        year, net of tax</strong
                      >
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.52%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>195</strong>
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(99</strong>
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>)</strong>
                    </td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>3,706</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.52%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="3"
                      style="width: 2.58%; vertical-align: bottom"
                    ></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="width: 4.04%; vertical-align: bottom"
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    ></td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">
                      <strong
                        >Total comprehensive loss for the year, net of
                        tax</strong
                      >
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.52%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(73,898</strong>
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>)</strong>
                    </td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(104,030</strong>
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>)</strong>
                    </td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(67,924</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    >
                      <strong>)</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 47.66%">
                      <strong>Basic and diluted loss per share - £</strong>
                    </td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td style="width: 0.74%; vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.52%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(2.79</strong>
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>)</strong>
                    </td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 10.7%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(4.66</strong>
                    </td>
                    <td
                      colspan="3"
                      style="
                        width: 2.58%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>)</strong>
                    </td>
                    <td
                      style="
                        width: 0.74%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 4.04%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      colspan="2"
                      style="
                        width: 9.22%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>(3.32</strong>
                    </td>
                    <td
                      colspan="2"
                      style="width: 1.02%; vertical-align: bottom"
                    >
                      <strong>)</strong>
                    </td>
                  </tr>
                </table>
                <p>
                  <em
                    ><strong><em>Cash Flows</em></strong></em
                  >
                </p>
                <p>
                  <em
                    >The following table summarizes the primary sources and uses
                    of cash for each period presented:</em
                  >
                </p>
                <table
                  style="
                    border-collapse: collapse;
                    width: 100%;
                    border-collapse: collapse;
                  "
                >
                  <tr>
                    <td></td>
                    <td style="vertical-align: bottom"></td>
                    <td
                      colspan="14"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>Year ended December 31,</strong>
                    </td>
                    <td style="vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td>​</td>
                    <td style="vertical-align: bottom"></td>
                    <td
                      colspan="6"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>2020</strong>
                    </td>
                    <td style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                      "
                    >
                      <strong>2019</strong>
                    </td>
                    <td style="vertical-align: bottom"></td>
                    <td style="vertical-align: bottom"></td>
                    <td
                      colspan="2"
                      style="
                        border-bottom: solid black 1pt;
                        text-align: center;
                        vertical-align: middle;
                        vertical-align: top;
                      "
                    >
                      <strong>2018</strong>
                    </td>
                    <td style="vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 52%"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>$</strong>
                    </td>
                    <td
                      style="
                        width: 9%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>000</strong>
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong>
                    </td>
                    <td
                      style="
                        width: 9%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>000</strong>
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong>
                    </td>
                    <td
                      style="
                        width: 9%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>000</strong>
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    >
                      <strong>£</strong>
                    </td>
                    <td
                      style="
                        width: 9%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      <strong>000</strong>
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 52%">
                      Cash and cash equivalents at beginning of the year
                    </td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      101,052
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      73,966
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      124,385
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      82,883
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 52%">
                      Net cash used in operating activities
                    </td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (82,756
                    </td>
                    <td style="width: 1%; vertical-align: bottom">)</td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (60,574
                    </td>
                    <td style="width: 1%; vertical-align: bottom">)</td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (101,376
                    </td>
                    <td style="width: 1%; vertical-align: bottom">)</td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (16,626
                    </td>
                    <td style="width: 1%; vertical-align: bottom">)</td>
                  </tr>
                  <tr>
                    <td style="width: 52%">
                      Net cash provided by / (used in) investing activities
                    </td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      638
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      467
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (4,137
                    </td>
                    <td style="width: 1%; vertical-align: bottom">)</td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      58,014
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 52%">
                      Net cash provided by financing activities
                    </td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      158,399
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      115,941
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      55,127
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 9%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      101
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 52%">Foreign exchange on cash held</td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 9%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (115
                    </td>
                    <td style="width: 1%; vertical-align: bottom">)</td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 9%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (84
                    </td>
                    <td style="width: 1%; vertical-align: bottom">)</td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 9%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      (33
                    </td>
                    <td style="width: 1%; vertical-align: bottom">)</td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1%;
                        border-bottom: solid black 1pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 9%;
                        border-bottom: solid black 1pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      13
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                  </tr>
                  <tr>
                    <td style="width: 52%">
                      Cash and cash equivalents at end of the year
                    </td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 9%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      177,218
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 9%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      129,716
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 9%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      73,966
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                    <td
                      style="
                        width: 1%;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 1%;
                        border-bottom: double 15px 3pt;
                        vertical-align: bottom;
                      "
                    ></td>
                    <td
                      style="
                        width: 9%;
                        border-bottom: double 15px 3pt;
                        text-align: right;
                        vertical-align: middle;
                        vertical-align: bottom;
                      "
                    >
                      124,385
                    </td>
                    <td style="width: 1%; vertical-align: bottom"></td>
                  </tr>
                </table>
                <p><br /></p>
                <p></p>
                <br />
                <p>
                  <a
                    href="https://www.globenewswire.com/NewsRoom/AttachmentNg/bcaafaab-2536-4b4b-899c-9913925b6e4b"
                    ><img
                      src="https://ml-eu.globenewswire.com/media/bcaafaab-2536-4b4b-899c-9913925b6e4b/small/immunocore-logo-2018.png"
                      border="0"
                      width="150"
                      height="41"
                      alt="Primary Logo"
                  /></a>
                </p>
                Source: Immunocore Holdings Limited
                <p class="spr-ir-news-article-date">Released March 25, 2021</p>
              </article>
            </main>
          </div>
        </div>`,
  },
];

export const events = [
  {
    param: "20240606-jefferies-global-healthcare-conference",
    title: `Jefferies Global Healthcare Conference :: Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>Events &amp; Presentations</h2>
                  </li>
                  <li class="active active-landing">
                    <a
                      href="/investors/events-presentations/events"
                      target="_self"
                      >Events</a
                    >
                  </li>
                  <li class="">
                    <a
                      href="/investors/events-presentations/presentations"
                      target="_self"
                      >Presentations</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article full-event">
                <h2>Jefferies Global Healthcare Conference</h2>
                <div class="related-documents-line ir-calendar-date clearfix">
                  <time class="date" datetime="2024-06-06T14:30:00"
                    >Jun 6, 2024 2:30 pm EDT</time
                  >
                  <div class="clear"></div>
                </div>
                <div class="mt-4 quarterly-results">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="result-line">
                        <div class="row justify-content-between">
                          <div class="col-auto file-title">
                            <a
                              href="https://wsw.com/webcast/jeff302/register.aspx?conf=jeff302&amp;amp;page=imcr&amp;amp;url=https://wsw.com/webcast/jeff302/imcr/1838384"
                              target="_blank"
                              rel="noopener"
                              >Webcast</a
                            >
                          </div>
                          <div class="col file-links">
                            <a
                              href="https://wsw.com/webcast/jeff302/register.aspx?conf=jeff302&amp;amp;page=imcr&amp;amp;url=https://wsw.com/webcast/jeff302/imcr/1838384"
                              class="result-link link"
                              target="_blank"
                              rel="noopener"
                              ><span class="eqicon-MP3"></span>Audio</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param: "20240531-asco-2024",
    title: `ASCO 2024 :: Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>Events &amp; Presentations</h2>
                  </li>
                  <li class="active active-landing">
                    <a
                      href="/investors/events-presentations/events"
                      target="_self"
                      >Events</a
                    >
                  </li>
                  <li class="">
                    <a
                      href="/investors/events-presentations/presentations"
                      target="_self"
                      >Presentations</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article full-event">
                <h2>ASCO 2024</h2>
                <div class="related-documents-line ir-calendar-date clearfix">
                  <time class="date" datetime="2024-05-31T19:15:00"
                    >May 31, 2024 6:15 pm CDT</time
                  >
                  <div class="clear"></div>
                </div>
                <div class="mt-4 quarterly-results">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="result-line">
                        <div class="row justify-content-between">
                          <div class="col-auto file-title">
                            <a
                              href="https://www.webcaster4.com/Webcast/Page/359/50582"
                              target="_blank"
                              rel="noopener"
                              >Webcast</a
                            >
                          </div>
                          <div class="col file-links">
                            <a
                              href="https://www.webcaster4.com/Webcast/Page/359/50582"
                              class="result-link link"
                              target="_blank"
                              rel="noopener"
                              ><span class="eqicon-MP3"></span>Audio</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>`,
  },
  {
    param: "20240523-2024-annual-general-meeting",
    title: `2024 Annual General Meeting :: Immunocore Holdings plc (IMCR)`,
    html: `<div class="row justify-content-center">
          <div class="col-lg-12">
            <aside id="tertiaryNav">
              <nav id="tertiary-navigation">
                <ul>
                  <li class="sidebar-title">
                    <h2>Events &amp; Presentations</h2>
                  </li>
                  <li class="active active-landing">
                    <a
                      href="/investors/events-presentations/events"
                      target="_self"
                      >Events</a
                    >
                  </li>
                  <li class="">
                    <a
                      href="/investors/events-presentations/presentations"
                      target="_self"
                      >Presentations</a
                    >
                  </li>
                </ul>
              </nav>
            </aside>
            <main id="mainContent" class="main-content has-tertiary-nav">
              <article class="full-news-article full-event">
                <h2>2024 Annual General Meeting</h2>
                <div class="related-documents-line ir-calendar-date clearfix">
                  <time class="date" datetime="2024-05-23T09:30:00"
                    >May 23, 2024 9:30 am EDT</time
                  >
                  <div class="clear"></div>
                </div>
                <div class="mt-4 quarterly-results">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="result-line">
                        <div class="row justify-content-between">
                          <div class="col-auto file-title">
                            <a
                              href="https://apps.computershare.com/MeetingsShareholderWeb/Home?Code=IHCAGM2024&amp;Invitation=&amp;Locale=en"
                              target="_blank"
                              rel="noopener"
                              >Webcast</a
                            >
                          </div>
                          <div class="col file-links">
                            <a
                              href="https://apps.computershare.com/MeetingsShareholderWeb/Home?Code=IHCAGM2024&amp;Invitation=&amp;Locale=en"
                              class="result-link link"
                              target="_blank"
                              rel="noopener"
                              ><span class="eqicon-MP3"></span>Audio</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>`,
  },
];
