import React from "react";

const NewsMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <aside id="tertiaryNav">
            <nav id="tertiary-navigation">
              <ul>
                <li class="sidebar-title">
                  <h2>News</h2>
                </li>
                <li class="active active-landing">
                  <a href="/investors/news/press-releases" target="_self">
                    Press Releases
                  </a>
                </li>
                <li class="">
                  <a href="/investors/news/email-alerts" target="_self">
                    Email Alerts
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <main id="mainContent" class="main-content has-tertiary-nav">
            <article class="media">
              <div class="row">
                <div class="media-body col-md">
                  <div class="date">
                    <time datetime="2024-06-18T07:00:00">
                      Jun 18, 2024 7:00am EDT
                    </time>
                  </div>
                  <h2 class="media-heading">
                    <a href="/investors/news/press-releases/detail/immunocore-announces-randomization-of-first-patient-in-the-global-registrational-phase-3-clinical-trial-testing-brenetafusp-for-the-treatment-of-first-line-advanced-or-metastatic-cutaneous-melanoma">
                      Immunocore announces randomization of first patient in the
                      global, registrational Phase 3 clinical trial testing
                      brenetafusp for the treatment of first-line advanced or
                      metastatic cutaneous melanoma
                    </a>
                  </h2>
                </div>
              </div>
            </article>
            <article class="media">
              <div class="row">
                <div class="media-body col-md">
                  <div class="date">
                    <time datetime="2024-06-01T08:00:00">
                      Jun 01, 2024 8:00am EDT
                    </time>
                  </div>
                  <h2 class="media-heading">
                    <a href="/investors/news/press-releases/detail/immunocore-presents-kimmtrak-clinical-data-demonstrating-that-patients-with-stable-disease-and-confirmed-tumor-reduction-have-similar-clinical-outcomes-to-patients-with-partial-response">
                      Immunocore presents KIMMTRAK clinical data demonstrating
                      that patients with stable disease and confirmed tumor
                      reduction have similar clinical outcomes to patients with
                      partial response
                    </a>
                  </h2>
                </div>
              </div>
            </article>
            <article class="media">
              <div class="row">
                <div class="media-body col-md">
                  <div class="date">
                    <time datetime="2024-05-31T16:05:00">
                      May 31, 2024 4:05pm EDT
                    </time>
                  </div>
                  <h2 class="media-heading">
                    <a href="/investors/news/press-releases/detail/immunocore-reports-updated-phase-1-data-of-brenetafusp-imc-f106c-an-immtac-bispecific-targeting-prame-in-immune-checkpoint-pre-treated-cutaneous-melanoma-patients-at-asco-2024">
                      Immunocore reports updated Phase 1 data of brenetafusp
                      (IMC-F106C), an ImmTAC bispecific targeting PRAME, in
                      immune checkpoint pre-treated cutaneous melanoma patients
                      at ASCO 2024
                    </a>
                  </h2>
                </div>
              </div>
            </article>
            <article class="media">
              <div class="row">
                <div class="media-body col-md">
                  <div class="date">
                    <time datetime="2024-05-30T16:00:00">
                      May 30, 2024 4:00pm EDT
                    </time>
                  </div>
                  <h2 class="media-heading">
                    <a href="/investors/news/press-releases/detail/immunocore-to-present-at-the-jefferies-global-healthcare-conference">
                      Immunocore to present at the Jefferies Global Healthcare
                      Conference
                    </a>
                  </h2>
                </div>
              </div>
            </article>
            <article class="media">
              <div class="row">
                <div class="media-body col-md">
                  <div class="date">
                    <time datetime="2024-05-29T07:00:00">
                      May 29, 2024 7:00am EDT
                    </time>
                  </div>
                  <h2 class="media-heading">
                    <a href="/investors/news/press-releases/detail/immunocore-converts-phase-23-tebe-am-clinical-trial-into-registrational-phase-3-trial-evaluating-kimmtrak-for-previously-treated-advanced-cutaneous-melanoma">
                      Immunocore converts Phase 2/3 TEBE-AM clinical trial into
                      registrational Phase 3 trial evaluating KIMMTRAK for
                      previously treated advanced cutaneous melanoma
                    </a>
                  </h2>
                </div>
              </div>
            </article>
            <article class="media">
              <div class="row">
                <div class="media-body col-md">
                  <div class="date">
                    <time datetime="2024-05-08T07:00:00">
                      May 08, 2024 7:00am EDT
                    </time>
                  </div>
                  <h2 class="media-heading">
                    <a href="/investors/news/press-releases/detail/immunocore-reports-first-quarter-financial-results-and-provides-a-business-update">
                      Immunocore reports first quarter financial results and
                      provides a business update
                    </a>
                  </h2>
                </div>
              </div>
            </article>
            <article class="media">
              <div class="row">
                <div class="media-body col-md">
                  <div class="date">
                    <time datetime="2024-04-24T10:00:00">
                      Apr 24, 2024 10:00am EDT
                    </time>
                  </div>
                  <h2 class="media-heading">
                    <a href="/investors/news/press-releases/detail/immunocore-announces-upcoming-presentation-and-posters-at-asco-2024">
                      Immunocore announces upcoming presentation and posters at
                      ASCO 2024
                    </a>
                  </h2>
                </div>
              </div>
            </article>
            <article class="media">
              <div class="row">
                <div class="media-body col-md">
                  <div class="date">
                    <time datetime="2024-04-04T16:00:00">
                      Apr 04, 2024 4:00pm EDT
                    </time>
                  </div>
                  <h2 class="media-heading">
                    <a href="/investors/news/press-releases/detail/immunocore-to-present-at-upcoming-investor-conferences">
                      Immunocore to present at upcoming investor conferences
                    </a>
                  </h2>
                </div>
              </div>
            </article>
            <article class="media">
              <div class="row">
                <div class="media-body col-md">
                  <div class="date">
                    <time datetime="2024-03-05T16:30:00">
                      Mar 05, 2024 4:30pm EST
                    </time>
                  </div>
                  <h2 class="media-heading">
                    <a href="/investors/news/press-releases/detail/immunocore-presented-two-posters-at-croi-2024">
                      Immunocore presented two posters at CROI 2024
                    </a>
                  </h2>
                </div>
              </div>
            </article>
            <article class="media">
              <div class="row">
                <div class="media-body col-md">
                  <div class="date">
                    <time datetime="2024-03-01T07:00:00">
                      Mar 01, 2024 7:00am EST
                    </time>
                  </div>
                  <h2 class="media-heading">
                    <a href="/investors/news/press-releases/detail/immunocore-to-present-at-upcoming-investor-conferences">
                      Immunocore to present at upcoming investor conferences
                    </a>
                  </h2>
                </div>
              </div>
            </article>
            <div class="clear"></div>
            <div class="rss-link">
              <a
                href="https://www.immunocore.com/investors/news/press-releases/rss"
                class="link--icon"
                target="_blank"
                rel="noopener"
              >
                <span class="material-icons">rss_feed</span> News RSS
              </a>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default NewsMain;
