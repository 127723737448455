import React from "react";

const SitemapMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <main id="mainContent" class="main-content">
            <section class="sitemap">
              <h2>Immunocore Holdings plc Sitemap</h2>
              <p>
                Browse the links below for pages that make up the Immunocore
                Holdings plc website.
              </p>
              <div class="sitemap__links">
                <section>
                  <h3 id="heading-home" class="heading--h4">
                    <a href="/">Home</a>
                  </h3>
                </section>
                <section>
                  <h3 id="heading-2" class="heading--h4">
                    <a href="/about-us" target="_self">
                      {" "}
                      About Us{" "}
                    </a>
                  </h3>

                  <div class="flex-row flex-3-col"></div>
                </section>
                <section>
                  <h3 id="heading-108898" class="heading--h4">
                    <a href="/pipeline-portfolio" target="_self">
                      Pipeline &amp; Portfolio
                    </a>
                  </h3>

                  <div class="flex-row flex-3-col">
                    <div class="link-group">
                      <ul class="link-group__items">
                        <li>
                          <a href="/pipeline-portfolio/pipeline" target="_self">
                            Pipeline
                          </a>
                        </li>
                        <li>
                          <a
                            href="/pipeline-portfolio/our-therapies"
                            target="_self"
                          >
                            Our Therapies
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
                <section>
                  <h3 id="heading-108902" class="heading--h4">
                    <a href="/science" target="_self">
                      {" "}
                      Science{" "}
                    </a>
                  </h3>
                </section>
                <section>
                  <h3 id="heading-108905" class="heading--h4">
                    <a href="/clinical-trials" target="_self">
                      Clinical Trials
                    </a>
                  </h3>
                </section>
                <section>
                  <h3 id="heading-107607" class="heading--h4">
                    <a href="/investors" target="_self">
                      {" "}
                      Investors{" "}
                    </a>
                  </h3>

                  <div class="flex-row flex-3-col">
                    <div class="link-group">
                      <h4 id="heading-107610" class="heading--h5">
                        <a href="/investors/news" target="_self">
                          News
                        </a>
                      </h4>
                      <ul class="link-group__items">
                        <li>
                          <a
                            href="/investors/news/press-releases"
                            target="_self"
                          >
                            Press Releases
                          </a>
                        </li>
                        <li>
                          <a href="/investors/news/email-alerts" target="_self">
                            Email Alerts
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="link-group">
                      <h4 id="heading-107608" class="heading--h5">
                        <a
                          href="/investors/events-presentations"
                          target="_self"
                        >
                          Events &amp; Presentations
                        </a>
                      </h4>
                      <ul class="link-group__items">
                        <li>
                          <a
                            href="/investors/events-presentations/events"
                            target="_self"
                          >
                            Events
                          </a>
                        </li>
                        <li>
                          <a
                            href="/investors/events-presentations/presentations"
                            target="_self"
                          >
                            Presentations
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="link-group">
                      <h4 id="heading-107625" class="heading--h5">
                        <a href="/investors/stock-info" target="_self">
                          Stock Info
                        </a>
                      </h4>
                      <ul class="link-group__items">
                        <li>
                          <a
                            href="/investors/stock-info/quote-and-chart"
                            target="_self"
                          >
                            Quote and Chart
                          </a>
                        </li>
                        <li>
                          <a
                            href="/investors/stock-info/historical-performance"
                            target="_self"
                          >
                            Historical Performance
                          </a>
                        </li>
                        <li>
                          <a
                            href="/investors/stock-info/analyst-coverage"
                            target="_self"
                          >
                            Analyst Coverage
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="link-group">
                      <h4 id="heading-107619" class="heading--h5">
                        <a href="/investors/financials-filings" target="_self">
                          Financials &amp; Filings
                        </a>
                      </h4>
                      <ul class="link-group__items">
                        <li>
                          <a
                            href="/investors/financials-filings/financial-results"
                            target="_self"
                          >
                            Financial Results
                          </a>
                        </li>
                        <li>
                          <a
                            href="/investors/financials-filings/sec-filings"
                            target="_self"
                          >
                            SEC Filings
                          </a>
                        </li>
                        <li>
                          <a
                            href="/investors/financials-filings/annual-reports"
                            target="_self"
                          >
                            Annual Reports
                          </a>
                        </li>
                        <li>
                          <a
                            href="/investors/financials-filings/email-alerts"
                            target="_self"
                          >
                            Email Alerts
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="link-group">
                      <h4 id="heading-108927" class="heading--h5">
                        <a href="/investors/ir-resources" target="_self">
                          IR Resources
                        </a>
                      </h4>
                      <ul class="link-group__items">
                        <li>
                          <a
                            href="/investors/ir-resources/investor-faqs"
                            target="_self"
                          >
                            Investor FAQs
                          </a>
                        </li>
                        <li>
                          <a
                            href="/investors/ir-resources/email-alerts"
                            target="_self"
                          >
                            Email Alerts
                          </a>
                        </li>
                        <li>
                          <a
                            href="/investors/ir-resources/contacts"
                            target="_self"
                          >
                            Contacts
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
                <section>
                  <h3 class="heading--h4">Additional Pages</h3>
                  <ul class="link-group__items">
                    <li>
                      <a href="/disclaimer" target="_self">
                        {" "}
                        Disclaimer{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/sitemap" target="_self">
                        {" "}
                        Sitemap{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/compliance-and-transparency" target="_self">
                        Compliance and Transparency
                      </a>
                    </li>
                    <li>
                      <a
                        href="/privacy-and-social-media-guidelines"
                        target="_self"
                      >
                        Privacy and social media guidelines
                      </a>
                    </li>
                    <li>
                      <a href="/cookies" target="_self">
                        {" "}
                        Cookies{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/payments-and-disclosures" target="_self">
                        Payments and Disclosures
                      </a>
                    </li>
                    <li>
                      <a href="/clinical-trials" target="_self">
                        Clinical Trials
                      </a>
                    </li>
                    <li>
                      <a href="/modern-slavery-statement" target="_self">
                        Modern Slavery Statement
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  );
};

export default SitemapMain;
