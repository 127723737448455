import React from "react";

const InvestorMain = () => {
  return (
    <main id="mainContent">
      <section class="module module-latest-news slider-with-images module-latest-news-block module-blocks-with-images secondary-bg-gradient">
        <div class="container">
          <div class="module-header">
            <div class="row justify-content-between align-items-center">
              <div class="col-md-8">
                <div class="text">
                  <h2>Latest news</h2>
                </div>
              </div>
              <div class="col-auto">
                <div class="slider-nav-wrapper">
                  <div class="slider-nav"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="news-listing">
            <div class="content">
              <div class="news-slider">
                <a
                  href="/investors/news/press-releases/detail/immunocore-announces-randomization-of-first-patient-in-the-global-registrational-phase-3-clinical-trial-testing-brenetafusp-for-the-treatment-of-first-line-advanced-or-metastatic-cutaneous-melanoma"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)`,
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-06-18T07:00:00">
                            Jun. 18, 2024 • Time: 7:00 AM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore announces randomization of first patient in
                          the global, registrational Phase 3 clinical trial
                          testing brenetafusp for the treatment of first-line
                          advanced or metastatic cutaneous melanoma
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-presents-kimmtrak-clinical-data-demonstrating-that-patients-with-stable-disease-and-confirmed-tumor-reduction-have-similar-clinical-outcomes-to-patients-with-partial-response"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)`,
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-06-01T08:00:00">
                            Jun. 1, 2024 • Time: 8:00 AM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore presents KIMMTRAK clinical data
                          demonstrating that patients with stable disease and
                          confirmed tumor reduction have similar clinical
                          outcomes to patients with partial response
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-reports-updated-phase-1-data-of-brenetafusp-imc-f106c-an-immtac-bispecific-targeting-prame-in-immune-checkpoint-pre-treated-cutaneous-melanoma-patients-at-asco-2024"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)`,
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-05-31T16:05:00">
                            May. 31, 2024 • Time: 4:05 PM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore reports updated Phase 1 data of brenetafusp
                          (IMC-F106C), an ImmTAC bispecific targeting PRAME, in
                          immune checkpoint pre-treated cutaneous melanoma
                          patients at ASCO 2024
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-to-present-at-the-jefferies-global-healthcare-conference"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)`,
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-05-30T16:00:00">
                            May. 30, 2024 • Time: 4:00 PM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore to present at the Jefferies Global
                          Healthcare Conference
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-converts-phase-23-tebe-am-clinical-trial-into-registrational-phase-3-trial-evaluating-kimmtrak-for-previously-treated-advanced-cutaneous-melanoma"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)`,
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-05-29T07:00:00">
                            May. 29, 2024 • Time: 7:00 AM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore converts Phase 2/3 TEBE-AM clinical trial
                          into registrational Phase 3 trial evaluating KIMMTRAK
                          for previously treated advanced cutaneous melanoma
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-reports-first-quarter-financial-results-and-provides-a-business-update"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)`,
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-05-08T07:00:00">
                            May. 8, 2024 • Time: 7:00 AM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore reports first quarter financial results and
                          provides a business update
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-announces-upcoming-presentation-and-posters-at-asco-2024"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)`,
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-04-24T10:00:00">
                            Apr. 24, 2024 • Time: 10:00 AM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore announces upcoming presentation and posters
                          at ASCO 2024
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-to-present-at-upcoming-investor-conferences"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)`,
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-04-04T16:00:00">
                            Apr. 4, 2024 • Time: 4:00 PM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore to present at upcoming investor conferences
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-presented-two-posters-at-croi-2024"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)`,
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-03-05T16:30:00">
                            Mar. 5, 2024 • Time: 4:30 PM EST
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore presented two posters at CROI 2024
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-to-present-at-upcoming-investor-conferences"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)`,
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-03-01T07:00:00">
                            Mar. 1, 2024 • Time: 7:00 AM EST
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore to present at upcoming investor conferences
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="btn-wrapper">
            <a href="/investors/news/press-releases" class="btn">
              View all
            </a>
          </div>
          <p class="p-style--xs mt-3">
            * Intended for investors and members of the media.
          </p>
        </div>
      </section>

      <div class="module module-presentation-event neutral-bg">
        <div class="container">
          <div class="row">
            <div class="col-lg">
              <div class="content">
                <div class="text">
                  <div class="content" data-mh="presentation-event-content">
                    <h2>Latest Presentation</h2>
                    <p>Corporate Presentation – June 2024</p>
                  </div>
                  <div class="inline-block-links">
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2265/22138/pdf/IMCR_Corporate+Deck_June2024_vFINAL.pdf"
                      target="_blank"
                      rel="noopener"
                      class="block-link"
                    >
                      View Presentation
                    </a>
                    <a
                      href="/investors/events-presentations/presentations"
                      class="block-link"
                    >
                      View All Presentations
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="financial-results"
        class="module module-overview-financial-results"
      >
        <div class="container">
          <div class="row">
            <div
              class="col-lg-6 module-image"
              style={{
                background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21032/image.png)`,
                backgroundPosition: "0% 50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundColor: "transparent",
              }}
            ></div>
            <div class="col-lg-6">
              <div class="results text">
                <div class="module-header text">
                  <h2>Latest Financial Results</h2>
                </div>
                <h3>Q1 2024</h3>
                <p class="date">Quarter Ended Mar 31, 2024</p>
                <div class="text">
                  <div class=" ">
                    <div>
                      <div class="result-line">
                        <div class="row justify-space-between">
                          <div class="col-auto file-title">
                            <a href="/investors/news/press-releases/detail/immunocore-reports-first-quarter-financial-results-and-provides-a-business-update/">
                              Earnings Release
                            </a>
                          </div>
                          <div class="col file-links">
                            <a
                              href="/investors/news/press-releases/detail/immunocore-reports-first-quarter-financial-results-and-provides-a-business-update"
                              title="Immunocore reports first quarter financial results and provides a business update"
                              class="link"
                            >
                              <span class="eqicon-HTML"></span>HTML
                            </a>

                            <a
                              href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-05-08_Immunocore_reports_first_quarter_financial_89.pdf"
                              class="link"
                              title="PDF: Immunocore reports first quarter financial results and provides a business update"
                              target="_blank"
                              rel="noopener"
                            >
                              <span class="eqicon-PDF"></span>PDF
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="result-line">
                        <div class="row justify-space-between">
                          <div class="col-auto file-title">
                            <a
                              href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-024784/ef20026074_10q.htm"
                              title="10-Q Filing Viewer"
                              class="fancybox fancybox.iframe"
                              rel="noopener"
                            >
                              10-Q <span class="sr-only">Filing</span>
                            </a>
                          </div>
                          <div class="col file-links">
                            <a
                              href="/investors/financials-filings/sec-filings/content/0001140361-24-024784/ef20026074_10q.htm"
                              title="10-Q Filing Viewer"
                              rel="noopener"
                              class="link fancybox fancybox.iframe"
                            >
                              <span class="eqicon-HTML"></span>HTML
                            </a>
                            <a
                              href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-024784/0001140361-24-024784.pdf"
                              title="10-Q"
                              class="link"
                              target="_blank"
                              rel="noopener"
                            >
                              <span class="eqicon-PDF"></span>PDF
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="result-line">
                        <div class="row justify-content-between">
                          <div class="col-auto file-title">
                            <a
                              href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-024784/0001140361-24-024784-xbrl.zip"
                              title="Download Raw XBRL Files"
                              target="_blank"
                              rel="noopener"
                            >
                              XBRL
                            </a>
                          </div>
                          <div class="col file-links">
                            <a
                              href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-024784/0001140361-24-024784-xbrl.zip"
                              title="Download Raw XBRL Files"
                              class="link"
                              target="_blank"
                              rel="noopener"
                            >
                              <span class="eqicon-ZIP"></span>ZIP
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ar-row">
            <section>
              <h2 class="heading--h3">Latest Annual Report</h2>
              <p>For Fiscal Year Ending Dec 31, 2023</p>
            </section>
            <section class="report-links">
              <h3 class="sr-only">Report Links</h3>
              <nav
                class="inline-btns right"
                aria-labelledby="document-navigation"
              >
                <h4 id="document-navigation" class="sr-only">
                  Annual Report Document Links
                </h4>
                <a
                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2288/21935/annual_report/2023+PLC+Annual+Report+FINAL+CLEAN+10Apr2024.pdf"
                  class="btn"
                  target="_blank"
                  rel="noopener"
                >
                  View Report
                </a>
                <a
                  href="/investors/financials-filings/sec-filings/content/0001140361-24-009921/0001140361-24-009921.pdf"
                  class="btn"
                  target="_blank"
                  rel="noopener"
                >
                  View 10-K
                </a>
              </nav>
            </section>
          </div>
        </div>
      </div>
      <div class="subscribe subscribe-module dark primary-color-bg">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-lg">
              <div class="text">
                <h2>Email Alerts</h2>
                <p>
                  Sign up today and receive company updates straight to your
                  inbox.
                </p>
              </div>
            </div>
            <div class="col-lg-auto">
              <a
                href="/investors/news/email-alerts"
                aria-label="Sign up today for email alerts"
                class="btn"
              >
                Sign Up Today
              </a>
            </div>
          </div>
        </div>
      </div>
      <section class="module module-stock-banner accent-1-bg">
        <div class="container">
          <h2 class="sr-only">Stock Snapshot</h2>
          <div
            class="stock-info quote-box qtool"
            data-qmod-tool="detailedquotetab"
            data-qmod-params='{"lang":"en","showLogo":false,"lowHigh":false,"symbol":"IMCR"}'
            data-name="qmod"
          >
            <span class="loader"></span>
            <script type="text/template">
              <div class="row">
                <div class="col">
                  {/* <p class="date">{data.pricedata.lasttradedatetime | momentjs 'true' 'LLL' tLang} {data.tz | asTimezone tLang}</p> */}
                </div>
              </div>
              <div class="row justify-content-between no-gutters">
                <div class="col-lg-auto symbol">
                  <h3 class="value-title">Market/Symbol</h3>
                  <span class="value">Nasdaq: IMCR</span>
                </div>
                <div class="col-lg-auto price">
                  <h3 class="value-title">Price</h3>
                  {/* <span class="value">${data.pricedata.last | asQHLast tLang }</span> */}
                </div>
                <div class="col-lg-auto change">
                  <h3 class="value-title">Change</h3>
                  <span class="value">
                    <span
                      rv-qmodchange="data.pricedata.change"
                      class="qmod-change"
                    >
                      {/* <span class="number">${data.pricedata.change | asQHLast tLang }</span> */}
                      {/* <span class="percent"> ({data.pricedata.changepercent | numeraljs '0.00' '0.00' | postFix '%'})</span> */}
                    </span>
                  </span>
                </div>
                <div class="col-lg-auto volume">
                  {/* <h3 class="value-title">{tplLang.volume| toLang tLang}</h3> */}
                  {/* <span class="value">${data.pricedata.sharevolume | numeraljs '0.00a'}</span> */}
                </div>
              </div>
            </script>
          </div>
        </div>
      </section>
      <section class="contact-info module">
        <div class="container">
          <div class="text">
            <h2>IR contacts</h2>
            <div class="row">
              <div class="col-lg contact-col" data-mh="contact-col">
                <h3 class="heading--h4">Headquarters</h3>
                <p>
                  Immunocore Holdings plc
                  <br />
                  Immunocore Limited
                  <br />
                  92 Park Drive
                  <br />
                  Milton Park
                  <br />
                  Abingdon, Oxon OX14 4RY <br />
                  UK <br />
                  T: +44 (0)1235 438600
                  <br />
                  <a href="mailto:info@immunocore.us">info@immunocore.us</a>
                </p>
              </div>
              <div class="col-lg contact-col" data-mh="contact-col">
                <h3 class="heading--h4">Investor relations</h3>
                <p>
                  Clayton Robertson
                  <br />
                  Head of Investor Relations
                  <br />
                  T: +1 (215) 384-4781
                  <br />
                  <a href="mailto:info@immunocore.us">info@immunocore.us</a>
                  <br />
                </p>
              </div>
              <div class="col-lg contact-col" data-mh="contact-col">
                <h3 class="heading--h4">Transfer agent</h3>
                <p>
                  Computershare Investor Services PLC
                  <br />
                  The Pavilions
                  <br />
                  Bridgwater Road
                  <br />
                  Bristol, BS99 6ZZ
                  <br />
                  United Kingdom
                  <br />
                  T: 0370 703 0387
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default InvestorMain;
