import React from "react";

const DocumentMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <aside id="tertiaryNav">
            <nav id="tertiary-navigation">
              <ul>
                <li class="sidebar-title">
                  <h2>Governance</h2>
                </li>
                <li class="">
                  <a href="/investors/corporate-governance" target="_self">
                    Overview
                  </a>
                </li>
                <li class="active active-landing">
                  <a
                    href="/investors/corporate-governance/governance-documents"
                    target="_self"
                  >
                    Governance Documents
                  </a>
                </li>
                <li class="">
                  <a
                    href="/investors/corporate-governance/management-team"
                    target="_self"
                  >
                    Management Team
                  </a>
                </li>
                <li class="">
                  <a href="/about-us/board-of-directors" target="_self">
                    Board of Directors
                  </a>
                </li>
                <li class="">
                  <a
                    href="/investors/corporate-governance/board-committees"
                    target="_self"
                  >
                    Board Committees
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <main id="mainContent" class="main-content has-tertiary-nav">
            <div class="module-asset-list">
              <div class="asset">
                <div class="row align-items-center justify-content-between">
                  <div class="col-md-8">
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2275/20296/file/Immunocore+-+Audit+Committee+Charter+-+FINAL+1+Jan+24.pdf"
                      target="_blank"
                      rel="noopener"
                    >
                      Audit Committee Charter
                    </a>
                  </div>
                  <div class="col-md-auto">
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2275/20296/file/Immunocore+-+Audit+Committee+Charter+-+FINAL+1+Jan+24.pdf"
                      target="_blank"
                      rel="noopener"
                      class="btn"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
              <div class="asset">
                <div class="row align-items-center justify-content-between">
                  <div class="col-md-8">
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2275/20297/file/Immunocore+-+Remuneration+Committee+Charter+-+FINAL+1+Jan+24.pdf"
                      target="_blank"
                      rel="noopener"
                    >
                      Remuneration Committee Charter
                    </a>
                  </div>
                  <div class="col-md-auto">
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2275/20297/file/Immunocore+-+Remuneration+Committee+Charter+-+FINAL+1+Jan+24.pdf"
                      target="_blank"
                      rel="noopener"
                      class="btn"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
              <div class="asset">
                <div class="row align-items-center justify-content-between">
                  <div class="col-md-8">
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2275/20298/file/Immunocore+-+Nominating+and+Governance+Committee+Charter+-+FINAL+1+Jan+24.pdf"
                      target="_blank"
                      rel="noopener"
                    >
                      Nominating and Governance Committee Charter
                    </a>
                  </div>
                  <div class="col-md-auto">
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2275/20298/file/Immunocore+-+Nominating+and+Governance+Committee+Charter+-+FINAL+1+Jan+24.pdf"
                      target="_blank"
                      rel="noopener"
                      class="btn"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
              <div class="asset">
                <div class="row align-items-center justify-content-between">
                  <div class="col-md-8">
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2275/21411/file/Immunocore+-+Code+of+Business+Conduct+and+Ethics+-+FINAL+1+Jan+24.pdf"
                      target="_blank"
                      rel="noopener"
                    >
                      Code of Business Conduct and Ethics
                    </a>
                  </div>
                  <div class="col-md-auto">
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2275/21411/file/Immunocore+-+Code+of+Business+Conduct+and+Ethics+-+FINAL+1+Jan+24.pdf"
                      target="_blank"
                      rel="noopener"
                      class="btn"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default DocumentMain;
