import React from "react";

const PortfolioSectionNav = () => {
  return (
    <nav
      id="sectionNav"
      class="sub-navigation-wrapper"
      aria-label="Pipeline &amp; Portfolio Section"
    >
      <div class="sub-navigation affixed">
        <div class="container">
          <a
            class="visible-xs visible-sm mobile-sub-nav-trigger collapsed"
            role="button"
            data-toggle="collapse"
            data-target="#sub-navigation"
          >
            <div class="trigger-container">
              <div class="meat"></div>
              <div class="meat"></div>
              <div class="meat"></div>
            </div>
            Pipeline &amp; Portfolio
          </a>
          <div class="collapse sb-nav clearfix" id="sub-navigation">
            <h3>Pipeline &amp; Portfolio</h3>
            <ul>
              <li class="active active-landing">
                <a href="/pipeline-portfolio/pipeline" target="_self">
                  Pipeline
                </a>
              </li>
              <li class="">
                <a href="/pipeline-portfolio/our-therapies" target="_self">
                  Our Therapies
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default PortfolioSectionNav;
