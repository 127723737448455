import React, { useState } from "react";

const OriginalContactMain = ({ notify, successNotify }) => {
  const [formState, setFormState] = useState({
    fullName: "",
    email: "",
    interests: "",
    message: "",
  });

  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!formState.fullName || !formState.email || !formState.message) {
      notify("Please fill all the fields");
      return;
    } else {
      const formData = new FormData();
      formData.append("purpose", "Get In Touch With Us");
      formData.append("name", `${formState.fullName}`);
      formData.append("email", formState.email);
      formData.append("interests", formState.interests);
      formData.append("message", formState.message);

      fetch("https://formspree.io/f/mrbzbnab", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(Object.fromEntries(formData.entries())),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            setFormState({
              ...formState,
              fullName: "",
              email: "",
              message: "",
            });
            successNotify("Message sent!!!");
          }
        })
        .catch((err) => {
          console.log(err);
          notify("Failed to send message.");
        });
    }
  };

  return (
    <main id="mainContent">
      <noscript>
        <div class="alert alert-warning">
          <strong>We're sorry</strong>, but this form has spam protection that
          requires JavaScript to be enabled to work correctly. If you'd like to
          contact us via this form, please enable scripts in your browser and
          reload the page.
        </div>
      </noscript>
      <div class="module module-contact-page accent-1-bg">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="module-content text">
                <h2>Get In Touch With Us</h2>
                <p class="p-style--sm">
                  For inquiries, please contact us using the information below.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module module-contact-page">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div data-name="hcaptcha" class="contact-form-wrapper">
                <form
                  method="post"
                  action="/form-submit"
                  id="contact-form"
                  class="contact-form validate-form"
                >
                  <input
                    autocomplete="off"
                    type="hidden"
                    name="f"
                    value="contact-form"
                  />
                  <fieldset>
                    <div class="row">
                      <div class="col-12">
                        <p class="p-style--xs">* denotes a required field</p>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="name">Name</label>
                          <input
                            autocomplete="given-name"
                            type="text"
                            name="fullName"
                            id="name"
                            value={formState.fullName}
                            onChange={changeHandler}
                            minlength="2"
                            maxlength="40"
                            class="required form-control"
                            placeholder="Enter Name"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="email">Email Address</label>
                          <input
                            autocomplete="email"
                            type="email"
                            name="email"
                            onChange={changeHandler}
                            id="email"
                            value={formState.email}
                            maxlength="40"
                            class="required form-control"
                            placeholder="Enter Email Address"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="interested_in">Interested in:</label>
                          <select
                            name="interests"
                            id="interested_in"
                            class="form-control eq-select"
                            title="Select Options"
                            value={formState.interests}
                            onChange={changeHandler}
                          >
                            <option value="General Inquiries">
                              General Inquiries
                            </option>
                            <option value="Public Relations">
                              Public Relations
                            </option>
                            <option value="Partnerships">Partnerships</option>
                            <option value="Clinical Trials">
                              Clinical Trials
                            </option>
                            <option value="Investor Relations">
                              Investor Relations
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="comments">Message</label>
                          <textarea
                            name="message"
                            value={formState.message}
                            onChange={changeHandler}
                            id="comments"
                            cols="30"
                            rows="6"
                            class="required form-control"
                            placeholder="Enter Message"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div id="captcha-contact" class="h-captcha"></div>
                    <div class="disclaimer">
                      This site is protected by hCaptcha and its{" "}
                      <a
                        href="https://hcaptcha.com/privacy"
                        target="_blank"
                        rel="noopener"
                      >
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://hcaptcha.com/terms"
                        target="_blank"
                        rel="noopener"
                      >
                        Terms of Service
                      </a>{" "}
                      apply.
                    </div>
                    <button class="btn" onClick={submitHandler}>
                      Send a Message
                    </button>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module module-contact-page accent-1-bg pt-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="module-contact-info">
                <div class="row">
                  <div class="col-12">
                    <h2>Office Locations</h2>
                  </div>
                  <div class="col-sm-4 contact-info-col">
                    <h3 class="heading--h4">Oxfordshire, UK</h3>
                    <p>
                      Immunocore Limited
                      <br />
                      92 Park Drive
                      <br />
                      Milton Park
                      <br />
                      Abingdon, Oxon OX14 4RY <br />
                      UK <br />
                      Phone +44 (0)1235 438600
                    </p>
                  </div>
                  <div class="col-sm-4 contact-info-col">
                    <h3 class="heading--h4">Pennsylvania, US</h3>
                    <p>
                      Immunocore LLC
                      <br />
                      Six Tower Bridge, Suite 200
                      <br />
                      181 Washington Street
                      <br />
                      Conshohocken, PA 19428 <br />
                      US <br />
                      Phone +1 (484) 534-5261
                    </p>
                  </div>
                  <div class="col-sm-4 contact-info-col">
                    <h3 class="heading--h4">Maryland, US</h3>
                    <p>
                      Immunocore LLC
                      <br />
                      2273 Research Boulevard
                      <br />
                      Suite 220
                      <br />
                      Rockville, MD 20850 <br />
                      US
                    </p>
                  </div>
                  <div class="col-sm-4 contact-info-col">
                    <h3 class="heading--h4">Dublin, Ireland</h3>
                    <p>
                      Immunocore Ireland Limited
                      <br />
                      Unit 1<br />
                      Sky Business Centre
                      <br />
                      Port Tunnel Business and Technology Park, Dublin D17 FY82{" "}
                      <br />
                      Ireland
                    </p>
                  </div>
                  <div class="col-sm-4 contact-info-col">
                    <h3 class="heading--h4">Risch-Rotkreuz, Switzerland</h3>
                    <p>
                      Immunocore GmbH
                      <br />
                      Suurstoffi 37
                      <br />
                      CH-6343
                      <br />
                      Risch-Rotkreuz <br />
                      Switzerland
                    </p>
                  </div>
                  <div class="col-sm-4 contact-info-col">
                    <h3 class="heading--h4">Email</h3>
                    <p>info&lt;wbr&gt;@immunocore.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script
        type="text/plain"
        id="hcaptcha"
        data-type="application/javascript"
        data-name="hcaptcha"
        data-src="https://hcaptcha.com/1/secure-api.js?onload=hCaptchaOnLoad&amp;render=explicit&amp;host=www.immunocore.com"
        async
      ></script>
    </main>
  );
};

export default OriginalContactMain;
