import React from "react";

const ScienceMain = () => {
  return (
    <main id="mainContent">
      <section class="module row-default row-two-col">
        <div class="container">
          <div class="row justify-content-center align-items-start">
            <div class="col-lg-6">
              <div class="module-content primary-area">
                <div class="text-area">
                  <div class="text">
                    <h2>
                      Pioneering transformative immunomodulating therapies to
                      improve outcomes for patients
                    </h2>
                    <figure class="image-wrapper mt-5">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21664/image.png"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <p>
                      Our immune system is our best defense against diseases, as
                      it recognizes and destroys diseased cells. But sometimes,
                      this defense does not work as intended when diseased cells
                      manage to hide from immune cells or when the immune system
                      turns against the body.
                    </p>
                    <p>
                      Our proprietary ImmTAX (Immune Mobilizing Monoclonal TCRs
                      Against X disease) technology is designed to address these
                      limitations, by activating the immune system to make
                      infected or tumor cells visible and then destroy them, or
                      by turning off the immune system to reduce an autoimmune
                      response.
                    </p>
                    <p>
                      T cells – a key type of white blood cell – constantly
                      survey the body for foreign, infected or abnormal cells by
                      recognizing specific antigens displayed on the surface of
                      these cells. When a T cell encounters cells with antigens
                      recognized as abnormal, it binds, activates and initiates
                      cytotoxic processes that lead to the destruction of the
                      targeted cell.
                    </p>
                    <p>
                      But as effective as T cells are at keeping the body clear
                      of threats, unhealthy cells have their own mechanisms for
                      evading the body’s defenses. Some cancers, for example,
                      display antigens in very low levels, making them harder to
                      identify.
                    </p>
                    <p>
                      By affinity-enhancing human TCRs and strapping them to an
                      effector arm that recruits T cells, we are able to
                      overcome the limitations of our natural immune system and
                      current therapeutic approaches.
                    </p>
                    <div class="btn-wrapper"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="" class="module row-default science-patient-care">
        <div class="container">
          <div class="row justify-content-center align-items-center flex-row-reverse">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21666/image.png"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <h2>Harnessing the power of the immune system</h2>
                    <p>
                      Our ImmTAX technology platform was engineered to unlock a
                      universe of diseases, by taking advantage of the T cell
                      receptor’s ability to target proteins coming from inside
                      an unhealthy cell.
                    </p>
                    <p>
                      Whereas existing forms of immunotherapy such as antibodies
                      are limited to extracellular proteins, ImmTAX molecules
                      are in theory capable of targeting 90% of infected and
                      tumor cells.
                    </p>
                    <p>
                      By affinity-enhancing human TCRs and strapping them to an
                      effector arm that recruits T cells, we are able to
                      overcome the limitations of our natural immune system and
                      current therapeutic approaches.
                    </p>
                    <p>
                      And because ImmTAX technology is modular, the targeting or
                      effector arms can be switched out depending on the disease
                      target and the intended immune system response.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="" class="module row-default">
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21837/image.png"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <h2>ImmTAC</h2>
                    <h3 class="heading--h4">
                      Making a difference for people living with cancer
                    </h3>
                    <p>
                      In cancer, our ImmTAC molecules are designed to recognize
                      and bind to the chosen target antigen presented by the
                      cell (through an HLA complex). Then the recruiting arm (an
                      anti-CD3 effector function) engages T cells to kill the
                      cancer cell through lytic processes.
                    </p>
                    <h2>ImmTAV</h2>
                    <h3 class="heading--h4">Pursuing functional cures</h3>
                    <p>
                      Our ImmTAV molecules are being investigated as a new
                      approach for the treatment of chronic infections by
                      redirecting non-exhausted immune cells to attack infected
                      cells, even if those cells present levels of target
                      antigen that are too low for the natural immune system to
                      see.
                    </p>
                    <h2>ImmTAAI</h2>
                    <h3 class="heading--h4">
                      Investigating organ-specific immune suppression
                    </h3>
                    <p>
                      As well as telling the immune system to kill an unhealthy
                      cell, our technology can be engineered to switch off the
                      immune system when it’s killing a healthy cell. ImmTAAI
                      molecules do this by targeting a specific organ under
                      attack and inhibiting the immune pathway to the rogue T
                      cells.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id=""
        class="module row-default neutral-bg no-padding-top no-padding-bottom image-align image-align--right"
      >
        <div class="container">
          <div class="row justify-content-center align-items-center flex-row-reverse">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21673/image.jpg"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <h2>A new wave of immunotherapies</h2>
                    <p>
                      Underpinned by our ImmTAX platform, we are investigating
                      new therapies for multiple indications in oncology,
                      infectious disease and autoimmune conditions.
                    </p>
                    <a href="/pipeline-portfolio/pipeline" class="btn">
                      View our pipeline
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ScienceMain;
