import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { countries } from "../utils/countries_data";
import { countryPhoneCodes } from "../utils/phone_code_data";
import "../css/open_positions.css";
import { Helmet } from "react-helmet";
import { jobs } from "../utils/jobs_data";
import Footer from "../components/Footer";
import FileUpload from "../components/openPositionsComponents/FileUpload";
import Header from "../components/home/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OpenPositions = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedPhoneCode, setSelectedPhoneCode] = useState("");
  const [location, setLocation] = useState("Location");
  const [locations, setLocations] = useState([]);
  const [timeType, setTimeType] = useState("Time Type");
  const [timeTypes, setTimeTypes] = useState([]);
  const [jobType, setJobType] = useState("Job Type");
  const [jobTypes, setJobTypes] = useState([]);
  const [resultJobs, setResultJobs] = useState([]);
  const [resultJob, setResultJob] = useState("");

  useEffect(() => {
    setLocations([...new Set(jobs.map((job) => job.location))]);
    setTimeTypes([...new Set(jobs.map((job) => job.timeType))]);
    setJobTypes([...new Set(jobs.map((job) => job.jobType))]);
  }, [jobs]);

  useEffect(() => {
    const filterJobs = (location, timeType, jobType) => {
      const isLocationDefault = location === "Location";
      const isTimeTypeDefault = timeType === "Time Type";
      const isJobTypeDefault = jobType === "Job Type";

      return jobs.filter((job) => {
        return (
          (isLocationDefault || job.location === location) &&
          (isTimeTypeDefault || job.timeType === timeType) &&
          (isJobTypeDefault || job.jobType === jobType)
        );
      });
    };
    setResultJobs([...filterJobs(location, timeType, jobType)]);
  }, [location, timeType, jobType, jobs]);

  const handleChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleChangeCode = (event) => {
    setSelectedPhoneCode(event.target.value);
  };

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
    setResultJob("");
  };

  const handleChangeTimeType = (event) => {
    setTimeType(event.target.value);
    setResultJob("");
  };

  const handleChangeJobType = (event) => {
    setJobType(event.target.value);
    setResultJob("");
  };

  const handleChangeResultJobs = (event) => {
    setResultJob(event.target.value);
  };

  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [formState, setFormState] = useState({
    givenName: "",
    familyName: "",
    email: "",
    phoneNumber: "",
    location: "",
    timeType: "",
    jobType: "",
    job: "",
  });

  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      !selectedCountry ||
      !formState.givenName ||
      !formState.familyName ||
      !formState.email ||
      !selectedPhoneCode ||
      !formState.phoneNumber
    ) {
      notify("Please fill all the fields");
      return;
    } else if (selectedFiles.length === 0) {
      notify("Please upload your resume");
      return;
    } else {
      const formData = new FormData();
      formData.append("purpose", "Job Application");
      formData.append("name", `${formState.givenName} ${formState.familyName}`);
      formData.append("email", formState.email);
      formData.append(
        "phoneNumber",
        `${selectedPhoneCode}${formState.phoneNumber}`
      );
      formData.append("location", location);
      formData.append("timeType", timeType);
      formData.append("jobType", jobType);
      formData.append("job", resultJob);
      formData.append(
        "country",
        countries.filter((c) => c.value === selectedCountry)[0].label
      );

      // selectedFiles.forEach((file) => {
      //   formData.append("file", file, file.name);
      // });
      fetch("https://formspree.io/f/mrbzbnab", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(Object.fromEntries(formData.entries())),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            setFormState({
              ...formState,
              givenName: "",
              familyName: "",
              phoneNumber: "",
              email: "",
              comment: "",
              department: "",
            });
            successNotify("Message sent!!!");
          }
        })
        .catch((err) => {
          console.log(err);
          notify("Failed to send message.");
        });
    }
  };

  return (
    <div className="open-positions-container">
      <Helmet>
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta charset="utf-8" />
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Immunocore Holdings plc - Recent News"
          href="https://www.immunocore.com/investors/news/press-releases/rss"
        />

        <title>Open Positions :: Immunocore Holdings plc (IMCR)</title>

        <meta property="og:site_name" content="Immunocore Holdings plc" />
        <meta property="og:title" content="Open Positions" />
        <meta property="og:type" content="website" />

        <meta
          property="og:image"
          content="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2281/20579/social_image_resized.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <link rel="preconnect" href="https://d1io3yog0oux5.cloudfront.net" />
        <link rel="preconnect" href="https://qmod.quotemedia.com" />

        <link
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/css/ir.stockpr.css"
          rel="stylesheet"
          type="text/css"
          media="screen"
        />
        <link
          rel="stylesheet"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/css/style.css"
        />

        <link
          rel="shortcut icon"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon.ico"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="manifest"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/manifest.json"
        />
        <link
          rel="mask-icon"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/safari-pinned-tab.svg"
        />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.datatables.net/1.13.7/css/jquery.dataTables.min.css"
        />
      </Helmet>
      <Header />
      <form className="open-form" action="" onSubmit={submitHandler}>
        <h4>Introduce Yourself</h4>
        <div className="text-p">
          Looking for your dream job? Drop off your contact information and
          resume and we will reach out to you if we find the perfect fit!
        </div>

        <div className="label">
          <label htmlFor="">
            Country<span>*</span>
          </label>
          <select value={selectedCountry} onChange={handleChange}>
            <option value="">Select a country</option>
            {countries.map((country, index) => (
              <option key={index} value={country.value}>
                {country.label}
              </option>
            ))}
          </select>
        </div>
        <div className="label">
          <label htmlFor="">
            Given Name(s)<span>*</span>
          </label>
          <input
            type="text"
            name="givenName"
            value={formState.givenName}
            onChange={changeHandler}
            id=""
          />
        </div>
        <div className="label">
          <label htmlFor="">
            Family Name<span>*</span>
          </label>
          <input
            type="text"
            name="familyName"
            value={formState.familyName}
            onChange={changeHandler}
            id=""
          />
        </div>
        <div className="label">
          <label htmlFor="">
            Email<span>*</span>
          </label>
          <input
            type="email"
            name="email"
            value={formState.email}
            onChange={changeHandler}
            id=""
          />
        </div>
        <div className="label">
          <label htmlFor="">
            Country Phone Code<span>*</span>
          </label>
          <select value={selectedPhoneCode} onChange={handleChangeCode}>
            <option value="">Select one</option>
            {countryPhoneCodes.map((phone_code, index) => (
              <option key={index} value={phone_code.value}>
                {phone_code.label}
              </option>
            ))}
          </select>
        </div>
        <div className="label">
          <label htmlFor="">
            Phone Number<span>*</span>
          </label>
          <input
            type="number"
            name="phoneNumber"
            value={formState.phoneNumber}
            onChange={changeHandler}
            id=""
          />
        </div>
        <h4>Filter Results ({resultJobs.length} JOBS)</h4>
        <div className="label">
          <select value={location} onChange={handleChangeLocation}>
            <option value="Location">Location</option>
            {locations.map((ltn, index) => (
              <option key={index} value={ltn}>
                {ltn}
              </option>
            ))}
          </select>
        </div>
        <div className="label">
          <select value={timeType} onChange={handleChangeTimeType}>
            <option value="Time Type">Time Type</option>
            {timeTypes.map((val, index) => (
              <option key={index} value={val}>
                {val}
              </option>
            ))}
          </select>
        </div>
        <div className="label">
          <select value={jobType} onChange={handleChangeJobType}>
            <option value="Job Type">Job Type</option>
            {jobTypes.map((val, index) => (
              <option key={index} value={val}>
                {val}
              </option>
            ))}
          </select>
        </div>
        <div className="label">
          <select value={resultJob} onChange={handleChangeResultJobs}>
            <option value="">Available Jobs</option>
            {resultJobs.map((val, index) => (
              <option key={index} value={val.job}>
                {val.job}
              </option>
            ))}
          </select>
        </div>
        {!resultJob && (
          <div className="allow-text">
            If you do not see a job position that matches your qualifications,
            please feel free to send us your resume/CV for future opportunities.
          </div>
        )}
        <div className="label">
          <div className="big-text">Resume/CV</div>
          <label htmlFor="">
            Upload either DOC, DOCX, HTML, PDF, or TXT file types (5MB max)
            <span>*</span>
          </label>
          <FileUpload
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
        </div>
        <div className="submit">
          <button type="submit">Submit</button>
        </div>
      </form>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default OpenPositions;
