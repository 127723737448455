import React from "react";
import { Helmet } from "react-helmet";
import InvestorHeader from "../components/investor/InvestorHeader";
import Footer from "../components/Footer";
import InvestorSectionNav from "../components/investor/InvestorSectionNav";
import InvestorMain from "../components/investor/InvestorMain";

const Investors = () => {
  return (
    <div>
      <Helmet>
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta charset="utf-8" />
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Immunocore Holdings plc - Recent News"
          href="https://www.immunocore.com/investors/news/press-releases/rss"
        />

        <title>Investors :: Immunocore Holdings plc (IMCR)</title>

        <link rel="canonical" href="https://www.immunocore.com/investors" />
        <meta
          property="og:url"
          content="https://www.immunocore.com/investors"
        />

        <meta property="og:site_name" content="Immunocore Holdings plc" />
        <meta property="og:title" content="Investors" />
        <meta property="og:type" content="website" />

        <meta
          property="og:image"
          content="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2281/20579/social_image_resized.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <meta
          property="og:description"
          content="Immunocore is a commercial-stage biotechnology company that discovered, developed and commercialized the world’s first approved T cell receptor (TCR) therapy. Our mission is to radically improve outcomes for patients with cancer,…"
        />
        <meta
          name="description"
          content="Immunocore is a commercial-stage biotechnology company that discovered, developed and commercialized the world’s first approved T cell receptor (TCR) therapy. Our mission is to radically improve outcomes for patients with cancer,…"
        />

        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <link rel="preconnect" href="https://d1io3yog0oux5.cloudfront.net" />
        <link rel="preconnect" href="https://qmod.quotemedia.com" />

        <link
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/css/ir.stockpr.css"
          rel="stylesheet"
          type="text/css"
          media="screen"
        />
        <link
          rel="stylesheet"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/css/style.css"
        />

        <link
          rel="shortcut icon"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon.ico"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="manifest"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/manifest.json"
        />
        <link
          rel="mask-icon"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/safari-pinned-tab.svg"
        />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.datatables.net/1.13.7/css/jquery.dataTables.min.css"
        />
      </Helmet>

      <div class="general-wrapper">
        <InvestorHeader />
        <header
          class="page-banner dark"
          style={{
            background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2370/21091/image_resized.jpg)`,
            backgroundPosition: "50% 50%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundColor: "#333",
          }}
        >
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="text">
                  <h1>Pioneers of the world’s first TCR therapy</h1>

                  <p>
                    We are a commercial-stage T cell receptor (TCR)
                    biotechnology company that is pioneering and delivering
                    transformative immunomodulating medicines to radically
                    improve outcomes for patients living with cancer, infectious
                    diseases, and autoimmune diseases.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <InvestorSectionNav />
        <InvestorMain />

        <div
          class="module-quick-links"
          role="navigation"
          aria-label="Helpful Investor Relations pages"
        >
          <div class="container">
            <ul>
              <li>
                <a href="/investors/news/email-alerts">
                  <span class="material-icons" aria-hidden="true">
                    email
                  </span>
                  Email Alerts
                </a>
              </li>
              <li>
                <a href="/investors/ir-resources/contacts" class="contacts">
                  <span class="material-icons" aria-hidden="true">
                    contact_page
                  </span>
                  Contacts
                </a>
              </li>
              <li>
                <a
                  href="https://www.immunocore.com/investors/news/press-releases/rss"
                  class="rss"
                  target="_blank"
                  rel="noopener"
                >
                  <span class="material-icons" aria-hidden="true">
                    rss_feed
                  </span>
                  RSS News Feed
                </a>
              </li>
              <li>
                <a href="/sitemap" class="sitemap">
                  <span class="material-icons" aria-hidden="true">
                    account_tree
                  </span>
                  Sitemap
                </a>
              </li>
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Investors;
