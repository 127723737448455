import React from "react";

const ManagementMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <main id="mainContent" class="main-content">
            <h2>Leaders today, for tomorrow</h2>
            <p>
              We have assembled a team of visionary leaders with some of the
              best track records in R&amp;D to pioneer the next generation of
              immunotherapies.
            </p>
            <div class="member-blocks">
              <div class="container">
                <div class="row team-row">
                  <div class="col-md-4 member-holder" id="bahija-jallal-phd">
                    <div class="member-wrap">
                      <a
                        href="#bahija-jallal-phd-info-box"
                        class="fancybox-bio"
                      >
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21412/photo_card.jpg"
                              alt="Bahija Jallal, PhD"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Bahija Jallal, PhD</h2>
                            <p class="title">Chief Executive Officer</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="bahija-jallal-phd-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21412/photo_resized.jpg"
                                alt="Bahija Jallal, PhD"
                                title="Bahija Jallal, PhD"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Bahija Jallal, PhD</h2>
                              <p class="title">Chief Executive Officer</p>
                              <p>
                                Dr Bahija Jallal is Chief Executive Officer and
                                Director of the Board of Immunocore. Prior to
                                joining Immunocore in January 2019, she was
                                President of MedImmune, AstraZeneca’s global
                                biologics research and development unit. She was
                                also Executive Vice President of AstraZeneca and
                                a member of its senior executive team.
                              </p>
                              <p>
                                Dr Jallal serves on the Board of Elevance, Inc.
                                and is a member of the Board of Trustees of
                                Johns Hopkins University and the Board of
                                Directors of the University of Maryland Health
                                Sciences Research Park Corporation.
                              </p>
                              <p>
                                Bahija has authored more than 70 peer-reviewed
                                publications and has more than 15 patents. She
                                is a Council Member of the
                                Government-University-Industry Research
                                Roundtable of the National Academies of
                                Sciences, Engineering and Medicine. She is also
                                the immediate past president of the Association
                                of Women in Science. Bahija was named the 2017
                                Woman of the Year by the Healthcare
                                Businesswomen’s Association.
                              </p>
                              <p>
                                Prior to joining MedImmune, Bahija was Vice
                                President, Drug Assessment and Development, at
                                Chiron Corporation, where she successfully
                                established the company’s translational medicine
                                group. Prior to Chiron, she was part of the
                                research team at Sugen, Inc.
                              </p>
                              <p>
                                Bahija received her PhD in Physiology from
                                Université de Paris VI, France and conducted her
                                post-doctorate work in molecular biology and
                                oncology at the Max Planck Institute for
                                Biochemistry in Germany.&nbsp;
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="brian-di-donato">
                    <div class="member-wrap">
                      <a href="#brian-di-donato-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21413/photo_card.jpg"
                              alt="Brian Di Donato"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Brian Di Donato</h2>
                            <p class="title">
                              EVP, Chief Financial Officer and Strategy
                            </p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="brian-di-donato-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21413/photo_resized.jpg"
                                alt="Brian Di Donato"
                                title="Brian Di Donato"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Brian Di Donato</h2>
                              <p class="title">
                                EVP, Chief Financial Officer and Strategy
                              </p>
                              <p>
                                Brian joined Immunocore in April 2020 from
                                Achillion Pharmaceuticals, Inc., where he was
                                Senior Vice President and Chief Financial
                                Officer. Brian has more than 20 years of
                                financial and leadership experience, and leads
                                Immunocore’s corporate strategy, finance,
                                investor relations, competitive intelligence and
                                information technology areas.
                              </p>
                              <p>
                                Brian is an experienced capital markets banker,
                                investor and fund manager, having held positions
                                as Managing Director and Co-Portfolio Manager at
                                Sorin Capital Management and President and Chief
                                Investment Officer at Capmark Investments. He
                                previously worked in fixed-income capital
                                markets as an Executive Director at Morgan
                                Stanley and as Vice President at UBS Securities.
                              </p>
                              <p>
                                Brian holds a Master of Business Administration
                                from New York University’s Stern School of
                                Business, and Bachelor of Science degrees in
                                Biology from Penn State University and in
                                Mechanical Engineering from Villanova
                                University. Prior to business school, he was an
                                aerospace engineering officer in the U.S. Navy.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="david-berman-md-phd">
                    <div class="member-wrap">
                      <a
                        href="#david-berman-md-phd-info-box"
                        class="fancybox-bio"
                      >
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21414/photo_card.jpg"
                              alt="David Berman, MD, PhD"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>David Berman, MD, PhD</h2>
                            <p class="title">EVP, Research and Development</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="david-berman-md-phd-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21414/photo_resized.jpg"
                                alt="David Berman, MD, PhD"
                                title="David Berman, MD, PhD"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>David Berman, MD, PhD</h2>
                              <p class="title">EVP, Research and Development</p>
                              <p>
                                Dr. David Berman is Head of Research and
                                Development. Over his career, David has worked
                                on multiple immuno-oncology (IO) programs at all
                                stages of development, including leadership
                                roles in developing four approved biologics.
                                Most recently, David was Senior Vice President
                                and Head of the AstraZeneca IO Franchise,
                                responsible for the strategy and execution of
                                the company’s late-stage IO program. Prior to
                                that, he was head of the early-stage oncology
                                program at MedImmune.
                              </p>
                              <p>
                                David has also held senior development roles at
                                Bristol-Myers Squibb, including as head of the
                                immuno-oncology exploratory development team and
                                as Global Clinical Lead for the first approved
                                IO checkpoint inhibitor and one of the first
                                monoclonal antibodies approved for myeloma.
                                Beginning in academia and throughout his
                                industry career, David has led efforts to
                                understand the mechanism of action of IO
                                therapies and predict their benefit.
                              </p>
                              <p>
                                David received his MD and PhD from the
                                University of Texas Southwestern Medical School.
                                He trained in pathology at the National Cancer
                                Institute, followed by a fellowship at The Johns
                                Hopkins Hospital.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row team-row">
                  <div class="col-md-4 member-holder" id="tina-st-leger">
                    <div class="member-wrap">
                      <a href="#tina-st-leger-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21427/photo_card.jpg"
                              alt="Tina St. Leger"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Tina St. Leger</h2>
                            <p class="title">Chief Human Resources Officer</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="tina-st-leger-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21427/photo_resized.jpg"
                                alt="Tina St. Leger"
                                title="Tina St. Leger"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Tina St. Leger</h2>
                              <p class="title">Chief Human Resources Officer</p>
                              <p>
                                Tina joined Immunocore in January 2022 to lead
                                the human resources function. Prior to
                                Immunocore, Tina was Chief Human Resources
                                Officer at GW Pharmaceuticals and led the
                                company’s transition following its acquisition
                                by Jazz Pharmaceuticals. Tina gained significant
                                experience in HR working across several areas at
                                GSK, including in emerging markets, Europe and
                                in global therapy areas, as well as during her
                                time as head of human resources for ViiV
                                Healthcare. Tina began her pharmaceutical career
                                in 2005 following an earlier career in finance
                                at Marks and Spencer. She graduated with a
                                chemistry degree from the University of St.
                                Andrews.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="lily-hepworth">
                    <div class="member-wrap">
                      <a href="#lily-hepworth-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21422/photo_card.jpg"
                              alt="Lily Hepworth"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Lily Hepworth</h2>
                            <p class="title">
                              SVP, General Counsel and Company Secretary
                            </p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="lily-hepworth-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21422/photo_resized.jpg"
                                alt="Lily Hepworth"
                                title="Lily Hepworth"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Lily Hepworth</h2>
                              <p class="title">
                                SVP, General Counsel and Company Secretary
                              </p>
                              <p>
                                Lily Hepworth is General Counsel and Company
                                Secretary at Immunocore, having joined the
                                company in 2018. Lily leads the company’s Legal,
                                IP and Compliance teams. Lily’s areas of
                                expertise include corporate &amp; securities
                                law, public companies, financing, M&amp;A and
                                governance. Prior to joining Immunocore, Lily
                                was Head of Legal for Corporate at a FTSE 100
                                company and was in private practice in the
                                Corporate/M&amp;A team at Linklaters, a leading
                                global law firm. Lily obtained her Graduate
                                Diploma in Law and her Legal Practice Course
                                qualifications from BPP Law School in London,
                                and also holds a Bachelor of Science in
                                Economics from the University of Bristol.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="ralph-torbay">
                    <div class="member-wrap">
                      <a href="#ralph-torbay-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21425/photo_card.jpg"
                              alt="Ralph Torbay"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Ralph Torbay</h2>
                            <p class="title">SVP, Commercial</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="ralph-torbay-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21425/photo_resized.jpg"
                                alt="Ralph Torbay"
                                title="Ralph Torbay"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Ralph Torbay</h2>
                              <p class="title">SVP, Commercial</p>
                              <p>
                                Ralph Torbay is Immunocore’s Head of Commercial.
                                Ralph is an experienced commercial leader with a
                                proven track record of successful oncology
                                launches and significant immuno-oncology (IO)
                                experience. Prior to joining Immunocore, Ralph
                                was Global Head of Hematology Marketing at
                                AstraZeneca, where, in addition to helping to
                                build their global hematology franchise, he
                                launched three major drugs in oncology,
                                including the first checkpoint inhibitor in
                                stage III unresectable non-small cell lung
                                cancer.
                              </p>
                              <p>
                                Prior to AstraZeneca, Ralph worked at Novartis
                                Oncology where he held roles of increasing
                                responsibility. His experience spans several
                                disease areas including hematology, oncology and
                                autoimmune diseases, as well as diverse
                                therapeutic platforms including immuno-oncology,
                                CAR-T therapy, small molecules, and gene
                                therapies, in industry roles and as a strategic
                                healthcare consultant.
                              </p>
                              <p>
                                Ralph earned his Master of Business
                                Administration degree at the Simon Graduate
                                School of Business, University of Rochester; and
                                a Bachelor of Science in Biotechnology with a
                                minor in Bioinformatics, from Rochester
                                Institute of Technology. Ralph has lived on
                                three continents and speaks four languages with
                                native fluency.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row team-row">
                  <div class="col-md-4 member-holder" id="sean-buckley">
                    <div class="member-wrap">
                      <a href="#sean-buckley-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21928/photo_card.jpg"
                              alt="Sean Buckley"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Sean Buckley</h2>
                            <p class="title">VP, Chief Information Officer</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="sean-buckley-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21928/photo_resized.jpg"
                                alt="Sean Buckley"
                                title="Sean Buckley"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Sean Buckley</h2>
                              <p class="title">VP, Chief Information Officer</p>
                              <p>
                                Sean leads Immunocore's information technology
                                group, having joined in September 2021 from
                                Emerson, where he was Senior Life Science
                                Consultant.
                              </p>
                              <p>
                                Sean has more than 20 years of experience within
                                information technology in life science,
                                including positions as SVP &amp; Chief Financial
                                Officer of Carmell Therapeutics, VP &amp; Chief
                                Business Officer of Castle Creek Biosciences,
                                and VP &amp; Chief Financial Officer of
                                Fibrocell Science. Prior to that, Sean held
                                senior roles at Fibrocell Science, including VP
                                of Business Administration &amp; Corporate
                                Secretary, as well as Vice President of
                                Information Technology, a position in which he
                                also held multiple commercial and business
                                responsibilities.
                              </p>
                              <p>
                                Sean holds a Master of Business Administration
                                from the University of Maryland's Robert H.
                                Smith School of Business and a Bachelor of
                                Science degree in Internet Computing from the
                                University of Hull. Sean is currently studying
                                for a Master of Artificial Intelligence degree
                                from the University of Bath.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="mohammed-dar-md">
                    <div class="member-wrap">
                      <a href="#mohammed-dar-md-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21424/photo_card.jpg"
                              alt="Mohammed Dar, MD"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Mohammed Dar, MD</h2>
                            <p class="title">
                              SVP, Clinical Development and Chief Medical
                              Officer
                            </p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="mohammed-dar-md-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21424/photo_resized.jpg"
                                alt="Mohammed Dar, MD"
                                title="Mohammed Dar, MD"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Mohammed Dar, MD</h2>
                              <p class="title">
                                SVP, Clinical Development and Chief Medical
                                Officer
                              </p>
                              <p>
                                Mohammed joined Immunocore in April 2019 as Head
                                of Clinical Development and Chief Medical
                                Officer. Mohammed has over 15 years of
                                pharmaceutical industry experience in the field
                                of oncology.
                              </p>
                              <p>
                                Before joining Immunocore, Mohammed was Vice
                                President, Clinical Development, Oncology,
                                R&amp;D at MedImmune and spent 10 years at GSK
                                in roles of increasing responsibility focused on
                                early clinical development in oncology. While at
                                MedImmune, he led the clinical trials and teams
                                supporting the approval of anti-PD-L1 checkpoint
                                inhibitor durvalumab, and of anti-CD22
                                immunotoxin moxetumomab.
                              </p>
                              <p>
                                Mohammed received his Bachelor of Science degree
                                in Chemistry from the University of North
                                Carolina at Chapel Hill, and his MD and Internal
                                Medicine training from Duke University School of
                                Medicine. He went on to complete his Fellowship
                                in Hematology and Oncology at the National
                                Cancer Institute.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="sbastien-desprez">
                    <div class="member-wrap">
                      <a href="#sbastien-desprez-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21429/photo_card.jpg"
                              alt="Sébastien Desprez"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Sébastien Desprez</h2>
                            <p class="title">VP, Communications</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="sbastien-desprez-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21429/photo_resized.jpg"
                                alt="Sébastien Desprez"
                                title="Sébastien Desprez"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Sébastien Desprez</h2>
                              <p class="title">VP, Communications</p>
                              <p>
                                Sébastien became Head of Communications
                                mid-2022. Prior to Immunocore, he worked at
                                Adaptimmune, where he was VP, Communications and
                                Investor Relations. In this role, as well as at
                                ViiV Healthcare and GSK Oncology, he built and
                                led integrated communications strategies and
                                teams. He started his communications career at
                                Rhone-Poulenc Rorer, in Paris, before working in
                                various agencies, including his last agency role
                                at Ketchum, where he led the UK and European
                                healthcare teams from 2002 to 2011. Sébastien
                                holds a master’s degree in interpretation and
                                translation from Université Paris 8, Paris.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row team-row">
                  <div class="col-md-4 member-holder" id="john-goll">
                    <div class="member-wrap">
                      <a href="#john-goll-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21925/photo_card.jpg"
                              alt="John Goll"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>John Goll</h2>
                            <p class="title">
                              SVP, Finance and Chief Accounting Officer
                            </p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="john-goll-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21925/photo_resized.jpg"
                                alt="John Goll"
                                title="John Goll"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>John Goll</h2>
                              <p class="title">
                                SVP, Finance and Chief Accounting Officer
                              </p>
                              <p>
                                John Goll has more than 25 years of financial
                                experience within the life sciences industry,
                                most recently serving as Insmed’s SVP, Chief
                                Accounting Officer. In this role, he managed
                                most of the financial activities for the
                                company, including accounting, audit, internal
                                controls, tax, treasury, and international
                                finance operations. During his nine years at
                                Insmed, he helped to raise approximately $3.5
                                billion in capital through secondary offerings,
                                convertible notes, and other debt financing.
                                John has extensive experience in financial
                                management, FP&amp;A, global operations,
                                corporate finance and accounting, global
                                commercial product launches, SEC compliance and
                                financial assessment of business development and
                                M&amp;A opportunities.
                              </p>
                              <p>
                                Prior to joining Insmed, John served as VP,
                                Corporate Controller at Warner Chilcott, a
                                global, commercial specialty pharmaceuticals
                                company, from May 2005 to February 2014 (upon
                                acquisition by Allergan). At Warner Chilcott, he
                                was responsible for accounting, audit, global
                                financial operations and long-range planning,
                                treasury, and corporate strategy/M&amp;A.
                              </p>
                              <p>
                                Having started his career in public accounting,
                                John held roles of increasing responsibility in
                                accounting and FP&amp;A in industry. John earned
                                a Bachelor of Accounting degree from The College
                                of New Jersey and a Master of Business
                                Administration with honors in finance from
                                Fairleigh Dickinson University. He is a
                                Certified Public Accountant and serves on the
                                Board of Directors of the Boys &amp; Girls Clubs
                                of Northwest New Jersey (non-profit).
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="andrew-hooker-phd">
                    <div class="member-wrap">
                      <a
                        href="#andrew-hooker-phd-info-box"
                        class="fancybox-bio"
                      >
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21416/photo_card.jpg"
                              alt="Andrew Hooker, PhD"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Andrew Hooker, PhD</h2>
                            <p class="title">
                              SVP, Chemistry, Manufacturing &amp; Controls (CMC)
                              and Supply Chain
                            </p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="andrew-hooker-phd-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21416/photo_resized.jpg"
                                alt="Andrew Hooker, PhD"
                                title="Andrew Hooker, PhD"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Andrew Hooker, PhD</h2>
                              <p class="title">
                                SVP, Chemistry, Manufacturing &amp; Controls
                                (CMC) and Supply Chain
                              </p>
                              <p>
                                Dr Andy Hooker joined Immunocore in 2018, and is
                                SVP of Chemistry, Manufacturing &amp; Controls
                                (CMC) and Supply Chain. He has global
                                responsibility for analytical, formulation,
                                stability, bioprocess development, GMP
                                biomanufacturing, and supply chain for
                                Immunocore, supporting both clinical development
                                and commercial life cycle programs.
                              </p>
                              <p>
                                Andy has more than 25 years of CMC leadership
                                experience, having held a number of senior CMC
                                positions with various pharmaceutical companies,
                                including Ipsen Bioinnovation, and UCB, where he
                                successfully supported the CMC regulatory
                                commercial approval of Cimzia, in both the US
                                and EU, for the treatment of Crohn’s disease and
                                rheumatoid arthritis. Andy was also previously a
                                CMC group leader with Pfizer R&amp;D in the UK,
                                supporting the progression of oncology
                                monoclonal antibody candidates through early
                                clinical development. He has also held the
                                position of Head of Analytical Services with the
                                proteomics company Oxford GlycoSciences. Andy
                                has a PhD in Biochemistry from the University of
                                Kent and a BSc (Hons) in Biochemistry from the
                                University of East Anglia.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="elizabeth-jobes">
                    <div class="member-wrap">
                      <a href="#elizabeth-jobes-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21927/photo_card.jpg"
                              alt="Elizabeth Jobes"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Elizabeth Jobes</h2>
                            <p class="title">VP, Compliance</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="elizabeth-jobes-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21927/photo_resized.jpg"
                                alt="Elizabeth Jobes"
                                title="Elizabeth Jobes"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Elizabeth Jobes</h2>
                              <p class="title">VP, Compliance</p>
                              <p>
                                Elizabeth Jobes is Chief Compliance Officer at
                                Immunocore, having joined in January 2024. Most
                                recently, Elizabeth worked at Amryt
                                Pharmaceuticals, which was sold in 2023. She has
                                also had compliance officer roles at EMD Serono,
                                Spark Therapeutics, Auxilium Pharmaceuticals,
                                Adolor Inc. and Cephalon Inc.
                              </p>
                              <p>
                                Elizabeth is a non-executive board member of
                                Eyam Vaccines and Immunotherapeutics, Ampio
                                Pharmaceuticals and Blue Foundry Bank. Elizabeth
                                obtained her JD from Rutgers Law School and a
                                Bachelor of Arts in Foreign Service and
                                International Politics from Pennsylvania State
                                University, State College PA.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row team-row">
                  <div class="col-md-4 member-holder" id="stephen-megit-phd">
                    <div class="member-wrap">
                      <a
                        href="#stephen-megit-phd-info-box"
                        class="fancybox-bio"
                      >
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21426/photo_card.jpg"
                              alt="Stephen Megit, PhD"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Stephen Megit, PhD</h2>
                            <p class="title">VP, Business Development</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="stephen-megit-phd-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21426/photo_resized.jpg"
                                alt="Stephen Megit, PhD"
                                title="Stephen Megit, PhD"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Stephen Megit, PhD</h2>
                              <p class="title">VP, Business Development</p>
                              <p>
                                Stephen began his career at Immunocore in 2007,
                                where he has since delivered partnerships with
                                Genentech, GSK, MedImmune and Eli Lilly. He
                                received his doctorate degree from the
                                University of Cardiff.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="mark-moyer">
                    <div class="member-wrap">
                      <a href="#mark-moyer-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21423/photo_card.jpg"
                              alt="Mark Moyer"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Mark Moyer</h2>
                            <p class="title">SVP, Regulatory Sciences</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="mark-moyer-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21423/photo_resized.jpg"
                                alt="Mark Moyer"
                                title="Mark Moyer"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Mark Moyer</h2>
                              <p class="title">SVP, Regulatory Sciences</p>
                              <p>
                                Mark joined Immunocore in 2018 to lead the
                                regulatory affairs function. Prior to
                                Immunocore, Mark was Vice President, Global
                                Regulatory Sciences – Oncology at Bristol-Myers
                                Squibb, where he led regulatory approval for
                                oncology projects, including Opdivo, Empliciti,
                                Yervoy and Sprycel. Before joining BMS, Mark
                                spent 22 years at Sanofi-Aventis Pharmaceuticals
                                where he oversaw the U.S. regulatory development
                                group of 70 professionals for all therapeutic
                                areas. Mark earned his Master of Science degree
                                in Immunology and Biochemistry from SUNY Medical
                                School and his Bachelor of Science degree in
                                Biology/Chemistry from Houghton College.&nbsp;
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="debra-nielsen">
                    <div class="member-wrap">
                      <a href="#debra-nielsen-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21419/photo_card.jpg"
                              alt="Debra Nielsen"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Debra Nielsen</h2>
                            <p class="title">VP, Chief of Staff</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="debra-nielsen-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21419/photo_resized.jpg"
                                alt="Debra Nielsen"
                                title="Debra Nielsen"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Debra Nielsen</h2>
                              <p class="title">VP, Chief of Staff</p>
                              <p>
                                Deb joined Immunocore in 2019. She was
                                previously Head, Executive Communications at
                                MedImmune, the global biopharmaceutical R&amp;D
                                unit of AstraZeneca, which she joined in 2008.
                                Prior to that, Deb established the executive
                                communications and issues management function at
                                CareFirst BlueCross Blue Shield, an independent
                                licensee of the Blue Cross and Blue Shield
                                Association that offers a comprehensive
                                portfolio of health insurance products in
                                Maryland, the District of Columbia and Northern
                                Virginia. Deb received her master’s degree in
                                journalism from the University of Maryland.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row team-row">
                  <div class="col-md-4 member-holder" id="scott-pagendarm">
                    <div class="member-wrap">
                      <a href="#scott-pagendarm-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/22122/photo_card.jpg"
                              alt="Scott Pagendarm"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Scott Pagendarm</h2>
                            <p class="title">
                              VP, Portfolio &amp; Program Management
                            </p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="scott-pagendarm-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/22122/photo_resized.jpg"
                                alt="Scott Pagendarm"
                                title="Scott Pagendarm"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Scott Pagendarm</h2>
                              <p class="title">
                                VP, Portfolio &amp; Program Management
                              </p>
                              <p>
                                Scott joined Immunocore in 2022 as VP, Portfolio
                                &amp; Program Management. Scott's previous roles
                                include positions at Macrogenics, MedImmune and
                                AstraZeneca, with responsibilities in
                                commercial, business development, finance, and
                                business operations, before specializing in
                                Program and Portfolio Management. Scott has led
                                programs and teams of different sizes and
                                scopes, from early research through clinical
                                development, licensing, alliances and portfolio
                                management.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="koustubh-ranade-phd">
                    <div class="member-wrap">
                      <a
                        href="#koustubh-ranade-phd-info-box"
                        class="fancybox-bio"
                      >
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21421/photo_card.jpg"
                              alt="Koustubh Ranade, PhD"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Koustubh Ranade, PhD</h2>
                            <p class="title">SVP, Translational Medicine</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="koustubh-ranade-phd-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21421/photo_resized.jpg"
                                alt="Koustubh Ranade, PhD"
                                title="Koustubh Ranade, PhD"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Koustubh Ranade, PhD</h2>
                              <p class="title">SVP, Translational Medicine</p>
                              <p>
                                Koustubh joined Immunocore in 2019 as Head of
                                Translational Medicine. He was most recently
                                Vice President of Translational Medicine at
                                MedImmune for all therapeutic areas, where he
                                contributed to four product approvals, including
                                durvalumab. With over three decades of research
                                experience at Genentech, BMS, Stanford and the
                                National Institutes of Health, Koustubh is a
                                co-inventor on many patents, has published
                                extensively in top-tier journals and has edited
                                a book on the application of genomics to drug
                                development. Koustubh received his PhD in
                                Molecular Genetics from the University of
                                Massachusetts Medical School.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="clayton-robertson">
                    <div class="member-wrap">
                      <a
                        href="#clayton-robertson-info-box"
                        class="fancybox-bio"
                      >
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21418/photo_card.jpg"
                              alt="Clayton Robertson"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Clayton Robertson</h2>
                            <p class="title">Head of Investor Relations</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="clayton-robertson-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21418/photo_resized.jpg"
                                alt="Clayton Robertson"
                                title="Clayton Robertson"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Clayton Robertson</h2>
                              <p class="title">Head of Investor Relations</p>
                              <p>
                                Clayton joined Immunocore in 2021. Most
                                recently, he served as Director of Investor
                                Relations at Actinium Pharmaceuticals and
                                PureTech Health. Prior to that, he led the
                                investor relations efforts at Achillion
                                Pharmaceuticals. He began his career in biotech
                                investor relations and capital markets at The
                                Trout Group, a premier life sciences advisory
                                firm. Clayton holds a Bachelor of Arts degree
                                from the University of Pennsylvania.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row team-row">
                  <div class="col-md-4 member-holder" id="joann-suzich-phd">
                    <div class="member-wrap">
                      <a href="#joann-suzich-phd-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21420/photo_card.jpg"
                              alt="JoAnn Suzich, PhD"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>JoAnn Suzich, PhD</h2>
                            <p class="title">SVP, Research</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="joann-suzich-phd-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21420/photo_resized.jpg"
                                alt="JoAnn Suzich, PhD"
                                title="JoAnn Suzich, PhD"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>JoAnn Suzich, PhD</h2>
                              <p class="title">SVP, Research</p>
                              <p>
                                JoAnn joined Immunocore in April 2020 after more
                                than 30 years at MedImmune/AstraZeneca, where
                                she most recently was Head of Microbial
                                Sciences. Prior to that role, JoAnn led the
                                Research function at MedImmune. Long considered
                                one of the world’s leading infectious disease
                                experts, JoAnn was responsible for critical
                                studies that led to the development of the human
                                papilloma virus vaccine for preventing cervical
                                cancer. She also led the research and early
                                development of nirsevimab, a monoclonal antibody
                                for the prevention of serious lower respiratory
                                disease caused by respiratory syncytial virus
                                (RSV) in infants.
                              </p>
                              <p>
                                JoAnn serves on the Board of Trustees of the
                                Sabin Vaccine Institute and Susquehanna
                                University. She earned her PhD in Biochemistry
                                from Purdue University and completed a
                                Postdoctoral Fellowship in Biochemistry at the
                                University of Minnesota.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="john-trainer">
                    <div class="member-wrap">
                      <a href="#john-trainer-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21926/photo_card.jpg"
                              alt="John Trainer"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>John Trainer</h2>
                            <p class="title">SVP, Chief Operating Officer</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="john-trainer-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21926/photo_resized.jpg"
                                alt="John Trainer"
                                title="John Trainer"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>John Trainer</h2>
                              <p class="title">SVP, Chief Operating Officer</p>
                              <p>
                                John Trainer joined Immunocore in 2023.
                                Previously, he was the Chief Financial Officer
                                at NexImmune (NEXI), where he was responsible
                                for ex-R&amp;D company operations. Prior to
                                that, John was in various positions of senior
                                leadership in the transactional, financial, and
                                commercial organizations at AstraZeneca and its
                                R&amp;D subsidiary, MedImmune. John has an MBA
                                from Harvard Business School and an AB from
                                Harvard College.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-4 member-holder"
                    id="annelise-vuidepot-phd"
                  >
                    <div class="member-wrap">
                      <a
                        href="#annelise-vuidepot-phd-info-box"
                        class="fancybox-bio"
                      >
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21417/photo_card.jpg"
                              alt="Annelise Vuidepot, PhD"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Annelise Vuidepot, PhD</h2>
                            <p class="title">
                              SVP, Chief Technology Officer, Research and UK
                              Site Lead
                            </p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="annelise-vuidepot-phd-info-box"
                        style={{
                          maxWidth: "960px",
                        }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21417/photo_resized.jpg"
                                alt="Annelise Vuidepot, PhD"
                                title="Annelise Vuidepot, PhD"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Annelise Vuidepot, PhD</h2>
                              <p class="title">
                                SVP, Chief Technology Officer, Research and UK
                                Site Lead
                              </p>
                              <p>
                                Annelise joined Immunocore when it was founded
                                (as Avidex) in 2000 and has played a lead role
                                in the design and engineering of the ImmTAX
                                platform, pioneering a novel class of TCR based
                                therapeutics.
                              </p>
                              <p>
                                She is currently leading Immunocore’s drug
                                discovery pipeline and research to expand the
                                ImmTAX technology further. She is also
                                Immunocore’s UK Site Head.
                              </p>
                              <p>
                                Before joining Immunocore, Annelise was a
                                postdoctoral researcher in the Biochemistry
                                department at University College London. She
                                gained her master’s degree in chemistry from the
                                University of Paris and her PhD in Biophysics
                                from the École Polytechnique in Saclay, France.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default ManagementMain;
