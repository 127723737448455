import React from "react";

const ModernMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <main id="mainContent" class="main-content">
            <p>
              We are a pioneering, commercial-stage T cell receptor
              biotechnology company working to develop and commercialise a new
              generation of transformative medicines to address unmet needs in
              cancer, infection and autoimmune disease.
            </p>
            <p>
              We provide employees with an anonymous whistleblower website and
              hotline to raise concerns in confidence and maintain a system for
              employees to report violations of our Code of Business Conduct and
              Ethics to our Compliance Officer. Immunocore Holdings plc, and its
              Audit Committee, work to make sure all complaints and alleged
              violations are fully investigated and resolved.
            </p>
            <p>
              We seek to ensure that we create and maintain an inclusive
              workplace, where everyone feels welcome. Our employment practices
              comply with applicable employment and health and safety
              legislation in the jurisdictions in which we carry on business.
              Additionally, through our Code of Business Conduct and Ethics we
              require all employees to perform their duties ethically, honestly
              and with integrity, including acting responsibly in our
              relationships with suppliers.&nbsp;
            </p>
            <p>
              We are not aware of any modern slavery or human trafficking taking
              place in our business or supply chain, and we shall continue to
              apply appropriate due diligence processes to ensure compliance
              with all national and international legislation on modern slavery
              and human trafficking.
            </p>
          </main>
        </div>
      </div>
    </div>
  );
};

export default ModernMain;
