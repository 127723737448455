import React from "react";

const CookiesMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <main id="mainContent" class="main-content">
            <p>
              This website is owned by Immunocore. Here is our cookie policy for
              this site and the types of cookies we use on it.
            </p>
            <h2>What are cookies?</h2>
            <p>
              Cookies are small files that websites place on the device you’re
              using to browse the site. Cookies are commonly used to record some
              aspect of your visit to a site, such as choices you’ve made or
              preferences you’ve set during your visit.
            </p>
            <h2>How and why we use cookies</h2>
            <p>
              We use cookies to give you the best experience when you visit our
              website. By using cookies, we can make it easier for you to do
              many things, such as managing your accounts, policies, or login
              details, and applying for products and services. Cookies can also
              allow us to tailor the content of our website so we can show you
              services or adverts we think you may be interested in.
            </p>
            <h2>Keeping your personal information safe</h2>
            <p>
              Our cookies do not store personal information such as your name,
              address, phone number, email or IP address in a format that can be
              read by others. The cookies we use cannot read or search your
              computer, smartphone or web-enabled device to obtain information
              about you or your family, or read any material kept on your hard
              drive.
            </p>
            <p>
              We do use a small number of cookies that store encrypted versions
              of information where you have asked us to, such as the ‘Remember
              me’ function that allows us to remember your Account username for
              subsequent visits.
            </p>
            <p>
              However, this is encrypted so that only Immunocore&nbsp;can read
              this information. The website that places a cookie owns that
              cookie. This means only that website and other sites that it has
              agreed to share information with can read the information stored
              using a cookie.
            </p>
            <h2>Links to sites provided by others</h2>
            <p>
              If you follow a link from our website to another website, please
              be aware that the owner of the other website will have their own
              privacy and cookie policies for their site. We recommend you read
              their policies as we are not responsible or liable for what
              happens at their site.
            </p>
            <h2>How to manage and switch off cookies</h2>
            <p>
              Your web browser provides settings that allow you to manage or
              switch off cookies. If you do switch off cookies, remember that
              you may not be able to use all of the services on our websites.
              <br />
              You can find out more about managing and switching off cookies at
              the independent website&nbsp;
              <a
                href="http://www.allaboutcookies.org/"
                target="_blank"
                rel="noopener"
              >
                www.allaboutcookies.org
              </a>
              .
            </p>
            <h2>The types of cookies we use on this site</h2>
            <p>
              <strong>Strictly necessary cookies</strong>
            </p>
            <p>
              These cookies are essential for our online services and tools to
              work. They collect or record information that we need to make our
              site work.
            </p>
            <p>Common uses for this type of cookie include:</p>
            <ul>
              <li>
                storing information so that shopping baskets can be provided
              </li>
              <li>storing information for e-billing</li>
              <li>
                allowing users to log in or access private areas of a site.
              </li>
            </ul>
            <p>
              These cookies only last for a single browsing session – when you
              leave our site, they are removed.
            </p>
            <p>
              <strong>Performance cookies</strong>
            </p>
            <p>
              These cookies help us to monitor and improve how our website
              works.
            </p>
            <p>Common uses for this type of cookie include:</p>
            <ul>
              <li>
                collecting information about which pages visitors go to most
                often
              </li>
              <li>noting if visitors get any error messages from web pages</li>
              <li>
                understanding which links visitors like to follow (including
                adverts) and which they choose not to.
              </li>
            </ul>
            <p>
              The information collected using these cookies is anonymous and
              cannot be used to identify an individual visitor.
            </p>
            <p>
              <strong>Functionality cookies</strong>
            </p>
            <p>
              These cookies allow us to provide you with enhanced features that
              need to remember your preferences and choices, such as your user
              name, language or region. They also let us monitor how our website
              is performing so we can keep on improving it.
            </p>
            <p>Common uses for this type of cookie include:</p>
            <ul>
              <li>
                providing you with local information, such as health providers
                in your area
              </li>
              <li>
                remembering how you like to use our site, such as what text size
                you like or what media software you use
              </li>
              <li>
                remembering what topics you’re interested in so we can provide
                you with related products, services and information.
              </li>
            </ul>
            <p>
              The information we store in these cookies is encrypted so that
              only we can read it.
            </p>
          </main>
        </div>
      </div>
    </div>
  );
};

export default CookiesMain;
