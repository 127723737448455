import React from "react";

const BodMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <main id="mainContent" class="main-content">
            <h2>
              <span>Guiding our bold mission</span>
            </h2>
            <div class="member-blocks">
              <div class="container">
                <div class="row team-row">
                  <div
                    class="col-md-4 member-holder"
                    id="professor-sir-john-bell"
                  >
                    <div class="member-wrap">
                      <a
                        href="#professor-sir-john-bell-info-box"
                        class="fancybox-bio"
                      >
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21430/photo_card.jpg"
                              alt="Professor Sir John Bell"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Professor Sir John Bell</h2>
                            <p class="title">Chairman of the Board</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="professor-sir-john-bell-info-box"
                        style={{ maxWidth: "960px" }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21430/photo_resized.jpg"
                                alt="Professor Sir John Bell"
                                title="Professor Sir John Bell"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Professor Sir John Bell</h2>
                              <p class="title">Chairman of the Board</p>
                              <p>
                                Professor Sir John Bell was appointed to the
                                Immunocore Board in March 2015. Professor Bell
                                is the Regius Professor of Medicine at Oxford
                                University and Chairman of the Office for the
                                Strategic Coordination of Health Research. He is
                                regarded as one of the world’s most
                                distinguished scientists in the fields of
                                genomic and genetic research and immunology,
                                also has significant experience in the
                                Healthcare industry, having been a founding
                                director at three biotechnology companies;
                                Avidex Ltd (a precursor to Immunocore which was
                                acquired by MediGene in 2006), Oxagen and
                                PowderJect Pharmaceuticals plc (acquired by
                                Chiron Corporation in 2003). He currently also
                                serves on the boards of Roche and Genentech and
                                had a previous role on the scientific advisory
                                board at AstraZeneca. Professor Bell was
                                involved in the founding of the Wellcome Trust
                                Centre for Human Genetics at Oxford University,
                                now chairs the scientific committee of UK
                                Biobank and the Global Health Scientific
                                Advisory Board of the Bill and Melinda Gates
                                Foundation, and is one of three Life Science
                                Champions for the UK, reporting to the UK Prime
                                Minister. His research has contributed
                                significantly to the understanding of immune
                                activation in a number of auto-immune diseases.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="bahija-jallal-phd">
                    <div class="member-wrap">
                      <a
                        href="#bahija-jallal-phd-info-box"
                        class="fancybox-bio"
                      >
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21412/photo_card.jpg"
                              alt="Bahija Jallal, PhD"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Bahija Jallal, PhD</h2>
                            <p class="title">Chief Executive Officer</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="bahija-jallal-phd-info-box"
                        style={{ maxWidth: "960px" }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21412/photo_resized.jpg"
                                alt="Bahija Jallal, PhD"
                                title="Bahija Jallal, PhD"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Bahija Jallal, PhD</h2>
                              <p class="title">Chief Executive Officer</p>
                              <p>
                                Dr Bahija Jallal is Chief Executive Officer and
                                Director of the Board of Immunocore. Prior to
                                joining Immunocore in January 2019, she was
                                President of MedImmune, AstraZeneca’s global
                                biologics research and development unit. She was
                                also Executive Vice President of AstraZeneca and
                                a member of its senior executive team.
                              </p>
                              <p>
                                Dr Jallal serves on the Board of Elevance, Inc.
                                and is a member of the Board of Trustees of
                                Johns Hopkins University and the Board of
                                Directors of the University of Maryland Health
                                Sciences Research Park Corporation.
                              </p>
                              <p>
                                Bahija has authored more than 70 peer-reviewed
                                publications and has more than 15 patents. She
                                is a Council Member of the
                                Government-University-Industry Research
                                Roundtable of the National Academies of
                                Sciences, Engineering and Medicine. She is also
                                the immediate past president of the Association
                                of Women in Science. Bahija was named the 2017
                                Woman of the Year by the Healthcare
                                Businesswomen’s Association.
                              </p>
                              <p>
                                Prior to joining MedImmune, Bahija was Vice
                                President, Drug Assessment and Development, at
                                Chiron Corporation, where she successfully
                                established the company’s translational medicine
                                group. Prior to Chiron, she was part of the
                                research team at Sugen, Inc.
                              </p>
                              <p>
                                Bahija received her PhD in Physiology from
                                Université de Paris VI, France and conducted her
                                post-doctorate work in molecular biology and
                                oncology at the Max Planck Institute for
                                Biochemistry in Germany.&nbsp;
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="mr-travis-coy">
                    <div class="member-wrap">
                      <a href="#mr-travis-coy-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21431/photo_card.jpg"
                              alt="Mr. Travis Coy"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Mr. Travis Coy</h2>
                            <p class="title">Non-Executive Director</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="mr-travis-coy-info-box"
                        style={{ maxWidth: "960px" }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21431/photo_resized.jpg"
                                alt="Mr. Travis Coy"
                                title="Mr. Travis Coy"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Mr. Travis Coy</h2>
                              <p class="title">Non-Executive Director</p>
                              <p>
                                Travis Coy is currently Vice President,
                                Transactions - Oncology and Diagnostics,
                                Corporate Business Development at Eli Lilly and
                                Company. Prior to this role, Mr. Coy was
                                responsible for leading and managing business
                                development transactions for Lilly’s
                                cardiometabolic disease, drug delivery, and
                                device efforts. He has had a variety of finance
                                and business development experiences at Lilly,
                                including positions as Finance Director of
                                Lilly’s Oncology Business Unit, Director of
                                Investor Relations, Director of Corporate
                                Finance and Investment Banking, and other
                                financial controllership roles. Travis’ business
                                development experiences have encompassed
                                responsibilities ranging from managing the
                                strategic and economic analyses of transactions
                                to leading contract negotiations to having
                                financial leadership of alliances. Before
                                transitioning to finance and business
                                development, he was a chemist in Lilly’s
                                research laboratories and a production manager
                                for Milliken &amp; Company.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row team-row">
                  <div class="col-md-4 member-holder" id="roy-s-herbst-md-phd">
                    <div class="member-wrap">
                      <a
                        href="#roy-s-herbst-md-phd-info-box"
                        class="fancybox-bio"
                      >
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21432/photo_card.jpg"
                              alt="Roy S. Herbst, MD, PhD"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Roy S. Herbst, MD, PhD</h2>
                            <p class="title">Non-Executive Director</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="roy-s-herbst-md-phd-info-box"
                        style={{ maxWidth: "960px" }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21432/photo_resized.jpg"
                                alt="Roy S. Herbst, MD, PhD"
                                title="Roy S. Herbst, MD, PhD"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Roy S. Herbst, MD, PhD</h2>
                              <p class="title">Non-Executive Director</p>
                              <p>
                                Ensign Professor of Medicine (Medical Oncology)
                                and Professor of Pharmacology; Chief of Medical
                                Oncology, Yale Cancer Center and Smilow Cancer
                                Hospital; Associate Cancer Center Director for
                                Translational Research, Yale Cancer Center, New
                                Haven, CT, USA
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="siddharth-sid-kaul">
                    <div class="member-wrap">
                      <a
                        href="#siddharth-sid-kaul-info-box"
                        class="fancybox-bio"
                      >
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21433/photo_card.jpg"
                              alt="Siddharth (Sid) Kaul"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Siddharth (Sid) Kaul</h2>
                            <p class="title">Non-Executive Director</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="siddharth-sid-kaul-info-box"
                        style={{ maxWidth: "960px" }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21433/photo_resized.jpg"
                                alt="Siddharth (Sid) Kaul"
                                title="Siddharth (Sid) Kaul"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Siddharth (Sid) Kaul</h2>
                              <p class="title">Non-Executive Director</p>
                              <p>
                                Sid Kaul was appointed to the Immunocore Board
                                of Directors as a Non-Executive Director in June
                                2022. Sid retired from Novartis as Group
                                Treasurer and Head of Business Planning and
                                Analysis in April 2021 after a 17 year career
                                there. He led Novartis’s Strategic Review and
                                Spin-Off for the Alcon (eye care) business. Sid
                                joined Novartis as CFO Pharma Europe and
                                subsequently served as CFO Pharma US following a
                                20-year career with Procter &amp; Gamble in
                                Financial Planning &amp; Analysis, Strategic
                                Planning, Accounting and Auditing in the US and
                                Japan.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="ranjeev-krishana">
                    <div class="member-wrap">
                      <a href="#ranjeev-krishana-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/22131/photo_card.jpg"
                              alt="Ranjeev Krishana"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Ranjeev Krishana</h2>
                            <p class="title">Non-Executive Director</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="ranjeev-krishana-info-box"
                        style={{ maxWidth: "960px" }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/22131/photo_resized.jpg"
                                alt="Ranjeev Krishana"
                                title="Ranjeev Krishana"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Ranjeev Krishana</h2>
                              <p class="title">Non-Executive Director</p>
                              <p>
                                Ranjeev Krishana serves as a member of our Board
                                of Directors. Mr. Krishana is a Partner at Baker
                                Bros. Advisors LP. Prior to joining Baker Bros.
                                in 2011, Mr. Krishana held a series of
                                commercial, strategy, and business development
                                leadership roles for Pfizer, Inc.’s
                                pharmaceutical business across a variety of
                                international regions and markets, including
                                Asia, Europe, and Latin America. Mr. Krishana
                                was at Pfizer from 2003 to 2007 and from 2008 to
                                2011. Mr. Krishana began his career as a
                                strategy consultant at Accenture plc. Mr.
                                Krishana currently serves on the boards of
                                BeiGene (Lead Independent Director), Sironax,
                                and ARTBio.
                              </p>
                              <p>
                                Mr. Krishana holds a B.A. in Economics and
                                Political Science from Brown University, and a
                                Masters of Public Policy from Harvard
                                University.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row team-row">
                  <div class="col-md-4 member-holder" id="mr-rob-perez">
                    <div class="member-wrap">
                      <a href="#mr-rob-perez-info-box" class="fancybox-bio">
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21434/photo_card.jpg"
                              alt="Mr. Rob Perez"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Mr. Rob Perez</h2>
                            <p class="title">Non-Executive Director</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="mr-rob-perez-info-box"
                        style={{ maxWidth: "960px" }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21434/photo_resized.jpg"
                                alt="Mr. Rob Perez"
                                title="Mr. Rob Perez"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Mr. Rob Perez</h2>
                              <p class="title">Non-Executive Director</p>
                              <p>
                                Rob Perez is an Operating Partner and part of
                                General Atlantic’s Operations Group, providing
                                strategic support and advice to the firm’s
                                investment team and portfolio companies with a
                                particular focus on the biopharma and life
                                sciences sectors. Before joining General
                                Atlantic in 2019, he was Managing Director of
                                Vineyard Sound Advisors, LLC, an advisory
                                practice focused on growth companies in the
                                biopharma industry. Prior to that, Rob was
                                President and CEO of Cubist Pharmaceuticals,
                                Inc. before its sale to Merck in 2015. Before
                                joining Cubist in 2003, he served as Vice
                                President of Biogen, Inc.’s CNS Business Unit.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="ms-kristine-peterson">
                    <div class="member-wrap">
                      <a
                        href="#ms-kristine-peterson-info-box"
                        class="fancybox-bio"
                      >
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21435/photo_card.jpg"
                              alt="Ms. Kristine Peterson"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Ms. Kristine Peterson</h2>
                            <p class="title">Non-Executive Director</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="ms-kristine-peterson-info-box"
                        style={{ maxWidth: "960px" }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21435/photo_resized.jpg"
                                alt="Ms. Kristine Peterson"
                                title="Ms. Kristine Peterson"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Ms. Kristine Peterson</h2>
                              <p class="title">Non-Executive Director</p>
                              <p>
                                Kristine Peterson was appointed to the
                                Immunocore Board of Directors as a Non-Executive
                                Director in November 2017. Ms. Peterson has more
                                than 30 years’ experience in the global
                                pharmaceutical and biotech industry. Her most
                                recent role was as Chief Executive Officer for
                                Valeritas, Inc., where she led the company’s
                                development from early stage R&amp;D through to
                                commercialisation, achieving approval by the US
                                Food and Drug Administration (FDA) and European
                                Medicines Agency (EMA) for the company’s Type 2
                                diabetes drug-device. In addition, Kristine
                                raised more than $200 million in equity
                                financing and prepared the company for its
                                public listing on NASDAQ. Prior to Valeritas,
                                Kristine was Company Group Chair at Johnson
                                &amp; Johnson for their worldwide biotech and
                                oncology groups, growing those businesses to
                                more than $6 billion in sales, launching several
                                new products, and re-establishing the foundation
                                for oncology R&amp;D. She was also President and
                                Senior Vice President, Commercial Operations for
                                Biovail Corporation with responsibility for the
                                US and Canadian business units. Earlier in her
                                career, Kristine spent 20 years at Bristol-Myers
                                Squibb in a variety of senior roles, including
                                running their cardiovascular and metabolics
                                business unit.
                              </p>
                              <p>
                                Kristine continues to play a role on several
                                other Boards of Directors at pharmaceutical and
                                biotech companies including Amarin Corporation,
                                Paratek Pharmaceuticals, Enanta Pharmaceutics,
                                pSivida, and ImmunoGen. She is also a senior
                                advisor to the Healthcare Businesswomen’s
                                Association and a former Member of the
                                Biotechnology Industry Organization Board.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 member-holder" id="peter-j-ratcliffe">
                    <div class="member-wrap">
                      <a
                        href="#peter-j-ratcliffe-info-box"
                        class="fancybox-bio"
                      >
                        <div class="card-container">
                          <div class="member-module">
                            <div class="member-module-overlay dark">
                              <div class="vertical-element">
                                <span class="btn">View Bio</span>
                              </div>
                            </div>
                            <img
                              src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21436/photo_card.jpg"
                              alt="Peter J. Ratcliffe"
                            />
                          </div>
                          <div class="member-info" data-mh="member-title">
                            <h2>Peter J. Ratcliffe</h2>
                            <p class="title">Non-Executive Director</p>
                          </div>
                        </div>
                      </a>
                      <div
                        class="member-modal member-desktop"
                        id="peter-j-ratcliffe-info-box"
                        style={{ maxWidth: "960px" }}
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="image">
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21436/photo_resized.jpg"
                                alt="Peter J. Ratcliffe"
                                title="Peter J. Ratcliffe"
                              />
                            </div>
                          </div>
                          <div class="col-sm-9">
                            <div class="text">
                              <h2>Peter J. Ratcliffe</h2>
                              <p class="title">Non-Executive Director</p>
                              <p>
                                Professor Sir Peter Ratcliffe has over 30 years’
                                experience as a clinically active physician
                                scientist. He currently serves as Director of
                                Clinical Research at The Francis Crick Institute
                                in London and Director of the Target Discovery
                                Institute and Distinguished Scholar of the
                                Ludwig Institute for Cancer Research within the
                                Nuffield Department of Medicine at the
                                University of Oxford. Sir Peter was elected to
                                the Fellowship of the Royal Society and to the
                                Academy of Medical Sciences in 2002. He is a
                                member of European Molecular Biology
                                Organization (EMBO), a foreign honorary member
                                of the American Academy of Arts and Sciences and
                                a Fellow of the American Association for Cancer
                                Research (AACR) Academy. His work has been
                                recognised through a number of awards, including
                                the Louis‐Jeantet Prize in Medicine, the Canada
                                Gairdner International Award, and the Lasker
                                Award for Basic Biomedical Research. In 2019, he
                                shared the Nobel Prize for Physiology or
                                Medicine for discovery of the key mechanisms
                                that cells use to detect and respond to hypoxia
                                (low oxygen levels), opening up promising new
                                ways to tackle cancer, anaemia and many other
                                diseases. He was knighted for services to
                                medicine in 2014.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default BodMain;
