import React from "react";

const FaqMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <aside id="tertiaryNav">
            <nav id="tertiary-navigation">
              <ul>
                <li class="sidebar-title">
                  <h2>IR Resources</h2>
                </li>
                <li class="active active-landing">
                  <a
                    href="/investors/ir-resources/investor-faqs"
                    target="_self"
                  >
                    Investor FAQs
                  </a>
                </li>
                <li class="">
                  <a href="/investors/ir-resources/email-alerts" target="_self">
                    Email Alerts
                  </a>
                </li>
                <li class="">
                  <a href="/investors/ir-resources/contacts" target="_self">
                    Contacts
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <main id="mainContent" class="main-content has-tertiary-nav">
            <dl class="spr-ir-faq">
              <dt class="question">
                <strong>
                  What is Immunocore’s role in the fight against devastating
                  diseases?
                </strong>
              </dt>
              <dd class="answer">
                <p>
                  Immunocore is a late-stage biotechnology company pioneering
                  the development of a novel class of TCR bispecific
                  immunotherapies to treat a broad range of diseases including
                  cancer, infection and autoimmune diseases.
                </p>
              </dd>
              <dt class="question">
                <strong>Where is Immunocore located?</strong>
              </dt>
              <dd class="answer">
                <p>
                  Immunocore’s principal offices are located at 92 Park Drive,
                  Milton Park, Abindgon, Oxfordshire OX14 4RY, United Kingdom.
                  The company also has offices at Six Tower Bridge, Suite 200,
                  181 Washington Street, Conshohocken, Pennsylvania 19428 and
                  2273 Research Boulevard, Suite 220, Rockville, Maryland 20850,
                  United States.
                </p>
              </dd>
              <dt class="question">
                <strong>
                  Where is Immunocore’s common stock listed and what is the
                  ticker symbol?
                </strong>
              </dt>
              <dd class="answer">
                <p>
                  Immunocore is listed on the Nasdaq Global Market under the
                  ticker symbol IMCR.
                </p>
              </dd>
              <dt class="question">
                <strong>When did Immunocore go public?</strong>
              </dt>
              <dd class="answer">
                <p>
                  Immunocore began trading common stock shares on the Nasdaq
                  Global Market on February 5, 2021.
                </p>
              </dd>
              <dt class="question">
                <strong>Where can I purchase shares of Immunocore?</strong>
              </dt>
              <dd class="answer">
                <p>
                  To purchase Immunocore shares, you must have an account with a
                  financial intermediary (bank, brokerage firm, online broker,
                  etc.) and place your order through them.
                </p>
              </dd>
              <dt class="question">
                <strong>When is the fiscal year-end for Immunocore?</strong>
              </dt>
              <dd class="answer">
                <p>Immunocore’s fiscal year-end is on December 31.</p>
              </dd>
              <dt class="question">
                <strong>
                  Who is Immunocore’s Registrar and how do I contact them?
                </strong>
              </dt>
              <dd class="answer">
                <p>
                  Computershare Investor Services PLC
                  <br />
                  The Pavilions
                  <br />
                  Bridgewater Road
                  <br />
                  Bristol
                  <br />
                  BS99 6ZY
                  <br />
                  Phone: 0370 703 0387
                </p>
              </dd>
              <dt class="question">
                <strong>
                  Who is Immunocore’s American Depository Share (“ADS”) bank?
                </strong>
              </dt>
              <dd class="answer">
                <p>
                  Immunocore’s ADS bank is Citibank, N.A. located at 388
                  Greenwich Street, New York, New York 10013.
                </p>
              </dd>
              <dt class="question">
                <strong>Who are Immunocore’s independent auditors?</strong>
              </dt>
              <dd class="answer">
                <p>
                  Deloitte LLP
                  <br />1 Station Square
                  <br />
                  Cambridge, CB1 2GA
                  <br />
                  United Kingdom
                </p>
              </dd>
              <dt class="question">
                <strong>Who is Immunocore’s outside legal counsel?</strong>
              </dt>
              <dd class="answer">
                <p>
                  Cooley LLP
                  <br />
                  55 Hudson Yards
                  <br />
                  New York, New York 10001
                  <br />
                  (212)479-6000
                </p>
              </dd>
            </dl>
          </main>
        </div>
      </div>
    </div>
  );
};

export default FaqMain;
