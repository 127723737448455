import React from 'react'

const BOCMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <aside id="tertiaryNav">
            <nav id="tertiary-navigation">
              <ul>
                <li class="sidebar-title">
                  <h2>Governance</h2>
                </li>
                <li class="">
                  <a href="/investors/corporate-governance" target="_self">
                    Overview
                  </a>
                </li>
                <li class="">
                  <a
                    href="/investors/corporate-governance/governance-documents"
                    target="_self"
                  >
                    Governance Documents
                  </a>
                </li>
                <li class="">
                  <a
                    href="/investors/corporate-governance/management-team"
                    target="_self"
                  >
                    Management Team
                  </a>
                </li>
                <li class="">
                  <a href="/about-us/board-of-directors" target="_self">
                    Board of Directors
                  </a>
                </li>
                <li class="active active-landing">
                  <a
                    href="/investors/corporate-governance/board-committees"
                    target="_self"
                  >
                    Board Committees
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <main id="mainContent" class="main-content has-tertiary-nav">
            <div class="legend legend--icons content-table d-flex mb-0 pb-0">
              <div class="chair">
                <div class="role chair">
                  <div
                    aria-label="Committee chair icon"
                    role="img"
                    class="material-icons"
                  >
                    account_circle
                  </div>
                </div>
                <span class="value">Chair</span>
              </div>
              <div class="member">
                <div class="role member">
                  <div
                    aria-label="Committee member icon"
                    role="img"
                    class="material-icons"
                  >
                    person
                  </div>
                </div>
                <span class="value">Member</span>
              </div>
              <div class="chairman-of-the-board">
                <div class="role chairman-of-the-board">
                  <div
                    aria-label="Chairman of the board icon"
                    role="img"
                    class="material-icons"
                  >
                    stars
                  </div>
                </div>
                <span class="value">Chairman of the board</span>
              </div>
            </div>

            <table class="content-table committee-list">
              <thead>
                <tr>
                  <th class="col-title">Audit Committee</th>
                  <th class="col-role">Position</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#audit-committee-board-bio-mr-travis-coy"
                        class="fancybox-bio"
                      >
                        Mr. Travis Coy
                      </a>
                    </div>
                    <div
                      id="audit-committee-mr-travis-coy"
                      class="member-bio collapse"
                    >
                      <p>
                        Travis Coy is currently Vice President, Transactions -
                        Oncology and Diagnostics, Corporate Business Development
                        at Eli Lilly and Company. Prior to this role, Mr. Coy
                        was responsible for leading and managing business
                        development transactions for Lilly’s cardiometabolic
                        disease, drug delivery, and device efforts. He has had a
                        variety of finance and business development experiences
                        at Lilly, including positions as Finance Director of
                        Lilly’s Oncology Business Unit, Director of Investor
                        Relations, Director of Corporate Finance and Investment
                        Banking, and other financial controllership roles.
                        Travis’ business development experiences have
                        encompassed responsibilities ranging from managing the
                        strategic and economic analyses of transactions to
                        leading contract negotiations to having financial
                        leadership of alliances. Before transitioning to finance
                        and business development, he was a chemist in Lilly’s
                        research laboratories and a production manager for
                        Milliken &amp; Company.
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role chair">
                        <div
                          aria-label="Committee chair icon"
                          role="img"
                          class="material-icons"
                        >
                          account_circle
                        </div>
                      </div>
                      <div class="role-label sr-only">Chair</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#audit-committee-board-bio-roy-s-herbst-md-phd"
                        class="fancybox-bio"
                      >
                        Roy S. Herbst, MD, PhD
                      </a>
                    </div>
                    <div
                      id="audit-committee-roy-s-herbst-md-phd"
                      class="member-bio collapse"
                    >
                      <p>
                        Ensign Professor of Medicine (Medical Oncology) and
                        Professor of Pharmacology; Chief of Medical Oncology,
                        Yale Cancer Center and Smilow Cancer Hospital; Associate
                        Cancer Center Director for Translational Research, Yale
                        Cancer Center, New Haven, CT, USA
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role member">
                        <div
                          aria-label="Committee member icon"
                          role="img"
                          class="material-icons"
                        >
                          person
                        </div>
                      </div>
                      <div class="role-label sr-only">Member</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#audit-committee-board-bio-siddharth-sid-kaul"
                        class="fancybox-bio"
                      >
                        Siddharth (Sid) Kaul
                      </a>
                    </div>
                    <div
                      id="audit-committee-siddharth-sid-kaul"
                      class="member-bio collapse"
                    >
                      <p>
                        Sid Kaul was appointed to the Immunocore Board of
                        Directors as a Non-Executive Director in June 2022. Sid
                        retired from Novartis as Group Treasurer and Head of
                        Business Planning and Analysis in April 2021 after a 17
                        year career there. He led Novartis’s Strategic Review
                        and Spin-Off for the Alcon (eye care) business. Sid
                        joined Novartis as CFO Pharma Europe and subsequently
                        served as CFO Pharma US following a 20-year career with
                        Procter &amp; Gamble in Financial Planning &amp;
                        Analysis, Strategic Planning, Accounting and Auditing in
                        the US and Japan.
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role member">
                        <div
                          aria-label="Committee member icon"
                          role="img"
                          class="material-icons"
                        >
                          person
                        </div>
                      </div>
                      <div class="role-label sr-only">Member</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#audit-committee-board-bio-mr-rob-perez"
                        class="fancybox-bio"
                      >
                        Mr. Rob Perez
                      </a>
                    </div>
                    <div
                      id="audit-committee-mr-rob-perez"
                      class="member-bio collapse"
                    >
                      <p>
                        Rob Perez is an Operating Partner and part of General
                        Atlantic’s Operations Group, providing strategic support
                        and advice to the firm’s investment team and portfolio
                        companies with a particular focus on the biopharma and
                        life sciences sectors. Before joining General Atlantic
                        in 2019, he was Managing Director of Vineyard Sound
                        Advisors, LLC, an advisory practice focused on growth
                        companies in the biopharma industry. Prior to that, Rob
                        was President and CEO of Cubist Pharmaceuticals, Inc.
                        before its sale to Merck in 2015. Before joining Cubist
                        in 2003, he served as Vice President of Biogen, Inc.’s
                        CNS Business Unit.
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role member">
                        <div
                          aria-label="Committee member icon"
                          role="img"
                          class="material-icons"
                        >
                          person
                        </div>
                      </div>
                      <div class="role-label sr-only">Member</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#audit-committee-board-bio-peter-j-ratcliffe"
                        class="fancybox-bio"
                      >
                        Peter J. Ratcliffe
                      </a>
                    </div>
                    <div
                      id="audit-committee-peter-j-ratcliffe"
                      class="member-bio collapse"
                    >
                      <p>
                        Professor Sir Peter Ratcliffe has over 30 years’
                        experience as a clinically active physician scientist.
                        He currently serves as Director of Clinical Research at
                        The Francis Crick Institute in London and Director of
                        the Target Discovery Institute and Distinguished Scholar
                        of the Ludwig Institute for Cancer Research within the
                        Nuffield Department of Medicine at the University of
                        Oxford. Sir Peter was elected to the Fellowship of the
                        Royal Society and to the Academy of Medical Sciences in
                        2002. He is a member of European Molecular Biology
                        Organization (EMBO), a foreign honorary member of the
                        American Academy of Arts and Sciences and a Fellow of
                        the American Association for Cancer Research (AACR)
                        Academy. His work has been recognised through a number
                        of awards, including the Louis‐Jeantet Prize in
                        Medicine, the Canada Gairdner International Award, and
                        the Lasker Award for Basic Biomedical Research. In 2019,
                        he shared the Nobel Prize for Physiology or Medicine for
                        discovery of the key mechanisms that cells use to detect
                        and respond to hypoxia (low oxygen levels), opening up
                        promising new ways to tackle cancer, anaemia and many
                        other diseases. He was knighted for services to medicine
                        in 2014.
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role member">
                        <div
                          aria-label="Committee member icon"
                          role="img"
                          class="material-icons"
                        >
                          person
                        </div>
                      </div>
                      <div class="role-label sr-only">Member</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title charter-title">
                    <p data-mh="committee-docs" class="title">
                      Audit Committee Charter
                    </p>
                  </td>
                  <td class="committee-role charter-button">
                    <a
                      data-mh="committee-docs"
                      class="btn"
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2275/20296/file/Immunocore+-+Audit+Committee+Charter+-+FINAL+1+Jan+24.pdf"
                      target="_blank"
                      rel="noopener"
                      aria-label="Download Audit Committee Charter"
                    >
                      Download
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="content-table committee-list">
              <thead>
                <tr>
                  <th class="col-title">Remuneration Committee</th>
                  <th class="col-role">Position</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#remuneration-committee-board-bio-ms-kristine-peterson"
                        class="fancybox-bio"
                      >
                        Ms. Kristine Peterson
                      </a>
                    </div>
                    <div
                      id="remuneration-committee-ms-kristine-peterson"
                      class="member-bio collapse"
                    >
                      <p>
                        Kristine Peterson was appointed to the Immunocore Board
                        of Directors as a Non-Executive Director in November
                        2017. Ms. Peterson has more than 30 years’ experience in
                        the global pharmaceutical and biotech industry. Her most
                        recent role was as Chief Executive Officer for
                        Valeritas, Inc., where she led the company’s development
                        from early stage R&amp;D through to commercialisation,
                        achieving approval by the US Food and Drug
                        Administration (FDA) and European Medicines Agency (EMA)
                        for the company’s Type 2 diabetes drug-device. In
                        addition, Kristine raised more than $200 million in
                        equity financing and prepared the company for its public
                        listing on NASDAQ. Prior to Valeritas, Kristine was
                        Company Group Chair at Johnson &amp; Johnson for their
                        worldwide biotech and oncology groups, growing those
                        businesses to more than $6 billion in sales, launching
                        several new products, and re-establishing the foundation
                        for oncology R&amp;D. She was also President and Senior
                        Vice President, Commercial Operations for Biovail
                        Corporation with responsibility for the US and Canadian
                        business units. Earlier in her career, Kristine spent 20
                        years at Bristol-Myers Squibb in a variety of senior
                        roles, including running their cardiovascular and
                        metabolics business unit.
                      </p>
                      <p>
                        Kristine continues to play a role on several other
                        Boards of Directors at pharmaceutical and biotech
                        companies including Amarin Corporation, Paratek
                        Pharmaceuticals, Enanta Pharmaceutics, pSivida, and
                        ImmunoGen. She is also a senior advisor to the
                        Healthcare Businesswomen’s Association and a former
                        Member of the Biotechnology Industry Organization Board.
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role chair">
                        <div
                          aria-label="Committee chair icon"
                          role="img"
                          class="material-icons"
                        >
                          account_circle
                        </div>
                      </div>
                      <div class="role-label sr-only">Chair</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#remuneration-committee-board-bio-professor-sir-john-bell"
                        class="fancybox-bio"
                      >
                        Professor Sir John Bell
                      </a>
                      &nbsp;
                      <div class="role chairman-of-the-board">
                        <div
                          aria-label="Chairman of the board icon"
                          role="img"
                          class="material-icons"
                        >
                          stars
                        </div>
                      </div>
                    </div>
                    <div
                      id="remuneration-committee-professor-sir-john-bell"
                      class="member-bio collapse"
                    >
                      <p>
                        Professor Sir John Bell was appointed to the Immunocore
                        Board in March 2015. Professor Bell is the Regius
                        Professor of Medicine at Oxford University and Chairman
                        of the Office for the Strategic Coordination of Health
                        Research. He is regarded as one of the world’s most
                        distinguished scientists in the fields of genomic and
                        genetic research and immunology, also has significant
                        experience in the Healthcare industry, having been a
                        founding director at three biotechnology companies;
                        Avidex Ltd (a precursor to Immunocore which was acquired
                        by MediGene in 2006), Oxagen and PowderJect
                        Pharmaceuticals plc (acquired by Chiron Corporation in
                        2003). He currently also serves on the boards of Roche
                        and Genentech and had a previous role on the scientific
                        advisory board at AstraZeneca. Professor Bell was
                        involved in the founding of the Wellcome Trust Centre
                        for Human Genetics at Oxford University, now chairs the
                        scientific committee of UK Biobank and the Global Health
                        Scientific Advisory Board of the Bill and Melinda Gates
                        Foundation, and is one of three Life Science Champions
                        for the UK, reporting to the UK Prime Minister. His
                        research has contributed significantly to the
                        understanding of immune activation in a number of
                        auto-immune diseases.
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role member">
                        <div
                          aria-label="Committee member icon"
                          role="img"
                          class="material-icons"
                        >
                          person
                        </div>
                      </div>
                      <div class="role-label sr-only">Member</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#remuneration-committee-board-bio-mr-travis-coy"
                        class="fancybox-bio"
                      >
                        Mr. Travis Coy
                      </a>
                    </div>
                    <div
                      id="remuneration-committee-mr-travis-coy"
                      class="member-bio collapse"
                    >
                      <p>
                        Travis Coy is currently Vice President, Transactions -
                        Oncology and Diagnostics, Corporate Business Development
                        at Eli Lilly and Company. Prior to this role, Mr. Coy
                        was responsible for leading and managing business
                        development transactions for Lilly’s cardiometabolic
                        disease, drug delivery, and device efforts. He has had a
                        variety of finance and business development experiences
                        at Lilly, including positions as Finance Director of
                        Lilly’s Oncology Business Unit, Director of Investor
                        Relations, Director of Corporate Finance and Investment
                        Banking, and other financial controllership roles.
                        Travis’ business development experiences have
                        encompassed responsibilities ranging from managing the
                        strategic and economic analyses of transactions to
                        leading contract negotiations to having financial
                        leadership of alliances. Before transitioning to finance
                        and business development, he was a chemist in Lilly’s
                        research laboratories and a production manager for
                        Milliken &amp; Company.
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role member">
                        <div
                          aria-label="Committee member icon"
                          role="img"
                          class="material-icons"
                        >
                          person
                        </div>
                      </div>
                      <div class="role-label sr-only">Member</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#remuneration-committee-board-bio-siddharth-sid-kaul"
                        class="fancybox-bio"
                      >
                        Siddharth (Sid) Kaul
                      </a>
                    </div>
                    <div
                      id="remuneration-committee-siddharth-sid-kaul"
                      class="member-bio collapse"
                    >
                      <p>
                        Sid Kaul was appointed to the Immunocore Board of
                        Directors as a Non-Executive Director in June 2022. Sid
                        retired from Novartis as Group Treasurer and Head of
                        Business Planning and Analysis in April 2021 after a 17
                        year career there. He led Novartis’s Strategic Review
                        and Spin-Off for the Alcon (eye care) business. Sid
                        joined Novartis as CFO Pharma Europe and subsequently
                        served as CFO Pharma US following a 20-year career with
                        Procter &amp; Gamble in Financial Planning &amp;
                        Analysis, Strategic Planning, Accounting and Auditing in
                        the US and Japan.
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role member">
                        <div
                          aria-label="Committee member icon"
                          role="img"
                          class="material-icons"
                        >
                          person
                        </div>
                      </div>
                      <div class="role-label sr-only">Member</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#remuneration-committee-board-bio-roy-s-herbst-md-phd"
                        class="fancybox-bio"
                      >
                        Roy S. Herbst, MD, PhD
                      </a>
                    </div>
                    <div
                      id="remuneration-committee-roy-s-herbst-md-phd"
                      class="member-bio collapse"
                    >
                      <p>
                        Ensign Professor of Medicine (Medical Oncology) and
                        Professor of Pharmacology; Chief of Medical Oncology,
                        Yale Cancer Center and Smilow Cancer Hospital; Associate
                        Cancer Center Director for Translational Research, Yale
                        Cancer Center, New Haven, CT, USA
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role member">
                        <div
                          aria-label="Committee member icon"
                          role="img"
                          class="material-icons"
                        >
                          person
                        </div>
                      </div>
                      <div class="role-label sr-only">Member</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title charter-title">
                    <p data-mh="committee-docs" class="title">
                      Remuneration Committee Charter
                    </p>
                  </td>
                  <td class="committee-role charter-button">
                    <a
                      data-mh="committee-docs"
                      class="btn"
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2275/20297/file/Immunocore+-+Remuneration+Committee+Charter+-+FINAL+1+Jan+24.pdf"
                      target="_blank"
                      rel="noopener"
                      aria-label="Download Remuneration Committee Charter"
                    >
                      Download
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="content-table committee-list">
              <thead>
                <tr>
                  <th class="col-title">
                    Nominating and Corporate Governance Committee
                  </th>
                  <th class="col-role">Position</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#nominating-and-corporate-governance-committee-board-bio-mr-rob-perez"
                        class="fancybox-bio"
                      >
                        Mr. Rob Perez
                      </a>
                    </div>
                    <div
                      id="nominating-and-corporate-governance-committee-mr-rob-perez"
                      class="member-bio collapse"
                    >
                      <p>
                        Rob Perez is an Operating Partner and part of General
                        Atlantic’s Operations Group, providing strategic support
                        and advice to the firm’s investment team and portfolio
                        companies with a particular focus on the biopharma and
                        life sciences sectors. Before joining General Atlantic
                        in 2019, he was Managing Director of Vineyard Sound
                        Advisors, LLC, an advisory practice focused on growth
                        companies in the biopharma industry. Prior to that, Rob
                        was President and CEO of Cubist Pharmaceuticals, Inc.
                        before its sale to Merck in 2015. Before joining Cubist
                        in 2003, he served as Vice President of Biogen, Inc.’s
                        CNS Business Unit.
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role chair">
                        <div
                          aria-label="Committee chair icon"
                          role="img"
                          class="material-icons"
                        >
                          account_circle
                        </div>
                      </div>
                      <div class="role-label sr-only">Chair</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#nominating-and-corporate-governance-committee-board-bio-professor-sir-john-bell"
                        class="fancybox-bio"
                      >
                        Professor Sir John Bell
                      </a>
                      &nbsp;
                      <div class="role chairman-of-the-board">
                        <div
                          aria-label="Chairman of the board icon"
                          role="img"
                          class="material-icons"
                        >
                          stars
                        </div>
                      </div>
                    </div>
                    <div
                      id="nominating-and-corporate-governance-committee-professor-sir-john-bell"
                      class="member-bio collapse"
                    >
                      <p>
                        Professor Sir John Bell was appointed to the Immunocore
                        Board in March 2015. Professor Bell is the Regius
                        Professor of Medicine at Oxford University and Chairman
                        of the Office for the Strategic Coordination of Health
                        Research. He is regarded as one of the world’s most
                        distinguished scientists in the fields of genomic and
                        genetic research and immunology, also has significant
                        experience in the Healthcare industry, having been a
                        founding director at three biotechnology companies;
                        Avidex Ltd (a precursor to Immunocore which was acquired
                        by MediGene in 2006), Oxagen and PowderJect
                        Pharmaceuticals plc (acquired by Chiron Corporation in
                        2003). He currently also serves on the boards of Roche
                        and Genentech and had a previous role on the scientific
                        advisory board at AstraZeneca. Professor Bell was
                        involved in the founding of the Wellcome Trust Centre
                        for Human Genetics at Oxford University, now chairs the
                        scientific committee of UK Biobank and the Global Health
                        Scientific Advisory Board of the Bill and Melinda Gates
                        Foundation, and is one of three Life Science Champions
                        for the UK, reporting to the UK Prime Minister. His
                        research has contributed significantly to the
                        understanding of immune activation in a number of
                        auto-immune diseases.
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role member">
                        <div
                          aria-label="Committee member icon"
                          role="img"
                          class="material-icons"
                        >
                          person
                        </div>
                      </div>
                      <div class="role-label sr-only">Member</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#nominating-and-corporate-governance-committee-board-bio-ms-kristine-peterson"
                        class="fancybox-bio"
                      >
                        Ms. Kristine Peterson
                      </a>
                    </div>
                    <div
                      id="nominating-and-corporate-governance-committee-ms-kristine-peterson"
                      class="member-bio collapse"
                    >
                      <p>
                        Kristine Peterson was appointed to the Immunocore Board
                        of Directors as a Non-Executive Director in November
                        2017. Ms. Peterson has more than 30 years’ experience in
                        the global pharmaceutical and biotech industry. Her most
                        recent role was as Chief Executive Officer for
                        Valeritas, Inc., where she led the company’s development
                        from early stage R&amp;D through to commercialisation,
                        achieving approval by the US Food and Drug
                        Administration (FDA) and European Medicines Agency (EMA)
                        for the company’s Type 2 diabetes drug-device. In
                        addition, Kristine raised more than $200 million in
                        equity financing and prepared the company for its public
                        listing on NASDAQ. Prior to Valeritas, Kristine was
                        Company Group Chair at Johnson &amp; Johnson for their
                        worldwide biotech and oncology groups, growing those
                        businesses to more than $6 billion in sales, launching
                        several new products, and re-establishing the foundation
                        for oncology R&amp;D. She was also President and Senior
                        Vice President, Commercial Operations for Biovail
                        Corporation with responsibility for the US and Canadian
                        business units. Earlier in her career, Kristine spent 20
                        years at Bristol-Myers Squibb in a variety of senior
                        roles, including running their cardiovascular and
                        metabolics business unit.
                      </p>
                      <p>
                        Kristine continues to play a role on several other
                        Boards of Directors at pharmaceutical and biotech
                        companies including Amarin Corporation, Paratek
                        Pharmaceuticals, Enanta Pharmaceutics, pSivida, and
                        ImmunoGen. She is also a senior advisor to the
                        Healthcare Businesswomen’s Association and a former
                        Member of the Biotechnology Industry Organization Board.
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role member">
                        <div
                          aria-label="Committee member icon"
                          role="img"
                          class="material-icons"
                        >
                          person
                        </div>
                      </div>
                      <div class="role-label sr-only">Member</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title">
                    <div class="name">
                      <a
                        href="#nominating-and-corporate-governance-committee-board-bio-peter-j-ratcliffe"
                        class="fancybox-bio"
                      >
                        Peter J. Ratcliffe
                      </a>
                    </div>
                    <div
                      id="nominating-and-corporate-governance-committee-peter-j-ratcliffe"
                      class="member-bio collapse"
                    >
                      <p>
                        Professor Sir Peter Ratcliffe has over 30 years’
                        experience as a clinically active physician scientist.
                        He currently serves as Director of Clinical Research at
                        The Francis Crick Institute in London and Director of
                        the Target Discovery Institute and Distinguished Scholar
                        of the Ludwig Institute for Cancer Research within the
                        Nuffield Department of Medicine at the University of
                        Oxford. Sir Peter was elected to the Fellowship of the
                        Royal Society and to the Academy of Medical Sciences in
                        2002. He is a member of European Molecular Biology
                        Organization (EMBO), a foreign honorary member of the
                        American Academy of Arts and Sciences and a Fellow of
                        the American Association for Cancer Research (AACR)
                        Academy. His work has been recognised through a number
                        of awards, including the Louis‐Jeantet Prize in
                        Medicine, the Canada Gairdner International Award, and
                        the Lasker Award for Basic Biomedical Research. In 2019,
                        he shared the Nobel Prize for Physiology or Medicine for
                        discovery of the key mechanisms that cells use to detect
                        and respond to hypoxia (low oxygen levels), opening up
                        promising new ways to tackle cancer, anaemia and many
                        other diseases. He was knighted for services to medicine
                        in 2014.
                      </p>
                    </div>
                  </td>
                  <td class="col-role">
                    <div class="committee-role">
                      <div class="role member">
                        <div
                          aria-label="Committee member icon"
                          role="img"
                          class="material-icons"
                        >
                          person
                        </div>
                      </div>
                      <div class="role-label sr-only">Member</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="col-title charter-title">
                    <p data-mh="committee-docs" class="title">
                      Nominating and Corporate Governance Committee Charter
                    </p>
                  </td>
                  <td class="committee-role charter-button">
                    <a
                      data-mh="committee-docs"
                      class="btn"
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2275/20298/file/Immunocore+-+Nominating+and+Governance+Committee+Charter+-+FINAL+1+Jan+24.pdf"
                      target="_blank"
                      rel="noopener"
                      aria-label="Download Nominating and Corporate Governance Committee Charter"
                    >
                      Download
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="hide" id="board-bios">
              <div
                class="board-bio"
                id="audit-committee-board-bio-mr-travis-coy"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21431/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Mr. Travis Coy</h2>
                    </div>
                    <div
                      class="member-info"
                      id="audit-committee-member-bio-mr-travis-coy"
                    >
                      <div class="member-description">
                        <p>
                          Travis Coy is currently Vice President, Transactions -
                          Oncology and Diagnostics, Corporate Business
                          Development at Eli Lilly and Company. Prior to this
                          role, Mr. Coy was responsible for leading and managing
                          business development transactions for Lilly’s
                          cardiometabolic disease, drug delivery, and device
                          efforts. He has had a variety of finance and business
                          development experiences at Lilly, including positions
                          as Finance Director of Lilly’s Oncology Business Unit,
                          Director of Investor Relations, Director of Corporate
                          Finance and Investment Banking, and other financial
                          controllership roles. Travis’ business development
                          experiences have encompassed responsibilities ranging
                          from managing the strategic and economic analyses of
                          transactions to leading contract negotiations to
                          having financial leadership of alliances. Before
                          transitioning to finance and business development, he
                          was a chemist in Lilly’s research laboratories and a
                          production manager for Milliken &amp; Company.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="board-bio"
                id="audit-committee-board-bio-roy-s-herbst-md-phd"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21432/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Roy S. Herbst, MD, PhD</h2>
                    </div>
                    <div
                      class="member-info"
                      id="audit-committee-member-bio-roy-s-herbst-md-phd"
                    >
                      <div class="member-description">
                        <p>
                          Ensign Professor of Medicine (Medical Oncology) and
                          Professor of Pharmacology; Chief of Medical Oncology,
                          Yale Cancer Center and Smilow Cancer Hospital;
                          Associate Cancer Center Director for Translational
                          Research, Yale Cancer Center, New Haven, CT, USA
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="board-bio"
                id="audit-committee-board-bio-siddharth-sid-kaul"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21433/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Siddharth (Sid) Kaul</h2>
                    </div>
                    <div
                      class="member-info"
                      id="audit-committee-member-bio-siddharth-sid-kaul"
                    >
                      <div class="member-description">
                        <p>
                          Sid Kaul was appointed to the Immunocore Board of
                          Directors as a Non-Executive Director in June 2022.
                          Sid retired from Novartis as Group Treasurer and Head
                          of Business Planning and Analysis in April 2021 after
                          a 17 year career there. He led Novartis’s Strategic
                          Review and Spin-Off for the Alcon (eye care) business.
                          Sid joined Novartis as CFO Pharma Europe and
                          subsequently served as CFO Pharma US following a
                          20-year career with Procter &amp; Gamble in Financial
                          Planning &amp; Analysis, Strategic Planning,
                          Accounting and Auditing in the US and Japan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="board-bio"
                id="audit-committee-board-bio-mr-rob-perez"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21434/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Mr. Rob Perez</h2>
                    </div>
                    <div
                      class="member-info"
                      id="audit-committee-member-bio-mr-rob-perez"
                    >
                      <div class="member-description">
                        <p>
                          Rob Perez is an Operating Partner and part of General
                          Atlantic’s Operations Group, providing strategic
                          support and advice to the firm’s investment team and
                          portfolio companies with a particular focus on the
                          biopharma and life sciences sectors. Before joining
                          General Atlantic in 2019, he was Managing Director of
                          Vineyard Sound Advisors, LLC, an advisory practice
                          focused on growth companies in the biopharma industry.
                          Prior to that, Rob was President and CEO of Cubist
                          Pharmaceuticals, Inc. before its sale to Merck in
                          2015. Before joining Cubist in 2003, he served as Vice
                          President of Biogen, Inc.’s CNS Business Unit.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="board-bio"
                id="audit-committee-board-bio-peter-j-ratcliffe"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21436/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Peter J. Ratcliffe</h2>
                    </div>
                    <div
                      class="member-info"
                      id="audit-committee-member-bio-peter-j-ratcliffe"
                    >
                      <div class="member-description">
                        <p>
                          Professor Sir Peter Ratcliffe has over 30 years’
                          experience as a clinically active physician scientist.
                          He currently serves as Director of Clinical Research
                          at The Francis Crick Institute in London and Director
                          of the Target Discovery Institute and Distinguished
                          Scholar of the Ludwig Institute for Cancer Research
                          within the Nuffield Department of Medicine at the
                          University of Oxford. Sir Peter was elected to the
                          Fellowship of the Royal Society and to the Academy of
                          Medical Sciences in 2002. He is a member of European
                          Molecular Biology Organization (EMBO), a foreign
                          honorary member of the American Academy of Arts and
                          Sciences and a Fellow of the American Association for
                          Cancer Research (AACR) Academy. His work has been
                          recognised through a number of awards, including the
                          Louis‐Jeantet Prize in Medicine, the Canada Gairdner
                          International Award, and the Lasker Award for Basic
                          Biomedical Research. In 2019, he shared the Nobel
                          Prize for Physiology or Medicine for discovery of the
                          key mechanisms that cells use to detect and respond to
                          hypoxia (low oxygen levels), opening up promising new
                          ways to tackle cancer, anaemia and many other
                          diseases. He was knighted for services to medicine in
                          2014.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="board-bio"
                id="remuneration-committee-board-bio-ms-kristine-peterson"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21435/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Ms. Kristine Peterson</h2>
                    </div>
                    <div
                      class="member-info"
                      id="remuneration-committee-member-bio-ms-kristine-peterson"
                    >
                      <div class="member-description">
                        <p>
                          Kristine Peterson was appointed to the Immunocore
                          Board of Directors as a Non-Executive Director in
                          November 2017. Ms. Peterson has more than 30 years’
                          experience in the global pharmaceutical and biotech
                          industry. Her most recent role was as Chief Executive
                          Officer for Valeritas, Inc., where she led the
                          company’s development from early stage R&amp;D through
                          to commercialisation, achieving approval by the US
                          Food and Drug Administration (FDA) and European
                          Medicines Agency (EMA) for the company’s Type 2
                          diabetes drug-device. In addition, Kristine raised
                          more than $200 million in equity financing and
                          prepared the company for its public listing on NASDAQ.
                          Prior to Valeritas, Kristine was Company Group Chair
                          at Johnson &amp; Johnson for their worldwide biotech
                          and oncology groups, growing those businesses to more
                          than $6 billion in sales, launching several new
                          products, and re-establishing the foundation for
                          oncology R&amp;D. She was also President and Senior
                          Vice President, Commercial Operations for Biovail
                          Corporation with responsibility for the US and
                          Canadian business units. Earlier in her career,
                          Kristine spent 20 years at Bristol-Myers Squibb in a
                          variety of senior roles, including running their
                          cardiovascular and metabolics business unit.
                        </p>
                        <p>
                          Kristine continues to play a role on several other
                          Boards of Directors at pharmaceutical and biotech
                          companies including Amarin Corporation, Paratek
                          Pharmaceuticals, Enanta Pharmaceutics, pSivida, and
                          ImmunoGen. She is also a senior advisor to the
                          Healthcare Businesswomen’s Association and a former
                          Member of the Biotechnology Industry Organization
                          Board.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="board-bio"
                id="remuneration-committee-board-bio-professor-sir-john-bell"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21430/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Professor Sir John Bell</h2>
                    </div>
                    <div
                      class="member-info"
                      id="remuneration-committee-member-bio-professor-sir-john-bell"
                    >
                      <div class="member-description">
                        <p>
                          Professor Sir John Bell was appointed to the
                          Immunocore Board in March 2015. Professor Bell is the
                          Regius Professor of Medicine at Oxford University and
                          Chairman of the Office for the Strategic Coordination
                          of Health Research. He is regarded as one of the
                          world’s most distinguished scientists in the fields of
                          genomic and genetic research and immunology, also has
                          significant experience in the Healthcare industry,
                          having been a founding director at three biotechnology
                          companies; Avidex Ltd (a precursor to Immunocore which
                          was acquired by MediGene in 2006), Oxagen and
                          PowderJect Pharmaceuticals plc (acquired by Chiron
                          Corporation in 2003). He currently also serves on the
                          boards of Roche and Genentech and had a previous role
                          on the scientific advisory board at AstraZeneca.
                          Professor Bell was involved in the founding of the
                          Wellcome Trust Centre for Human Genetics at Oxford
                          University, now chairs the scientific committee of UK
                          Biobank and the Global Health Scientific Advisory
                          Board of the Bill and Melinda Gates Foundation, and is
                          one of three Life Science Champions for the UK,
                          reporting to the UK Prime Minister. His research has
                          contributed significantly to the understanding of
                          immune activation in a number of auto-immune diseases.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="board-bio"
                id="remuneration-committee-board-bio-mr-travis-coy"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21431/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Mr. Travis Coy</h2>
                    </div>
                    <div
                      class="member-info"
                      id="remuneration-committee-member-bio-mr-travis-coy"
                    >
                      <div class="member-description">
                        <p>
                          Travis Coy is currently Vice President, Transactions -
                          Oncology and Diagnostics, Corporate Business
                          Development at Eli Lilly and Company. Prior to this
                          role, Mr. Coy was responsible for leading and managing
                          business development transactions for Lilly’s
                          cardiometabolic disease, drug delivery, and device
                          efforts. He has had a variety of finance and business
                          development experiences at Lilly, including positions
                          as Finance Director of Lilly’s Oncology Business Unit,
                          Director of Investor Relations, Director of Corporate
                          Finance and Investment Banking, and other financial
                          controllership roles. Travis’ business development
                          experiences have encompassed responsibilities ranging
                          from managing the strategic and economic analyses of
                          transactions to leading contract negotiations to
                          having financial leadership of alliances. Before
                          transitioning to finance and business development, he
                          was a chemist in Lilly’s research laboratories and a
                          production manager for Milliken &amp; Company.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="board-bio"
                id="remuneration-committee-board-bio-siddharth-sid-kaul"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21433/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Siddharth (Sid) Kaul</h2>
                    </div>
                    <div
                      class="member-info"
                      id="remuneration-committee-member-bio-siddharth-sid-kaul"
                    >
                      <div class="member-description">
                        <p>
                          Sid Kaul was appointed to the Immunocore Board of
                          Directors as a Non-Executive Director in June 2022.
                          Sid retired from Novartis as Group Treasurer and Head
                          of Business Planning and Analysis in April 2021 after
                          a 17 year career there. He led Novartis’s Strategic
                          Review and Spin-Off for the Alcon (eye care) business.
                          Sid joined Novartis as CFO Pharma Europe and
                          subsequently served as CFO Pharma US following a
                          20-year career with Procter &amp; Gamble in Financial
                          Planning &amp; Analysis, Strategic Planning,
                          Accounting and Auditing in the US and Japan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="board-bio"
                id="remuneration-committee-board-bio-roy-s-herbst-md-phd"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21432/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Roy S. Herbst, MD, PhD</h2>
                    </div>
                    <div
                      class="member-info"
                      id="remuneration-committee-member-bio-roy-s-herbst-md-phd"
                    >
                      <div class="member-description">
                        <p>
                          Ensign Professor of Medicine (Medical Oncology) and
                          Professor of Pharmacology; Chief of Medical Oncology,
                          Yale Cancer Center and Smilow Cancer Hospital;
                          Associate Cancer Center Director for Translational
                          Research, Yale Cancer Center, New Haven, CT, USA
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="board-bio"
                id="nominating-and-corporate-governance-committee-board-bio-mr-rob-perez"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21434/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Mr. Rob Perez</h2>
                    </div>
                    <div
                      class="member-info"
                      id="nominating-and-corporate-governance-committee-member-bio-mr-rob-perez"
                    >
                      <div class="member-description">
                        <p>
                          Rob Perez is an Operating Partner and part of General
                          Atlantic’s Operations Group, providing strategic
                          support and advice to the firm’s investment team and
                          portfolio companies with a particular focus on the
                          biopharma and life sciences sectors. Before joining
                          General Atlantic in 2019, he was Managing Director of
                          Vineyard Sound Advisors, LLC, an advisory practice
                          focused on growth companies in the biopharma industry.
                          Prior to that, Rob was President and CEO of Cubist
                          Pharmaceuticals, Inc. before its sale to Merck in
                          2015. Before joining Cubist in 2003, he served as Vice
                          President of Biogen, Inc.’s CNS Business Unit.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="board-bio"
                id="nominating-and-corporate-governance-committee-board-bio-professor-sir-john-bell"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21430/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Professor Sir John Bell</h2>
                    </div>
                    <div
                      class="member-info"
                      id="nominating-and-corporate-governance-committee-member-bio-professor-sir-john-bell"
                    >
                      <div class="member-description">
                        <p>
                          Professor Sir John Bell was appointed to the
                          Immunocore Board in March 2015. Professor Bell is the
                          Regius Professor of Medicine at Oxford University and
                          Chairman of the Office for the Strategic Coordination
                          of Health Research. He is regarded as one of the
                          world’s most distinguished scientists in the fields of
                          genomic and genetic research and immunology, also has
                          significant experience in the Healthcare industry,
                          having been a founding director at three biotechnology
                          companies; Avidex Ltd (a precursor to Immunocore which
                          was acquired by MediGene in 2006), Oxagen and
                          PowderJect Pharmaceuticals plc (acquired by Chiron
                          Corporation in 2003). He currently also serves on the
                          boards of Roche and Genentech and had a previous role
                          on the scientific advisory board at AstraZeneca.
                          Professor Bell was involved in the founding of the
                          Wellcome Trust Centre for Human Genetics at Oxford
                          University, now chairs the scientific committee of UK
                          Biobank and the Global Health Scientific Advisory
                          Board of the Bill and Melinda Gates Foundation, and is
                          one of three Life Science Champions for the UK,
                          reporting to the UK Prime Minister. His research has
                          contributed significantly to the understanding of
                          immune activation in a number of auto-immune diseases.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="board-bio"
                id="nominating-and-corporate-governance-committee-board-bio-ms-kristine-peterson"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21435/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Ms. Kristine Peterson</h2>
                    </div>
                    <div
                      class="member-info"
                      id="nominating-and-corporate-governance-committee-member-bio-ms-kristine-peterson"
                    >
                      <div class="member-description">
                        <p>
                          Kristine Peterson was appointed to the Immunocore
                          Board of Directors as a Non-Executive Director in
                          November 2017. Ms. Peterson has more than 30 years’
                          experience in the global pharmaceutical and biotech
                          industry. Her most recent role was as Chief Executive
                          Officer for Valeritas, Inc., where she led the
                          company’s development from early stage R&amp;D through
                          to commercialisation, achieving approval by the US
                          Food and Drug Administration (FDA) and European
                          Medicines Agency (EMA) for the company’s Type 2
                          diabetes drug-device. In addition, Kristine raised
                          more than $200 million in equity financing and
                          prepared the company for its public listing on NASDAQ.
                          Prior to Valeritas, Kristine was Company Group Chair
                          at Johnson &amp; Johnson for their worldwide biotech
                          and oncology groups, growing those businesses to more
                          than $6 billion in sales, launching several new
                          products, and re-establishing the foundation for
                          oncology R&amp;D. She was also President and Senior
                          Vice President, Commercial Operations for Biovail
                          Corporation with responsibility for the US and
                          Canadian business units. Earlier in her career,
                          Kristine spent 20 years at Bristol-Myers Squibb in a
                          variety of senior roles, including running their
                          cardiovascular and metabolics business unit.
                        </p>
                        <p>
                          Kristine continues to play a role on several other
                          Boards of Directors at pharmaceutical and biotech
                          companies including Amarin Corporation, Paratek
                          Pharmaceuticals, Enanta Pharmaceutics, pSivida, and
                          ImmunoGen. She is also a senior advisor to the
                          Healthcare Businesswomen’s Association and a former
                          Member of the Biotechnology Industry Organization
                          Board.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="board-bio"
                id="nominating-and-corporate-governance-committee-board-bio-peter-j-ratcliffe"
              >
                <div class="personnel-listing">
                  <div class="member">
                    <div class="image">
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2276/21436/photo_resized.jpg"
                        alt=""
                      />
                    </div>
                    <div class="info">
                      <h2>Peter J. Ratcliffe</h2>
                    </div>
                    <div
                      class="member-info"
                      id="nominating-and-corporate-governance-committee-member-bio-peter-j-ratcliffe"
                    >
                      <div class="member-description">
                        <p>
                          Professor Sir Peter Ratcliffe has over 30 years’
                          experience as a clinically active physician scientist.
                          He currently serves as Director of Clinical Research
                          at The Francis Crick Institute in London and Director
                          of the Target Discovery Institute and Distinguished
                          Scholar of the Ludwig Institute for Cancer Research
                          within the Nuffield Department of Medicine at the
                          University of Oxford. Sir Peter was elected to the
                          Fellowship of the Royal Society and to the Academy of
                          Medical Sciences in 2002. He is a member of European
                          Molecular Biology Organization (EMBO), a foreign
                          honorary member of the American Academy of Arts and
                          Sciences and a Fellow of the American Association for
                          Cancer Research (AACR) Academy. His work has been
                          recognised through a number of awards, including the
                          Louis‐Jeantet Prize in Medicine, the Canada Gairdner
                          International Award, and the Lasker Award for Basic
                          Biomedical Research. In 2019, he shared the Nobel
                          Prize for Physiology or Medicine for discovery of the
                          key mechanisms that cells use to detect and respond to
                          hypoxia (low oxygen levels), opening up promising new
                          ways to tackle cancer, anaemia and many other
                          diseases. He was knighted for services to medicine in
                          2014.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default BOCMain