import React from "react";

const Footer = () => {
  return (
    <footer id="footer" class="has-qmod">
      <div class="footer-main">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-6">
              <img
                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/footer-logo.svg"
                width="180"
                height="18"
                alt="Immunocore Holdings plc"
              />
              <ul class="social-media-links row justify-content-center justify-content-md-start align-items-center">
                {/* <li>
                  <a
                    href="https://twitter.com/immunocore"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2283/21826/icon/x%402x.png"
                      alt="Visit us on Twitter"
                      height="24"
                      width="24"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/immunocore"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2283/21827/icon/linkedin%402x.svg"
                      alt="Visit us on Linkedin"
                      height="24"
                      width="24"
                    />
                  </a>
                </li> */}
              </ul>
              <div class="copyright">
                &copy;<time datetime="2024">2024</time>
                <a href="/"> Immunocore Holdings plc</a>. All Rights Reserved.
              </div>
            </div>
            <div class="col-lg-6">
              <div class="footer-links">
                <div class="row">
                  <div class="col-md-auto">
                    <a href="/disclaimer">Disclaimer</a>
                    <a href="/sitemap">Sitemap</a>
                    <a href="/compliance-and-transparency">
                      Compliance and Transparency
                    </a>
                  </div>
                  <div class="col-md-auto">
                    <a href="/privacy-and-social-media-guidelines">
                      Privacy and Social Media Guidelines
                    </a>
                    <a href="/cookies">Cookies</a>
                    <a href="/payments-and-disclosures">
                      Payments and Disclosures
                    </a>
                  </div>
                  <div class="col-md-auto">
                    <a href="/clinical-trials">Clinical Trials</a>
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2303/21795/file/UK_Req._Gender_Pay_Gap_report_Final_2023+%281%29.pdf"
                      target="_blank"
                      rel="noopener"
                    >
                      Gender Pay Report
                    </a>
                    <a href="/modern-slavery-statement">
                      Modern Slavery Statement
                    </a>
                    <a id="klaroPrefs" href="#" role="button">
                      Manage Cookie Preferences
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mt-3">
              <div class="footer-notes">
                <p>
                  This is Immunocore's global website, intended for visitors
                  seeking information on Immunocore's worldwide business.
                </p>
                <p>
                  If you are a medical professional and would like further
                  information about Immunocore, our clinical research or
                  clinical trials, please send an email to{" "}
                  <a href="mailto:info@immunocore.us">info@immunocore.us</a>
                  (US) or{" "}
                  <a href="mailto:info@immunocore.us">
                    info@immunocore.us
                  </a>{" "}
                  (outside of US).
                </p>
                <p class="mt-3">
                  CM-GL-TAX-2400001
                  <br />
                  Date of production: April 2024
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="qmod-attribution">
        <div class="container">
          <div class="q-mod-attribution disclaimer">
            <div class="row justify-content-center">
              <div class="col-md-10 center">
                <p>
                  Market Data copyright &copy; 2024{" "}
                  <a
                    href="http://www.quotemedia.com"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    QuoteMedia
                  </a>
                  . Data delayed 15 minutes unless otherwise indicated (view{" "}
                  <a
                    href="http://www.quotemedia.com/legal/tos/#times"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    delay times
                  </a>{" "}
                  for all exchanges).
                  <span class="qmf-rt">RT</span>=Real-Time,
                  <span class="qmf-non-rt">EOD</span>=End of Day,
                  <span class="qmf-non-rt">PD</span>=Previous Day. Market Data
                  powered by{" "}
                  <a
                    href="http://www.quotemedia.com"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    QuoteMedia
                  </a>
                  .{" "}
                  <a
                    href="http://www.quotemedia.com/legal/tos/"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    Terms of Use
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
