import React from "react";

const DisclaimerMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <main id="mainContent" class="main-content">
            <p>
              All statements and expressions are the sole opinion of the company
              and are subject to change without notice.&nbsp; The Company is not
              liable for any investment decisions by its readers or
              subscribers.&nbsp; It is strongly recommended that any purchase or
              sale decision be discussed with a financial advisor, or a
              broker-dealer, or a member of any financial regulatory
              bodies.&nbsp; The information contained herein has been provided
              as an information service only.&nbsp; The accuracy or completeness
              of the information is not warranted and is only as reliable as the
              sources from which it was obtained.&nbsp; Investors are cautioned
              that they may lose all or a portion of their investment in this or
              any other company.
            </p>
            <p>
              Information contained herein contains "forward looking statements"
              within the meaning of Section 27A of the Securities Act of 1933,
              as amended and Section 21E of the Securities and Exchange Act of
              1934, as amended.&nbsp; Any statements that express or involve
              discussions with respect to predictions, expectations, beliefs,
              plans, projections, objectives, goals, assumptions or future
              events or performance are not statements of historical facts and
              may be "forward looking statements".&nbsp; Forward looking
              statements are based on expectations, estimates and projections at
              the time the statements are made that involve a number of risks
              and uncertainties which could cause actual results or events to
              differ materially from those presently anticipated. Forward
              looking statements may be identified through the use of words such
              as "expects", "will", "anticipates", "estimates", "believes", or
              by statements indicating certain actions "may", "could", "should"
              or "might" occur.
            </p>
          </main>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerMain;
