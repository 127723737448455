import React from "react";

const ComplianceMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <main id="mainContent" class="main-content">
            <p>
              <strong>
                Immunocore’s Code of Business Conduct and Ethics:&nbsp;{" "}
              </strong>
              <a href="/investors/corporate-governance/governance-documents">
                https://www.immunocore.us/investors/corporate-governance/governance-documents
              </a>
            </p>
            <p>
              <strong>UK Compliance hotline (toll free):</strong>
              &nbsp;08000232968
              <br />
              <strong>US Compliance hotline (anonymous):&nbsp;</strong>(877)
              657-5005
            </p>
            <p>
              <strong>
                Whistleblower and ethics portal (anonymous):&nbsp;
              </strong>
              <a
                href="https://www.whistleblowerservices.com/IMCR/"
                target="_blank"
                rel="noopener"
              >
                https://www.whistleblowerservices.com/IMCR/
              </a>
            </p>
            <h2>Immunocore Compliance Program</h2>
            <p>
              Effective July 1, 2005, California Health &amp; Safety Code,
              Sections 119400 – 119402, (“California Compliance Law”) requires
              that certain pharmaceutical companies doing business in California
              adopt a compliance program in accordance with the April 2003
              publication “Compliance Program Guidance for Pharmaceutical
              Manufacturers” (“OIG Compliance Guidance”) developed by the United
              States Department of Health and Human Services Office of Inspector
              General (“OIG”) and policies for compliance with the
              Pharmaceutical Research and Manufacturers of America (“PhRMA”)
              “Code on Interactions with Health Care Professionals” (“PhRMA
              Code”) within six months of any update or revision of the PhRMA
              Code. Revisions to the PhRMA Code were implemented/will be
              implemented in January 2009, October 2019 and January 2022.
            </p>
            <p>
              Immunocore’s current policies support adherence to the voluntary
              guidelines that California law will now enforce. Immunocore will
              enhance its existing policies and programs to cover the formal
              annual dollar limits required by the law and begin monitoring
              performance against those limits. For purposes of compliance with
              the requirements of the California Compliance Law and as part of
              the Compliance Program, Immunocore has established a specific
              annual aggregate dollar limit of $2000 on gifts, promotional
              materials, or items or activities that Immunocore may give or
              otherwise provide to an individual medical or healthcare
              professional in California on an annual basis from January 1st to
              December 31st.
            </p>
          </main>
        </div>
      </div>
    </div>
  );
};

export default ComplianceMain;
