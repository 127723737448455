import React from "react";

const CareerMain = () => {
  return (
    <main id="mainContent">
      <section
        id=""
        class="module row-default w-line light-blue-bg no-overlay no-dark text-left background overlay dark"
        style={{
          backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21859/background_image.jpg)`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-12">
              <div class="module-content">
                <div class="text-area">
                  <div class="text center">
                    <h2>Why Immunocore?</h2>
                    <p>
                      Join us and you’ll be part of a team of outstanding people
                      who pioneer the research, development and delivery of
                      bi-specific TCR therapies, changing lives for the better.
                      Explore how we do what we do, our values and what you can
                      expect from a career here.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="module module-teasers module-stride">
        <div class="container">
          <div class="row justify-content-start align-items-center">
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text">
                  <h2>STRIDE</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text">
                  <p>
                    We strive to make STRIDEs for patients, so it’s only right
                    that our values support that. We all come to work every day
                    aligned to our mission, and with a passion to deliver
                    transformative medicines to patients.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row flex-row flex-5-col">
            <div class="col-lg teaser-0">
              <div class="content-box">
                <div class="text">
                  <figure class="teaser-img">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2424/22005/image_resized.png"
                      alt=""
                      role="presentation"
                    />
                  </figure>
                  <h3 class="title" data-mh="item-title">
                    We lead with science to benefit patients
                  </h3>
                  <p>
                    We work together to set clear priorities and objectives
                    aligned to the organization’s goals, creating an environment
                    maximizing individual contributions benefitting patients.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg teaser-1">
              <div class="content-box">
                <div class="text">
                  <figure class="teaser-img">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2424/22006/image_resized.png"
                      alt=""
                      role="presentation"
                    />
                  </figure>
                  <h3 class="title" data-mh="item-title">
                    We respect and trust each other
                  </h3>
                  <p>
                    We respect and trust each other with good intentions, and
                    are consistent and fair in how we treat others. We support
                    transparent and frequent communication.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg teaser-2">
              <div class="content-box">
                <div class="text">
                  <figure class="teaser-img">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2424/22007/image_resized.png"
                      alt=""
                      role="presentation"
                    />
                  </figure>
                  <h3 class="title" data-mh="item-title">
                    We act with integrity
                  </h3>
                  <p>
                    We act with integrity, and take responsibility for our
                    actions. We challenge actions or decisions we believe to be
                    wrong. We take decisions to improve the success of our
                    organization.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg teaser-3">
              <div class="content-box">
                <div class="text">
                  <figure class="teaser-img">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2424/22008/image_resized.png"
                      alt=""
                      role="presentation"
                    />
                  </figure>
                  <h3 class="title" data-mh="item-title">
                    We value diversity to drive innovation
                  </h3>
                  <p>
                    We value all views and opinions. Our success is dependent on
                    pushing boundaries, utilizing diverse skills and fostering
                    creative thinking.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg teaser-4">
              <div class="content-box">
                <div class="text">
                  <figure class="teaser-img">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2424/22009/image_resized.png"
                      alt=""
                      role="presentation"
                    />
                  </figure>
                  <h3 class="title" data-mh="item-title">
                    We are entrepreneurial
                  </h3>
                  <p>
                    We are drawn to opportunities seeking out key areas of
                    improvement. We ask questions, voice opinions and take
                    initiative to get things done.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section
        id=""
        class="module row-default no-padding-top no-padding-bottom image-align image-align--right text-padding image-tall"
      >
        <div class="container">
          <div class="row justify-content-center align-items-center flex-row-reverse">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21885/image.jpg"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <h2>Our career areas</h2>
                    <p>
                      Because we take therapies from the lab bench all the way
                      to the patient bedside, we’ve got a wide range of careers
                      here. Whatever department you join us in, you’ll be part
                      of a high-performing team, and, in return, we’ll give you
                      all the development, training and feedback you need to
                      succeed.
                    </p>
                    <div class="link-list">
                      <div class="row">
                        <div class="col-lg-6">
                          <a
                            rel="noopener"
                            target="_blank"
                            href="/careers/open-positions/"
                            // href="https://peoplecore.my.salesforce-sites.com/recruit/fRecruit__ApplyJobList?uid="
                          >
                            Research
                          </a>
                        </div>
                        <div class="col-lg-6">
                          <a
                            rel="noopener"
                            target="_blank"
                            href="/careers/open-positions/"
                            // href="https://peoplecore.my.salesforce-sites.com/recruit/fRecruit__ApplyJobList?uid="
                          >
                            Business functions
                          </a>
                        </div>
                        <div class="col-lg-6">
                          <a
                            rel="noopener"
                            target="_blank"
                            href="/careers/open-positions/"
                            // href="https://peoplecore.my.salesforce-sites.com/recruit/fRecruit__ApplyJobList?uid="
                          >
                            Commercial
                          </a>
                        </div>
                        <div class="col-lg-6">
                          <a
                            rel="noopener"
                            target="_blank"
                            href="/careers/open-positions/"
                            // href="https://peoplecore.my.salesforce-sites.com/recruit/fRecruit__ApplyJobList?uid="
                          >
                            Medical
                          </a>
                        </div>
                        <div class="col-lg-6">
                          <a
                            rel="noopener"
                            target="_blank"
                            href="/careers/open-positions/"
                            // href="https://peoplecore.my.salesforce-sites.com/recruit/fRecruit__ApplyJobList?uid="
                          >
                            Clinical
                          </a>
                        </div>
                        <div class="col-lg-6">
                          <a
                            rel="noopener"
                            target="_blank"
                            href="/careers/open-positions/"
                            // href="https://peoplecore.my.salesforce-sites.com/recruit/fRecruit__ApplyJobList?uid="
                          >
                            Development
                          </a>
                        </div>
                        <div class="col-lg-6">
                          <a
                            rel="noopener"
                            target="_blank"
                            href="/careers/open-positions/"
                            // href="https://peoplecore.my.salesforce-sites.com/recruit/fRecruit__ApplyJobList?uid="
                          >
                            Translational medicine
                          </a>
                        </div>
                        <div class="col-lg-6">
                          <a
                            rel="noopener"
                            target="_blank"
                            href="/careers/open-positions/"
                            // href="https://peoplecore.my.salesforce-sites.com/recruit/fRecruit__ApplyJobList?uid="
                          >
                            Early careers
                          </a>
                        </div>
                        <div class="col-lg-6">
                          <a
                            rel="noopener"
                            target="_blank"
                            href="/careers/open-positions/"
                            class="no-border"
                          >
                            Manufacturing
                          </a>
                        </div>
                        <div class="col-lg-6">
                          <a
                            rel="noopener"
                            target="_blank"
                            // href="https://peoplecore.my.salesforce-sites.com/recruit/fRecruit__ApplyJobList?uid="
                            class="no-border"
                          >
                            Industry placements
                          </a>
                        </div>
                      </div>
                    </div>
                    <a
                      // href="https://peoplecore.my.salesforce-sites.com/recruit/fRecruit__ApplyJobList"
                      href="/careers/open-positions/"
                      class="btn"
                      target="_blank"
                      rel="noopener"
                    >
                      Browse our jobs
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="module module-testimonial-slider dark no-overlay overlay dark"
        style={{
          backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21882/background_image.jpg)`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="testimonial-slider">
                <div class="testimonial-block">
                  <div class="text center">
                    <p class="quote">
                      &quot;Working at Immunocore is a truly rewarding
                      experience: to work closely with talented, passionate and
                      supportive colleagues in our endeavors to deliver new
                      medicines to patients is fulfilling.&quot;
                    </p>
                    <p class="employee">Praveen Singh</p>
                    <p class="title">Associate Director Infectious Diseases</p>
                  </div>
                </div>
                <div class="testimonial-block">
                  <div class="text center">
                    <p class="quote">
                      &quot;The environment is dynamic, challenging and very
                      much rewarding. It is fantastic that everyone is driven in
                      the same way whilst supporting each other to achieve our
                      goals.&quot;
                    </p>
                    <p class="employee">Poonam Khatri</p>
                    <p class="title">Associate Director, Clinical Pharmacist</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="module module-teasers-list">
        <div class="container">
          <div class="row justify-content-start align-items-center">
            <div class="col-sm-12">
              <div class="module-content">
                <div class="text">
                  <h2>Your UK Benefits</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="teaser-list">
            <div class="row">
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2418/21872/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">25 days&#039; annual leave</h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2418/21873/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">Private health insurance</h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2418/21874/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">Defined contribution pension scheme</h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2418/21875/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">Life insurance</h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2418/21876/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">
                  Critical illness &amp; income protection
                </h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2418/21877/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">Employee assistance programme</h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2418/21878/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">Enhanced parental pay</h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2418/21879/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">Subsidized gym membership</h3>
                <p></p>
              </div>
            </div>
          </div>
          <div class="link-container">
            <a
              // href="https://peoplecore.my.salesforce-sites.com/recruit/fRecruit__ApplyJobList"
              href="/careers/open-positions/"
              class="btn"
              target="_blank"
              rel="noopener"
            >
              Browse Our Jobs
            </a>
          </div>
        </div>
      </div>
      <div class="module module-teasers-list light-blue-bg">
        <div class="container">
          <div class="row justify-content-start align-items-center">
            <div class="col-sm-12">
              <div class="module-content">
                <div class="text">
                  <h2>Your US benefits</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="teaser-list">
            <div class="row">
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2417/21864/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">20 days&#039; vacation</h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2417/21865/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">Employee assistance programme</h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2417/21866/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">Life &amp; disability insurance</h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2417/21867/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">Parental leave</h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2417/21868/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">Medical, dental &amp; vision plan</h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2417/21869/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">State-mandated benefits</h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2417/21870/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">FSA health &amp; dependent care</h3>
                <p></p>
              </div>
              <div class="col-md-3 p-4">
                <div class="logo-container">
                  <img
                    role="presentation"
                    alt=""
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2417/21871/logo.svg"
                  />
                </div>
                <h3 class="heading--h5">
                  401(k) &amp; retirement contribution
                </h3>
                <p></p>
              </div>
            </div>
          </div>
          <div class="link-container">
            <a
              // href="https://peoplecore.my.salesforce-sites.com/recruit/fRecruit__ApplyJobList"
              href="/careers/open-positions/"
              class="btn"
              target="_blank"
              rel="noopener"
            >
              Browse Our Jobs
            </a>
          </div>
        </div>
      </div>
      <section class="module row-default row-two-col boxes">
        <div class="container">
          <div class="row justify-content-center align-items-start">
            <div class="col-lg-6">
              <div class="module-content primary-area">
                <div class="text-area">
                  <div class="text">
                    Please contact{" "}
                    <a href="mailto:info@immunocore.us">info@immunocore.us</a>{" "}
                    for general enquiries about careers.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    Please contact{" "}
                    <a href="mailto:info@immunocore.us">info@immunocore.us</a>{" "}
                    for company reference requests and employment verification.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id=""
        class="module row-default neutral-bg no-padding-top no-padding-bottom image-align image-align--right"
      >
        <div class="container">
          <div class="row justify-content-center align-items-center flex-row-reverse">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21860/image.jpg"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <h2>Our commitment to diversity</h2>
                    Innovation is driven by diverse thinking. That’s why we’re
                    working hard to create an inclusive and supportive
                    environment where individual contributions and initiatives
                    can be maximized, while fostering a culture of
                    collaboration, based on respect and integrity.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="module row-default row-two-col">
        <div class="container">
          <div class="row justify-content-center align-items-start">
            <div class="col-lg-6">
              <div class="module-content primary-area">
                <div class="text-area">
                  <div class="text">
                    <h2>Important notice to recruitment organizations</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    Immunocore does not accept referrals from recruitment
                    organizations in respect of the vacancies posted on this
                    site or available in the public domain. All recruitment
                    organizations are required to contact the Company’s Human
                    Resources department to obtain prior written authorization
                    before referring any candidates to Immunocore.
                    <br />
                    <br />
                    The obtaining of prior written authorization is a condition
                    precedent to any agreement (verbal or written) between the
                    recruitment organization and Immunocore. In the absence of
                    such written authorization being obtained, any actions
                    undertaken by the recruitment organizations shall be deemed
                    to have been performed without the consent or contractual
                    agreement of Immunocore. Immunocore shall not be liable for
                    any fees arising from such actions or any fees arising from
                    any referrals by recruitment organizations in respect of the
                    vacancies posted on this site or those available in the
                    public domain.
                    <div class="btn-wrapper"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CareerMain;
