import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import "../../css/fileupload.css";

const FileUpload = ({ setSelectedFiles }) => {
  const [setselectedFIles, setSetselectedFIles] = useState("");
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: {
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/html": [".html"],
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
    },
    maxSize: 5 * 1024 * 1024, // 5MB
    onDrop: (acceptedFiles) => {
      setSetselectedFIles(acceptedFiles);
      setSelectedFiles(acceptedFiles);
      console.log("Accepted files:", acceptedFiles);
    },
    onDropRejected: (fileRejections) => {
      console.log("Rejected files:", fileRejections);
    },
  });

  const isError = isDragReject || fileRejections.length > 0;

  return (
    <div
      className={`dropzone-container ${isError ? "error" : ""}`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {setselectedFIles ? (
        <p>{setselectedFIles[0].path}</p>
      ) : (
        <p>
          Drag & drop your file here <br />
          or click to select a file
        </p>
      )}
      {isDragActive && !isDragReject && <p>Drop the files here ...</p>}
      {isDragReject && <p>Unsupported file type or file too large</p>}
      {fileRejections.length > 0 && <p>Invalid file type or file too large</p>}
    </div>
  );
};

export default FileUpload;
