import React from "react";

const PrivacyMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <main id="mainContent" class="main-content">
            <section
              class="u-pt-10 u-pb-10"
              data-block="content"
              data-template="basic_text_page"
            >
              <div class="l-container">
                <div class="l-row">
                  <p>
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_5cf342a23c9521d7c796e2357b8a6f3e/immunocore/files/pages/Privacy_and_social_media_guidelines_French_17Jan2022.pdf"
                      target="_blank"
                      rel="noopener"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_47444463113538c654810e0a28b790a7/immunocore/files/pages/23px-Flag_of_France.png"
                        alt="Flag of France"
                      />
                      Lignes directrices relatives aux informations personnelles
                      et aux médias sociaux
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_47444463113538c654810e0a28b790a7/immunocore/files/pages/Privacy_and_social_media_guidelines_German_18Jan2022.pdf"
                      target="_blank"
                      rel="noopener"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_47444463113538c654810e0a28b790a7/immunocore/files/pages/23px-Flag_of_Germany.png"
                        alt="Flag of Germany"
                      />
                      Datenschutzerklärung und Social-Media-Richtlinien
                    </a>
                  </p>
                  <div class="l-col l-col-md-8">
                    <p>
                      We want to make sure you understand what personal
                      information we may collect about you when you interact
                      with Immunocore Ltd, how we use your personal information,
                      and how we keep it safe.
                    </p>
                    <p>This Privacy Notice explains:</p>
                    <ul>
                      <li>What personal information we collect about you</li>
                      <li>How we use your personal information</li>
                      <li>On what basis we use your personal information</li>
                      <li>How long we keep your personal information</li>
                      <li>
                        We may share your personal information with others and
                        transfer it internationally
                      </li>
                      <li>How we protect your personal information</li>
                      <li>Your rights regarding your personal information</li>
                      <li>
                        What to do if you don’t want to provide us with your
                        personal information
                      </li>
                      <li>
                        How we use cookies and where to find further information
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>
                      We may change this Privacy Notice from time to time.&nbsp;
                      We encourage you to review this Privacy Notice
                      periodically.
                    </p>
                    <p>
                      If you have any questions, please get in touch via one of
                      the methods set out in the Contact us section below.
                    </p>
                    <p>
                      <b>What personal information do we collect?</b>
                    </p>
                    <p>
                      The personal information we collect when you interact with
                      us via this website, by email, by phone, at a conference
                      or via any other means includes:
                    </p>
                    <p>Information you provide us with, such as</p>
                    <ul>
                      <li>Your email address</li>
                      <li>Email communications sent to Immunocore Ltd</li>
                    </ul>
                    <p>
                      Our website uses Google Analytics, a service which
                      transmits website traffic data to Google servers in the
                      United States. Google Analytics does not identify
                      individual users or associate your IP address with any
                      other data held by Google. We use reports provided by
                      Google Analytics to help us understand website traffic and
                      webpage usage.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <b>How do we use your personal information?</b>
                    </p>
                    <p>We may use your personal information to:</p>
                    <p>Provide you with information and services including:</p>
                    <ul>
                      <li>Our press releases</li>
                      <li>Our newsletters</li>
                    </ul>
                    <p>Contact and interact with you, including to:</p>
                    <ul>
                      <li>
                        Respond to your requests (for instance if you send us an
                        email).
                      </li>
                      <li>
                        Provide important notices and updates, such as changes
                        to our terms and policies, security alerts and
                        administrative messages.
                      </li>
                    </ul>
                    <p>Operate our business, including to:</p>
                    <ul>
                      <li>
                        Comply with applicable laws, regulations and guidance.
                      </li>
                      <li>
                        Comply with demands or requests made by regulators,
                        governments, courts and law enforcement authorities.
                      </li>
                      <li>
                        Investigate and take action against illegal or harmful
                        behaviour of users.
                      </li>
                    </ul>
                    <p>Improve our day-to-day operations, including:</p>
                    <ul>
                      <li>
                        For internal purposes such as auditing, data analysis
                        and research to help us deliver and improve our
                        Immunocore digital platforms, content and services.
                      </li>
                      <li>
                        To monitor and analyse trends, usage and activities in
                        connection with our products and services to understand
                        which parts of our digital platforms and services are of
                        the most interest and to improve the design and content
                        of our platforms.
                      </li>
                      <li>
                        To improve our products and services and our
                        communications to you.
                      </li>
                      <li>
                        Where applicable, to ensure we have up-to-date contact
                        information for you.
                      </li>
                    </ul>
                    <p>
                      <b>On what basis do we use your personal information?</b>
                    </p>
                    <p>
                      Data privacy law sets out a number of different reasons on
                      which a company may rely to collect and use your personal
                      information.
                    </p>
                    <p>
                      We use your personal information for the following
                      reasons:
                    </p>
                    <ul>
                      <li>
                        For legitimate business purposes: We use your personal
                        information to make our communications with you more
                        relevant and personalised to you, and to make your
                        experience of our products and services efficient and
                        effective. It also helps us to operate and improve our
                        business and minimise any disruption to the services
                        that we may offer to you.
                      </li>
                      <li>
                        To comply with our legal obligations and other demands
                        for information: It is important to us that we are able
                        to comply with laws, regulations and guidance, as well
                        as the other requests or demands for data as set out
                        here. They affect the way in which we run our business
                        and help us to make our products and services as safe as
                        we can.
                      </li>
                      <li>
                        You have given your consent: At times we may need to get
                        your consent to allow us to use your personal
                        information for one or more of the purposes set out
                        above. See the “Your rights regarding your personal
                        information” section for information about the rights
                        that you have if we process your information on the
                        basis of your consent.
                      </li>
                    </ul>
                    <p>
                      <b>&nbsp;</b>
                    </p>
                    <p>
                      <b>How long will we keep your personal information?</b>
                    </p>
                    <p>
                      We will always keep your personal information for the
                      period required by law and where we need to do so in
                      connection with legal action or an investigation involving
                      Immunocore. Otherwise, we keep your personal information:
                    </p>
                    <ul>
                      <li>
                        For as long as needed to provide you with access to
                        services you have requested.
                      </li>
                      <li>
                        Where you have contacted us with a question or request,
                        for as long as necessary to allow us to respond your
                        question or request.
                      </li>
                    </ul>
                    <p>
                      <b>
                        We sometimes share your personal information with others
                        and transfer it internationally
                      </b>
                    </p>
                    <p>We may share your personal information with:</p>
                    <ul>
                      <li>Members of Immunocore Holdings plc</li>
                      <li>
                        The following trusted third parties:
                        <ul>
                          <li>
                            Our agents and suppliers, including those who
                            provide us with technology services such as data
                            analytics, hosting and technical support.
                          </li>
                          <li>
                            Our professional advisors, auditors and business
                            partners.
                          </li>
                          <li>
                            Regulators, governments and law enforcement
                            authorities.
                          </li>
                          <li>
                            Other third parties in connection with re-organising
                            all or any part of our business.
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <p>
                      Your personal information may be processed by Immunocore
                      and Immunocore’s trusted third party suppliers outside of
                      your home country. Data privacy laws in the countries to
                      which your personal information is transferred may not be
                      equivalent to, or as protective as, the laws in your home
                      country.
                    </p>
                    <p>
                      We will implement appropriate measures to ensure that your
                      personal information remains protected and secure when it
                      is transferred outside of your home country, in accordance
                      with applicable data protection and privacy laws.&nbsp;
                      These measures include data transfer agreements
                      implementing standard data protection clauses.&nbsp; You
                      can find more information about data transfer agreements
                      here.
                    </p>
                    <p>
                      <b>Protecting your personal information</b>
                    </p>
                    <p>
                      We use a variety of security measures and technologies to
                      help protect your personal information from unauthorised
                      access, use, disclosure, alteration or destruction in line
                      with applicable data protection and privacy laws. For
                      example, when we share your personal information with
                      external suppliers, we may put in place a written
                      agreement which commits the suppliers to keep your
                      information confidential, and to put in place appropriate
                      security measures to keep your information secure.
                    </p>
                    <p>
                      The transmission to us of information via the internet or
                      a mobile phone network connection may not be completely
                      secure and any transmission is at your own risk.
                    </p>
                    <p>
                      <b>Websites that we do not own or control</b>
                    </p>
                    <p>
                      From time to time we may provide links to websites or
                      mobile applications that are not owned or controlled by
                      us. This Privacy Notice does not apply to those websites.
                      If you choose to use those websites, please check the
                      legal and privacy statements posted on each website or
                      mobile application you access to understand their privacy
                      practices.
                    </p>
                    <p>
                      <b>Your rights regarding your personal information</b>
                    </p>
                    <p>
                      Data privacy laws provide you with a number of rights over
                      your personal information.
                    </p>
                    <p>You may be entitled to:</p>
                    <ul>
                      <li>
                        Ask Immunocore for access to the personal information
                        Immunocore holds about you.
                      </li>
                      <li>
                        Request the correction and/or deletion of your personal
                        information.
                      </li>
                      <li>
                        Request the restriction of the processing of your
                        personal information, or object to that processing.
                      </li>
                      <li>
                        Withdraw your consent to the processing of your personal
                        information (where Immunocore is processing your
                        personal information based on your consent).
                      </li>
                      <li>
                        Request for the receipt or the transfer to another
                        organisation, in a machine-readable form, of the
                        personal information that you have provided to
                        Immunocore.
                      </li>
                      <li>
                        Complain to your local data protection authority if your
                        privacy rights are violated, or if you have suffered as
                        a result of unlawful processing of your personal
                        information.
                      </li>
                    </ul>
                    <p>
                      If you would like to exercise your rights, please let us
                      know by getting in touch with us as set out in the Contact
                      us section below.
                    </p>
                    <p>
                      <b>
                        What if you do not want to provide us with your personal
                        information?
                      </b>
                    </p>
                    <p>
                      Where you are given the option to share your personal
                      information with us, you can always choose not to do
                      so.&nbsp;
                    </p>
                    <p>
                      If you object to the processing of your personal
                      information, or if you have provided your consent to
                      processing and you later choose to withdraw it, we will
                      respect that choice in accordance with our legal
                      obligations. This could mean that we may not be able to
                      perform the actions necessary to achieve the purposes as
                      set out in the section “How do we use your personal
                      information?” above or that you are unable to make use of
                      the services and products offered by us.
                    </p>
                    <p>
                      <b>Cookies and other technologies</b>
                    </p>
                    <p>
                      To improve your experience on our site, we may use
                      'cookies'. Cookies are an industry standard and most major
                      web sites use them. A cookie is a small text file that our
                      site may place on your computer as a tool to remember your
                      preferences. You may refuse the use of cookies by
                      selecting the appropriate settings on your browser,
                      however please note that if you do this you may not be
                      able to use the full functionality of this website.
                    </p>
                    <p>
                      <strong>Social media guidelines</strong>
                    </p>
                    <p>
                      Welcome to our Immunocore online community. We value your
                      interest and participation. Because we work in a regulated
                      industry, however, when you engage with us on any of
                      Immunocore’s social media channels you are agreeing to
                      abide by these Community Guidelines. These are a
                      supplement to any other disclosures provided, such as
                      those discussing Privacy or Terms of Use, including those
                      of the platform itself (e.g. Twitter, LinkedIn,
                      etc.).&nbsp;
                      <br />
                      Our posts are informational only and are not to be
                      considered health or medical advice. You should also not
                      consider any comments or posts by others to be a
                      substitute for advice from your own doctor. &nbsp;Always
                      talk to a medical professional to address questions about
                      a medical condition.
                    </p>
                    <p>
                      We have set up our social accounts to share news and
                      information with you. We do not promise to respond to
                      comments or links you may post but do reserve the right to
                      remove a comment or link at our sole discretion.
                      &nbsp;Reasons we might remove them include:
                    </p>
                    <ul>
                      <li>
                        The comment mentions a specific product - whether ours
                        or those of another company- and does not include all
                        the information required by the government;
                      </li>
                      <li>
                        The comment includes false, misleading, inaccurate or
                        deceptive information;
                      </li>
                      <li>
                        The comment contains language, images or links that are
                        defamatory, profane, obscene, or disparaging of others’
                        race, gender, religion, age or lifestyle, or it contains
                        threats/ messages condoning violence or illegal
                        behaviors;&nbsp;
                      </li>
                      <li>
                        The comment is off-topic or is using our space to
                        advertise or promote other sites, products or services
                        or to solicit followers.
                      </li>
                    </ul>
                    <p>
                      If you are a minor, do not post or share comments without
                      the permission of a parent or legal guardian.
                    </p>
                    <p>
                      Please be cautious about sharing details about your own
                      healthcare or providing advice or suggestions to others.
                      If you share personal information about other people, you
                      are representing to us that you have the legal right
                      and/or permissions to do so.
                    </p>
                    <p>
                      Comments or content posted by any person other than
                      Immunocore are solely the views, opinions and
                      responsibility of the person expressing them, and do not
                      necessarily reflect our views or opinions. Immunocore does
                      not guarantee the accuracy or reliability of third party
                      materials.
                    </p>
                    <p>
                      On occasion, Immunocore may link to another website or
                      social media account not generated by Immunocore. Doing so
                      is not an endorsement of that site, and Immunocore has no
                      responsibility to independently validate the accuracy or
                      content on the third-party account.&nbsp;
                    </p>
                    <p>
                      If you are a medical professional and require further
                      information about Immunocore, clinical research or
                      clinical trials we are conducting, please send an e-mail
                      to info@immunocore.us&nbsp;
                    </p>
                    <p>
                      If you are a patient and require further information about
                      Immunocore, clinical research or clinical trials we are
                      conducting, please contact your physician.
                    </p>
                    <p>
                      <b>Contact us</b>
                    </p>
                    <p>
                      If you have questions or requests regarding this Privacy
                      Notice, or if you would like to exercise your rights,
                      please contact Immunocore Ltd using the contact
                      information below.
                    </p>
                    <p>T: +44 (0) 1235 438600</p>
                    <p>
                      E:
                      <a href="mailto:info@immunocore.us">info@immunocore.us</a>
                    </p>
                    <p>
                      You can contact Immunocore’s Data Protection Officer using
                      the email address:
                      <a href="mailto:info@immunocore.us">info@immunocore.us</a>
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section
              class="u-pt-10 u-pb-10"
              data-block="content"
              data-template="basic_text_page"
            >
              <div class="l-container">
                <div class="l-row">
                  <div class="l-col l-col-md-8">
                    <h2>
                      <b>California Privacy Policy</b>
                    </h2>
                    <p>Effective Date: 05/06/2020</p>
                    <p>
                      This Privacy Policy for California Residents (“California
                      Privacy Policy”) supplements the information contained in
                      the Immunocore Ltd (“Immuncore,” “we,” “us,” or “our”)
                      <span>&nbsp;</span>
                      <a href="/privacy-policy">privacy policy</a>.
                      &nbsp;Immunocore has adopted this California Privacy
                      Policy in accordance with the notice requirements under
                      the California Consumer Privacy Act of 2018, as amended
                      (“CCPA”), and any terms defined in the CCPA have the same
                      meaning when used in this California Privacy Policy.
                    </p>
                    <p>
                      This California Privacy Policy is parallel in scope to our
                      <span>&nbsp;</span>
                      <a href="/privacy-policy">privacy policy</a>.
                    </p>
                    <p>
                      <b>Definitions Specific to this Policy</b>
                    </p>
                    <p>
                      The CCPA includes definitions for terms specific to this
                      California Privacy Policy that do not apply to
                      Immunocore’s privacy policy, including the following
                      terms:
                    </p>
                    <p>
                      “<u>Personal Information</u>” means information that
                      identifies, relates to, describes, is reasonably capable
                      of being associated with, or could reasonably be linked,
                      directly or indirectly, with a particular consumer or
                      household.&nbsp; Personal Information does not include
                      publicly available information obtained from government
                      records; deidentified or aggregated consumer information
                      that cannot be reconstructed to identify you; any
                      information covered under the Gramm-Leach-Bliley Act or
                      the California Financial Information Privacy Act,
                      activities covered by the Fair Credit Reporting Act, or
                      protected health information as defined under the Health
                      Insurance Portability and Accountability Act.
                    </p>
                    <p>
                      “<u>Sale</u>” or “<u>sell</u>” means selling, renting,
                      releasing, disclosing, disseminating, making available,
                      transferring, or otherwise communicating orally, in
                      writing, or by electronic or other means, a consumer’s
                      personal information by the business to another business
                      or a third party for monetary or other valuable
                      consideration.
                    </p>
                    <p>
                      “<u>Service</u>
                      <span>&nbsp;</span>
                      <u>Provider</u>” means a sole proprietorship, partnership,
                      limited liability company, corporation, association, or
                      other legal entity that is organized or operated for the
                      profit or financial benefit of its shareholders or other
                      owners, that processes information on behalf of a business
                      and to which the business discloses a consumer’s Personal
                      Information for a business purpose pursuant to a written
                      contract.
                    </p>
                    <p>
                      “<u>Third Party</u>” means a person or entity who is not a
                      business that collects Personal Information from
                      consumers, as defined in the CCPA, and to whom the
                      business discloses a consumer’s Personal Information for a
                      business purpose pursuant to a written contract.
                    </p>
                    <p>
                      <b>The Personal Information We Collect</b>
                    </p>
                    <p>
                      The chart below shows the categories of Personal
                      Information we collect or “may collect”; examples of the
                      type of Personal Information in each category; all types
                      of sources from which each category of Personal
                      Information is collected; and the business purpose(s) for
                      which that category of Personal Information is
                      collected.&nbsp; We do not sell Personal
                      Information.&nbsp; This information covers the past 12
                      months.
                    </p>
                    <p>&nbsp;</p>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <p>
                              <b>Categories of Personal Information</b>
                            </p>
                          </td>
                          <td>
                            <p>
                              <b>Examples:</b>
                            </p>
                          </td>
                          <td>
                            <p>
                              <b>
                                Types of Sources from which this Category of
                                Personal Information Is Collected
                              </b>
                            </p>
                          </td>
                          <td>
                            <p>
                              <b>Business Purpose(s) for Collection</b>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>
                              <b>Unique identifiers</b>
                            </p>
                          </td>
                          <td>
                            <p>
                              Name, address, telephone, email, online
                              identifier, IP address
                            </p>
                          </td>
                          <td>
                            <p>Emails to us from website users</p>
                          </td>
                          <td>
                            <p>To respond to emails to us from website users</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>
                              <b>Internet activity</b>
                            </p>
                          </td>
                          <td>
                            <p>
                              Internet or other electronic network activity
                              information, including, but not limited to,
                              browsing history, search history, and information
                              regarding a consumer’s interaction with an
                              Internet Web site, application, or advertisement
                            </p>
                          </td>
                          <td>
                            <p>Website host</p>
                          </td>
                          <td>
                            <p>To analyze use of our website</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>
                              <b>Geolocation data</b>
                            </p>
                          </td>
                          <td>
                            <p>Geolocation data</p>
                          </td>
                          <td>
                            <p>Website host</p>
                          </td>
                          <td>
                            <p>To analyze use of our website</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p>&nbsp;</p>
                    <p>
                      We will not collect a category of Personal Information not
                      listed above, or use any Personal Information collected in
                      any of the above categories for a business purpose not
                      listed above, without first providing you with notice.
                    </p>
                    <p>
                      You have the right to request certain information
                      regarding the Personal Information we have collected about
                      you in the preceding 12 months.&nbsp; You may make such a
                      request up to twice in a 12-month span.&nbsp; Please note
                      that there are circumstances in which we may not be able
                      to comply with your request pursuant to the CCPA,
                      including when we cannot verify your request and/or when
                      there is a conflict with our own obligations to comply
                      with other legal or regulatory requirements.&nbsp; We will
                      notify you following submission of your request if this is
                      the case.
                    </p>
                    <ul>
                      <li>
                        If you would like to request (1) the categories of
                        Personal Information collected about you; (2) the
                        categories of sources providing that Personal
                        Information; and (3) the business purpose for collecting
                        that Personal Information, please email us at
                        <span>&nbsp;</span>
                        <a href="mailto:info@immunocore.us">
                          info@immunocore.us
                        </a>
                        . &nbsp;&nbsp;
                      </li>
                      <li>
                        If you would like to request the specific pieces of
                        Personal Information we collected about you, please
                        email us at<span>&nbsp;</span>
                        <a href="mailto:info@immunocore.us">
                          info@immunocore.us
                        </a>
                        . We will confirm receipt of the request within ten (10)
                        business days.
                      </li>
                      <li>
                        If you would like to request (1) the categories of
                        Personal Information collected about you; and (2) the
                        categories of Personal Information that were disclosed
                        for a business purpose, please email us at
                        <span>&nbsp;</span>
                        <a href="mailto:info@immunocore.us">
                          info@immunocore.us
                        </a>
                        .&nbsp;
                      </li>
                    </ul>
                    <p>
                      <b>Verification Process</b>
                    </p>
                    <p>
                      Only you, or a person that you authorize to act on your
                      behalf, may make a request related to your personal
                      information.&nbsp;
                      <i>
                        In the case of access and deletion, your request must be
                        verifiable before we can fulfill such request.
                      </i>
                    </p>
                    <p>
                      Verifying your request will require you to provide
                      sufficient information for us to reasonably verify that
                      you are the person about whom we collected personal
                      information, or a person authorized to act on your behalf.
                      <br />
                      <br />
                      We will only use the personal information that you have
                      provided in a verifiable request in order to verify your
                      request. As stated above, we cannot respond to your
                      request or provide you with personal information if we
                      cannot verify your identity or authority.&nbsp;
                    </p>
                    <p>
                      <b>Non-Discrimination</b>
                    </p>
                    <p>
                      We will not discriminate against you if you exercise any
                      of the rights provided by the CCPA set forth in this
                      California Privacy Policy.&nbsp; Please note that a
                      legitimate denial of a request for information, deletion,
                      or to opt-out is not discriminatory, nor is charging a fee
                      for excessive or repetitive consumer requests as permitted
                      by the CCPA.
                    </p>
                    <p>
                      <b>Deletion of Your Personal Information</b>
                    </p>
                    <p>
                      You have the right to request that we delete certain
                      Personal Information that we have collected.&nbsp; Please
                      note that there are circumstances in which we may not be
                      able to comply with your request pursuant to the CCPA,
                      including when we cannot verify your request and/or when
                      there is a conflict with our own obligations to comply
                      with other legal or regulatory requirements.&nbsp; We will
                      notify you following submission of your request if this is
                      the case.
                    </p>
                    <p>
                      If you would like to request that we delete your Personal
                      Information pursuant to the requirements of the CCPA,
                      please email us at<span>&nbsp;</span>
                      <a href="mailto:info@immunocore.us">info@immunocore.us</a>
                      . &nbsp;
                    </p>
                    <p>
                      <b>Authorized Agents</b>
                    </p>
                    <p>
                      You may designate an authorized agent to exercise your
                      rights under the CCPA on your behalf.&nbsp;
                    </p>
                    <p>Pursuant to the CCPA:</p>
                    <ul>
                      <li>
                        Only a business entity or natural person registered with
                        the California Secretary of State may act as an
                        authorized agent.
                      </li>
                      <li>
                        You must provide the authorized agent written permission
                        to exercise your rights under the CCPA on your behalf.
                      </li>
                      <li>
                        We may deny a request from an authorized agent on your
                        behalf if the authorized agent does not submit proof
                        that he, she, or it has been authorized by you to act on
                        your behalf if we request such proof, as permitted by
                        the CCPA.
                      </li>
                      <li>
                        Even if you use an authorized agent to exercise your
                        rights under the CCPA on your behalf, pursuant to the
                        CCPA we may still require that you verify your own
                        identity directly to us.&nbsp; This provision does not
                        apply if you have provided with a power of attorney
                        under California Probate Code sections 4000 to 4465.
                      </li>
                    </ul>
                    <p>
                      <b>Additional Information</b>
                    </p>
                    <p>
                      If you would like additional information regarding our
                      California Privacy Policy, please email us at
                      <span>&nbsp;</span>
                      <a href="mailto:info@immunocore.us">info@immunocore.us</a>
                      . Additionally, if you would like to receive our
                      California Privacy Policy in an alternative form, please
                      email us at<span>&nbsp;</span>
                      <a href="mailto:info@immunocore.us">info@immunocore.us</a>
                      .
                    </p>
                    <p>
                      <b>Additional California Privacy Rights</b>
                    </p>
                    <p>
                      California Civil Code Section § 1798.83 permits users of
                      our web site that are California residents to request
                      certain information regarding our disclosure of personal
                      information to other parties for their direct marketing
                      purposes. To make such a request, please send an email to
                      <a href="mailto:info@immunocore.us">info@immunocore.us</a>
                      with the subject “Shine the Light Request.”
                    </p>
                    <p>
                      You may view our main privacy policy&nbsp;
                      <a href="/privacy-policy">here</a>&nbsp;or contact us at
                      <span>&nbsp;</span>
                      <a href="mailto:info@immunocore.us">info@immunocore.us</a>
                      <span>&nbsp;</span>with any questions.
                    </p>
                    <p>
                      <b>Changes to Our California Privacy Policy</b>
                    </p>
                    <p>
                      Immunocore reserves the right to amend this California
                      Privacy Policy at our discretion and at any time. When we
                      make changes to this California Privacy Policy, we will
                      post an updated notice on our website with the effective
                      date.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  );
};

export default PrivacyMain;
