import React from "react";

const FinancialMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <aside id="tertiaryNav">
            <nav id="tertiary-navigation">
              <ul>
                <li class="sidebar-title">
                  <h2>Financials &amp; Filings</h2>
                </li>
                <li class="active active-landing">
                  <a
                    href="/investors/financials-filings/financial-results"
                    target="_self"
                  >
                    Financial Results
                  </a>
                </li>
                <li class="">
                  <a
                    href="/investors/financials-filings/sec-filings"
                    target="_self"
                  >
                    SEC Filings
                  </a>
                </li>
                <li class="">
                  <a
                    href="/investors/financials-filings/annual-reports"
                    target="_self"
                  >
                    Annual Reports
                  </a>
                </li>
                <li class="">
                  <a
                    href="/investors/financials-filings/email-alerts"
                    target="_self"
                  >
                    Email Alerts
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <main id="mainContent" class="main-content has-tertiary-nav">
            <div class="box quarterly-results" id="2024-22116-results">
              <div
                class="header"
                role="button"
                tabindex="0"
                data-toggle="collapse"
                data-target="#qr2024-22116-results-box"
                aria-controls="qr2024-22116-results-box"
                aria-expanded="true"
              >
                <div class="d-flex align-items-center">
                  <h2>2024</h2>
                  <span class="fiscal-year-notes pl-4"></span>
                  <span class="icon-trigger"></span>
                </div>
              </div>
              <div
                class="toggle-wrapper collapse show"
                id="qr2024-22116-results-box"
              >
                <div class="text">
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>Q1 2024</h3>
                          <div class="date">Quarter Ended Mar 31, 2024</div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="/investors/news/press-releases/detail/immunocore-reports-first-quarter-financial-results-and-provides-a-business-update/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/news/press-releases/detail/immunocore-reports-first-quarter-financial-results-and-provides-a-business-update"
                                  title="Immunocore reports first quarter financial results and provides a business update"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-05-08_Immunocore_reports_first_quarter_financial_89.pdf"
                                  class="link"
                                  title="PDF: Immunocore reports first quarter financial results and provides a business update"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-024784/ef20026074_10q.htm"
                                  title="10-Q Filing Viewer"
                                  class="fancybox fancybox.iframe"
                                  rel="noopener"
                                >
                                  10-Q <span class="sr-only">Filing</span>
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-024784/ef20026074_10q.htm"
                                  title="10-Q Filing Viewer"
                                  rel="noopener"
                                  class="link fancybox fancybox.iframe"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-024784/0001140361-24-024784.pdf"
                                  title="10-Q"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="result-line">
                            <div class="row justify-content-between">
                              <div class="col-auto file-title">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-024784/0001140361-24-024784-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  XBRL
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-024784/0001140361-24-024784-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-ZIP"></span>ZIP
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box quarterly-results" id="2023-21498-results">
              <div
                class="header collapsed"
                role="button"
                tabindex="0"
                data-toggle="collapse"
                data-target="#qr2023-21498-results-box"
                aria-controls="qr2023-21498-results-box"
                aria-expanded="false"
              >
                <div class="d-flex align-items-center">
                  <h2>2023</h2>
                  <span class="fiscal-year-notes pl-4"></span>
                  <span class="icon-trigger"></span>
                </div>
              </div>
              <div
                class="toggle-wrapper collapse"
                id="qr2023-21498-results-box"
              >
                <div class="text">
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>FY 2023</h3>
                          <div class="date">Fiscal Year Ended Dec 31, 2023</div>

                          <div class="qr-module-report">
                            <label id="ar-label-21935" class="ar-label">
                              Annual Report
                            </label>
                            <a
                              href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2288/21935/annual_report/2023+PLC+Annual+Report+FINAL+CLEAN+10Apr2024.pdf"
                              target="_blank"
                              rel="noopener"
                              aria-labelledby="ar-label-21935"
                            >
                              <img
                                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2288/21935/annual_report_resized.jpg"
                                alt="Year End Results"
                              />
                            </a>
                          </div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="/investors/news/press-releases/detail/immunocore-reports-fourth-quarter-and-full-year-2023-financial-results-and-provides-a-business-update/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/news/press-releases/detail/immunocore-reports-fourth-quarter-and-full-year-2023-financial-results-and-provides-a-business-update"
                                  title="Immunocore reports fourth quarter and full year 2023 financial results and provides a business update"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2024-02-28_Immunocore_reports_fourth_quarter_and_full_year_84.pdf"
                                  class="link"
                                  title="PDF: Immunocore reports fourth quarter and full year 2023 financial results and provides a business update"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_10k.htm"
                                  title="10-K Filing Viewer"
                                  class="fancybox fancybox.iframe"
                                  rel="noopener"
                                >
                                  10-K <span class="sr-only">Filing</span>
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_10k.htm"
                                  title="10-K Filing Viewer"
                                  rel="noopener"
                                  class="link fancybox fancybox.iframe"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/0001140361-24-009921.pdf"
                                  title="10-K"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="result-line">
                            <div class="row justify-content-between">
                              <div class="col-auto file-title">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/0001140361-24-009921-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  XBRL
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/0001140361-24-009921-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-ZIP"></span>ZIP
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="result-line">
                            <div class="row justify-content-between">
                              <div class="col-auto file-title">
                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2288/21935/annual_report/2023+PLC+Annual+Report+FINAL+CLEAN+10Apr2024.pdf"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Annual Report
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2288/21935/annual_report/2023+PLC+Annual+Report+FINAL+CLEAN+10Apr2024.pdf"
                                  target="_blank"
                                  rel="noopener"
                                  aria-label="PDF: Annual Report"
                                  class="link"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>Q3 2023</h3>
                          <div class="date">Quarter Ended Sep 30, 2023</div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="https://www.immunocore.com/investors/news/press-releases/detail/77/immunocore-reports-third-quarter-2023-financial-results-and-provides-business-update/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/news/press-releases/detail/77/immunocore-reports-third-quarter-2023-financial-results-and-provides-business-update"
                                  title="Immunocore Reports Third Quarter 2023 Financial Results and Provides Business Update"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2023-11-07_Immunocore_Reports_Third_Quarter_2023_Financial_77.pdf"
                                  class="link"
                                  title="PDF: Immunocore Reports Third Quarter 2023 Financial Results and Provides Business Update"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>Q2 2023</h3>
                          <div class="date">Quarter Ended Jun 30, 2023</div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="https://www.immunocore.com/investors/news/press-releases/detail/70/immunocore-reports-second-quarter-2023-financial-results-and-provides-business-update/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/news/press-releases/detail/70/immunocore-reports-second-quarter-2023-financial-results-and-provides-business-update"
                                  title="Immunocore Reports Second Quarter 2023 Financial Results and Provides Business Update"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2023-08-10_Immunocore_Reports_Second_Quarter_2023_Financial_70.pdf"
                                  class="link"
                                  title="PDF: Immunocore Reports Second Quarter 2023 Financial Results and Provides Business Update"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="result-line">
                            <div class="row justify-content-between">
                              <div class="col-auto file-title">
                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2288/21499/presentation/279ec186-f637-43e2-9a37-4dc731bc83d3.pdf"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Presentation
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2288/21499/presentation/279ec186-f637-43e2-9a37-4dc731bc83d3.pdf"
                                  target="_blank"
                                  rel="noopener"
                                  aria-label="PDF: Presentation"
                                  class="link"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>Q1 2023</h3>
                          <div class="date">Quarter Ended Mar 31, 2023</div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="https://www.immunocore.com/investors/news/press-releases/detail/7/immunocore-reports-first-quarter-2023-financial-results-and-provides-business-update/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/news/press-releases/detail/7/immunocore-reports-first-quarter-2023-financial-results-and-provides-business-update"
                                  title="Immunocore Reports First Quarter 2023 Financial Results and Provides Business Update"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2023-05-10_Immunocore_Reports_First_Quarter_2023_Financial_7.pdf"
                                  class="link"
                                  title="PDF: Immunocore Reports First Quarter 2023 Financial Results and Provides Business Update"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box quarterly-results" id="2022-21494-results">
              <div
                class="header collapsed"
                role="button"
                tabindex="0"
                data-toggle="collapse"
                data-target="#qr2022-21494-results-box"
                aria-controls="qr2022-21494-results-box"
                aria-expanded="false"
              >
                <div class="d-flex align-items-center">
                  <h2>2022</h2>
                  <span class="fiscal-year-notes pl-4"></span>
                  <span class="icon-trigger"></span>
                </div>
              </div>
              <div
                class="toggle-wrapper collapse"
                id="qr2022-21494-results-box"
              >
                <div class="text">
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>FY 2022</h3>
                          <div class="date">Fiscal Year Ended Dec 31, 2022</div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="/investors/news/press-releases/detail/immunocore-reports-2022-financial-results-and-provides-business-update/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/news/press-releases/detail/immunocore-reports-2022-financial-results-and-provides-business-update"
                                  title="Immunocore Reports 2022 Financial Results and Provides Business Update"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2023-03-01_Immunocore_Reports_2022_Financial_Results_and_12.pdf"
                                  class="link"
                                  title="PDF: Immunocore Reports 2022 Financial Results and Provides Business Update"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-23-009313/brhc10048769_20f.htm"
                                  title="20-F Filing Viewer"
                                  class="fancybox fancybox.iframe"
                                  rel="noopener"
                                >
                                  20-F <span class="sr-only">Filing</span>
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-23-009313/brhc10048769_20f.htm"
                                  title="20-F Filing Viewer"
                                  rel="noopener"
                                  class="link fancybox fancybox.iframe"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-23-009313/0001140361-23-009313.pdf"
                                  title="20-F"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="result-line">
                            <div class="row justify-content-between">
                              <div class="col-auto file-title">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-23-009313/0001140361-23-009313-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  XBRL
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-23-009313/0001140361-23-009313-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-ZIP"></span>ZIP
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>Q3 2022</h3>
                          <div class="date">Quarter Ended Sep 30, 2022</div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="/investors/news/press-releases/detail/immunocore-reports-third-quarter-2022-financial-results-and-provides-business-update/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/news/press-releases/detail/immunocore-reports-third-quarter-2022-financial-results-and-provides-business-update"
                                  title="Immunocore Reports Third Quarter 2022 Financial Results and Provides Business Update"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2022-11-09_Immunocore_Reports_Third_Quarter_2022_Financial_25.pdf"
                                  class="link"
                                  title="PDF: Immunocore Reports Third Quarter 2022 Financial Results and Provides Business Update"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>Q2 2022</h3>
                          <div class="date">Quarter Ended Jun 30, 2022</div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="https://www.immunocore.com/investors/news/press-releases/detail/27/immunocore-reports-second-quarter-2022-financial-results-and-provides-business-update/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/news/press-releases/detail/27/immunocore-reports-second-quarter-2022-financial-results-and-provides-business-update"
                                  title="Immunocore Reports Second Quarter 2022 Financial Results and Provides Business Update"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2022-08-10_Immunocore_Reports_Second_Quarter_2022_Financial_27.pdf"
                                  class="link"
                                  title="PDF: Immunocore Reports Second Quarter 2022 Financial Results and Provides Business Update"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>Q1 2022</h3>
                          <div class="date">Quarter Ended Mar 31, 2022</div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="https://www.immunocore.com/investors/news/press-releases/detail/39/immunocore-reports-first-quarter-2022-financial-results-and-provides-business-update/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/news/press-releases/detail/39/immunocore-reports-first-quarter-2022-financial-results-and-provides-business-update"
                                  title="Immunocore Reports First Quarter 2022 Financial Results and Provides Business Update"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2022-05-11_Immunocore_Reports_First_Quarter_2022_Financial_39.pdf"
                                  class="link"
                                  title="PDF: Immunocore Reports First Quarter 2022 Financial Results and Provides Business Update"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box quarterly-results" id="2021-21490-results">
              <div
                class="header collapsed"
                role="button"
                tabindex="0"
                data-toggle="collapse"
                data-target="#qr2021-21490-results-box"
                aria-controls="qr2021-21490-results-box"
                aria-expanded="false"
              >
                <div class="d-flex align-items-center">
                  <h2>2021</h2>
                  <span class="fiscal-year-notes pl-4"></span>
                  <span class="icon-trigger"></span>
                </div>
              </div>
              <div
                class="toggle-wrapper collapse"
                id="qr2021-21490-results-box"
              >
                <div class="text">
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>FY 2021</h3>
                          <div class="date">Fiscal Year Ended Dec 31, 2021</div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="/investors/news/press-releases/detail/immunocore-reports-full-year-2021-financial-results-and-provides-business-update/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/news/press-releases/detail/immunocore-reports-full-year-2021-financial-results-and-provides-business-update"
                                  title="Immunocore Reports Full Year 2021 Financial Results and Provides Business Update"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2022-03-03_Immunocore_Reports_Full_Year_2021_Financial_43.pdf"
                                  class="link"
                                  title="PDF: Immunocore Reports Full Year 2021 Financial Results and Provides Business Update"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/brhc10034614_20f.htm"
                                  title="20-F Filing Viewer"
                                  class="fancybox fancybox.iframe"
                                  rel="noopener"
                                >
                                  20-F <span class="sr-only">Filing</span>
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/brhc10034614_20f.htm"
                                  title="20-F Filing Viewer"
                                  rel="noopener"
                                  class="link fancybox fancybox.iframe"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/0001140361-22-007578.pdf"
                                  title="20-F"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="result-line">
                            <div class="row justify-content-between">
                              <div class="col-auto file-title">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/0001140361-22-007578-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  XBRL
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/0001140361-22-007578-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-ZIP"></span>ZIP
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>Q3 2021</h3>
                          <div class="date">Quarter Ended Sep 30, 2021</div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="/investors/news/press-releases/detail/immunocore-reports-third-quarter-2021-financial-results-and-provides-business-update/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/news/press-releases/detail/immunocore-reports-third-quarter-2021-financial-results-and-provides-business-update"
                                  title="Immunocore Reports Third Quarter 2021 Financial Results and Provides Business Update"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2021-11-10_Immunocore_Reports_Third_Quarter_2021_Financial_50.pdf"
                                  class="link"
                                  title="PDF: Immunocore Reports Third Quarter 2021 Financial Results and Provides Business Update"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>Q2 2021</h3>
                          <div class="date">Quarter Ended Jun 30, 2021</div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="/investors/news/press-releases/detail/immunocore-reports-second-quarter-2021-financial-results-and-provides-business-update/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/news/press-releases/detail/immunocore-reports-second-quarter-2021-financial-results-and-provides-business-update"
                                  title="Immunocore Reports Second Quarter 2021 Financial Results and Provides Business Update"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2021-08-11_Immunocore_Reports_Second_Quarter_2021_Financial_59.pdf"
                                  class="link"
                                  title="PDF: Immunocore Reports Second Quarter 2021 Financial Results and Provides Business Update"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>Q1 2021</h3>
                          <div class="date">Quarter Ended Mar 31, 2021</div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="/investors/news/press-releases/detail/immunocore-reports-first-quarter-2021-financial-results/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/news/press-releases/detail/immunocore-reports-first-quarter-2021-financial-results"
                                  title="Immunocore Reports First Quarter 2021 Financial Results"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2021-05-12_Immunocore_Reports_First_Quarter_2021_Financial_63.pdf"
                                  class="link"
                                  title="PDF: Immunocore Reports First Quarter 2021 Financial Results"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box quarterly-results" id="2020-21501-results">
              <div
                class="header collapsed"
                role="button"
                tabindex="0"
                data-toggle="collapse"
                data-target="#qr2020-21501-results-box"
                aria-controls="qr2020-21501-results-box"
                aria-expanded="false"
              >
                <div class="d-flex align-items-center">
                  <h2>2020</h2>
                  <span class="fiscal-year-notes pl-4"></span>
                  <span class="icon-trigger"></span>
                </div>
              </div>
              <div
                class="toggle-wrapper collapse"
                id="qr2020-21501-results-box"
              >
                <div class="text">
                  <div class="text">
                    <div class="text">
                      <div class="row">
                        <div class="col-md-4 results-info">
                          <h3>FY 2020</h3>
                          <div class="date">Fiscal Year Ended Dec 31, 2020</div>
                        </div>

                        <div class="col-md-8">
                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a href="/investors/news/press-releases/detail/immunocore-provides-business-update-and-reports-full-year-2020-financial-results/">
                                  Earnings Release
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="/investors/news/press-releases/detail/immunocore-provides-business-update-and-reports-full-year-2020-financial-results"
                                  title="Immunocore Provides Business Update and Reports Full Year 2020 Financial Results"
                                  class="link"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>

                                <a
                                  href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/news/2021-03-25_Immunocore_Provides_Business_Update_and_Reports_66.pdf"
                                  class="link"
                                  title="PDF: Immunocore Provides Business Update and Reports Full Year 2020 Financial Results"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="result-line">
                            <div class="row justify-space-between">
                              <div class="col-auto file-title">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/brhc10022122_20f.htm"
                                  title="20-F Filing Viewer"
                                  class="fancybox fancybox.iframe"
                                  rel="noopener"
                                >
                                  20-F <span class="sr-only">Filing</span>
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/brhc10022122_20f.htm"
                                  title="20-F Filing Viewer"
                                  rel="noopener"
                                  class="link fancybox fancybox.iframe"
                                >
                                  <span class="eqicon-HTML"></span>HTML
                                </a>
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/0001140361-21-009904.pdf"
                                  title="20-F"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-PDF"></span>PDF
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="result-line">
                            <div class="row justify-content-between">
                              <div class="col-auto file-title">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/0001140361-21-009904-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  XBRL
                                </a>
                              </div>
                              <div class="col file-links">
                                <a
                                  href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/0001140361-21-009904-xbrl.zip"
                                  title="Download Raw XBRL Files"
                                  class="link"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <span class="eqicon-ZIP"></span>ZIP
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn expand-all-quarters">
              Expand All
            </button>
          </main>
        </div>
      </div>
    </div>
  );
};

export default FinancialMain;
