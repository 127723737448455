import React from "react";

const EventHeader = () => {
  return (
    <nav id="header" class="affixed" aria-label="Main">
      <div id="skipLinks">
        <a href="#mainContent">Skip to main content</a>
        <a href="#sectionNav">Skip to section navigation</a>
        <a href="#footer">Skip to footer</a>
      </div>
      <div class="zero-nav d-none d-lg-block">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-12">
              <div class="zero-nav-links">
                <a href="/investors/news" target="_self">
                  News
                </a>
                <a href="/careers" target="_self">
                  Careers
                </a>
                <a href="/contact-us" target="_self">
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="d-flex justify-content-between">
          <div class="logo">
            <a href="/">
              <img
                src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2317/21310/image.svg"
                width="226"
                height="23"
                alt="Immunocore Holdings plc"
              />
            </a>
          </div>
          <a
            role="button"
            class="mobile-nav-trigger"
            id="showRight"
            aria-label="Expand main navigation"
            aria-controls="mobile-nav"
          >
            <div class="meat"></div>
            <div class="meat"></div>
            <div class="meat"></div>
          </a>
          <div
            class="cbp-spmenu cbp-spmenu-vertical cbp-spmenu-right main-nav"
            id="mobile-nav"
          >
            <ul class="main-menu">
              <li class="has-children" data-submenu-id="submenu-about-us">
                <a href="/about-us" target="_self">
                  About Us
                </a>
                <ul id="submenu-about-us">
                  <li class="">
                    <a href="/about-us" target="_self">
                      Overview
                    </a>
                  </li>
                  <li class="">
                    <a href="/about-us/management-team" target="_self">
                      Management Team
                    </a>
                  </li>
                  <li class="">
                    <a href="/about-us/board-of-directors" target="_self">
                      Board of Directors
                    </a>
                  </li>
                </ul>
              </li>
              <li
                class="has-children"
                data-submenu-id="submenu-pipeline-portfolio"
              >
                <a href="/pipeline-portfolio" target="_self">
                  Pipeline &amp; Portfolio
                </a>
                <ul id="submenu-pipeline-portfolio">
                  <li class="">
                    <a href="/pipeline-portfolio/pipeline" target="_self">
                      Pipeline
                    </a>
                  </li>
                  <li class="">
                    <a href="/pipeline-portfolio/our-therapies" target="_self">
                      Our Therapies
                    </a>
                  </li>
                </ul>
              </li>
              <li class="" data-submenu-id="submenu-science">
                <a href="/science" target="_self">
                  Science
                </a>
              </li>
              <li class="" data-submenu-id="submenu-clinical-trials">
                <a href="/clinical-trials" target="_self">
                  Clinical Trials
                </a>
              </li>
              <li
                class="has-children active last-item"
                data-submenu-id="submenu-investors"
              >
                <a href="/investors" target="_self">
                  Investors
                </a>
                <ul id="submenu-investors">
                  <li class="">
                    <a href="/investors" target="_self">
                      Overview
                    </a>
                  </li>
                  <li class="has-children">
                    <a href="/investors/news" target="_self">
                      News
                    </a>
                  </li>
                  <li class="has-children active">
                    <a href="/investors/events-presentations" target="_self">
                      Events &amp; Presentations
                    </a>
                  </li>
                  <li class="has-children">
                    <a href="/investors/stock-info" target="_self">
                      Stock Info
                    </a>
                  </li>
                  <li class="has-children">
                    <a href="/investors/financials-filings" target="_self">
                      Financials &amp; Filings
                    </a>
                  </li>
                  <li class="has-children">
                    <a href="/investors/corporate-governance" target="_self">
                      Governance
                    </a>
                  </li>
                  <li class="has-children">
                    <a href="/investors/ir-resources" target="_self">
                      IR Resources
                    </a>
                  </li>
                </ul>
              </li>
              <li class="d-block zero-nav-item">
                <a href="/investors/news" target="_self" rel="noopener">
                  News
                </a>
              </li>
              <li class="d-block zero-nav-item">
                <a href="/careers" target="_self" rel="noopener">
                  {" "}
                  Careers{" "}
                </a>
              </li>
              <li class="d-block zero-nav-item">
                <a href="/contact-us" target="_self" rel="noopener">
                  Contact us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default EventHeader;
