import React from "react";

const PaymentMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <main id="mainContent" class="main-content">
            <h2>Relationships with Patient Organisations</h2>
            <p>At Immunocore, we value collaboration and partnerships.&nbsp;</p>
            <p>
              Our commitment to working with patient organisations for the
              benefit of patients, is in accordance with the requirements of
              Clause 27 of the ABPI Code of Practice for the Pharmaceutical
              Industry.
            </p>
            <p>
              Immunocore upholds the highest standards of integrity in the way
              that we conduct business and we fully support the industry
              movement toward increased transparency of financial relationships.
              Disclosure of our financial support to patient organisations is
              one of the activities that we undertake to improve transparency
              concerning Company dealings with third parties.&nbsp;
            </p>
            <p>
              Immunocore declares that the Company has made its best efforts to
              make a comprehensive disclosure of concerned activities for 2019
              and from hereon in.
            </p>
            <ul>
              <li>
                <a
                  href="https://d1io3yog0oux5.cloudfront.net/_35ac4818e85a5a8c154f45778e26ea34/immunocore/files/pages/Payments_and_Disclosures_2019.pdf"
                  target="_blank"
                  rel="noopener"
                >
                  Payments and disclosures 2019
                </a>
              </li>
            </ul>
          </main>
        </div>
      </div>
    </div>
  );
};

export default PaymentMain;
