import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/slider.css";

const HomeMain = () => {
  const sliderRef = useRef(null);
  const targetRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, []);

  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const settings = {
    infinite: false,
    dots: false,
    variableWidth: false,
    arrows: false, // Hide default arrows
    draggable: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          variableWidth: false,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          variableWidth: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <main id="mainContent">
      <div
        class="page-banner dark home-hero"
        style={{
          backgroundImage:
            "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2365/20985/image_home.jpg)",
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="text">
                <div class="row justify-content-md-start justify-content-center">
                  <div class="col-md-6">
                    <h1 class="sr-only">Immunocore Holdings plc</h1>
                    <h2>Pioneers of the world’s first TCR therapy</h2>
                  </div>
                </div>
                <div class="row justify-content-md-end justify-content-center">
                  <div class="col-md-6">
                    <p>
                      We want to radically improve outcomes for patients with
                      cancer, infectious diseases, and autoimmune diseases, by
                      pioneering and delivering transformative immunomodulating
                      medicines.
                    </p>
                    <a href="/about-us" class="btn">
                      Learn about us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="scroll-more"
          role="button"
          aria-label="Scroll down the page and view more content"
          onClick={scrollToTarget}
          tabindex="0"
        ></div>
      </div>
      <section
        ref={targetRef}
        class="module row-default row-two-col pt--xl col-4-layout mobile-white-background bg-pos-top scale-bg-image background"
        style={{
          background:
            "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2364/21512/background_image.jpg)",
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-between align-items-start">
            <div class="col-lg-4">
              <div class="module-content primary-area">
                <div class="text-area">
                  <div class="text">
                    <h2>Harnessing the power of the immune system</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <p>
                      Our immune system is our best defense against diseases, as
                      it recognizes and destroys diseased cells. But sometimes,
                      this defense does not work as intended when diseased cells
                      manage to hide from immune cells or when the immune system
                      turns against the body.
                    </p>
                    <p>
                      Our proprietary ImmTAX technology is designed to address
                      these limitations, by activating the immune system to make
                      infected or tumor cells visible and then destroy them, or
                      by turning off the immune system to reduce an autoimmune
                      response.
                    </p>
                    <div class="btn-wrapper">
                      <a href="/science" class="btn">
                        {" "}
                        View our science{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id=""
        class="module row-default neutral-bg home-pipeline no-padding-top no-padding-bottom image-align image-align--left"
      >
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2364/21514/image.jpg"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <h2>Our pipeline</h2>
                    <p>
                      We have a diverse pipeline with multiple bispecific T cell
                      receptor therapies across a range of indications, from
                      solid tumors to infectious and autoimmune diseases.
                    </p>
                    <a href="/pipeline-portfolio/pipeline" class="btn">
                      View our pipeline
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id=""
        class="module row-default no-padding-top no-padding-bottom no-overlay no-dark image-align image-align--right background overlay dark"
        style={{
          background:
            "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2364/21517/background_image.jpg)",
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-center align-items-center flex-row-reverse">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2364/21517/image.png"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <h2>Pioneering immunotherapies, together</h2>
                    <p>
                      Join our team at the forefront of the next generation of
                      immunotherapy. We take our medicines through their entire
                      journey from the lab bench to the patient bedside, so
                      whether you’re in research, development or delivery, we
                      have the career for you.
                    </p>
                    <a href="/careers" class="btn">
                      {" "}
                      View our careers{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* movement */}

      <section class="module module-latest-news slider-with-images module-latest-news-block module-blocks-with-images">
        <div class="container">
          <div class="module-header">
            <div class="row justify-content-between align-items-center">
              <div class="col-md-8">
                <div class="text">
                  <h2>Latest news</h2>
                </div>
              </div>
              <div class="col-auto">
                <div class="slider-nav-wrapper">
                  <div class="slider-nav"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="news-listing">
            <div class="content">
              <div class="news-slider">
                <a
                  href="/investors/news/press-releases/detail/immunocore-announces-randomization-of-first-patient-in-the-global-registrational-phase-3-clinical-trial-testing-brenetafusp-for-the-treatment-of-first-line-advanced-or-metastatic-cutaneous-melanoma"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background:
                          "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)",
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-06-18T07:00:00">
                            Jun. 18, 2024 • Time: 7:00 AM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore announces randomization of first patient in
                          the global, registrational Phase 3 clinical trial
                          testing brenetafusp for the treatment of first-line
                          advanced or metastatic cutaneous melanoma
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-presents-kimmtrak-clinical-data-demonstrating-that-patients-with-stable-disease-and-confirmed-tumor-reduction-have-similar-clinical-outcomes-to-patients-with-partial-response"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background:
                          "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)",
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-06-01T08:00:00">
                            Jun. 1, 2024 • Time: 8:00 AM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore presents KIMMTRAK clinical data
                          demonstrating that patients with stable disease and
                          confirmed tumor reduction have similar clinical
                          outcomes to patients with partial response
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-reports-updated-phase-1-data-of-brenetafusp-imc-f106c-an-immtac-bispecific-targeting-prame-in-immune-checkpoint-pre-treated-cutaneous-melanoma-patients-at-asco-2024"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background:
                          "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)",
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-05-31T16:05:00">
                            May. 31, 2024 • Time: 4:05 PM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore reports updated Phase 1 data of brenetafusp
                          (IMC-F106C), an ImmTAC bispecific targeting PRAME, in
                          immune checkpoint pre-treated cutaneous melanoma
                          patients at ASCO 2024
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-to-present-at-the-jefferies-global-healthcare-conference"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background:
                          "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)",
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-05-30T16:00:00">
                            May. 30, 2024 • Time: 4:00 PM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore to present at the Jefferies Global
                          Healthcare Conference
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-converts-phase-23-tebe-am-clinical-trial-into-registrational-phase-3-trial-evaluating-kimmtrak-for-previously-treated-advanced-cutaneous-melanoma"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background:
                          "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)",
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-05-29T07:00:00">
                            May. 29, 2024 • Time: 7:00 AM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore converts Phase 2/3 TEBE-AM clinical trial
                          into registrational Phase 3 trial evaluating KIMMTRAK
                          for previously treated advanced cutaneous melanoma
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-reports-first-quarter-financial-results-and-provides-a-business-update"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background:
                          "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)",
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-05-08T07:00:00">
                            May. 8, 2024 • Time: 7:00 AM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore reports first quarter financial results and
                          provides a business update
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-announces-upcoming-presentation-and-posters-at-asco-2024"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background:
                          "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)",
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-04-24T10:00:00">
                            Apr. 24, 2024 • Time: 10:00 AM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore announces upcoming presentation and posters
                          at ASCO 2024
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-to-present-at-upcoming-investor-conferences"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background:
                          "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)",
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-04-04T16:00:00">
                            Apr. 4, 2024 • Time: 4:00 PM EDT
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore to present at upcoming investor conferences
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-presented-two-posters-at-croi-2024"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background:
                          "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)",
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-03-05T16:30:00">
                            Mar. 5, 2024 • Time: 4:30 PM EST
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore presented two posters at CROI 2024
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
                <a
                  href="/investors/news/press-releases/detail/immunocore-to-present-at-upcoming-investor-conferences"
                  class="slider-article-link"
                >
                  <div class="content-box">
                    <div
                      class="content-image"
                      style={{
                        background:
                          "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/default-article-image.jpg)",
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#333",
                      }}
                    ></div>

                    <div class="text" data-mh="content">
                      <article class="media">
                        <p class="date">
                          <time datetime="2024-03-01T07:00:00">
                            Mar. 1, 2024 • Time: 7:00 AM EST
                          </time>
                        </p>
                        <h3 class="heading--h6">
                          Immunocore to present at upcoming investor conferences
                        </h3>
                      </article>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="btn-wrapper">
            <a
              href="https://www.immunocore.com/investors/news/press-releases"
              class="btn"
            >
              View all
            </a>
          </div>
          <p class="p-style--xs mt-3">
            * Intended for investors and members of the media.
          </p>
        </div>
      </section>

      <div
        class="module module-stock-info-right-data"
        style={{
          background:
            "url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2364/21519/background_image.jpg)",
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="ir-quote-box">
          <div class="stock-info stock-info-right-data no-padding-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-4 neutral-bg">
                  <div class="email-content module-content">
                    <div class="text">
                      <h2>Email alerts</h2>
                      <p>
                        Stay informed and receive company updates straight to
                        your inbox.
                      </p>
                      <a href="/investors/news/email-alerts" class="btn">
                        Sign up today
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 dark stock-content-container">
                  <div class="stock-content">
                    <div data-name="qmod">
                      <div
                        class="stock-info quote-box qtool"
                        data-qmod-tool="detailedquotetab"
                        data-qmod-params='{"lang":"en","showLogo":false,"lowHigh":false,"symbol":"IMCR"}'
                      >
                        <span class="loader"></span>
                        <script type="text/template">
                          <div class="row module-header">
                            <div class="col-12">
                              <h2 class="heading--h4">Investor relations</h2>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4">
                              <h3 class="title">Nasdaq: IMCR</h3>
                              {/* <p class="date">{data.pricedata.lasttradedatetime | momentjs 'true' 'MMM. D, YYYY' tLang}</p> */}
                            </div>
                            <div class="col-md-8">
                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="stock-section price">
                                    {/* <span class="value">${data.pricedata.last | asQHLast tLang }</span> */}
                                    {/* <span class="value-title">{tplLang.price| toLang tLang}</span> */}
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="stock-section change">
                                    <span class="value">
                                      <span
                                        rv-qmodchange="data.pricedata.change"
                                        class="qmod-change"
                                      >
                                        {/* <span class="number">{data.pricedata.change | asQHLast tLang }</span> */}
                                        {/* <span class="percent"> ({data.pricedata.changepercent | numeraljs '0.00' '0.00' | postFix '%'})</span> */}
                                      </span>
                                    </span>
                                    <span class="value-title">Change</span>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="stock-section volume">
                                    {/* <span class="value">{data.pricedata.sharevolume | numeraljs '0.00a'}</span> */}
                                    {/* <span class="value-title">{tplLang.volume| toLang tLang}</span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </script>
                      </div>
                    </div>
                    <a href="/investors/stock-info" class="block-link">
                      View all stock information
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HomeMain;
