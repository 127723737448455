import React from "react";

const DocumentSectionNav = () => {
  return (
    <nav
      id="sectionNav"
      class="sub-navigation-wrapper"
      aria-label="Investors Section"
    >
      <div class="sub-navigation affixed">
        <div class="container">
          <a
            class="visible-xs visible-sm mobile-sub-nav-trigger collapsed"
            role="button"
            data-toggle="collapse"
            data-target="#sub-navigation"
          >
            <div class="trigger-container">
              <div class="meat"></div>
              <div class="meat"></div>
              <div class="meat"></div>
            </div>
            Investors
          </a>
          <div class="collapse sb-nav clearfix" id="sub-navigation">
            <h3>Investors</h3>
            <ul>
              <li class="">
                <a href="/investors" target="_self">
                  Overview
                </a>
              </li>
              <li class="has-children dropdown">
                <a href="/investors/news" target="_self">
                  News
                </a>
                <ul class="dropdown-menu">
                  <li class="">
                    <a href="/investors/news/press-releases" target="_self">
                      Press Releases
                    </a>
                  </li>
                  <li class="">
                    <a href="/investors/news/email-alerts" target="_self">
                      Email Alerts
                    </a>
                  </li>
                </ul>
              </li>
              <li class="has-children dropdown">
                <a href="/investors/events-presentations" target="_self">
                  Events &amp; Presentations
                </a>
                <ul class="dropdown-menu">
                  <li class="">
                    <a
                      href="/investors/events-presentations/events"
                      target="_self"
                    >
                      Events
                    </a>
                  </li>
                  <li class="">
                    <a
                      href="/investors/events-presentations/presentations"
                      target="_self"
                    >
                      Presentations
                    </a>
                  </li>
                </ul>
              </li>
              <li class="has-children dropdown">
                <a href="/investors/stock-info" target="_self">
                  Stock Info
                </a>
                <ul class="dropdown-menu">
                  <li class="">
                    <a
                      href="/investors/stock-info/quote-and-chart"
                      target="_self"
                    >
                      Quote and Chart
                    </a>
                  </li>
                  <li class="">
                    <a
                      href="/investors/stock-info/historical-performance"
                      target="_self"
                    >
                      Historical Performance
                    </a>
                  </li>
                  <li class="">
                    <a
                      href="/investors/stock-info/analyst-coverage"
                      target="_self"
                    >
                      Analyst Coverage
                    </a>
                  </li>
                </ul>
              </li>
              <li class="has-children dropdown">
                <a href="/investors/financials-filings" target="_self">
                  Financials &amp; Filings
                </a>
                <ul class="dropdown-menu">
                  <li class="">
                    <a
                      href="/investors/financials-filings/financial-results"
                      target="_self"
                    >
                      Financial Results
                    </a>
                  </li>
                  <li class="">
                    <a
                      href="/investors/financials-filings/sec-filings"
                      target="_self"
                    >
                      SEC Filings
                    </a>
                  </li>
                  <li class="">
                    <a
                      href="/investors/financials-filings/annual-reports"
                      target="_self"
                    >
                      Annual Reports
                    </a>
                  </li>
                  <li class="">
                    <a
                      href="/investors/financials-filings/email-alerts"
                      target="_self"
                    >
                      Email Alerts
                    </a>
                  </li>
                </ul>
              </li>
              <li class="has-children active dropdown">
                <a href="/investors/corporate-governance" target="_self">
                  Governance
                </a>
                <ul class="dropdown-menu">
                  <li class="">
                    <a href="/investors/corporate-governance" target="_self">
                      Overview
                    </a>
                  </li>
                  <li class="active active-landing">
                    <a
                      href="/investors/corporate-governance/governance-documents"
                      target="_self"
                    >
                      Governance Documents
                    </a>
                  </li>
                  <li class="">
                    <a
                      href="/investors/corporate-governance/management-team"
                      target="_self"
                    >
                      Management Team
                    </a>
                  </li>
                  <li class="">
                    <a href="/about-us/board-of-directors" target="_self">
                      Board of Directors
                    </a>
                  </li>
                  <li class="">
                    <a
                      href="/investors/corporate-governance/board-committees"
                      target="_self"
                    >
                      Board Committees
                    </a>
                  </li>
                </ul>
              </li>
              <li class="has-children dropdown">
                <a href="/investors/ir-resources" target="_self">
                  IR Resources
                </a>
                <ul class="dropdown-menu">
                  <li class="">
                    <a
                      href="/investors/ir-resources/investor-faqs"
                      target="_self"
                    >
                      Investor FAQs
                    </a>
                  </li>
                  <li class="">
                    <a
                      href="/investors/ir-resources/email-alerts"
                      target="_self"
                    >
                      Email Alerts
                    </a>
                  </li>
                  <li class="">
                    <a href="/investors/ir-resources/contacts" target="_self">
                      Contacts
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DocumentSectionNav;
