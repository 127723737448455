import React from "react";

const ClinicalMain = () => {
  return (
    <main id="mainContent">
      <section
        class="module row-default row-two-col pt--xl clinical-trials col-8-layout background"
        style={{
          backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21603/background_image.jpg)`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-center align-items-start">
            <div class="col-12">
              <div class="module-content">
                <div class="text-area">
                  <div class="text"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="module-content primary-area">
                <div class="text-area">
                  <div class="text">
                    <p class="basic-p">
                      By taking part in clinical trials, patients can make an
                      important contribution to ongoing research and better
                      understanding of a disease. Clinical trials are also an
                      opportunity to be a part of testing a potential new
                      therapy.
                    </p>
                    <h2 class="mb-0 p-big">
                      We have a number of clinical trials currently recruiting
                      patients:
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="module-content">
                <div class="text-area">
                  <div class="text"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="module module-teasers module-clinical-trials light-blue-bg">
        <div class="container">
          <div class="row child-page">
            <div class="col-lg-8">
              <div class="content">
                <h2>
                  Tebentafusp Regimen Versus Investigator&#039;s Choice in
                  Previously Treated Advanced Melanoma (TEBE-AM)
                </h2>
                <a
                  href="https://clinicaltrials.gov/study/NCT05549297?term=immunocore&amp;checkSpell=false&amp;rank=7"
                  target="_blank"
                  rel="noopener"
                  class="btn"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div class="row child-page">
            <div class="col-lg-8">
              <div class="content">
                <h2>
                  Safety and Efficacy of IMC-F106C as a Single Agent and in
                  Combination With Checkpoint Inhibitors
                </h2>
                <a
                  href="https://clinicaltrials.gov/study/NCT04262466?term=immunocore&amp;checkSpell=false&amp;rank=8"
                  target="_blank"
                  rel="noopener"
                  class="btn"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div class="row child-page">
            <div class="col-lg-8">
              <div class="content">
                <h2>
                  IMC-F106C Regimen Versus Nivolumab Regimens in Previously
                  Untreated Advanced Melanoma (PRISM-MEL-301) (PRISM-MEL-301)
                </h2>
                <a
                  href="https://www.clinicaltrials.gov/study/NCT06112314?term=immunocore&amp;rank=3"
                  target="_blank"
                  rel="noopener"
                  class="btn"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div class="row child-page">
            <div class="col-lg-8">
              <div class="content">
                <h2>
                  An open-label dose-escalation study evaluating the safety,
                  pharmacokinetics and antiviral activity of IMC-M113V in
                  HLA-A*02:01 positive subjects with chronic HIV infection who
                  are virologically suppressed
                </h2>
                <a
                  href="https://www.clinicaltrialsregister.eu/ctr-search/search?query=immunocore"
                  target="_blank"
                  rel="noopener"
                  class="btn"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div class="row child-page">
            <div class="col-lg-8">
              <div class="content">
                <h2>
                  Study of IMC-I109V in Non-cirrhotic HBeAg-negative Chronic HBV
                  Infection
                </h2>
                <a
                  href="https://clinicaltrials.gov/study/NCT05867056?term=immunocore&amp;checkSpell=false&amp;rank=6"
                  target="_blank"
                  rel="noopener"
                  class="btn"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="module module-accordion row-one-accordion dark no-overlay clinical-trials background overlay dark"
        style={{
          backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21605/background_image.jpg)`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="collapse-section">
                <div class="panel collapse-element">
                  <a
                    class="collapsed"
                    role="button"
                    aria-expanded="false"
                    data-toggle="collapse"
                    href="#element-1"
                    aria-controls="element-1"
                  >
                    <span class="icon-trigger"></span>
                    <h3 class="heading--h3 mb-0">Expanded Access Policy</h3>
                    <p>FDA-required notice</p>
                  </a>
                  <div class="panel-collapse collapse" id="element-1">
                    <div class="text">
                      <p>
                        Immunocore is focused on delivering first-in-class
                        biological therapies that have the potential to
                        transform the lives of people with serious diseases.
                      </p>
                      <p>
                        These novel therapies are tested in clinical trials to
                        study the safety and effectiveness of the
                        investigational treatment. The data generated from these
                        clinical trials may be used to support an application to
                        regulatory bodies such as the US Food and Drug
                        Administration (FDA) and the European Medicines Agency
                        (EMA). For a list of our clinical trials currently
                        recruiting patients, please visit clinicaltrials.gov.
                      </p>
                      <p>
                        In general, patients obtain access to investigational
                        agents prior to regulatory approval by participating in
                        clinical trials. There may, however, be circumstances in
                        which a patient is facing a serious or life-threatening
                        condition, has exhausted available treatment options,
                        and is unable to participate in a clinical trial. In
                        those cases, regulators may permit companies such as
                        Immunocore to provide special access to investigational
                        medicines outside of a clinical trial setting. These
                        situations are often called expanded access programmes,
                        but are also referred to as compassionate use, early
                        access, pre-approval access, or emergency use. An
                        investigational treatment has not yet been demonstrated
                        to be safe and effective and has not been approved by
                        the FDA or the EMA.
                      </p>
                      <p>Current Status of Medicines in Development</p>
                      <p>
                        Immunocore currently has the following medicines in
                        clinical development:
                      </p>
                      <ul>
                        <li>Tebentafusp (gp100)</li>
                        <li>IMC-F106C (PRAME)</li>
                        <li>IMC-M113V2 (Gag)</li>
                        <li>IMC-I109V (Envelope)</li>
                      </ul>
                      <p>
                        Based on the policy outlined below, and specifically the
                        guiding principle that there must be sufficient clinical
                        data to support a positive benefit/risk before early
                        access can be considered, we currently consider requests
                        for access to tebentafusp through an early access
                        programme for patients with metastatic uveal melanoma
                        (please see below for further information on our
                        policy). We do not offer any of our other developmental
                        medicines through such a programme. We continue to study
                        and actively enrol patients in these clinical
                        development programmes and encourage those who are
                        interested in learning more to visit clinicaltrials.gov.
                      </p>
                      <p>
                        The safety and efficacy of tebentafusp and other
                        agent(s) under investigation have not been established.
                      </p>
                      <p>How Expanded Access Works</p>
                      <p>
                        Consistent with applicable laws and regulations,
                        Immunocore may, at its discretion, provide patients with
                        access to our investigational treatments through
                        expanded access programmes when there is sufficient
                        evidence of the investigational agent's safety and
                        efficacy to support its use in a particular situation.
                      </p>
                      <p>
                        The decision to allow expanded access to an
                        investigational treatment is complex and Immunocore's
                        policy is aligned with guidelines from FDA and other
                        regulatory authorities. Consideration of a
                      </p>
                      <p>
                        request for expanded access to an investigational
                        treatment may be granted only if each of the following
                        criteria are met:
                      </p>
                      <ul>
                        <li>
                          The patient has a serious or life-threatening disease
                        </li>
                        <li>
                          The patient is ineligible for an ongoing clinical
                          trial of that investigational treatment
                        </li>
                        <li>
                          The patient's disease is similar to the indication(s)
                          for which the investigational treatment is currently
                          being evaluated in pivotal study(ies)
                        </li>
                        <li>
                          No comparable therapies (investigational or approved)
                          are available
                        </li>
                        <li>
                          Immunocore must be able to review data from certain
                          completed studies to determine whether, for patients
                          with disease or condition to be treated, the potential
                          benefits of the investigational treatment outweigh the
                          potential risks.
                        </li>
                        <li>
                          There is an adequate supply of the investigational
                          treatment
                        </li>
                        <li>
                          It is logistically feasible to make the
                          investigational treatment available and it can be
                          safely administered in a controlled setting. The
                          referring physician must refer to a physician and site
                          experienced with the safe use of an ImmTAC product via
                          clinical study(ies) participation.
                        </li>
                        <li>
                          Physician is willing to complete and adhere to
                          regulatory mechanism for access, and have adequate
                          staff and facilities to safely administer and monitor
                          patient.
                        </li>
                        <li>
                          The treatment plan and required observation/follow-up
                          (inclusion/exclusion criteria, treatment plan,
                          follow-up and safety monitoring) must be adhered as
                          provided.
                        </li>
                        <li>Required informed consent must be obtained</li>
                        <li>
                          The expanded access to the investigational treatment
                          will not in any way compromise the planned development
                          or interfere with the regulatory approval process
                        </li>
                      </ul>
                      <p>
                        Immunocore will evaluate each request for expanded
                        access in a timely and fair manner in compliance with
                        the Immunocore policy and applicable laws. A request for
                        expanded access to an investigational treatment must be
                        made by a qualified and licensed physician to
                        info@immunocore.us.
                      </p>
                      <p>
                        Immunocore will acknowledge receipt of a request within
                        5 business days. Each request will be evaluated by key
                        members of our medical and regulatory team based on the
                        criteria above for each experimental agent, and a
                        decision will be provided to the requesting healthcare
                        provider.
                      </p>
                      <p>
                        Immunocore may revise this policy at any time and will
                        remain aligned with the 21st Century Cures Act and other
                        applicable laws. This website and policy will be updated
                        with a hyperlink or other reference to the expanded
                        access record on clinicaltrials.gov after such record
                        becomes active.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section
        id=""
        class="module row-default neutral-bg no-padding-top no-padding-bottom image-align image-align--right"
      >
        <div class="container">
          <div class="row justify-content-center align-items-center flex-row-reverse">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21606/image.jpg"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <h2>Enrollment</h2>
                    <p>
                      If you are a medical professional and would like further
                      information about our clinical trials, please send an
                      email to{" "}
                      <a href="mailto:info@immunocore.us">info@immunocore.us</a>
                      (US) or{" "}
                      <a href="mailto:info@immunocore.us">
                        info@immunocore.us
                      </a>{" "}
                      (outside of US).
                    </p>
                    <p>
                      Patients should contact their doctor for information
                      regarding any aspect of their treatment or involvement in
                      clinical trials.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id=""
        class="module row-default dark primary-color-bg text-left pt--40 pb--40"
      >
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-12">
              <div class="module-content">
                <div class="text-area">
                  <div class="text center">
                    <p class="p-style--sm">
                      The safety and efficacy of the investigational use of
                      these products have not been determined. There is no
                      guarantee that the investigational use listed will be
                      filed with and/or approved for marketing by a regulatory
                      agency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ClinicalMain;
