import React from "react";

const PortfolioMain = () => {
  return (
    <main id="mainContent">
      <section
        id=""
        class="module row-default no-overlay no-dark background-align--top module-pipeline-content background overlay dark"
        style={{
          backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21534/background_image.jpg)`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-center align-items-center flex-row-reverse">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21534/image.png"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <h2 class="pt-5">
                      Built on our proprietary ImmTAX platform, we have a
                      diverse pipeline of bispecific therapies across a broad
                      range of indications.
                    </h2>
                    <p>
                      Our pipeline includes clinical-stage programs in oncology
                      and infectious diseases, as well as numerous pre-clinical
                      projects
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="module row-our-pipeline no-padding-top no-padding-bottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="pipeline-super-header">
                <div class="gap"></div>
                <div class="center">
                  <p>Stage of Clinical Development</p>
                </div>
              </div>
              <div class="pipeline-header">
                <div class="header">
                  <p></p>
                </div>
                <div class="header">
                  <p>Candidate</p>
                </div>
                <div class="header">
                  <p>Target (HLA type)</p>
                </div>
                <div class="header center">
                  <p>Indication</p>
                </div>
                <div class="header">
                  <p>IND-enabling</p>
                </div>
                <div class="header">
                  <p>Phase 1</p>
                </div>
                <div class="header">
                  <p>Phase 2</p>
                </div>
                <div class="header">
                  <p>Phase 3</p>
                </div>
                <div class="header">
                  <p>Approved</p>
                </div>
                <div class="header" data-mw="column-width">
                  <p>Catalyst</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="product-row">
                <div class="type"></div>
                <div class="product-name">
                  <div class="name">
                    <h3>Tebentafusp</h3>
                  </div>
                </div>
                <div class="product-area">
                  <div class="area">
                    <p>gp100 (A02)</p>
                  </div>
                </div>
                <div class="product-info">
                  <div class="info-row">
                    <div class="content-wrapper dark-blue">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>gp100 (A02)</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger">
                                <p>Adjuvant uveal (ocular) melanoma</p>
                              </span>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="65"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="65"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="Phase 3"
                              >
                                <span
                                  style={{
                                    opacity: 0,
                                    transition: "opacity 2.5s ease",
                                  }}
                                  class="d-none d-lg-inline-block"
                                >
                                  ATOM sponsored by EORTC
                                </span>
                              </div>
                              <span
                                style={{
                                  fontSize: "10px",
                                  marginTop: "10px",
                                  marginLeft: "2px",
                                }}
                                class="d-inline-block d-lg-none"
                              >
                                ATOM sponsored by EORTC
                              </span>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>Phase 3</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21942"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst">
                        <div
                          class="area"
                          style={{
                            minHeight: "0%",
                          }}
                          data-mw="column-width"
                        >
                          <p>Phase 3 Start l 2H24</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-row">
                    <div class="content-wrapper dark-blue">
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>2L+ Advanced cutaneous melanoma</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="65"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="65"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="Phase 3"
                              >
                                <span
                                  style={{
                                    opacity: 0,
                                    transition: "opacity 2.5s ease",
                                  }}
                                  class="d-none d-lg-inline-block"
                                >
                                  TEBE-AM
                                </span>
                              </div>
                              <span
                                style={{
                                  fontSize: "10px",
                                  marginTop: "10px",
                                  marginLeft: "2px",
                                }}
                                class="d-inline-block d-lg-none"
                              >
                                TEBE-AM
                              </span>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>Phase 3</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21943"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst">
                        <div
                          class="area"
                          style={{
                            minHeight: "0%",
                          }}
                          data-mw="column-width"
                        >
                          <p>Phase 3 Data l 4Q24</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-row oncology">
                <div class="type oncology">
                  <span>Oncology</span>
                </div>
                <div class="product-name">
                  <div class="name">
                    <h3>Brenetafusp (IMC-F106C)</h3>
                  </div>
                </div>
                <div class="product-area">
                  <div class="area">
                    <p>PRAME (A02)</p>
                  </div>
                </div>
                <div class="product-info">
                  <div class="info-row">
                    <div class="content-wrapper dark-blue">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>PRAME (A02)</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>1L advanced cutaneous melanoma</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="65"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="65"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="Phase 3"
                              >
                                <span
                                  style={{
                                    opacity: 0,
                                    transition: "opacity 2.5s ease",
                                  }}
                                  class="d-none d-lg-inline-block"
                                >
                                  PRISM-MEL-301
                                </span>
                              </div>
                              <span
                                style={{
                                  fontSize: "10px",
                                  marginTop: "10px",
                                  marginLeft: "2px",
                                }}
                                class="d-inline-block d-lg-none"
                              >
                                PRISM-MEL-301
                              </span>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>Phase 3</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21905"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst">
                        <div
                          class="area"
                          style={{
                            minHeight: "0%",
                          }}
                          data-mw="column-width"
                        >
                          <p>Phase 3 Start l 2Q24</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-row">
                    <div class="content-wrapper dark-blue">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>PRAME (A02)</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>2L+ cutaneous melanoma</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="41"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="41"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="Phase 1/2"
                              ></div>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>Phase 1/2</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21908"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst">
                        <div
                          class="area catalyst-overflow"
                          style={{
                            minHeight: "500%",
                          }}
                          data-mw="column-width"
                        >
                          <p>2Q24- 4Q2024</p>
                          <hr />
                          <p>Phase 1 Clinical Data</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-row">
                    <div class="content-wrapper dark-blue">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>PRAME (A02)</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>PRR ovarian&lt;sup&gt;1&lt;/sup&gt;</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="41"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="41"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="Phase 1/2"
                              ></div>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>Phase 1/2</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21909"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst">
                        <div
                          class="area"
                          style={{
                            minHeight: "0%",
                          }}
                          data-mw="column-width"
                        >
                          <p>2Q24- 4Q2024</p>
                          <hr />
                          <p>Phase 1 Clinical Data</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-row">
                    <div class="content-wrapper dark-blue">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>PRAME (A02)</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>2L+ NSCLC</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="41"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="41"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="Phase 1/2"
                              ></div>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>Phase 1/2</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21911"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst">
                        <div
                          class="area"
                          style={{
                            minHeight: "0%",
                          }}
                          data-mw="column-width"
                        >
                          <p>2Q24- 4Q2024</p>
                          <hr />
                          <p>Phase 1 Clinical Data</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-row">
                    <div class="content-wrapper dark-blue">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>PRAME (A02)</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>Advanced endometrial</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="41"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="41"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="Phase 1/2"
                              ></div>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>Phase 1/2</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21910"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst">
                        <div
                          class="area"
                          style={{
                            minHeight: "0%",
                          }}
                          data-mw="column-width"
                        >
                          <p>Phase 1 Clinical Data</p>
                          <hr />
                          <p>2Q24- 4Q2024</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-row">
                    <div class="content-wrapper dark-blue">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>PRAME (A02)</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>Multiple solid tumors</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="41"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="41"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="Phase 1/2"
                              >
                                <span
                                  style={{
                                    opacity: 0,
                                    transition: "opacity 2.5s ease",
                                  }}
                                  class="d-none d-lg-inline-block"
                                >
                                  Mono &amp; combination arms
                                </span>
                              </div>
                              <span
                                style={{
                                  fontSize: "10px",
                                  marginTop: "10px",
                                  marginLeft: "2px",
                                }}
                                class="d-inline-block d-lg-none"
                              >
                                Mono &amp; combination arms
                              </span>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>Phase 1/2</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21906"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst">
                        <div
                          class="area"
                          style={{
                            minHeight: "0%",
                          }}
                          data-mw="column-width"
                        >
                          <p>2Q24- 4Q2024</p>
                          <hr />
                          <p>Phase 1 Clinical Data</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-row">
                <div class="type"></div>
                <div class="product-name">
                  <div class="name">
                    <h3>IMC-P115C</h3>
                  </div>
                </div>
                <div class="product-area">
                  <div class="area">
                    <p>PRAME-HLE (A02)</p>
                  </div>
                </div>
                <div class="product-info">
                  <div class="info-row">
                    <div class="content-wrapper blue">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>PRAME-HLE (A02)</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>Multiple solid tumors</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="21"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="21"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="IND-enabling"
                              ></div>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>IND-enabling</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21896"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst">
                        <div
                          class="area"
                          style={{
                            minHeight: "0%",
                          }}
                          data-mw="column-width"
                        >
                          <p>IND/CTA | Mid-24</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-row">
                <div class="type"></div>
                <div class="product-name">
                  <div class="name">
                    <h3>IMC-T119C</h3>
                  </div>
                </div>
                <div class="product-area">
                  <div class="area">
                    <p>PRAME (A24)</p>
                  </div>
                </div>
                <div class="product-info">
                  <div class="info-row">
                    <div class="content-wrapper blue">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>PRAME (A24)</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>Multiple solid tumors</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="21"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="21"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="IND-enabling"
                              ></div>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>IND-enabling</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21902"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst">
                        <div
                          class="area"
                          style={{
                            minHeight: "0%",
                          }}
                          data-mw="column-width"
                        >
                          <p>IND/CTA | 4Q24</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-row">
                <div class="type"></div>
                <div class="product-name">
                  <div class="name">
                    <h3>IMC-R117C</h3>
                  </div>
                </div>
                <div class="product-area">
                  <div class="area">
                    <p>PIWIL1 (A02)</p>
                  </div>
                </div>
                <div class="product-info">
                  <div class="info-row">
                    <div class="content-wrapper blue">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>PIWIL1 (A02)</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>Colorectal and GI cancers</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="35"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="35"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="Phase 1"
                              ></div>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>Phase 1</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21899"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst">
                        <div
                          class="area"
                          style={{
                            minHeight: "0%",
                          }}
                          data-mw="column-width"
                        >
                          <p>Phase 1 Start | 2H 2024</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-row infectious_diseases">
                <div class="type infectious_diseases">
                  <span>Infectious Diseases</span>
                </div>
                <div class="product-name">
                  <div class="name">
                    <h3>IMC-M113V&lt;sup&gt;2&lt;/sup&gt;</h3>
                  </div>
                </div>
                <div class="product-area">
                  <div class="area">
                    <p>Gag (A02)</p>
                  </div>
                </div>
                <div class="product-info">
                  <div class="info-row">
                    <div class="content-wrapper green">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>Gag (A02)</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>Human Immunodeficiency Virus (HIV)</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="35"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="35"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="Phase 1"
                              ></div>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>Phase 1</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21920"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst">
                        <div
                          class="area"
                          style={{
                            minHeight: "0%",
                          }}
                          data-mw="column-width"
                        >
                          <p>MAD Data | 2H24</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-row">
                <div class="type"></div>
                <div class="product-name">
                  <div class="name">
                    <h3>IMC-I109V</h3>
                  </div>
                </div>
                <div class="product-area">
                  <div class="area">
                    <p>Envelope (A02)</p>
                  </div>
                </div>
                <div class="product-info">
                  <div class="info-row">
                    <div class="content-wrapper green">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>Envelope (A02)</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>Hepatitis B Virus (HBV)</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="35"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="35"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="Phase 1"
                              ></div>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>Phase 1</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21923"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-row autoimmune_diseases">
                <div class="type autoimmune_diseases">
                  <span>Autoimmune Diseases</span>
                </div>
                <div class="product-name">
                  <div class="name">
                    <h3>IMC-S118AI</h3>
                  </div>
                </div>
                <div class="product-area">
                  <div class="area">
                    <p>PPIxPD1 (A02)</p>
                  </div>
                </div>
                <div class="product-info">
                  <div class="info-row">
                    <div class="content-wrapper purple">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>PPI (A02)</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>Type 1 Diabetes</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="15"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="15"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="IND-enabling"
                              ></div>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>IND-enabling</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21946"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-row">
                <div class="type"></div>
                <div class="product-name">
                  <div class="name">
                    <h3>Undisclosed</h3>
                  </div>
                </div>
                <div class="product-area">
                  <div class="area">
                    <p>(universal)&lt;sup&gt;3&lt;/sup&gt;</p>
                  </div>
                </div>
                <div class="product-info">
                  <div class="info-row">
                    <div class="content-wrapper purple">
                      <div class="product-area-mobile">
                        <div class="area">
                          <p>(universal)&lt;sup&gt;3&lt;/sup&gt;</p>
                        </div>
                      </div>
                      <div class="info-accordion">
                        <div class="panel collapse-element">
                          <div class="pipe-wrapper">
                            <div class="title-wrapper">
                              <span class="icon-trigger"></span>
                              <p>Dermatology</p>
                            </div>
                            <div class="progress-bar-wrapper fade-right">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                data-percent="15"
                                style={{
                                  width: "0%",
                                  transition: "width 1.5s ease",
                                }}
                                aria-valuenow="15"
                                aria-valuemin="0"
                                aria-valuemax="90"
                                title="IND-enabling"
                              ></div>
                            </div>
                            <div class="product-phase-mobile">
                              <div class="phase">
                                <p>IND-enabling</p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="panel-collapse collapse"
                            id="element-21950"
                          >
                            <div class="text">
                              <p class="status">STATUS</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="catalyst"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="module row-default no-padding-top no-padding-x">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <div class="date-updated mt-4">
                      <span>Date last updated: June 17, 2024</span>
                    </div>
                    <div class="d-flex">
                      <div>
                        <span class="footnotes mt-1 ml-1">
                          <sup>1</sup> Platinum refractory or resistant serous
                          ovarian carcinoma. <sup>2</sup> Program is wholly
                          owned, development costs being provided by the Bill
                          &amp; Melinda Gates Foundation (BMGF), Immunocore
                          retains all development and commercialization rights
                          in the developed world.
                          <sup>3</sup> Program is not HLA restricted (i.e.
                          universal for all populations)
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="module row-default row-two-col dark btn-bottom sr-only-title pt-40 pb-40 bg-white mobile-background-cover background"
        style={{
          backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/22002/background_image.png)`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-center align-items-end">
            <div class="col-lg-6">
              <div class="module-content primary-area">
                <div class="text-area">
                  <div class="text">
                    <h2>KIMMTRAK</h2>
                    <h3 class="heading--h4">
                      KIMMTRAK approved for HLA-A*02:01-positive metastatic or
                      unresectable uveal melanoma
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <div class="btn-wrapper">
                      <a href="/pipeline-portfolio/our-therapies" class="btn">
                        Our approved therapies
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id=""
        class="module row-default blue-color-bg text-left text-small"
      >
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-12">
              <div class="module-content">
                <div class="text-area">
                  <div class="text center">
                    <h3 class="heading--h4">Disclaimer</h3>
                    <p>
                      All trademarks, company and product names or logos are the
                      property of their respective owners. The efficacy and
                      safety of the molecules under investigation have not yet
                      been established. There is no guarantee that pipeline
                      molecules will receive approval and become commercially
                      available for the uses being investigated as drug
                      development is uncertain and agents in development may be
                      terminated along the development process. The above
                      information is accurate as of November 2023.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PortfolioMain;
