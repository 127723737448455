import React from "react";

const ContactMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <aside id="tertiaryNav">
            <nav id="tertiary-navigation">
              <ul>
                <li class="sidebar-title">
                  <h2>IR Resources</h2>
                </li>
                <li class="">
                  <a
                    href="/investors/ir-resources/investor-faqs"
                    target="_self"
                  >
                    Investor FAQs
                  </a>
                </li>
                <li class="">
                  <a href="/investors/ir-resources/email-alerts" target="_self">
                    Email Alerts
                  </a>
                </li>
                <li class="active active-landing">
                  <a href="/investors/ir-resources/contacts" target="_self">
                    Contacts
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <main id="mainContent" class="main-content has-tertiary-nav">
            <h2>Investor Relations</h2>
            <div class="row">
              <div class="col-lg ir-contact-col">
                <div class="text ir-contact-text">
                  <p>
                    Clayton Robertson
                    <br />
                    Head of Investor Relations
                    <br />
                    T: +1 (215) 384-4781
                    <br />
                    <a class="exposed-link" href="mailto:info@immunocore.us">
                      info@immunocore.us
                    </a>
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <h2>Transfer Agent</h2>
            <div class="row">
              <div class="col-lg ir-contact-col">
                <div class="text ir-contact-text">
                  <p>
                    Computershare Investor Services PLC
                    <br />
                    The Pavilions
                    <br />
                    Bridgwater Road
                    <br />
                    Bristol, BS99 6ZZ
                    <br />
                    United Kingdom
                    <br />
                    T: 0370 703 0387
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <h2>Auditor</h2>
            <div class="row">
              <div class="col-lg ir-contact-col">
                <div class="text ir-contact-text">
                  <p>
                    Deloitte LLP
                    <br />
                    1 Station Square
                    <br />
                    Cambridge, CB1 2GA
                    <br />
                    United Kingdom
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <h2>Legal Counsel</h2>
            <div class="row">
              <div class="col-lg ir-contact-col">
                <div class="text ir-contact-text">
                  <p>
                    Cooley LLP
                    <br />
                    55 Hudson Yards
                    <br />
                    New York, NY 10001
                    <br />
                    T: +1 (212) 479-6000
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default ContactMain;
