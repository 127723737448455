import React from "react";

const OurMain = () => {
  return (
    <main id="mainContent">
      <section
        id=""
        class="module row-default no-overlay no-dark no-padding-top no-padding-bottom background-align--top module-padding our-therapies-content image-align image-align--right background overlay dark"
        style={{
          background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21543/background_image.jpg)`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-center align-items-center flex-row-reverse">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21543/image.png"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <p>
                      We currently have one approved medication on the market,
                      as well as a number of late-stage and pre-clinical
                      programs in our pipeline.
                    </p>
                    <a href="/pipeline-portfolio/pipeline" class="btn">
                      View our pipeline
                    </a>
                    <div class="secondary-content">
                      <div class="row">
                        <div class="col-12">
                          <p>
                            Adverse events should be reported. Please report to
                            your country competent authority.
                          </p>
                          <p>
                            To report negative side effects of prescription
                            drugs to the FDA, visit{" "}
                            <a
                              href="https://www.fda.gov/medwatch"
                              target="_blank"
                              rel="noopener"
                            >
                              www.fda.gov/medwatch
                            </a>
                            , or call 1-800-FDA-1088 (1-800-332-1088).
                          </p>
                          <p>
                            If you are a UK health care professional, reporting
                            forms and information can be found at{" "}
                            <a
                              href="https://yellowcard.mhra.gov.uk/"
                              target="_blank"
                              rel="noopener"
                            >
                              https://yellowcard.mhra.gov.uk/
                            </a>
                            .
                          </p>
                          <p>
                            Adverse events should also be reported to
                            Immunocore, through the channels below.
                          </p>
                        </div>
                        <div class="col-12">
                          <h2 class="heading--h5 mb-0">United States:</h2>
                          <p>
                            <a href="mailto:info@immunocore.us">
                              info@immunocore.us
                            </a>
                            <br />
                            844-IMMUNO1
                          </p>
                          <h2 class="heading--h5 mb-0">
                            Europe/Rest of World:
                          </h2>
                          <p>
                            <a href="mailto:info@immunocore.us">
                              info@immunocore.us
                            </a>
                            <br />
                            +442076645100 (European local toll)
                            <br />
                            +00 800-74451111 (toll-free)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="module row-default module-sections neutral-bg">
        <div class="container">
          <div class="row justify-content-center align-items-start module-row">
            <div class="col-md-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text"></div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row section-row">
            <div class="col-md-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2413/21806/flag.svg"
                      alt=""
                      role="presentation"
                      class="flag"
                    />
                    <h3>KIMMTRAK in the United States</h3>
                    <p>
                      KIMMTRAK (tebentafusp-tebn)
                      <br />
                      (Injection for Intravenous Use 100 mcg/0.5 mL)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <div class="btn-wrapper">
                      <a
                        href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2414/21810/file/Approved_USPI_11_20_22_for_commercial_printing_and_website.pdf"
                        target="_blank"
                        class="block-link"
                      >
                        U.S. Full Prescribing Information including Boxed
                        WARNING for CRS
                      </a>
                    </div>
                    <div class="btn-wrapper">
                      <a
                        href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2414/21811/file/Approved_PI_01_25_22.pdf"
                        target="_blank"
                        class="block-link"
                      >
                        U.S. Patient Information including BOXED WARNING for CRS
                      </a>
                    </div>
                    <div class="btn-wrapper">
                      <a
                        href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2414/21812/file/Information_for_Colorado_Prescribers_of_Prescription_Drugs_-_1July2023.pdf"
                        target="_blank"
                        class="block-link"
                      >
                        Information for Colorado Prescribers of Prescription
                        Drugs
                      </a>
                    </div>
                    <div class="btn-wrapper">
                      <a
                        href="https://www.kimmtrak.com/"
                        target="_blank"
                        class="block-link"
                      >
                        U.S. Product website
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row section-row">
            <div class="col-md-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2413/21807/flag.svg"
                      alt=""
                      role="presentation"
                      class="flag"
                    />
                    <h3>KIMMTRAK in the United Kingdom</h3>
                    <p>
                      KIMMTRAK (tebentafusp)
                      <br />
                      (Concentrate for Solution for Infusion 200 mcg/mL)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <div class="btn-wrapper">
                      <a
                        href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2414/21813/file/339.pdf"
                        target="_blank"
                        class="block-link"
                      >
                        GB Summary of Product Characteristics
                      </a>
                    </div>
                    <div class="btn-wrapper">
                      <a
                        href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2414/21814/file/339.pdf"
                        target="_blank"
                        class="block-link"
                      >
                        GB Patient Information Leaflet
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row section-row">
            <div class="col-md-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2413/21808/flag.svg"
                      alt=""
                      role="presentation"
                      class="flag"
                    />
                    <h3>KIMMTRAK in Europe</h3>
                    <p>
                      KIMMTRAK (tebentafusp)
                      <br />
                      (Concentrate for Solution for Infusion 100 mcg/0.5 mL)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <div class="btn-wrapper">
                      <a
                        href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2414/21815/file/340.pdf"
                        target="_blank"
                        class="block-link"
                      >
                        EU Summary of Product Characteristics
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row section-row">
            <div class="col-md-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2413/22003/flag.svg"
                      alt=""
                      role="presentation"
                      class="flag"
                    />
                    <h3>KIMMTRAK in Switzerland</h3>
                    <p data-pm-slice="1 1 []">
                      KIMMTRAK (tebentafusp)
                      <br />
                      (Concentrate for Solution for Infusion 100mcg/0.5mL)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <div class="btn-wrapper">
                      <a
                        href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2414/22025/file/ch-prof-kimmtrak-fr-clean.pdf"
                        target="_blank"
                        class="block-link"
                      >
                        Summary of Product Characteristics (French)
                      </a>
                    </div>
                    <div class="btn-wrapper">
                      <a
                        href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2414/22026/file/ch-prof-kimmtrak-de-clean.pdf"
                        target="_blank"
                        class="block-link"
                      >
                        Summary of Product Characteristics (German)
                      </a>
                    </div>
                    <div class="btn-wrapper">
                      <a
                        href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2414/22027/file/ch-prof-kimmtrak-it-clean.pdf"
                        target="_blank"
                        class="block-link"
                      >
                        Summary of Product Characteristics (Italian)
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="module row-default row-two-col dark bg-white background"
        style={{
          background: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21836/background_image.png)`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-center align-items-start">
            <div class="col-lg-6">
              <div class="module-content primary-area">
                <div class="text-area">
                  <div class="text">
                    <h2>
                      KIMMTRAK is built on our proprietary ImmTAX platform
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <div class="btn-wrapper">
                      <a href="/science" class="btn">
                        Learn more about our technology
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default OurMain;
