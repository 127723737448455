import React from "react";

const PresentationMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <aside id="tertiaryNav">
            <nav id="tertiary-navigation">
              <ul>
                <li class="sidebar-title">
                  <h2>Events &amp; Presentations</h2>
                </li>
                <li class="">
                  <a
                    href="/investors/events-presentations/events"
                    target="_self"
                  >
                    Events
                  </a>
                </li>
                <li class="active active-landing">
                  <a
                    href="/investors/events-presentations/presentations"
                    target="_self"
                  >
                    Presentations
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <main id="mainContent" class="main-content has-tertiary-nav">
            <article class="media">
              <div class="row">
                <div class="media-left col-md-3" aria-hidden="true">
                  <img
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2265/22138/pdf_thumbnail.jpg"
                    alt=""
                    class="media-object"
                  />
                </div>
                <div class="media-body col-md">
                  <h2 class="media-heading">
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2265/22138/pdf/IMCR_Corporate+Deck_June2024_vFINAL.pdf"
                      target="_blank"
                      rel="noopener"
                    >
                      Corporate Presentation – June 2024
                    </a>
                  </h2>
                </div>
              </div>
            </article>
            <article class="media">
              <div class="row">
                <div class="media-left col-md-3" aria-hidden="true">
                  <img
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2265/22136/pdf_thumbnail.jpg"
                    alt=""
                    class="media-object"
                  />
                </div>
                <div class="media-body col-md">
                  <h2 class="media-heading">
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2265/22136/pdf/2024_ASCO_Investor_Analyst+Data+Presentation_vF.pdf"
                      target="_blank"
                      rel="noopener"
                    >
                      PRAME Phase 1 Cutaneous Melanoma Data – ASCO 2024
                    </a>
                  </h2>
                </div>
              </div>
            </article>
            <article class="media">
              <div class="row">
                <div class="media-left col-md-3" aria-hidden="true">
                  <img
                    src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2265/21478/pdf_thumbnail.jpg"
                    alt=""
                    class="media-object"
                  />
                </div>
                <div class="media-body col-md">
                  <h2 class="media-heading">
                    <a
                      href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2265/21478/pdf/7b9399b0-9a13-4314-b0d1-e1e4b9065aad.pdf"
                      target="_blank"
                      rel="noopener"
                    >
                      Initial Phase 1 Data from PRAME Program (ESMO
                      Presentation)
                    </a>
                  </h2>
                </div>
              </div>
            </article>
            <div class="rss-link mt-5">
              <a
                href="/investors/events-presentations/presentations/rss"
                class="link--icon"
                target="_blank"
                rel="noopener"
              >
                <span class="material-icons">rss_feed</span> RSS
              </a>
            </div>
            <div class="pagination-wrapper">
              <div id="pagination--mobile" class="mt-3 d-md-none"></div>
            </div>
            <div class="clear"></div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default PresentationMain;
