import React from "react";

const SECMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <aside id="tertiaryNav">
            <nav id="tertiary-navigation">
              <ul>
                <li class="sidebar-title">
                  <h2>Financials &amp; Filings</h2>
                </li>
                <li class="">
                  <a
                    href="/investors/financials-filings/financial-results"
                    target="_self"
                  >
                    Financial Results
                  </a>
                </li>
                <li class="active active-landing">
                  <a
                    href="/investors/financials-filings/sec-filings"
                    target="_self"
                  >
                    SEC Filings
                  </a>
                </li>
                <li class="">
                  <a
                    href="/investors/financials-filings/annual-reports"
                    target="_self"
                  >
                    Annual Reports
                  </a>
                </li>
                <li class="">
                  <a
                    href="/investors/financials-filings/email-alerts"
                    target="_self"
                  >
                    Email Alerts
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <main id="mainContent" class="main-content has-tertiary-nav">
            <table class="content-table spr-ir-sec-filings">
              <thead>
                <tr>
                  <th class="spr-sec-date">Date</th>
                  <th class="spr-sec-form">Form</th>
                  <th class="spr-sec-desc">Description</th>
                  <th class="spr-sec-view-pdf">PDF</th>
                  <th class="spr-sec-view-docs">XBRL</th>
                  <th class="spr-sec-pages">Pages</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="spr-sec-date">05/31/24</td>
                  <td class="spr-sec-form">8-K</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-028366/ef20030354_8k.htm"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 8-K: Current report filing
                    </a>
                    <div class="spr-expander">
                      <button
                        class="spr-sec-doc-list-head spr-toggle-expandable"
                        type="button"
                        aria-pressed="false"
                      >
                        05/31/24 8-K Documents
                        <span class="material-icons">expand_more</span>
                      </button>
                      <div class="spr-expandable">
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-028366/ef20030354_ex99-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-416-0001140361-24-028366"
                        >
                          EX-99.1
                        </a>
                        <br />
                      </div>
                    </div>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-028366/0001140361-24-028366.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 8-K filing published on May 31, 2024"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-028366/0001140361-24-028366-xbrl.zip"
                      aria-label="Download Raw XBRL files for 8-K filing published on May 31, 2024 "
                      title="Download Raw XBRL Files"
                      target="_blank"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-XBRL.svg"
                        alt="XAML"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-pages">12</td>
                </tr>
                <tr>
                  <td class="spr-sec-date">05/30/24</td>
                  <td class="spr-sec-form">8-K</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-028194/ef20030315_8k.htm"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 8-K: Current report filing
                    </a>
                    <div class="spr-expander">
                      <button
                        class="spr-sec-doc-list-head spr-toggle-expandable"
                        type="button"
                        aria-pressed="false"
                      >
                        05/30/24 8-K Documents
                        <span class="material-icons">expand_more</span>
                      </button>
                      <div class="spr-expandable">
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-028194/ef20030315_ex99-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-414-0001140361-24-028194"
                        >
                          EX-99.1
                        </a>
                        <br />
                      </div>
                    </div>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-028194/0001140361-24-028194.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 8-K filing published on May 30, 2024"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-028194/0001140361-24-028194-xbrl.zip"
                      aria-label="Download Raw XBRL files for 8-K filing published on May 30, 2024 "
                      title="Download Raw XBRL Files"
                      target="_blank"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-XBRL.svg"
                        alt="XAML"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-pages">9</td>
                </tr>
                <tr>
                  <td class="spr-sec-date">05/29/24</td>
                  <td class="spr-sec-form">4</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0000950170-24-066036/ownership.html"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 4: Statement of changes in beneficial ownership of
                      securities
                    </a>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0000950170-24-066036/0000950170-24-066036.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 4 filing published on May 29, 2024"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs"></td>
                  <td class="spr-sec-pages">3</td>
                </tr>
                <tr>
                  <td class="spr-sec-date">05/29/24</td>
                  <td class="spr-sec-form">3</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0000950170-24-066026/ownership.html"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 3: Initial statement of beneficial ownership of
                      securities
                    </a>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0000950170-24-066026/0000950170-24-066026.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 3 filing published on May 29, 2024"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs"></td>
                  <td class="spr-sec-pages">1</td>
                </tr>
                <tr>
                  <td class="spr-sec-date">05/29/24</td>
                  <td class="spr-sec-form">3</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0000950170-24-066015/ownership.html"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 3: Initial statement of beneficial ownership of
                      securities
                    </a>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0000950170-24-066015/0000950170-24-066015.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 3 filing published on May 29, 2024"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs"></td>
                  <td class="spr-sec-pages">3</td>
                </tr>
                <tr>
                  <td class="spr-sec-date">05/28/24</td>
                  <td class="spr-sec-form">8-K</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-027667/ef20030042_8k.htm"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 8-K: Current report filing
                    </a>
                    <div class="spr-expander">
                      <button
                        class="spr-sec-doc-list-head spr-toggle-expandable"
                        type="button"
                        aria-pressed="false"
                      >
                        05/28/24 8-K Documents
                        <span class="material-icons">expand_more</span>
                      </button>
                      <div class="spr-expandable">
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-027667/ef20030042_ex10-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-408-0001140361-24-027667"
                        >
                          EX-10.1
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-027667/ef20030042_ex10-2.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-408-0001140361-24-027667"
                        >
                          EX-10.2
                        </a>
                        <br />
                      </div>
                    </div>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-027667/0001140361-24-027667.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 8-K filing published on May 28, 2024"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-027667/0001140361-24-027667-xbrl.zip"
                      aria-label="Download Raw XBRL files for 8-K filing published on May 28, 2024 "
                      title="Download Raw XBRL Files"
                      target="_blank"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-XBRL.svg"
                        alt="XAML"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-pages">30</td>
                </tr>
                <tr>
                  <td class="spr-sec-date">05/28/24</td>
                  <td class="spr-sec-form">4</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001415889-24-014657/form4-05282024_040513.html"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 4: Statement of changes in beneficial ownership of
                      securities
                    </a>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001415889-24-014657/0001415889-24-014657.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 4 filing published on May 28, 2024"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs"></td>
                  <td class="spr-sec-pages">1</td>
                </tr>
                <tr>
                  <td class="spr-sec-date">05/28/24</td>
                  <td class="spr-sec-form">4</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001415889-24-014656/form4-05282024_040510.html"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 4: Statement of changes in beneficial ownership of
                      securities
                    </a>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001415889-24-014656/0001415889-24-014656.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 4 filing published on May 28, 2024"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs"></td>
                  <td class="spr-sec-pages">1</td>
                </tr>
                <tr>
                  <td class="spr-sec-date">05/28/24</td>
                  <td class="spr-sec-form">4</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001415889-24-014654/form4-05282024_040504.html"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 4: Statement of changes in beneficial ownership of
                      securities
                    </a>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001415889-24-014654/0001415889-24-014654.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 4 filing published on May 28, 2024"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs"></td>
                  <td class="spr-sec-pages">1</td>
                </tr>
                <tr>
                  <td class="spr-sec-date">05/28/24</td>
                  <td class="spr-sec-form">4</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001415889-24-014655/form4-05282024_040507.html"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 4: Statement of changes in beneficial ownership of
                      securities
                    </a>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001415889-24-014655/0001415889-24-014655.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 4 filing published on May 28, 2024"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs"></td>
                  <td class="spr-sec-pages">1</td>
                </tr>
              </tbody>
            </table>

            <div class="rss-link">
              <a
                href="https://www.immunocore.com/investors/financials-filings/sec-filings/rss"
                class="link--icon"
                target="_blank"
                rel="noopener"
              >
                <span class="material-icons">rss_feed</span> Filing RSS
              </a>
            </div>

            <div class="clear"></div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default SECMain;
