export const countryPhoneCodes = [
  { value: "+93", label: "Afghanistan (+93)" },
  { value: "+355", label: "Albania (+355)" },
  { value: "+213", label: "Algeria (+213)" },
  { value: "+1-684", label: "American Samoa (+1-684)" },
  { value: "+376", label: "Andorra (+376)" },
  { value: "+244", label: "Angola (+244)" },
  { value: "+1-264", label: "Anguilla (+1-264)" },
  { value: "+1-268", label: "Antigua and Barbuda (+1-268)" },
  { value: "+54", label: "Argentina (+54)" },
  { value: "+374", label: "Armenia (+374)" },
  { value: "+297", label: "Aruba (+297)" },
  { value: "+61", label: "Australia (+61)" },
  { value: "+43", label: "Austria (+43)" },
  { value: "+994", label: "Azerbaijan (+994)" },
  { value: "+242", label: "Republic of the Congo (+242)" },
  { value: "+243", label: "Democratic Republic of the Congo (+243)" },
  { value: "+1-242", label: "Bahamas (+1-242)" },
  { value: "+973", label: "Bahrain (+973)" },
  { value: "+880", label: "Bangladesh (+880)" },
  { value: "+1-246", label: "Barbados (+1-246)" },
  { value: "+375", label: "Belarus (+375)" },
  { value: "+32", label: "Belgium (+32)" },
  { value: "+501", label: "Belize (+501)" },
  { value: "+229", label: "Benin (+229)" },
  { value: "+1-441", label: "Bermuda (+1-441)" },
  { value: "+975", label: "Bhutan (+975)" },
  { value: "+591", label: "Bolivia (+591)" },
  { value: "+387", label: "Bosnia and Herzegovina (+387)" },
  { value: "+267", label: "Botswana (+267)" },
  { value: "+55", label: "Brazil (+55)" },
  { value: "+1-284", label: "British Virgin Islands (+1-284)" },
  { value: "+673", label: "Brunei (+673)" },
  { value: "+359", label: "Bulgaria (+359)" },
  { value: "+226", label: "Burkina Faso (+226)" },
  { value: "+257", label: "Burundi (+257)" },
  { value: "+855", label: "Cambodia (+855)" },
  { value: "+237", label: "Cameroon (+237)" },
  { value: "+1", label: "Canada (+1)" },
  { value: "+238", label: "Cape Verde (+238)" },
  { value: "+1-345", label: "Cayman Islands (+1-345)" },
  { value: "+236", label: "Central African Republic (+236)" },
  { value: "+235", label: "Chad (+235)" },
  { value: "+56", label: "Chile (+56)" },
  { value: "+86", label: "China (+86)" },
  { value: "+61", label: "Christmas Island (+61)" },
  { value: "+61", label: "Cocos (Keeling) Islands (+61)" },
  { value: "+57", label: "Colombia (+57)" },
  { value: "+269", label: "Comoros (+269)" },
  { value: "+242", label: "Congo (+242)" },
  { value: "+850", label: "North Korea (+850)" },
  { value: "+82", label: "South Korea (+82)" },
  { value: "+965", label: "Kuwait (+965)" },
  { value: "+996", label: "Kyrgyzstan (+996)" },
  { value: "+856", label: "Laos (+856)" },
  { value: "+371", label: "Latvia (+371)" },
  { value: "+961", label: "Lebanon (+961)" },
  { value: "+266", label: "Lesotho (+266)" },
  { value: "+231", label: "Liberia (+231)" },
  { value: "+218", label: "Libya (+218)" },
  { value: "+423", label: "Liechtenstein (+423)" },
  { value: "+370", label: "Lithuania (+370)" },
  { value: "+352", label: "Luxembourg (+352)" },
  { value: "+853", label: "Macau (+853)" },
  { value: "+389", label: "North Macedonia (+389)" },
  { value: "+261", label: "Madagascar (+261)" },
  { value: "+265", label: "Malawi (+265)" },
  { value: "+60", label: "Malaysia (+60)" },
  { value: "+960", label: "Maldives (+960)" },
  { value: "+223", label: "Mali (+223)" },
  { value: "+356", label: "Malta (+356)" },
  { value: "+692", label: "Marshall Islands (+692)" },
  { value: "+596", label: "Martinique (+596)" },
  { value: "+222", label: "Mauritania (+222)" },
  { value: "+230", label: "Mauritius (+230)" },
  { value: "+52", label: "Mexico (+52)" },
  { value: "+691", label: "Micronesia (+691)" },
  { value: "+373", label: "Moldova (+373)" },
  { value: "+377", label: "Monaco (+377)" },
  { value: "+976", label: "Mongolia (+976)" },
  { value: "+382", label: "Montenegro (+382)" },
  { value: "+1-664", label: "Montserrat (+1-664)" },
  { value: "+212", label: "Morocco (+212)" },
  { value: "+258", label: "Mozambique (+258)" },
  { value: "+95", label: "Myanmar (+95)" },
  { value: "+264", label: "Namibia (+264)" },
  { value: "+674", label: "Nauru (+674)" },
  { value: "+977", label: "Nepal (+977)" },
  { value: "+31", label: "Netherlands (+31)" },
  { value: "+687", label: "New Caledonia (+687)" },
  { value: "+64", label: "New Zealand (+64)" },
  { value: "+505", label: "Nicaragua (+505)" },
  { value: "+227", label: "Niger (+227)" },
  { value: "+234", label: "Nigeria (+234)" },
  { value: "+683", label: "Niue (+683)" },
  { value: "+672", label: "Norfolk Island (+672)" },
  { value: "+1-670", label: "Northern Mariana Islands (+1-670)" },
  { value: "+47", label: "Norway (+47)" },
  { value: "+968", label: "Oman (+968)" },
  { value: "+92", label: "Pakistan (+92)" },
  { value: "+680", label: "Palau (+680)" },
  { value: "+970", label: "Palestine (+970)" },
  { value: "+507", label: "Panama (+507)" },
  { value: "+675", label: "Papua New Guinea (+675)" },
  { value: "+595", label: "Paraguay (+595)" },
  { value: "+51", label: "Peru (+51)" },
  { value: "+63", label: "Philippines (+63)" },
  { value: "+48", label: "Poland (+48)" },
  { value: "+351", label: "Portugal (+351)" },
  { value: "+1-787", label: "Puerto Rico (+1-787)" },
  { value: "+974", label: "Qatar (+974)" },
  { value: "+262", label: "Réunion (+262)" },
  { value: "+40", label: "Romania (+40)" },
  { value: "+7", label: "Russia (+7)" },
  { value: "+250", label: "Rwanda (+250)" },
  { value: "+590", label: "Saint Barthélemy (+590)" },
  { value: "+290", label: "Saint Helena (+290)" },
  { value: "+1-869", label: "Saint Kitts and Nevis (+1-869)" },
  { value: "+1-758", label: "Saint Lucia (+1-758)" },
  { value: "+590", label: "Saint Martin (+590)" },
  { value: "+508", label: "Saint Pierre and Miquelon (+508)" },
  { value: "+1-784", label: "Saint Vincent and the Grenadines (+1-784)" },
  { value: "+685", label: "Samoa (+685)" },
  { value: "+378", label: "San Marino (+378)" },
  { value: "+239", label: "Sao Tome and Principe (+239)" },
  { value: "+966", label: "Saudi Arabia (+966)" },
  { value: "+221", label: "Senegal (+221)" },
  { value: "+381", label: "Serbia (+381)" },
  { value: "+248", label: "Seychelles (+248)" },
  { value: "+232", label: "Sierra Leone (+232)" },
  { value: "+65", label: "Singapore (+65)" },
  { value: "+1-721", label: "Sint Maarten (+1-721)" },
  { value: "+421", label: "Slovakia (+421)" },
  { value: "+386", label: "Slovenia (+386)" },
  { value: "+677", label: "Solomon Islands (+677)" },
  { value: "+252", label: "Somalia (+252)" },
  { value: "+27", label: "South Africa (+27)" },
  {
    value: "+500",
    label: "South Georgia and the South Sandwich Islands (+500)",
  },
  { value: "+211", label: "South Sudan (+211)" },
  { value: "+34", label: "Spain (+34)" },
  { value: "+94", label: "Sri Lanka (+94)" },
  { value: "+249", label: "Sudan (+249)" },
  { value: "+597", label: "Suriname (+597)" },
  { value: "+47", label: "Svalbard and Jan Mayen (+47)" },
  { value: "+268", label: "Eswatini (+268)" },
  { value: "+46", label: "Sweden (+46)" },
  { value: "+41", label: "Switzerland (+41)" },
  { value: "+963", label: "Syria (+963)" },
  { value: "+886", label: "Taiwan (+886)" },
  { value: "+992", label: "Tajikistan (+992)" },
  { value: "+255", label: "Tanzania (+255)" },
  { value: "+66", label: "Thailand (+66)" },
  { value: "+670", label: "Timor-Leste (+670)" },
  { value: "+228", label: "Togo (+228)" },
  { value: "+690", label: "Tokelau (+690)" },
  { value: "+676", label: "Tonga (+676)" },
  { value: "+1-868", label: "Trinidad and Tobago (+1-868)" },
  { value: "+216", label: "Tunisia (+216)" },
  { value: "+90", label: "Turkey (+90)" },
  { value: "+993", label: "Turkmenistan (+993)" },
  { value: "+1-649", label: "Turks and Caicos Islands (+1-649)" },
  { value: "+688", label: "Tuvalu (+688)" },
  { value: "+256", label: "Uganda (+256)" },
  { value: "+380", label: "Ukraine (+380)" },
  { value: "+971", label: "United Arab Emirates (+971)" },
  { value: "+44", label: "United Kingdom (+44)" },
  { value: "+1", label: "United States (+1)" },
  { value: "+1-808", label: "United States Minor Outlying Islands (+1-808)" },
  { value: "+598", label: "Uruguay (+598)" },
  { value: "+998", label: "Uzbekistan (+998)" },
  { value: "+678", label: "Vanuatu (+678)" },
  { value: "+58", label: "Venezuela (+58)" },
  { value: "+84", label: "Vietnam (+84)" },
  { value: "+1-284", label: "Virgin Islands, British (+1-284)" },
  { value: "+1-340", label: "Virgin Islands, U.S. (+1-340)" },
  { value: "+681", label: "Wallis and Futuna (+681)" },
  { value: "+212", label: "Western Sahara (+212)" },
  { value: "+967", label: "Yemen (+967)" },
  { value: "+260", label: "Zambia (+260)" },
  { value: "+263", label: "Zimbabwe (+263)" },
];
