import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/home/Header";
import Footer from "../components/Footer";
import SitemapMain from "../components/footers/SitemapMain";

const SiteMap = () => {
  return (
    <div>
      <Helmet>
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta charset="utf-8" />
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Immunocore Holdings plc - Recent News"
          href="https://www.immunocore.com/investors/news/press-releases/rss"
        />

        <title>Sitemap :: Immunocore Holdings plc (IMCR)</title>

        <link rel="canonical" href="https://www.immunocore.com/sitemap" />
        <meta property="og:url" content="https://www.immunocore.com/sitemap" />

        <meta property="og:site_name" content="Immunocore Holdings plc" />
        <meta property="og:title" content="Sitemap" />
        <meta property="og:type" content="website" />

        <meta
          property="og:image"
          content="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2281/20579/social_image_resized.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <link rel="preconnect" href="https://d1io3yog0oux5.cloudfront.net" />
        <link rel="preconnect" href="https://qmod.quotemedia.com" />

        <link
          rel="stylesheet"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/css/style.css"
        />

        <link
          rel="shortcut icon"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon.ico"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="manifest"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/manifest.json"
        />
        <link
          rel="mask-icon"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/safari-pinned-tab.svg"
        />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.datatables.net/1.13.7/css/jquery.dataTables.min.css"
        />
      </Helmet>

      <div class="general-wrapper">
        <Header />
        <header
          class="page-banner"
          style={{
            backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/pagebanner-default.jpg)`,
            backgroundPosition: "50% 50%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundColor: "#333",
          }}
        >
          <div class="container">
            <div class="row">
              <div class="col-lg-10">
                <h1>Sitemap</h1>
              </div>
            </div>
          </div>
        </header>
        <SitemapMain />
        <Footer />
      </div>
    </div>
  );
};

export default SiteMap;
