import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Science from "./pages/Science";
import PipelinePortfolio from "./pages/Pipeline-Portfolio";
import Careers from "./pages/Careers";
import Disclaimer from "./pages/Disclaimer";
import SiteMap from "./pages/SiteMap";
import Compliance from "./pages/Compliance";
import Privacy from "./pages/Privacy";
import Cookies from "./pages/Cookies";
import PaymentAndDIsclosure from "./pages/PaymentAndDIsclosure";
import ClinicalTrials from "./pages/ClinicalTrials";
import Mordern from "./pages/Mordern";
import ManagementTeam from "./pages/ManagementTeam";
import BoardOfDirectors from "./pages/BoardOfDirectors";
import OurTherapies from "./pages/OurTherapies";
import Investors from "./pages/Investors";
import PressBlog from "./pages/PressBlog";
import PressReleases from "./pages/PressReleases";
import Presentations from "./pages/Presentations";
import EmailContact from "./pages/EmailContact";
import Contact from "./pages/Contact";
import Events from "./pages/Events";
import EventPage from "./pages/EventPage";
import QuoteAndChart from "./pages/QuoteAndChart";
import AnalystCoverage from "./pages/AnalystCoverage";
import FinancialResults from "./pages/FinancialResults";
import SECFillings from "./pages/SECFillings";
import AnnualReports from "./pages/AnnualReports";
import FinancialEmail from "./pages/FinancialEmail";
import CorporateGovernance from "./pages/CorporateGovernance";
import GovernanceDocuments from "./pages/GovernanceDocuments";
import MangementTeam from "./pages/MangementTeam";
import BoardOfCommitee from "./pages/BoardOfCommitee";
import InvestorFaqs from "./pages/InvestorFaqs";
import IrEmail from "./pages/IrEmail";
import MainContact from "./pages/MainContact";
import OpenPositions from "./pages/OpenPositions";
import "./css/open_positions.css";
import PageNotFound from "./pages/PageNotFound";

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/science" element={<Science />} />
          <Route
            path="/pipeline-portfolio/pipeline"
            element={<PipelinePortfolio />}
          />
          <Route path="/pipeline-portfolio/" element={<PipelinePortfolio />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/sitemap" element={<SiteMap />} />
          <Route path="/compliance-and-transparency" element={<Compliance />} />
          <Route
            path="/privacy-and-social-media-guidelines"
            element={<Privacy />}
          />
          <Route path="/cookies" element={<Cookies />} />
          <Route
            path="/payments-and-disclosures"
            element={<PaymentAndDIsclosure />}
          />
          <Route path="/clinical-trials" element={<ClinicalTrials />} />
          <Route path="/modern-slavery-statement" element={<Mordern />} />
          <Route
            path="/about-us/management-team"
            element={<ManagementTeam />}
          />
          <Route
            path="/about-us/board-of-directors"
            element={<BoardOfDirectors />}
          />
          <Route
            path="/pipeline-portfolio/our-therapies"
            element={<OurTherapies />}
          />
          <Route path="/investors" element={<Investors />} />
          <Route
            path="/investors/news/press-releases/detail/:title"
            element={<PressBlog />}
          />
          <Route
            path="/investors/events-presentations/events/detail/:title"
            element={<EventPage />}
          />
          <Route
            path="/investors/news/press-releases"
            element={<PressReleases />}
          />
          <Route path="/investors/news" element={<PressReleases />} />
          <Route
            path="/investors/events-presentations/presentations"
            element={<Presentations />}
          />
          <Route
            path="/investors/news/email-alerts"
            element={<EmailContact />}
          />
          <Route
            path="/investors/ir-resources/contacts"
            element={<Contact />}
          />
          <Route
            path="/investors/events-presentations/events"
            element={<Events />}
          />
          <Route path="/investors/events-presentations" element={<Events />} />
          <Route
            path="/investors/stock-info/analyst-coverage"
            element={<AnalystCoverage />}
          />
          <Route
            path="/investors/financials-filings/financial-results"
            element={<FinancialResults />}
          />
          <Route
            path="/investors/financials-filings"
            element={<FinancialResults />}
          />
          <Route
            path="/investors/financials-filings/sec-filings"
            element={<SECFillings />}
          />
          <Route
            path="/investors/financials-filings/annual-reports"
            element={<AnnualReports />}
          />
          <Route
            path="/investors/financials-filings/email-alerts"
            element={<FinancialEmail />}
          />
          <Route
            path="/investors/corporate-governance"
            element={<CorporateGovernance />}
          />
          <Route
            path="/investors/corporate-governance/governance-documents"
            element={<GovernanceDocuments />}
          />
          <Route
            path="/investors/corporate-governance/management-team"
            element={<MangementTeam />}
          />
          <Route
            path="/investors/corporate-governance/board-committees"
            element={<BoardOfCommitee />}
          />
          <Route path="/investors/ir-resources" element={<InvestorFaqs />} />
          <Route
            path="/investors/ir-resources/investor-faqs"
            element={<InvestorFaqs />}
          />
          <Route
            path="/investors/ir-resources/email-alerts"
            element={<IrEmail />}
          />
          <Route path="/contact-us" element={<MainContact />} />
          <Route path="/careers/open-positions/" element={<OpenPositions />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/notfound" element={<PageNotFound />} />
          {/* <Route
            path="/stock-info/quote-and-chart"
            element={<QuoteAndChart />}
          />
          <Route path="/stock-info" element={<QuoteAndChart />} /> */}
        </Routes>
      </div>
    </Router>
  );
}
export default App;
