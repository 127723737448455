import React from "react";
import { Helmet } from "react-helmet";
import AnalystHeader from "../components/analystCoverage/AnalystHeader";
import AnalystSectionNav from "../components/analystCoverage/AnalystSectionNav";
import Footer from "../components/Footer";

const AnalystCoverage = () => {
  return (
    <div>
      <Helmet>
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta charset="utf-8" />
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Immunocore Holdings plc - Recent News"
          href="https://www.immunocore.com/investors/news/press-releases/rss"
        />

        <title>Analyst Coverage :: Immunocore Holdings plc (IMCR)</title>

        <link
          rel="canonical"
          href="https://www.immunocore.com/investors/stock-info/analyst-coverage"
        />
        <meta
          property="og:url"
          content="https://www.immunocore.com/investors/stock-info/analyst-coverage"
        />

        <meta property="og:site_name" content="Immunocore Holdings plc" />
        <meta property="og:title" content="Analyst Coverage" />
        <meta property="og:type" content="website" />

        <meta
          property="og:image"
          content="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2281/20579/social_image_resized.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <link rel="preconnect" href="https://d1io3yog0oux5.cloudfront.net" />
        <link rel="preconnect" href="https://qmod.quotemedia.com" />

        <link
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/css/ir.stockpr.css"
          rel="stylesheet"
          type="text/css"
          media="screen"
        />
        <link
          rel="stylesheet"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/css/style.css"
        />

        <link
          rel="shortcut icon"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon.ico"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="manifest"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/manifest.json"
        />
        <link
          rel="mask-icon"
          href="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/favicons/safari-pinned-tab.svg"
        />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.datatables.net/1.13.7/css/jquery.dataTables.min.css"
        />
      </Helmet>

      <div class="general-wrapper">
        <AnalystHeader />
        <header
          class="page-banner"
          style={{
            backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2370/21091/image_resized.jpg)`,
            backgroundPosition: "50% 50%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundColor: "#333",
          }}
        >
          <div class="container">
            <div class="row">
              <div class="col-lg-10">
                <h1>Analyst Coverage</h1>
              </div>
            </div>
          </div>
        </header>
        <AnalystSectionNav />
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <aside id="tertiaryNav">
                <nav id="tertiary-navigation">
                  <ul>
                    <li class="sidebar-title">
                      <h2>Stock Info</h2>
                    </li>
                    <li class="">
                      <a
                        href="/investors/stock-info/quote-and-chart"
                        target="_self"
                      >
                        Quote and Chart
                      </a>
                    </li>
                    <li class="">
                      <a
                        href="/investors/stock-info/historical-performance"
                        target="_self"
                      >
                        Historical Performance
                      </a>
                    </li>
                    <li class="active active-landing">
                      <a
                        href="/investors/stock-info/analyst-coverage"
                        target="_self"
                      >
                        Analyst Coverage
                      </a>
                    </li>
                  </ul>
                </nav>
              </aside>
              <main id="mainContent" class="main-content has-tertiary-nav">
                <table class="content-table">
                  <thead>
                    <tr>
                      <th>Firm</th>
                      <th>Analyst</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Barclays</td>
                      <td>Peter Lawson</td>
                    </tr>
                    <tr>
                      <td>BTIG</td>
                      <td>Justin Zelin</td>
                    </tr>
                    <tr>
                      <td>Cantor</td>
                      <td>Eric Schmidt</td>
                    </tr>
                    <tr>
                      <td>Capital One Securities</td>
                      <td>Naureen Quibria</td>
                    </tr>
                    <tr>
                      <td>Goldman Sachs</td>
                      <td>Rajan Sharma</td>
                    </tr>
                    <tr>
                      <td>Guggenheim Securities</td>
                      <td>Michael Schmidt</td>
                    </tr>
                    <tr>
                      <td>H.C. Wainwright &amp; Co.</td>
                      <td>Patrick Trucchio</td>
                    </tr>
                    <tr>
                      <td>Jefferies</td>
                      <td>Michael Yee</td>
                    </tr>
                    <tr>
                      <td>JP Morgan</td>
                      <td>Jessica Fye</td>
                    </tr>
                    <tr>
                      <td>Ladenburg Thalmann</td>
                      <td>Ahu Demir</td>
                    </tr>
                    <tr>
                      <td>Leerink Partners</td>
                      <td>Jonathan Chang</td>
                    </tr>
                    <tr>
                      <td>Mizuho Securities USA</td>
                      <td>Graig Suvannavejh</td>
                    </tr>
                    <tr>
                      <td>Morgan Stanley</td>
                      <td>Jeff Hung</td>
                    </tr>
                    <tr>
                      <td>Needham &amp; Company</td>
                      <td>Gil Blum</td>
                    </tr>
                    <tr>
                      <td>Oppenheimer &amp; Co.</td>
                      <td>Jeff Jones</td>
                    </tr>
                    <tr>
                      <td>RW Baird</td>
                      <td>Jack Allen</td>
                    </tr>
                    <tr>
                      <td>TD Cowen</td>
                      <td>Tyler Van Buren</td>
                    </tr>
                  </tbody>
                </table>

                <p>
                  Immunocore Holdings plc is followed by the analysts listed
                  above. Please note that any opinions, estimates or forecasts
                  regarding Immunocore Holdings plc's performance made by these
                  analysts are theirs alone and do not represent opinions,
                  forecasts or predictions of Immunocore Holdings plc or its
                  management. Immunocore Holdings plc does not by its reference
                  above or distribution imply its endorsement of or concurrence
                  with such information, conclusions or recommendations.
                </p>
              </main>
            </div>
          </div>
        </div>

        <div
          class="module-quick-links"
          role="navigation"
          aria-label="Helpful Investor Relations pages"
        >
          <div class="container">
            <ul>
              <li>
                <a href="/investors/news/email-alerts">
                  <span class="material-icons" aria-hidden="true">
                    email
                  </span>
                  Email Alerts
                </a>
              </li>
              <li>
                <a href="/investors/ir-resources/contacts" class="contacts">
                  <span class="material-icons" aria-hidden="true">
                    contact_page
                  </span>
                  Contacts
                </a>
              </li>
              <li>
                <a
                  href="https://www.immunocore.com/investors/news/press-releases/rss"
                  class="rss"
                  target="_blank"
                  rel="noopener"
                >
                  <span class="material-icons" aria-hidden="true">
                    rss_feed
                  </span>
                  RSS News Feed
                </a>
              </li>
              <li>
                <a href="/sitemap" class="sitemap">
                  <span class="material-icons" aria-hidden="true">
                    account_tree
                  </span>
                  Sitemap
                </a>
              </li>
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AnalystCoverage;
