import React from "react";

const AboutMain = () => {
  return (
    <main id="mainContent">
      <section
        id=""
        class="module row-default no-overlay no-dark no-padding-bottom about-us-section image-align image-align--right-alt background overlay dark"
        style={{
          backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21520/background_image.jpg)`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-center align-items-center flex-row-reverse">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21520/image.png"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <h2>
                      Focusing on transformative innovation that benefits
                      patients
                    </h2>
                    <p>
                      Immunocore is a commercial-stage biotechnology company
                      that discovered, developed and commercialized the world’s
                      first approved T cell receptor (TCR) therapy.
                    </p>
                    <p>
                      Our mission is to radically improve outcomes for patients
                      with cancer, infectious diseases, and autoimmune diseases,
                      by pioneering and delivering transformative
                      immunomodulating medicines.
                    </p>
                    <p>
                      Built on our proprietary ImmTAX platform, we have a
                      diverse pipeline of bispecific therapies across a broad
                      range of indications, including multiple cancers, HIV and
                      HBV.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id=""
        class="module row-default no-overlay no-dark bg-position-bt background overlay dark"
        style={{
          backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21521/background_image.jpg)`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21521/image.png"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <h2>Our science</h2>
                    <p>
                      Based on research from the University of Oxford in the
                      1990s, we have developed a wholly-proprietary technology
                      platform, called Immune Mobilizing Monoclonal TCRs Against
                      X disease (ImmTAX).
                    </p>
                    <p>
                      Our platform is modular, allowing us to upregulate the
                      immune system to tackle cancer (ImmTAC) or infectious
                      diseases (ImmTAV), and downregulate it to address
                      autoimmune conditions (ImmTAAI).
                    </p>
                    <p>
                      Based on this technology, in 2022 we launched the world’s
                      first TCR therapy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="module row-default row-two-col dark about-us background"
        style={{
          backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21522/background_image.jpg)`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-center align-items-start">
            <div class="col-lg-6">
              <div class="module-content primary-area">
                <div class="text-area">
                  <div class="text">
                    <h2>Leaders today, for tomorrow</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <p class="basic-p">
                      We have assembled a team of visionary leaders with some of
                      the best track records in R&amp;D to pioneer the next
                      generation of immunotherapies.
                    </p>
                    <div class="btn-wrapper">
                      <a href="/about-us/management-team" class="btn">
                        View our management team
                      </a>
                      <a href="/about-us/board-of-directors" class="btn">
                        View our board of directors
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id=""
        class="module row-default no-padding-top no-padding-bottom no-overlay no-dark module-padding image-align image-align--right background overlay dark background overlay dark"
        style={{
          backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21838/background_image.jpg)`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-center align-items-center flex-row-reverse">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21838/image.png"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <h2>Partnerships</h2>
                    <p>
                      We have a strong history of creating value through
                      partnerships with industry, academia and the charitable
                      sector. Our partners past and present include Genentech,
                      GSK, and the Bill &amp; Melinda Gates Foundation.
                    </p>
                    <p>
                      Current areas of partnering focus are combinations with
                      clinical stage assets that could significantly shift the
                      needle with respect to patient benefit, and technologies
                      that could enhance future iterations of our successful
                      proprietary ImmTAX platform.
                    </p>
                    <p>
                      Please direct any partnering inquiries to:
                      <a href="mailto:info@immunocore.us">info@immunocore.us</a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="module module-teasers module-stride">
        <div class="container">
          <div class="row justify-content-start align-items-center">
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text">
                  <h2>STRIDE</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text">
                  <p>
                    We strive to make STRIDEs for patients, so it’s only right
                    that our values support that. We all come to work every day
                    aligned to our mission, and with a passion to deliver
                    transformative medicines to patients.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row flex-row flex-5-col">
            <div class="col-lg teaser-0">
              <div class="content-box">
                <div class="text">
                  <figure class="teaser-img">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2415/21841/image_resized.png"
                      alt=""
                      role="presentation"
                    />
                  </figure>
                  <h3 class="title" data-mh="item-title">
                    We lead with science to benefit patients
                  </h3>
                  <p>
                    We work together to set clear priorities and objectives
                    aligned to the organization’s goals, creating an environment
                    maximizing individual contributions benefitting patients.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg teaser-1">
              <div class="content-box">
                <div class="text">
                  <figure class="teaser-img">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2415/21842/image_resized.png"
                      alt=""
                      role="presentation"
                    />
                  </figure>
                  <h3 class="title" data-mh="item-title">
                    We respect and trust each other
                  </h3>
                  <p>
                    We respect and trust each other with good intentions, and
                    are consistent and fair in how we treat others. We support
                    transparent and frequent communication.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg teaser-2">
              <div class="content-box">
                <div class="text">
                  <figure class="teaser-img">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2415/21843/image_resized.png"
                      alt=""
                      role="presentation"
                    />
                  </figure>
                  <h3 class="title" data-mh="item-title">
                    We act with integrity
                  </h3>
                  <p>
                    We act with integrity, and take responsibility for our
                    actions. We challenge actions or decisions we believe to be
                    wrong. We take decisions to improve the success of our
                    organization.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg teaser-3">
              <div class="content-box">
                <div class="text">
                  <figure class="teaser-img">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2415/21844/image_resized.png"
                      alt=""
                      role="presentation"
                    />
                  </figure>
                  <h3 class="title" data-mh="item-title">
                    We value diversity to drive innovation
                  </h3>
                  <p>
                    We value all views and opinions. Our success is dependent on
                    pushing boundaries, utilizing diverse skills and fostering
                    creative thinking.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg teaser-4">
              <div class="content-box">
                <div class="text">
                  <figure class="teaser-img">
                    <img
                      src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2415/21845/image_resized.png"
                      alt=""
                      role="presentation"
                    />
                  </figure>
                  <h3 class="title" data-mh="item-title">
                    We are entrepreneurial
                  </h3>
                  <p>
                    We are drawn to opportunities seeking out key areas of
                    improvement. We ask questions, voice opinions and take
                    initiative to get things done.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section
        id=""
        class="module row-default no-padding-top no-padding-bottom no-overlay no-dark image-align image-align--right-cut-fix background overlay dark"
        style={{
          backgroundImage: `url(https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21840/background_image.jpg)`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#333",
        }}
      >
        <div class="container">
          <div class="row justify-content-center align-items-center flex-row-reverse">
            <div class="col-lg-6">
              <figure class="module-image m-0">
                <img
                  src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/db/2369/21840/image.png"
                  alt=""
                />
              </figure>
            </div>
            <div class="col-lg-6">
              <div class="module-content">
                <div class="text-area">
                  <div class="text">
                    <h2>Pioneering immunotherapies, together</h2>
                    <p>
                      Join our team at the forefront of the next generation of
                      immunotherapy. We take our medicines through their entire
                      journey from the lab bench to the patient bedside, so
                      whether you’re in research, development or delivery, we
                      have the career for you.
                    </p>
                    <a href="/careers" class="btn">
                      {" "}
                      View our careers{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AboutMain;
