import React from "react";

const EventMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <aside id="tertiaryNav">
            <nav id="tertiary-navigation">
              <ul>
                <li class="sidebar-title">
                  <h2>Events &amp; Presentations</h2>
                </li>
                <li class="active active-landing">
                  <a
                    href="/investors/events-presentations/events"
                    target="_self"
                  >
                    Events
                  </a>
                </li>
                <li class="">
                  <a
                    href="/investors/events-presentations/presentations"
                    target="_self"
                  >
                    Presentations
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <main id="mainContent" class="main-content has-tertiary-nav">
            <h2>Upcoming Events</h2>

            <p>
              There are no upcoming events scheduled at this time.
              <a href="/investors/news/email-alerts">Sign up here</a> to receive
              notices about upcoming events and press releases.
            </p>

            <h2>Past Events</h2>

            <article class="media">
              <div class="media-body">
                <div class="date">
                  <time datetime="2024-06-06T14:30:00">
                    Jun 6, 2024 2:30 pm EDT
                  </time>
                </div>
                <h2 class="media-heading">
                  <a
                    href="/investors/events-presentations/events/detail/20240606-jefferies-global-healthcare-conference"
                    target="_self"
                  >
                    Jefferies Global Healthcare Conference
                  </a>
                </h2>
              </div>
            </article>
            <article class="media">
              <div class="media-body">
                <div class="date">
                  <time datetime="2024-05-31T19:15:00">
                    May 31, 2024 6:15 pm CDT
                  </time>
                </div>
                <h2 class="media-heading">
                  <a
                    href="/investors/events-presentations/events/detail/20240531-asco-2024"
                    target="_self"
                  >
                    ASCO 2024
                  </a>
                </h2>
              </div>
            </article>
            <article class="media">
              <div class="media-body">
                <div class="date">
                  <time datetime="2024-05-23T09:30:00">
                    May 23, 2024 9:30 am EDT
                  </time>
                </div>
                <h2 class="media-heading">
                  <a
                    href="/investors/events-presentations/events/detail/20240523-2024-annual-general-meeting"
                    target="_self"
                  >
                    2024 Annual General Meeting
                  </a>
                </h2>
              </div>
            </article>
            <article class="media">
              <div class="media-body">
                <div class="date">
                  <time datetime="2024-04-16T00:00:00">Apr 16, 2024</time>
                </div>
                <h2 class="media-heading">Kempen Life Sciences Conference</h2>
              </div>
            </article>
            <article class="media">
              <div class="media-body">
                <div class="date">
                  <time datetime="2024-04-15T16:00:00">
                    Apr 15, 2024 4:00 pm EDT
                  </time>
                </div>
                <h2 class="media-heading">Canaccord Horizons in Oncology</h2>
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>
  );
};

export default EventMain;
