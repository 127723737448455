import React from "react";

const AnnualReportMain = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <aside id="tertiaryNav">
            <nav id="tertiary-navigation">
              <ul>
                <li class="sidebar-title">
                  <h2>Financials &amp; Filings</h2>
                </li>
                <li class="">
                  <a
                    href="/investors/financials-filings/financial-results"
                    target="_self"
                  >
                    Financial Results
                  </a>
                </li>
                <li class="">
                  <a
                    href="/investors/financials-filings/sec-filings"
                    target="_self"
                  >
                    SEC Filings
                  </a>
                </li>
                <li class="active active-landing">
                  <a
                    href="/investors/financials-filings/annual-reports"
                    target="_self"
                  >
                    Annual Reports
                  </a>
                </li>
                <li class="">
                  <a
                    href="/investors/financials-filings/email-alerts"
                    target="_self"
                  >
                    Email Alerts
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <main id="mainContent" class="main-content has-tertiary-nav">
            <table class="content-table spr-ir-sec-filings">
              <thead>
                <tr>
                  <th class="spr-sec-date">Date</th>
                  <th class="spr-sec-form">Form</th>
                  <th class="spr-sec-desc">Description</th>
                  <th class="spr-sec-view-pdf">PDF</th>
                  <th class="spr-sec-view-docs">XBRL</th>
                  <th class="spr-sec-pages">Pages</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="spr-sec-date">04/12/24</td>
                  <td class="spr-sec-form">ARS</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-019632/ny20024279x4_ars.pdf"
                      class="doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form ARS: Annual report to security holders
                    </a>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/annual-reports/content/0001140361-24-019632/0001140361-24-019632.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of ARS filing published on Apr 12, 2024"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs"></td>
                  <td class="spr-sec-pages">184</td>
                </tr>
                <tr>
                  <td class="spr-sec-date">02/28/24</td>
                  <td class="spr-sec-form">10-K</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_10k.htm"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 10-K: Annual report pursuant to Section 13 and 15(d)
                    </a>
                    <div class="spr-expander">
                      <button
                        class="spr-sec-doc-list-head spr-toggle-expandable"
                        type="button"
                        aria-pressed="false"
                      >
                        02/28/24 10-K Documents
                        <span class="material-icons">expand_more</span>
                      </button>
                      <div class="spr-expandable">
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_ex10-12.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-357-0001140361-24-009921"
                        >
                          EX-10.2
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_ex10-13.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-357-0001140361-24-009921"
                        >
                          EX-10.13
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_ex10-14.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-357-0001140361-24-009921"
                        >
                          EX-10.14
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_ex21-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-357-0001140361-24-009921"
                        >
                          EX-21.1
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_ex23-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-357-0001140361-24-009921"
                        >
                          EX-23.1
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_ex23-2.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-357-0001140361-24-009921"
                        >
                          EX-23.2
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_ex31-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-357-0001140361-24-009921"
                        >
                          EX-31.1
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_ex31-2.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-357-0001140361-24-009921"
                        >
                          EX-31.2
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_ex32-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-357-0001140361-24-009921"
                        >
                          EX-32.1
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-24-009921/ef20015345_ex97-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-357-0001140361-24-009921"
                        >
                          EX-97.1
                        </a>
                        <br />
                      </div>
                    </div>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/annual-reports/content/0001140361-24-009921/0001140361-24-009921.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 10-K filing published on Feb 28, 2024"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/annual-reports/content/0001140361-24-009921/0001140361-24-009921-xbrl.zip"
                      aria-label="Download Raw XBRL files for 10-K filing published on Feb 28, 2024 "
                      title="Download Raw XBRL Files"
                      target="_blank"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-XBRL.svg"
                        alt="XAML"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-pages">233</td>
                </tr>
                <tr>
                  <td class="spr-sec-date">03/01/23</td>
                  <td class="spr-sec-form">20-F</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-23-009313/brhc10048769_20f.htm"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 20-F: Annual and transition report of foreign private
                      issuers pursuant to Section 13 or 15(d)
                    </a>
                    <div class="spr-expander">
                      <button
                        class="spr-sec-doc-list-head spr-toggle-expandable"
                        type="button"
                        aria-pressed="false"
                      >
                        03/01/23 20-F Documents
                        <span class="material-icons">expand_more</span>
                      </button>
                      <div class="spr-expandable">
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-23-009313/brhc10048769_ex12-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-234-0001140361-23-009313"
                        >
                          EX-12.1
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-23-009313/brhc10048769_ex12-2.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-234-0001140361-23-009313"
                        >
                          EX-12.2
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-23-009313/brhc10048769_ex13-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-234-0001140361-23-009313"
                        >
                          EX-13.1
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-23-009313/brhc10048769_ex15-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-234-0001140361-23-009313"
                        >
                          EX-15.1
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-23-009313/brhc10048769_ex16-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-234-0001140361-23-009313"
                        >
                          EX-16.1
                        </a>
                        <br />
                      </div>
                    </div>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/annual-reports/content/0001140361-23-009313/0001140361-23-009313.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 20-F filing published on Mar 1, 2023"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/annual-reports/content/0001140361-23-009313/0001140361-23-009313-xbrl.zip"
                      aria-label="Download Raw XBRL files for 20-F filing published on Mar 1, 2023 "
                      title="Download Raw XBRL Files"
                      target="_blank"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-XBRL.svg"
                        alt="XAML"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-pages">198</td>
                </tr>
                <tr>
                  <td class="spr-sec-date">03/03/22</td>
                  <td class="spr-sec-form">20-F</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/brhc10034614_20f.htm"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 20-F: Annual and transition report of foreign private
                      issuers pursuant to Section 13 or 15(d)
                    </a>
                    <div class="spr-expander">
                      <button
                        class="spr-sec-doc-list-head spr-toggle-expandable"
                        type="button"
                        aria-pressed="false"
                      >
                        03/03/22 20-F Documents
                        <span class="material-icons">expand_more</span>
                      </button>
                      <div class="spr-expandable">
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/brhc10034614_ex4-21.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-140-0001140361-22-007578"
                        >
                          EX-4.21
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/brhc10034614_ex4-22.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-140-0001140361-22-007578"
                        >
                          EX-4.22
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/brhc10034614_ex4-23.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-140-0001140361-22-007578"
                        >
                          EX-4.23
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/brhc10034614_ex4-24.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-140-0001140361-22-007578"
                        >
                          EX-4.24
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/brhc10034614_ex12-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-140-0001140361-22-007578"
                        >
                          EX-12.1
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/brhc10034614_ex12-2.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-140-0001140361-22-007578"
                        >
                          EX-12.2
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/brhc10034614_ex13-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-140-0001140361-22-007578"
                        >
                          EX-13.1
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-22-007578/brhc10034614_ex15-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-140-0001140361-22-007578"
                        >
                          EX-15.1
                        </a>
                        <br />
                      </div>
                    </div>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/annual-reports/content/0001140361-22-007578/0001140361-22-007578.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 20-F filing published on Mar 3, 2022"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/annual-reports/content/0001140361-22-007578/0001140361-22-007578-xbrl.zip"
                      aria-label="Download Raw XBRL files for 20-F filing published on Mar 3, 2022 "
                      title="Download Raw XBRL Files"
                      target="_blank"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-XBRL.svg"
                        alt="XAML"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-pages">249</td>
                </tr>
                <tr>
                  <td class="spr-sec-date">03/25/21</td>
                  <td class="spr-sec-form">20-F</td>
                  <td class="spr-sec-desc">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/brhc10022122_20f.htm"
                      class="fancybox fancybox.iframe doc-title"
                      target="_blank"
                      rel="noopener"
                    >
                      Form 20-F: Annual and transition report of foreign private
                      issuers pursuant to Section 13 or 15(d)
                    </a>
                    <div class="spr-expander">
                      <button
                        class="spr-sec-doc-list-head spr-toggle-expandable"
                        type="button"
                        aria-pressed="false"
                      >
                        03/25/21 20-F Documents
                        <span class="material-icons">expand_more</span>
                      </button>
                      <div class="spr-expandable">
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/brhc10022122_ex1-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-99-0001140361-21-009904"
                        >
                          EX-1.1
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/brhc10022122_ex2-2.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-99-0001140361-21-009904"
                        >
                          EX-2.2
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/brhc10022122_ex2-4.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-99-0001140361-21-009904"
                        >
                          EX-2.4
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/brhc10022122_ex4-20.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-99-0001140361-21-009904"
                        >
                          EX-4.20
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/brhc10022122_ex8-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-99-0001140361-21-009904"
                        >
                          EX-8.1
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/brhc10022122_ex12-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-99-0001140361-21-009904"
                        >
                          EX-12.1
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/brhc10022122_ex12-2.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-99-0001140361-21-009904"
                        >
                          EX-12.2
                        </a>
                        <br />
                        <a
                          href="https://www.immunocore.com/investors/financials-filings/sec-filings/content/0001140361-21-009904/brhc10022122_ex13-1.htm"
                          class="fancybox fancybox.iframe"
                          data-fancybox="#document-99-0001140361-21-009904"
                        >
                          EX-13.1
                        </a>
                        <br />
                      </div>
                    </div>
                  </td>
                  <td class="spr-sec-view-pdf">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/annual-reports/content/0001140361-21-009904/0001140361-21-009904.pdf"
                      target="_blank"
                      title="PDF"
                      aria-label="Download PDF of 20-F filing published on Mar 25, 2021"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-PDF.svg"
                        alt="PDF"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-view-docs">
                    <a
                      href="https://www.immunocore.com/investors/financials-filings/annual-reports/content/0001140361-21-009904/0001140361-21-009904-xbrl.zip"
                      aria-label="Download Raw XBRL files for 20-F filing published on Mar 25, 2021 "
                      title="Download Raw XBRL Files"
                      target="_blank"
                    >
                      <img
                        src="https://d1io3yog0oux5.cloudfront.net/_b06498f58f97f2d31ba6131bc6c93849/immunocore/files/theme/images/icon-XBRL.svg"
                        alt="XAML"
                      />
                    </a>
                  </td>
                  <td class="spr-sec-pages">497</td>
                </tr>
              </tbody>
            </table>

            <div class="rss-link">
              <a
                href="https://www.immunocore.com/investors/financials-filings/annual-reports/rss"
                class="link--icon"
                target="_blank"
                rel="noopener"
              >
                <span class="material-icons">rss_feed</span> Filing RSS
              </a>
            </div>

            <div class="pagination-wrapper">
              <div id="pagination--mobile" class="mt-3 d-md-none"></div>
            </div>
            <div class="clear"></div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default AnnualReportMain;
